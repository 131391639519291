import React, { useState } from "react";
import { useNavigate , useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import SupplierNavs from "../SupplierNavs"
import http from "../../../../utils/http";
const AddContactDetails = () => {
  const {supplier_id}  = useParams();
  console.log(supplier_id);
  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please select name"),
    email: Yup.string().required("Please select email"),
    mobile: Yup.number().required("Please select mobile"),
    whatsapp: Yup.number().required("Please select whatsapp"),
    designation: Yup.string().required("Please select designation"),
    
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  const getFetchSupplier = () => {
    http.get(`get_supplier_by_id/${supplier_id}`)
      .then((res) => {
        setValue("name", res.data.contact_name)
        setValue("email", res.data.contact_email)
        setValue("mobile", res.data.contact_mobile)
        setValue("whatsapp", res.data.whatsapp)
        setValue("designation", res.data.designation)
        
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  let editCase = (supplier_id) ? 1 : 0;
  if (editCase == 1) {
    getFetchSupplier();
  }


  function onSubmit(data) {
    setLoading(true)
    console.log(data);
    http
      .put(`save_supplier_contact_details/${supplier_id}`, data)
      // .put(`http://192.168.1.222:8080/admin/save_supplier_contact_details/${supplier_id}`, data)
      .then((response) => {
        setLoading(false)
      reset()
      navigate(`/supplier-management/add-additional-details/${supplier_id}`)
      })
      .catch((error) => {
        setLoading(false)
        setMessage("Something went wrong");
      });
  }
  return (
    <>
      <div className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message === '' && "d-none"}`}>{message}</div>
      {/* <Header />
      <SideBar /> */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                <SupplierNavs supplier_id={supplier_id} />
                    <div className="card-body">
                      <div className="row">
                        
                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Name"
                              {...register("name")}
                            />
                            <div
                              className={`invalid-feedback ${errors.name ? "d-block" : ""
                                }`}
                            >
                              {errors.name
                                ? errors.name.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Email
                            </label>
                            <input
                              className="form-control"
                              name="email"
                              placeholder="Email"
                              {...register("email")}
                            />
                            <div
                              className={`invalid-feedback ${errors.email ? "d-block" : ""
                                }`}
                            >
                              {errors.email
                                ? errors.email.message
                                : ""}
                            </div>
                          </div>
                        </div>


                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Mobile
                            </label>
                            <input
                              className="form-control"
                              name="mobile"
                              placeholder="Mobile"
                              {...register("mobile")}
                            />

                            <div
                              className={`invalid-feedback ${errors.mobile ? "d-block" : ""
                                }`}
                            >
                              {errors.mobile
                                ? errors.mobile.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Whatsapp
                            </label>
                            <input
                              className="form-control"
                              name="whatsapp"
                              placeholder="Whatsapp"
                              {...register("whatsapp")}
                            />
                            <div
                              className={`invalid-feedback ${errors.whatsapp ? "d-block" : ""
                                }`}
                            >
                              {errors.whatsapp
                                ? errors.whatsapp.message
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Designation
                            </label>
                            <input
                              className="form-control"
                              name="designation"
                              placeholder="Designation"
                              {...register("designation")}
                            />
                            <div
                              className={`invalid-feedback ${errors.designation ? "d-block" : ""
                                }`}
                            >
                              {errors.designation
                                ? errors.designation.message
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit" className="btn btn-danger">{loading ? "Loading..." : "Save"}</button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContactDetails;