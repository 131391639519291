import React  from "react";
import Flatpickr from "react-flatpickr";
// import ReactFlatpickr from "../../../../shared/ReactFlatpickr";

export default function ReactFlatpickr(props) {
  
  return (
    <Flatpickr
    className="form-control"
    data-date-format="d/m/Y"
      options={props.options}
      value={props.value}
      placeholder={props.placeholder}
    />
  );
}
