import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import axios from "axios";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";

export const Webpage = () => {
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);

  const [userList, setuserList] = useState([]);

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    http
      .get("get_webpage")
      .then((res) => {
        console.log(res.data);
        setuserList(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete webpage?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_webpage_by_id/${id}`)
      .then((response) => {
        toast.success("Webpage deleted");
        setDeleteMessage("User has been deleted");
        fetchdata();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  //   const [error, setError] = useState(null);

  //   const showClauseForm = (id) => {
  //     setUserId(id);

  //     console.log(id);
  //     // setDeleteMessage(`Are you sure you want to delete the vegetable`);
  //     setshowClauseModal(true);
  //   };

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  //   const handleEdit = (idd) => {
  //     setType("Edit");
  //     setUserId(idd);
  //     setshowClauseModal(true);
  //   };

  // Loader Code
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  // Loader code end

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />

            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex  justify-content-between">
                      <h4 className="card-title mb-0">Webpage List</h4>
                      <Link
                        // onClick={() => showClauseForm()}
                        to="/webpage/add-webpage"
                        className="btn btn-primary add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Add
                        Webpage
                      </Link>
                    </div>

                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          {/* <div className="col-sm-auto">
                            <div></div>
                          </div> */}
                          <div className="col-sm">
                            <div className="d-flex  justify-content-between ">
                              <div className="search-box ">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Search..."
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                              <button
                                type="button"
                                className="btn btn-secondary"
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th>
                                <th data-sort="customer_name">#</th>
                                <th data-sort="customer_name">Title</th>
                                <th data-sort="email">Indate</th>
                                <th data-sort="date">Created by</th>
                                <th data-sort="status">Status</th>
                                <th data-sort="action">Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {userList.map((user, index) => (
                                <tr key={index}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>
                                  <td className="customer_name">{index + 1}</td>
                                  <td className="email">{user.title}</td>
                                  <td className="email">{user.indate}</td>
                                  <td className="date">{user.indate}</td>
                                  <td className="status">
                                    <span className="badge bg-success-subtle text-success text-uppercase">
                                      {user.status == 1
                                        ? "Active"
                                        : "In-active"}
                                    </span>
                                  </td>

                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <Link
                                          className="ri-edit-2-fill fs-4"
                                          to={`/webpage/edit-webpage/${user.id}`}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          className="ri-delete-bin-6-line fs-4"
                                          onClick={() =>
                                            showDeleteModal("User", user.id)
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};