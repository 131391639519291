import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import http from "../../../utils/http";

export const AddMasterBrandName = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  const yupValidation = Yup.object().shape({
    brand_name: Yup.string().required("Please enter name"),
    status: Yup.string().required("Please enter status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const [File, setFile] = useState("");
  const { errors } = formState;

  function onSubmit(data) {
    console.log(data);
    setLoading(true);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    http
      .post("save_brand", data, config)
      // .post(`http://192.168.1.177:8080/admin/save_brand`,data,config)
      .then((response) => {
        console.log(response);

        setLoading(false);
        reset();
        hideModal();
        refreshPage();
        reset();
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Master Brand Name</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 ${resss !== "" && ""}`}>
            <div className="col-lg-6">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  Name
                </label>
                <input
                  name="brand_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  {...register("brand_name")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${
                    errors.brand_name ? "d-block" : ""
                  }`}
                >
                  {errors.brand_name ? errors.brand_name.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="assignedtoName-field" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  className="form-select"
                  {...register("status")}
                >
                  <option value="">Select status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.status ? "d-block" : ""
                  }`}
                >
                  {errors.status ? errors.status.message : ""}
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              <label className="form-label" htmlFor="project-title-input">
                File
              </label>
              <input
                type="file"
                className="form-control"
                name="image"
                onChange={(e) => {
                  setValue("image", e.target.files[0]);
                  setFile(URL.createObjectURL(e.target.files[0]));
                }}
                placeholder="Enter display order"
              />
              <div
                className={`invalid-feedback ${errors.image ? "d-block" : ""}`}
              >
                {errors.image ? errors.image.message : ""}
              </div>
              {File !== "" && (
                <div
                  className=" d-flex align-items-center overflow-hidden flex-column mt-2"
                  style={{ width: "40%" }}
                >
                  <img src={File} alt="" style={{ width: "100%" }} />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit">
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
