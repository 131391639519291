import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../../shared/Loader";
import http from "../../../../../utils/http";
import logo from "../../../../assets/images/ING-logo.png";

export const OrderInvoice = () => {
  const { id } = useParams();
  const [order_item, setorder_item] = useState([]);
  const [order_list, setorder_list] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    fetchOrderbyId();
  };

  const fetchOrderbyId = () => {
    setLoading(true);
    http
      .get(process.env.REACT_APP_API_BASE + "get_order_by_id/" + id)
      .then((res) => {
        console.log(res.data);
        setorder_list([res.data]);
        setorder_item(res.data.order_items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const yupValidation = Yup.object().shape({
    // name: Yup.string().required("Please enter attribute name"),
    // value: Yup.string().required("Please enter master values"),
    // attribute_group_id: Yup.string().required("Please select attribute group"),
    // // type: Yup.string().required("Please select type"),
    // status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  const orderStatus = [
    {
      class: "badge bg-primary d-flex align-items-center mb-0 ms-1",
      name: "Placed",
    },
    {
      class: "badge bg-warning d-flex align-items-center mb-0 ms-1",
      name: "Ready for Dispatch",
    },
    {
      class: "badge bg-success d-flex align-items-center mb-0 ms-1",
      name: "Shipped",
    },
    {
      class: "badge bg-danger d-flex align-items-center mb-0 ms-1",
      name: "Cancelled",
    },
  ];

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />
            {loading === false && (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Order Invoice</h4>
                    </div>
                  </div>
                </div>
                {order_list.map((order, index) => {
                  return (
                    <div className="row justify-content-center">
                      <div className="col-xxl-9">
                        <div className="card" id="demo">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card-header border-bottom-dashed p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <img
                                      src={logo}
                                      className="card-logo card-logo-dark"
                                      alt="logo dark"
                                      height="85"
                                    />
                                    <img
                                      src={logo}
                                      className="card-logo card-logo-light"
                                      alt="logo light"
                                      height="85"
                                    />
                                    <div className="mt-sm-5 mt-4">
                                      <h6 className="text-muted text-uppercase fw-semibold">
                                        Address
                                      </h6>
                                      <p
                                        className="text-muted mb-1"
                                        id="address-details"
                                      >
                                        Logix Technova, Ground Floor-031 <br />
                                        Tower B, Sector 132, Noida
                                      </p>
                                      <p
                                        className="text-muted mb-0"
                                        id="zip-code"
                                      >
                                        <span>Zip-code:</span> 201304
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 mt-sm-0 mt-3">
                                    <h6>
                                      <span className="text-muted fw-normal">
                                        Legal Registration No:
                                      </span>
                                      <span id="legal-register-no">987654</span>
                                    </h6>
                                    <h6>
                                      <span className="text-muted fw-normal">
                                        Email:
                                      </span>
                                      <span id="email">igenyria@mart.com</span>
                                    </h6>
                                    <h6>
                                      <span className="text-muted fw-normal">
                                        Website:
                                      </span>{" "}
                                      <Link
                                        to="https://woodensole.com/"
                                        className="link-primary"
                                        target="_blank"
                                        id="website"
                                      >
                                        www.woodensole.com
                                      </Link>
                                    </h6>
                                    <h6 className="mb-0">
                                      <span className="text-muted fw-normal">
                                        Contact No:{" "}
                                      </span>
                                      <span id="contact-no">
                                        {" "}
                                        +(01) 234 6789
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              {/* <!--end card-header--> */}
                            </div>
                            {/* <!--end col--> */}
                            <div className="col-lg-12">
                              <div className="card-body p-4">
                                <div className="row g-3">
                                  <div className="col-lg-3 col-6">
                                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                                      Invoice No
                                    </p>
                                    <h5 className="fs-14 mb-0">
                                      #VL<span id="invoice-no">25000355</span>
                                    </h5>
                                  </div>
                                  {/* <!--end col--> */}
                                  <div className="col-lg-3 col-6">
                                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                                      Date
                                    </p>
                                    <h5 className="fs-14 mb-0">
                                      <span id="invoice-date">
                                        23 Nov, 2021
                                      </span>{" "}
                                      <small
                                        className="text-muted"
                                        id="invoice-time"
                                      >
                                        02:36PM
                                      </small>
                                    </h5>
                                  </div>
                                  {/* <!--end col--> */}
                                  <div className="col-lg-3 col-6">
                                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                                      Payment Status
                                    </p>
                                    <span
                                      className="badge bg-success-subtle text-success fs-11"
                                      id="payment-status"
                                    >
                                      Paid
                                    </span>
                                  </div>
                                  {/* <!--end col--> */}
                                  <div className="col-lg-3 col-6">
                                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                                      Total Amount
                                    </p>
                                    <h5 className="fs-14 mb-0">
                                      ₹
                                      <span id="total-amount">
                                        {order.total_amount}
                                      </span>
                                    </h5>
                                  </div>
                                  {/* <!--end col--> */}
                                </div>
                                {/* <!--end row--> */}
                              </div>
                              {/* <!--end card-body--> */}
                            </div>
                            {/* <!--end col--> */}
                            <div className="col-lg-12">
                              <div className="card-body p-4 border-top border-top-dashed">
                                <div className="row g-3">
                                  <div className="col-6">
                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                      Billing Address
                                    </h6>
                                    <p
                                      className="fw-medium mb-2"
                                      id="billing-name"
                                    >
                                      {order.billing_name}
                                    </p>
                                    <p
                                      className="text-muted mb-1"
                                      id="billing-address-line-1"
                                    >
                                      {order.billing_address},{" "}
                                      {order.billing_city}
                                      <br /> {order.billing_state} ,
                                      {order.billing_country}
                                    </p>
                                    <p className="text-muted mb-1">
                                      <span>Phone: +</span>
                                      <span id="billing-phone-no">
                                        (123) 456-7890
                                      </span>
                                    </p>
                                  </div>
                                  {/* <!--end col--> */}
                                  <div className="col-6">
                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                      Shipping Address
                                    </h6>
                                    <p
                                      className="fw-medium mb-2"
                                      id="shipping-name"
                                    >
                                      {order.shipping_name}
                                    </p>
                                    <p
                                      className="text-muted mb-1"
                                      id="shipping-address-line-1"
                                    >
                                      {order.shipping_address},{" "}
                                      {order.shipping_city}
                                      <br /> {order.shipping_state},{" "}
                                      {order.shipping_country}
                                    </p>
                                    <p className="text-muted mb-1">
                                      <span>Phone: +</span>
                                      <span id="shipping-phone-no">
                                        (123) 456-7890
                                      </span>
                                    </p>
                                  </div>
                                  {/* <!--end col--> */}
                                </div>
                                {/* <!--end row--> */}
                              </div>
                              {/* <!--end card-body--> */}
                            </div>
                            {/* <!--end col--> */}
                            <div className="col-lg-12">
                              <div className="card-body p-4">
                                <div className="table-responsive">
                                  {/* <table className="table table-borderless text-center table-nowrap align-middle mb-0"> */}
                                  <table className="table table-nowrap align-middle table-borderless mb-0">
                                    <thead>
                                      <tr className="table-active">
                                        <th scope="col">Product Name</th>
                                        <th scope="col">UOM</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Sales Price</th>
                                        <th scope="col">HSN</th>
                                        <th scope="col">Tax (%)</th>
                                        <th scope="col" className="text-end">
                                          Taxable Amt
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {order_item.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{item.product_name}</td>
                                            <td>{item.uom}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.price}</td>
                                            <td>{item.hsn}</td>
                                            <td>{item.tax_rate}</td>
                                            <td className="text-end">
                                              {item.total_price}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      <tr className="border-top border-top-dashed">
                                        <td colspan="5"></td>
                                        <td
                                          colspan="2"
                                          className="fw-medium p-0"
                                        >
                                          <table className="table table-borderless mb-0">
                                            <tbody>
                                              <tr>
                                                <td>Sub Total :</td>
                                                <td className="text-end">
                                                  ₹{order.taxable_amount}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  Discount{" "}
                                                  <span className="text-muted">
                                                    (HOLI24)
                                                  </span>{" "}
                                                  : :
                                                </td>
                                                <td className="text-end">
                                                  -₹53.99
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Shipping Charge :</td>
                                                <td className="text-end">
                                                  ₹65.00
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Estimated Tax :</td>
                                                <td className="text-end">
                                                  ₹{order.igst}
                                                </td>
                                              </tr>
                                              <tr className="border-top border-top-dashed fs-5">
                                                <th scope="row">Total :</th>
                                                <th className="text-end">
                                                  ₹{order.total_amount}
                                                </th>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* <!--end table--> */}
                                </div>
                                {/* <div className="mt-3">
                                  <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                    Payment Details:
                                  </h6>
                                  <p className="text-muted mb-1">
                                    Payment Method:{" "}
                                    <span
                                      className="fw-medium"
                                      id="payment-method"
                                    >
                                      Mastercard
                                    </span>
                                  </p>
                                  <p className="text-muted mb-1">
                                    Card Holder:{" "}
                                    <span
                                      className="fw-medium"
                                      id="card-holder-name"
                                    >
                                      David Nichols
                                    </span>
                                  </p>
                                  <p className="text-muted mb-1">
                                    Card Number:{" "}
                                    <span
                                      className="fw-medium"
                                      id="card-number"
                                    >
                                      xxx xxxx xxxx 1234
                                    </span>
                                  </p>
                                  <p className="text-muted">
                                    Total Amount:{" "}
                                    <span className="fw-medium" id="">
                                      ₹{" "}
                                    </span>
                                    <span id="card-total-amount">{order.total_amount}</span>
                                  </p>
                                </div>
                                <div className="mt-4">
                                  <div className="alert alert-info">
                                    <p className="mb-0">
                                      <span className="fw-semibold">
                                        NOTES:
                                      </span>
                                      <span id="note">
                                        All accounts are to be paid within 7
                                        days from receipt of invoice. To be paid
                                        by cheque or credit card or direct
                                        payment online. If account is not paid
                                        within 7 days the credits details
                                        supplied as confirmation of work
                                        undertaken will be charged the agreed
                                        quoted fee noted above.
                                      </span>
                                    </p>
                                  </div>
                                </div> */}
                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                  <a
                                    href="javascript:window.print()"
                                    className="btn btn-success"
                                  >
                                    <i className="ri-printer-line align-bottom me-1"></i>{" "}
                                    Print
                                  </a>
                                  <a
                                    href="javascript:void(0);"
                                    className="btn btn-primary"
                                  >
                                    <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                                    Download
                                  </a>
                                </div>
                              </div>
                              {/* <!--end card-body--> */}
                            </div>
                            {/* <!--end col--> */}
                          </div>
                          {/* <!--end row--> */}
                        </div>
                        {/* <!--end card--> */}
                      </div>
                      {/* <!--end col--> */}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
