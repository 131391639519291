import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import SideBar from "../../common/SideBar";
const SmfMatrixMapping = () => {
  const [standards, setStandard] = useState([]);
  const [clauses,setClauses] = useState([]);
  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE + "standard")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setStandard(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    }, []);

  const getClause = (e) => {
    fetch(process.env.REACT_APP_API_BASE + `clause_standard/${e.target.value}`)
    .then((response) => response.json())
    .then((data) => {
      setClauses(data);
    })
    .catch((err) => {});
  };

  return (
    <>
      <Header />
      <SideBar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">
                      Matrix Mapping - SMF to SMF
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row g-2">
                      <div className="col-xl-2 col-md-3">
                        <select
                          onChange={(e) => getClause(e)}
                          type="text"
                          className="form-select search"
                          name="standard"
                        >
                          <option>Select</option>
                          {standards.map((stndrd, index) => (
                            <option value={stndrd._id}>{stndrd.standard_name}</option>
                          ))}
                        </select>
                      </div>
                    </div>


                    <div className="row g-2">
                      <div className="col-xl-2 col-md-3">
                        
                          
                          {clauses.map((stndrd, index) => (
                            
                            <input type="checkbox" />
                          ))}
                       
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default SmfMatrixMapping;
