import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import CustomerDropdown from "../Customer/CustomerDropdown";
import http from "../../../../../utils/http";
import { AddCustomerModal } from "../Customer/AddCustomer";
import BillingAddDrop from "./BillingAddDrop";
import { CustomerAddressModal } from "./CustomerAddressModal";
import ReactFlatpickr from "../../../../shared/ReactFlatpickr";
import DeliveryAddDrop from "./DeliveryAddDrop";
import { AddOrderCustomer } from "./AddOrderCustomer";
import Loader from "../../../../shared/Loader";

export const OrderForm = () => {
  // const [Cust_List, setCust_List] = useState([]);
 
  const [Refresh, setRefresh] = useState(false);
  const [temp_customer_id, settemp_customer_id] = useState("");
  console.log("temp_customer_id" , temp_customer_id);

  const [billingObjData, setbillingObjData] = useState({});
  console.log("billingObjData",billingObjData);



  useEffect(() => {
    refresh();
  }, []);
  // {gettemp_billing_id ?  setValue("billing_address_id",billingObjData) :""}

  // console.log("sdasdasdasdasdasd",setValue("billing_address_id",billingObjData));

  const refresh = () => {
    fetchOrderNo();
  };

  const fetchOrderNo = () => {
    http
      .get("generate_order_no")
      .then((res) => {
        console.log(res.data);
        setValue("order_no", res.data.order_no);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [CustomerId, setCustomerId] = useState("");
  const [CustAdd_List, setCustAdd_List] = useState([]);
  const getCustomerID = (e) => {
    console.log(e);
    let id = e.value;
    console.log(id);
    setForm(true);
    setCustomerId(id);
    setValue("customer_id", id);
    setValue("customer_name", e);
  };

  const [BillAdd, setBillAdd] = useState([]);
  console.log("BillAdd................" , BillAdd);
  const [ShipAdd, setShipAdd] = useState([]);
 
    
    const BillAddchange = (e) => {
      console.log("Click" , e);
      setValue("billing_address_id", gettemp_billing_id);
      setBillAdd("");
      console.log(e);
      if (e.value == "create_new") {
        setTempBillingId(true)
        console.log("modal"  ,gettemp_billing_id);
        showCustAddForm(CustomerId);
      } 
       else {
        setBillAdd(e);
        setGetTempBillingId(e.value)
        setValue("billing_address_id", e.value);
      }
    };

  const ShipAddchange = (e) => {
    setValue("shipping_address_id", "");
    setShipAdd("");
    console.log(e);
    if (e.value == "create_new") {
      console.log("modal");
      showCustAddForm(CustomerId);
      setValue("shipping_address_id", e.value);
    } else {
      setShipAdd(e);
      setGetTempDelivaryId(e.value)
      setValue("shipping_address_id", e.value);
    }
  };

  function refreshBillAddchange () {
    console.log("CallAgainBillAddChange" , );
  }

  console.log(CustAdd_List);

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [loading, setLoading] = useState(false);
   const [message, setMessage] = useState("");
   const [temp_address_id, setTempAddressId] = useState("");
   const [temp_billing_id, setTempBillingId] = useState(false);
   const [gettemp_billing_id, setGetTempBillingId] = useState("");
   console.log(gettemp_billing_id);
   const [temp_delivary_id, setTempDelivaryId] = useState(false);
   const [gettemp_delivary_id, setGetTempDelivaryId] = useState("");
 

  const yupValidation = Yup.object().shape({
    // customer_id: Yup.string().required("Please enter attribute name"),
    // order_no: Yup.string().required("Please enter master values"),
    order_date: Yup.string().required("Please select order date"),
    billing_address_id: Yup.string().required("Please select Billing address"),
    shipping_address_id: Yup.string().required(
      "Please select Shipping address"
    ),
    // remarks: Yup.string().required("Please enter remarks"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);


  useEffect(() => {
   if(billingObjData){
    setBillAdd(billingObjData.id)
   }
   if(temp_customer_id){

   }
  }, []);

  const { errors } = formState;

  let date = new Date();
  let finaldate = [
    date.getFullYear(),
    date.getMonth() <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
    date.getDate() <= 9 ? "0" + date.getDate() : date.getDate(),
  ].join("-");
  console.log(finaldate);

  

  const DateChange = (e) => {
    console.log(e.target.value);
    setOrderDate(e.target.value);
  };

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    console.log("Billing", BillAdd);
   
    console.log(data);
    setBtnloading(true);
    http
      .post(`save_order`, data)
      .then((res) => {
        console.log(res.data.order_id);
        toast.success("Order has been created");
        let id = res.data.order_id;
        const timer = setTimeout(() => {
          setBtnloading(false);
          navigate(`/order/add-items/${id}`);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const [Form, setForm] = useState(false);

  // Add customer modal
  // const [id, setId] = useState(null);
  const [user_id, setUserId] = useState("");
  const [showAddModal, setshowAddModal] = useState(false);

  const showClauseForm = (id) => {
    setUserId(id);
    setshowAddModal(true);
  };

  // Hide the modal
  const hideAddModal = () => {
    setshowAddModal(false);
  };

  // // Add customer address modal
  const [CustAddModal, setCustAddModal] = useState(false);

  const showCustAddForm = (id) => {
    setCustAddModal(true);
  };

  // Hide the modal
  const hideCustAddForm = () => {
    setCustAddModal(false);
  };

  const [OrderDate, setOrderDate] = useState("");
  const order_Date = {
    onChange: (selectedDates, dateStr, instance) => {
      // console.log(dateStr)
      setValue("order_date", dateStr);
      setOrderDate(dateStr);
    },
  };

  return (
    <>
      <div
        className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message ===
          "" && "d-none"}`}
      >
        {message}
      </div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Create Order</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <div className="col-lg-11">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Customer
                      </label>
                      <CustomerDropdown
                        placeholder="Select Customer"
                        value={getValues("customer_name")}
                        change={(e) => getCustomerID(e)}
                        refresh={Refresh}
                        temp_customer_id ={temp_customer_id}
                        defaultvalue = {temp_customer_id ? setValue("customer_id" , temp_customer_id) : ""}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.customer_id ? "d-block" : ""
                        }`}
                      >
                        {errors.customer_id ? errors.customer_id.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-1">
                    <label
                        className="form-label"
                        htmlFor="project-title-input"
                        style={{visibility:"hidden"}}
                      >
                        Customer
                      </label>
                      <Link
                        onClick={() => showClauseForm()}
                        to="/order/create-order"
                        type="button"
                        className="btn btn-primary add-btn float-end"
                      ><i className="ri-add-line align-bottom me-1"></i>Add
                      </Link>
                    </div>
                  </div>
                  {Form === true ? (
                    <div>
                      <div className="row mt-3 mb-3">
                        <div className="col-lg-4">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Order No
                          </label>
                          <input
                            className="form-control"
                            name="order_no"
                            placeholder="Enter Order No"
                            {...register("order_no")}
                            value={getValues("order_no")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.order_no ? "d-block" : ""
                            }`}
                          >
                            {errors.order_no ? errors.order_no.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Order Date
                          </label>
                          <ReactFlatpickr
                        placeholder="Enter From Date"
                        name="order_date"
                        options={order_Date}
                        value={OrderDate}
                        {...register("order_date")}
                      />
                          <div
                            className={`invalid-feedback ${
                              errors.order_date ? "d-block" : ""
                            }`}
                          >
                            {errors.order_date ? errors.order_date.message : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-4">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Billing Address
                          </label>
                          <BillingAddDrop
                         
                            placeholder="Select Billing Address"
                            name="billing_address_id"
                            value={BillAdd}
                            change={(e) => {BillAddchange(e)}}
                            id={CustomerId || temp_customer_id}
                            refresh={Refresh}
                            gettemp_billing_id = {gettemp_billing_id}
                            defaultvalue = {gettemp_billing_id ? setValue("billing_address_id" , gettemp_billing_id) : ""}
                           
                            {...register(`billing_address_id`)} // Make sure to register the field with react-hook-form
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.billing_address_id ? "d-block" : ""
                            }`}
                          >
                            {errors.billing_address_id
                              ? errors.billing_address_id.message
                              : ""}
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Delivery Address
                          </label>
                          <DeliveryAddDrop
                            placeholder="Select Delivery Address"
                            value={ShipAdd}
                            change={(e) => ShipAddchange(e)}
                            id={CustomerId || temp_customer_id}
                            refresh={Refresh}
                            gettemp_delivary_id = {gettemp_delivary_id}
                            defaultvalue = {setValue("shipping_address_id" , gettemp_delivary_id)}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.shipping_address_id ? "d-block" : ""
                            }`}
                          >
                            {errors.shipping_address_id
                              ? errors.shipping_address_id.message
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-12 mb-3">
                          <label>Remark</label>
                          <textarea
                            className="form-control"
                            name="remarks"
                            {...register("remarks")}
                            placeholder="Enter Remark"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                          <div
                            className={`invalid-feedback ${
                              errors.remarks ? "d-block" : ""
                            }`}
                          >
                            {errors.remarks ? errors.remarks.message : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 mt-4 text-end d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-success w-sm"
                          disabled={Btnloading}
                        >
                          {Btnloading ? "Loading..." : " Create Sales Order"}
                        </button>
                        <Link
                          type="button"
                          to="/order"
                          className="btn btn-danger ms-2 w-sm"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  ) : ""}
                </form>
              </div>
            </div>
          </div>
          <AddOrderCustomer
            showModal={showAddModal}
            hideModal={hideAddModal}
            refreshPage={refresh}
            setRefresh={setRefresh}
            settemp_customer_id = {settemp_customer_id}
            setForm = {setForm}
            
          />
          <CustomerAddressModal
            showModal={CustAddModal}
            hideModal={hideCustAddForm}
            refreshPage={refresh}
            setRefresh={setRefresh}
            setTempAddressId = {setTempAddressId}
            temp_billing_id = {temp_billing_id}
            setTempBillingId = {setTempBillingId}
            setGetTempBillingId = {setGetTempBillingId}
            setGetTempDelivaryId = {setGetTempDelivaryId}
            setbillingObjData={setbillingObjData}
            id={CustomerId || temp_customer_id}
          />
        </div>
      </div>
    </>
  );
};
