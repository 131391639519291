import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import slugify from "slugify";
import http from "../../../../../utils/http";
import { Link } from "react-router-dom";

export const CustomerAddressForm = () => {
  // const [parent_category, setparent_category] = useState([]);

  useEffect(() => {
    // fetchparent_category();
    
  }, []);

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);
  const [slug, setSlug] = useState("");

  const yupValidation = Yup.object().shape({
    category_name: Yup.string().required("Please enter category name"),
    // parent_id: Yup.string().required("Please select parent category"),
    description: Yup.string().required("Please enter description"),
    // display: Yup.string().required("Please select display"),
    // show_home: Yup.string().required("Please select show home"),
    // status: Yup.string().required("Please select status"),
    // image: Yup.string().required("Please choose image"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    console.log(data);
    // http
    //   .post(`save_category`, data, config)
    //   .then((response) => {
    //     console.log(response);

    //     toast.success("Category has been added");
    //     const timer = setTimeout(() => {
    //       setLoading(false);
    //       navigate("/category");
    //     }, 2000);
    //     return () => clearTimeout(timer);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     setMessage("Something went wrong");
    //   });
  }

  return (
    <>
      <div
        className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message ===
          "" && "d-none"}`}
      >
        {message}
      </div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="card">
              <div className="card-header">
                <h4 className="card-title mb-0">Customer Address</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Customer Name
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter Customer Name"
                        {...register("category_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Email 
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter Email "
                        {...register("category_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Mobile
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter Mobile no"
                        {...register("category_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  
                  <div className="row mb-3">
                    <div className="col-lg-8 ">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Address
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter Address "
                        {...register("category_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Pincode
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter Pincode "
                        {...register("category_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                  <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        City
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter City "
                        {...register("category_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                  <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        State
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter State "
                        {...register("category_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                  <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Country
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter Country "
                        {...register("category_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 mt-4 text-end">
                    <Link
                      type="button"
                      className="btn btn-danger me-2 pt-8 pb-8"
                      to="/customer/customer-address-list"
                    >
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-success ps-8 pe-8 "
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Save Category"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
