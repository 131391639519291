import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useEffect } from "react";

export const EditMasterBrandNameModal = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  useEffect(() => {
    btHandleEdit(id)
  }, [id]);

  const yupValidation = Yup.object().shape({
    brand_name: Yup.string().required("Please enter gst"),
    status: Yup.string().required("Please enter status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const [File, setFile] = useState("");
  const [brandImg, setBrandImg] = useState("");
  const { errors } = formState;

  const btHandleEdit = () => {
    console.log(id);
    fetch(process.env.REACT_APP_API_BASE + `get_brand_by_id/${id}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setValue("brand_name", data.brand_name);
        setValue("status", data.status);
        setValue("image_old", data.image);
        setBrandImg(data.image);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  btHandleEdit();
console.log(brandImg);
  function onSubmit(data) {
    setValue("image_old", brandImg);
    console.log(data);

    setLoading(true);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    console.log(data , config , brandImg);
    axios
      .put(process.env.REACT_APP_API_BASE + `update_brand/${id}`, data, config)
      .then((response) => {
        refreshPage();
      })
      .catch((error) => {
        console.log("error delete");
        if (error) {
          console.log(error);
        }
      });
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Master Brand Name</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 ${resss !== "" && ""}`}>
            <div className="col-lg-6">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  Brand Name
                </label>
                <input
                  name="brand_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Brand name"
                  {...register("brand_name")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${
                    errors.brand_name ? "d-block" : ""
                  }`}
                >
                  {errors.brand_name ? errors.brand_name.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label for="assignedtoName-field" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  className="form-select"
                  {...register("status")}
                >
                  <option value=""> --status-- </option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.status ? "d-block" : ""
                  }`}
                >
                  {errors.status ? errors.status.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <label className="form-label" htmlFor="project-title-input">
                File
              </label>
              <input
                type="file"
                className="form-control"
                name="image"
                onChange={(e) => {
                  setValue("image", e.target.files[0]);
                  setFile(URL.createObjectURL(e.target.files[0]));
                }}
                placeholder="Enter Brand Name"
              />
              {File !== "" ? (
                <div
                  className=" d-flex align-items-center overflow-hidden flex-column mt-2"
                  style={{ width: "40%" }}
                >
                  <img src={File} alt="" style={{ width: "100%" }} />
                </div>
              ) : (
                <div
                  className=" d-flex align-items-center overflow-hidden flex-column mt-2"
                  style={{ width: "40%" }}
                >
                  <img
                    src={`https://woodens3.s3.ap-south-1.amazonaws.com/${brandImg}`}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </div>
          </div>
          {/* <div className="hstack gap-2 justify-content-end mt-4 ">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {btnToggle ? (
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            )}
            {/* <button type="submit" className="btn btn-danger" onClick={()=>trashAll()}>Trash All</button> */}
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit" onClick={hideModal}>
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
