import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import http from "../../../../../utils/http";

export const OrderConfirmForm = () => {
  const { id } = useParams();
  const [order_item, setorder_item] = useState([]);
  const [order_list, setorder_list] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    fetchOrderbyId();
  };

  const fetchOrderbyId = () => {
    setLoading(true);
    http
      .get("get_order_by_id/" + id)
      .then((res) => {
        console.log(res.data);
        setorder_list([res.data]);
        setorder_item(res.data.order_items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const yupValidation = Yup.object().shape({
    // name: Yup.string().required("Please enter attribute name"),
    // value: Yup.string().required("Please enter master values"),
    // attribute_group_id: Yup.string().required("Please select attribute group"),
    // // type: Yup.string().required("Please select type"),
    // status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    setValue("order_id", id);
    console.log(data);
    setBtnloading(true);

    http
      .post(`confirm_order`, data)
      .then((res) => {
        console.log(res);
        toast.success("Order confirmed");
        if (res.status === 200) {
          const timer = setTimeout(() => {
            setBtnloading(false);
            navigate("/order");
          }, 2000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }
  const [type, setType] = useState(null);
  const [ids, setId] = useState(null);
  const [error, setError] = useState(null);

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to order item?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_order_item/${ids}`)
      .then((response) => {
        setDeleteMessage("User has been deleted");
        refresh();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  return (
    <>
      <div
        className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message ===
          "" && "d-none"}`}
      >
        {message}
      </div>
      {/* <Header />
      <SideBar /> */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />

            {loading === false && (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Sales Order</h4>
                    </div>
                  </div>
                </div>
                <div className="card">
                  {order_list.map((order, index) => {
                    return (
                      <div key={index} className="card-body">
                        <div className="row g-3">
                          <div className="col-lg-4 col-6">
                            <p className="text-muted mb-2 text-uppercase fw-semibold">
                              Ref No / Order No
                            </p>
                            <h5 className="fs-14 mb-0">
                              <span id="invoice-no">{order.order_no}</span>
                            </h5>
                          </div>

                          <div className="col-lg-4 col-6">
                            <p className="text-muted mb-2 text-uppercase fw-semibold">
                              Order Date
                            </p>
                            <h5 className="fs-14 mb-0">
                              <span id="invoice-date">
                                {new Date(
                                  order.order_date
                                ).toLocaleDateString()}
                              </span>
                            </h5>
                          </div>
                          <div className="col-lg-4 col-6">
                            <p className="text-muted mb-2 text-uppercase fw-semibold">
                              Payment Term
                            </p>
                            <h5 className="fs-14 mb-0">
                              <span id="invoice-date">
                                100% Advance Payment
                              </span>
                            </h5>
                            <p className="text-muted mb-1">
                              <span>Freight Term: </span>
                              <span id="shipping-phone-no">included</span>
                            </p>
                          </div>
                        </div>
                        <div className="row g-3">
                          <div className="col-4">
                            <h6 className="text-muted text-uppercase fw-semibold mb-2">
                              Billing Address
                            </h6>
                            {/* <p className="fw-medium mb-2" id="billing-name">
                          Dotsq
                        </p> */}
                            <p
                              className="text-muted mb-1"
                              id="billing-address-line-1"
                            >
                              {order.billing_address}
                              <br />
                              {order.billing_city}
                              <br />
                              {order.billing_state}, {order.billing_country}
                            </p>
                            <p className="text-muted mb-1">
                              <span>
                                <strong>Taxt Type:</strong>{" "}
                              </span>
                              <span id="billing-phone-no"></span>
                            </p>
                            <p className="text-muted mb-0">
                              <span>
                                <strong>GSTIN:</strong>{" "}
                              </span>
                              <span id="billing-tax-no"></span>{" "}
                            </p>
                          </div>
                          <div className="col-4">
                            <h6 className="text-muted text-uppercase fw-semibold mb-2">
                              Delivery Address
                            </h6>
                            {/* <p className="fw-medium mb-2" id="shipping-name">
                          Dotsq
                        </p> */}
                            <p
                              className="text-muted mb-1"
                              id="shipping-address-line-1"
                            >
                              {order.shipping_address}
                              <br />
                              {order.shipping_city}
                              <br />
                              {order.shipping_state}, {order.shipping_country}
                            </p>
                            <p className="text-muted mb-1">
                              <span>Contact Person: </span>
                              <span id="shipping-phone-no">
                                {order.customer_name}
                              </span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Contact No: </span>
                              <span id="shipping-phone-no"></span>
                            </p>
                          </div>
                          <div className="col-4">
                            <h6 className="text-muted text-uppercase fw-semibold mb-2">
                              Remarks
                            </h6>
                            <p className="fw-medium mb-2" id="remarks">
                              {order.remarks}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "30%" }}>
                              Product Name
                            </th>
                            <th scope="col">UOM</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Sales Price</th>
                            <th scope="col">HSN</th>
                            <th scope="col">Tax (%)</th>
                            <th scope="col">Taxable Amt</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order_item.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.product_name}</td>
                                <td>{item.uom}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                                <td>{item.hsn}</td>
                                <td>{item.tax_rate}</td>
                                <td>{item.taxable_amount}</td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="remove">
                                      <Link
                                        type="button"
                                        onClick={() =>
                                          showDeleteModal("User", item.id)
                                        }
                                        className="remove-item-btn ri-delete-bin-6-line fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                      >
                                        
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="mb-3 mt-4 text-end d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-success ms-2 w-sm"
                          disabled={Btnloading}
                        >
                          {Btnloading ? "Loading..." : "Confirm"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
            <DeleteConfirmation
              showModal={displayConfirmationModal}
              confirmModal={submitDelete}
              hideModal={hideConfirmationModal}
              id={id}
              message={deleteMessage}
            />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
