import React , { useEffect,  useState } from "react";

const Dashboard2 = () => {
  const [user_name, setuser_name] = useState("");
    useEffect(() => {
      if (localStorage.getItem("token")) {
        const name = localStorage.getItem("name");
        setuser_name(name);
      }
    }, []);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row mb-3 pb-1">
            <div className="col-12">
              <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                <div className="flex-grow-1">
                  <h4 className="fs-16 mb-1">Hii , {user_name}</h4>
                  <p className="text-muted mb-0">
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard2;
