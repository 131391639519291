import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUser, removeUserSession } from "../../utils/common";
import { logo } from "../assets/images/logo.png";

const SideBar = () => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  let uriSegment = currentPath.split("/");
  let usg1 = uriSegment.length > 1 ? uriSegment[1] : "";
  let usg2 = uriSegment.length > 2 ? uriSegment[2] : "";

  console.log("uriSegment.length", uriSegment.length);
  console.log("uriSegment[1]", uriSegment[1]);
  console.log("USG", usg1);
  console.log("USG2", usg2);
  // console.log("USG" ,usg2);

  const handleLogout = () => {
    removeUserSession();
    navigate("/login");
  };

  return (
    <>
      <div className="app-menu navbar-menu ">
        <div className="navbar-brand-box">
          <img src={logo} />
          <Link to="/" className="logo ">
            <span className="logo-sm">
              <span className="h3 text-white">WS</span>
              <img src={logo} height="30" />
            </span>
            <span className="logo-lg">
              <span className="h3 text-white">WOODEN</span>
              <span className="h3 text-warning">SOLE</span>
            </span>
          </Link>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div className="mt-2 ">
            <div id="two-column-menu"> </div>
            <ul className="navbar-nav sidebar_scroll " id="navbar-nav">
              <li className="nav-item">
                <Link
                  to="/"
                  className={`nav-link menu-link ${currentPath === "/" &&
                    "active"}`}
                  data-key="t-chat"
                >
                  <i className="ri-home-4-fill"></i>
                  <span data-key="t-apps">&nbsp;Dashboard</span>
                </Link>
              </li>

              <li className="nav-item ">
                <a
                  className={`nav-link  menu-link   ${usg1 === "master" &&
                    "active"}`}
                  href="#master"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 === "master" && "true"}`}
                  aria-controls="master"
                >
                  <i className ="ri-file-list-line "></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Master</span>

                </a>
                <div
                  // className="collapse menu-dropdown pt-0"
                  className={`collapse menu-dropdown pt-0 ${usg1 === "master" &&
                    "show "}`}
                  id="master"
                  // style={`${usg1}` === "master" && {{marginTop: "-8px" }}}
                  // style={ usg1 === "master" ?  {marginTop: "0px"}  :  {marginTop: "-8px"}}
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/master/add-gst"
                        className={`nav-link ${currentPath ===
                          "/master/add-gst" && "active"}`}
                        data-key="t-analytics"
                      >
                        <span data-key="t-apps">&nbsp;GST(%)</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/master/add-uom"
                        className={`nav-link ${currentPath ===
                          "/master/add-uom" && "active"}`}
                        data-key="t-crm"
                      >
                        <span data-key="t-apps">&nbsp;UOM</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/master/add-business-type"
                        className={`nav-link ${currentPath ===
                          "/master/add-business-type" && "active"}`}
                        data-key="t-ecommerce"
                      >
                        <span data-key="t-apps">&nbsp;Business Type</span>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link
                        to="/master/add-pincode"
                        className="nav-link"
                        data-key="t-crypto"
                      >
                      <span data-key="t-apps">&nbsp;Pincode</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/master/add-brand-name"
                        className="nav-link"
                        data-key="t-projects"
                      >
                      <span data-key="t-apps">&nbsp;Brand Name</span>
                        
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <Link
                        to="/master/add-typeof-company"
                        className={`nav-link ${currentPath ===
                          "/master/add-typeof-company" && "active"}`}
                        data-key="t-nft"
                      >
                        <span data-key="t-apps">&nbsp;Type of Company</span>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        to="/master/add-payment-modes"
                        className={`nav-link ${currentPath ===
                          "/master/add-payment-modes" && "active"}`}
                        data-key="t-nft"
                      >
                        <span data-key="t-apps">&nbsp;Payment Modes</span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  // className={`nav-link menu-link  ${usg2 === (usg1 || usg1 || usg1) && "active"}`}
                  className={`nav-link menu-link  ${usg1 ===
                    ("category" || "product" || "attribute") && "active"}`}
                  href="#catalog"
                  data-bs-toggle="collapse"
                  role="button"
                  // aria-expanded={`${usg2 === (usg1 || usg1 || usg1) && "active"}`}
                  aria-expanded={`${usg1 ===
                    ("category" || "product" || "attribute") && "active"}`}
                  aria-controls="catalog"
                >
                  <i className="ri-dashboard-fill "></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Catalog Management</span>
                </a>
                <div
                  // className={`collapse menu-dropdown pt-0 ${usg2 === (usg1 || usg1 || usg1)   && "show"}`}
                  className={`collapse menu-dropdown pt-0 ${usg1 ===
                    ("category" || "product" || "attribute") && "show"}`}
                  id="catalog"
                  // style={{ marginTop: "-8px" }}
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/category"
                        className={`nav-link ${currentPath === "/category" &&
                          "active"}`}
                        data-key="t-analytics"
                      >
                        <span data-key="t-dashboards">&nbsp;Category</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/product"
                        className={`nav-link  ${currentPath === "/product" &&
                          "active"}`}
                        data-key="t-crm"
                      >
                        <span data-key="t-dashboards">&nbsp;Product</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/product/bulk-product-upload"
                        className={`nav-link  ${currentPath ===
                          "/product/bulk-product-upload" && "active"}`}
                        data-key="t-crm"
                      >
                        <span data-key="t-dashboards">&nbsp;Bulk Creation</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/attribute"
                        className={`nav-link  ${currentPath === "/attribute" &&
                          "active"}`}
                        data-key="t-ecommerce"
                      >
                        <span data-key="t-dashboards">&nbsp;Attribute</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/catlog/product-review"
                        className={`nav-link  ${currentPath === "/catlog/product-review" &&
                          "active"}`}
                        data-key="t-crm"
                      >
                        <span data-key="t-dashboards">&nbsp;Product Reviews</span>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        to="/pricing"
                        className={`nav-link  ${currentPath == "/pricing" &&
                          "active"}`}
                        data-key="t-crypto"
                      >
                        <span data-key="t-dashboards">&nbsp;Pricing</span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${usg1 === "order" &&
                    "active"}`}
                  href="#order"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 === "order" && "active"}`}
                  aria-controls="admin_user"
                >
                  <i className="ri-shopping-cart-line"></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Order Management</span>
                </a>
                <div
                  className={`collapse menu-dropdown pt-0 ${usg1 === "order" &&
                    "show"}`}
                  id="order"
                  // style={{ marginTop: "-8px" }}
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/order"
                        className={`nav-link  ${currentPath === "/order" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Sales Order</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/order/sales-return"
                        className={`nav-link  ${currentPath === "/order/sales-return" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Sales Return</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${usg1 === "customer" &&
                    "active"}`}
                  href="#customer"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 === "customer" && "active"}`}
                  aria-controls="admin_user"
                >
                  <i className="ri-user-add-line"></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Customer</span>
                </a>
                <div
                  className={`collapse menu-dropdown pt-0 ${usg1 ===
                    "customer" && "show"}`}
                  id="customer"
                  // style={{ marginTop: "-8px" }}
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/customer"
                        className={`nav-link  ${currentPath === "/customer" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Customer List</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${usg1 === "lead-management" &&
                    "active"}`}
                  href="#lead"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 === "lead-management" && "active"}`}
                  aria-controls="admin_user"
                >
                  <i className="ri-question-answer-line"></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Lead Management</span>
                </a>
                <div
                  className={`collapse menu-dropdown pt-0 ${usg1 ===
                    "lead-management" && "show"}`}
                  id="lead"
                  // style={{ marginTop: "-8px" }}
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/lead-management"
                        className={`nav-link  ${currentPath ==="/lead-management" && "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Lead Management</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/lead-management/assign-lead-list"
                        className={`nav-link  ${currentPath === "/lead-management/assign-lead-list" && "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Assign Lead </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${usg1 === ("rfq") &&
                  "active"}`}
                  href="#rfq"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 === "rfq" && "true"}`}
                  aria-controls="rfq"
                >
                  <i className="ri-file-edit-line"></i>{" "}
                  <span data-key="t-dashboards">&nbsp;RFQ</span>
                </a>
                <div
                  className={`collapse menu-dropdown pt-0 ${usg1 === "rfq" &&
                  "show "}`}
                  id="rfq"
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/rfq/rfq-list"
                        className={`nav-link  ${currentPath === usg1 ?  (`/rfq/rfq-list`) : usg2 &&
                          "active"}`}
                        data-key="t-rfq"
                      >
                        <span data-key="t-apps">&nbsp;RFQ List</span>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        to="/create-offer"
                        className={`nav-link  ${currentPath ===
                          "/create-offer" && "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Create Offer</span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${usg1 === "escalation-management" &&
                  "active"}`}
                  href="#escalation-management"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 === "escalation-management" && "true"}`}
                  aria-controls="escalation-management"
                >
                  <i className="ri-parent-line"></i>{" "}
                  <span data-key="t-dashboards">
                    &nbsp;Escalation Management
                  </span>
                </a>
                <div
                  className={`collapse menu-dropdown pt-0 ${usg1 === "escalation-management" &&
                  "show "}`}
                  id="escalation-management"
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/escalation-management/create-ticket"
                        className={`nav-link  ${currentPath ===
                          "/escalation-management/create-ticket" && "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Create Ticket</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/escalation-management/ticket-list"
                        className={`nav-link  ${currentPath ===
                          "/escalation-management/ticket-list" && "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Ticket List</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <Link
                  to="/supplier-management"
                  className={`nav-link menu-link ${usg1 ===
                    "supplier-management" && "active"}`}
                  data-key="t-chat"
                >
                  <i className="ri-contacts-line "></i>{" "}
                  <span data-key="t-apps">&nbsp;Supplier Management</span>
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link
                to={"/supplier-management"}
                  className="nav-link menu-link "
                  href="#supplier"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="supplier"
                >
                  <i className="ri-contacts-line "></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Supplier Management</span>
                </Link>
                <div className="collapse menu-dropdown pt-0" id="supplier" style={{marginTop: "-8px"}}>
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/supplier-management"
                        className={`nav-link  ${currentPath == "/category" &&
                          "active"}`}
                        data-key="t-analytics"
                      >
                        <span data-key="t-dashboards">&nbsp;Supplier</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={`nav-link  ${currentPath == "/category" &&
                          "active"}`}
                        data-key="t-analytics"
                      >
                        Supplier Approval
                      </Link>
                    </li>
                  </ul>
                </div>
              </li> */}

              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${usg1 === "reports" &&
                    "active"}`}
                  href="#reports"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 === "reports" && "active"}`}
                  aria-controls="admin_user"
                >
                  <i className="ri-chat-history-line"></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Report</span>
                </a>
                <div
                  className={`collapse menu-dropdown pt-0 ${usg1 ===
                    "reports" && "show"}`}
                  id="reports"
                  // style={{ marginTop: "-8px" }}
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/reports/sales-report"
                        className={`nav-link  ${currentPath === "/reports/sales-report" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Sales Report</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/reports/user-report"
                        className={`nav-link  ${currentPath === "/reports/user-report" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;User Report</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/reports/supplier-report"
                        className={`nav-link  ${currentPath === "/reports/supplier-report" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Supplier Report</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${usg1 ===
                    ("website-management" &&
                      "add-banner" &&
                      "seo" &&
                      "webpage") && "active"}`}
                  href="#website_management"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 ===
                    ("website-management" &&
                      "add-banner" &&
                      "seo" &&
                      "webpage") && "active"}`}
                  aria-controls="website_management"
                >
                  <i className="ri-mac-line"></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Website Management</span>
                </a>
                <div
                  className={`collapse menu-dropdown pt-0 ${usg1 ===
                    ("website-management" &&
                      "add-banner" &&
                      "seo" &&
                      "webpage") && "show"}`}
                  id="website_management"
                  // style={{ marginTop: "-8px" }}
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={`nav-link  ${currentPath === "/users" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Static Page</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/website-management/discount-coupon"
                        className={`nav-link ${currentPath ===
                          "/website-management/discount-coupon" && "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Discount Coupon</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/add-banner"
                        className={`nav-link ${currentPath === "/add-banner" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Home Page</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="#"
                        className={`nav-link  ${currentPath === "/users" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Campaign</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/seo"
                        className={`nav-link  ${currentPath === "/seo" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Seo Setting</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/webpage"
                        className={`nav-link  ${currentPath === "/webpage" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;Webpage List</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link menu-link ${usg1 === "users" &&
                    "active"}`}
                  href="#Settings"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={`${usg1 === "users" && "active"}`}
                  aria-controls="admin_user"
                >
                  <i className="ri-settings-2-line"></i>{" "}
                  <span data-key="t-dashboards">&nbsp;Settings</span>
                </a>
                <div
                  className={`collapse menu-dropdown pt-0 ${usg1 === "users" &&
                    "show"}`}
                  id="Settings"
                  // style={{ marginTop: "-8px" }}
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/users"
                        className={`nav-link  ${currentPath === "/users" &&
                          "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;User List</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/users/user-roles-list"
                        className={`nav-link  ${currentPath ===
                          "/users/user-roles-list" && "active"}`}
                        data-key="t-chat"
                      >
                        <span data-key="t-apps">&nbsp;User Roles</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>

            {/* <div className="setting_tray">
              <ul className="navbar-nav" id="navbar-nav">
                <li className="nav-item">
                <Link
                  to="/help"
                  className="nav-link menu-link"
                  data-key="t-chat"
                >
                  <i className="mdi mdi-logout text-muted  align-middle"></i>
                  <span data-key="t-apps">&nbsp;&nbsp;Help</span>
                </Link>
                <Link
                  to="/help"
                  className="nav-link menu-link"
                  data-key="t-chat"
                >
                  <i className="ri-questionnaire-line"></i>
                  <span data-key="t-apps">&nbsp;&nbsp;Help</span>
                </Link>
              </li>
                <li className="nav-item">
                  <Link
                    to="/login"
                    onClick={handleLogout}
                    className="nav-link menu-link"
                    data-key="t-chat"
                  >
                    <i className="mdi mdi-logout  align-middle"></i>
                    <span data-key="t-apps">&nbsp;Logout</span>
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        <div className="sidebar-background"></div>
      </div>
    </>
  );
};

export default SideBar;
