import React, { useState, useEffect } from "react";
import Select from "react-select";
import http from "../../../../../utils/http";

const CategoryChildParentDropdown = (props) => {
  const opt = [];

  const [options, setoptions] = useState([]);
  useEffect(() => {
    http
      .get("get_category")
      .then((res) => {
        res.data.forEach((val) => {
          opt.push({
            label: val.category_name,
            value: val.id,
          });
        });

        setoptions(opt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <Select
      options={options}
      onChange={props.change}
      placeholder={props.placeholder}
      // isMulti
      value={props.value}
      isSearchable={true}
    />
  );
};

export default CategoryChildParentDropdown;
