import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../../shared/Loader";
import http from "../../../../../utils/http";

export const EditWebpageForm = () => {
  let { id } = useParams();
  const [web_page_List, setweb_page_List] = useState([]);
  const [web_page_img, setweb_page_img] = useState("");

  useEffect(() => {
    fetchWebpageList();
  }, []);

  const fetchWebpageList = () => {
    setLoading(true);
    http
      .get("/get_webpage_by_id/" + id)
      .then((res) => {
        console.log(res.data);
        setweb_page_List(res.data);
        setweb_page_img(res.data.image);
        setValue("title", res.data.title);
        setValue("short_description", res.data.short_description);
        setValue("description", res.data.description);
        setContent(res.data.description);
        setValue("image_old", res.data.image);
        setValue("meta_title", res.data.meta_title);
        setValue("meta_description", res.data.meta_description);
        setValue("meta_keyword", res.data.meta_keyword);
        setValue("status", res.data.status);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const [File, setFile] = useState("");

  const yupValidation = Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    short_description: Yup.string().required("Please enter short description"),
    // description: Yup.string().required("Please enter description"),
    meta_title: Yup.string().required("Please enter meta title"),
    meta_description: Yup.string().required("Please enter meta description"),
    meta_keyword: Yup.string().required("Please enter meta keywords"),
    status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    console.log(data);
    setBtnloading(true);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    http
      .put(`update_webpage_by_id/` + id,
        data,
        config
      )
      .then((response) => {
        toast.success("Webpage has been updated");
        const timer = setTimeout(() => {
          setBtnloading(false);
          navigate("/webpage");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const handleDropDown = (d, e) => {
    let fvalues = [];
    e.map((n, index) => {
      fvalues.push(n.value);
    });
    let textsss = fvalues.join();
    setValue(d, textsss, { shouldValidate: true });
  };

  const [value, setVal] = useState("");

  const [content, setContent] = useState("");
  const onChange = (text) => {
    console.log(text);
    setContent(text);
    setValue("description", text);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />
            {loading === false && (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Edit Webpage</h4>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row mb-3">
                        <div className="col-lg-12">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Title
                          </label>
                          <input
                            className="form-control"
                            name="title"
                            placeholder="Enter Title"
                            {...register("title")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.title ? "d-block" : ""
                            }`}
                          >
                            {errors.title ? errors.title.message : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <label> Short Description</label>
                          <textarea
                            className="form-control"
                            name="short_description"
                            {...register("short_description")}
                            placeholder="Enter Short Description"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                          <div
                            className={`invalid-feedback ${
                              errors.short_description ? "d-block" : ""
                            }`}
                          >
                            {errors.short_description
                              ? errors.short_description.message
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <label>Description</label>
                          <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={onChange}
                            placeholder="Enter Description"
                          />
                          {/* <textarea
                        className="form-control"
                        name="description"
                        {...register("description")}
                        placeholder="Enter Description"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea> */}
                          {/* <div
                        className={`invalid-feedback ${
                          errors.description ? "d-block" : ""
                        }`}
                      >
                        {errors.description ? errors.description.message : ""}
                      </div> */}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-6 ">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Meta Title
                          </label>
                          <input
                            className="form-control"
                            name="meta_title"
                            placeholder="Enter Meta Title"
                            {...register("meta_title")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.meta_title ? "d-block" : ""
                            }`}
                          >
                            {errors.meta_title ? errors.meta_title.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <label>Meta Description</label>
                          <textarea
                            className="form-control"
                            name="meta_description"
                            {...register("meta_description")}
                            placeholder="Enter Meta Description"
                            id="exampleFormControlTextarea1"
                            rows="1"
                          ></textarea>
                          <div
                            className={`invalid-feedback ${
                              errors.meta_description ? "d-block" : ""
                            }`}
                          >
                            {errors.meta_description
                              ? errors.meta_description.message
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-6 ">
                          <label>Meta Keywords</label>
                          <textarea
                            className="form-control"
                            name="meta_keyword"
                            {...register("meta_keyword")}
                            placeholder="Enter Meta Keywords"
                            id="exampleFormControlTextarea1"
                            rows="1"
                          ></textarea>
                          <div
                            className={`invalid-feedback ${
                              errors.meta_keyword ? "d-block" : ""
                            }`}
                          >
                            {errors.meta_keyword
                              ? errors.meta_keyword.message
                              : ""}
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Image
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            accept="application/jpg,application/png"
                            onChange={(e) => {
                              setValue("image", e.target.files[0]);
                              setFile(URL.createObjectURL(e.target.files[0]));
                            }}
                            placeholder="Enter display order"
                          />
                          {File !== "" ||  web_page_img == ""? (
                            <div
                              className=" d-flex align-items-center overflow-hidden flex-column mt-3"
                              style={{ width: "40%" }}
                            >
                              <img
                                src={File}
                                alt=""
                                style={{ width: "100%" }}
                              />
                            </div>
                          ) : (
                            <div
                              className=" d-flex align-items-center overflow-hidden flex-column mt-3"
                              style={{ width: "40%" }}
                            >
                              <img
                                src={`https://woodens3.s3.ap-south-1.amazonaws.com/${web_page_img}`}
                                alt="webpage image"
                                style={{ width: "100%" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Status
                          </label>
                          <select
                            className="form-select"
                            name="status"
                            {...register("status")}
                          >
                            <option defaultValue="1" value="1">
                              Active
                            </option>
                            <option value="0">In Active</option>
                          </select>
                          <div
                            className={`invalid-feedback ${
                              errors.status ? "d-block" : ""
                            }`}
                          >
                            {errors.status ? errors.status.message : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 mt-4 text-end">
                        <button
                          type="submit"
                          className="btn btn-danger w-sm"
                          disabled={Btnloading}
                        >
                          {Btnloading ? "Loading..." : "Save Webpage"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
