import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AddUserModal from "../../shared/AddUserModal";
import DeleteConfirmation from "../../shared/DeleteConfirmation";

import Loader from "../../shared/Loader";
import http from "../../../utils/http";
import EditUserModal from "./EditUserModal";
import moment from "moment";

const UserList = (props) => {
  // console.log(props);
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete user`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    console.log("delete", id);
    
    http
      .delete(`delete_user_by_id/${id}`)
      .then((response) => {
        setDeleteMessage("User has been deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  // Add banner modal

  const [showAddModal, setshowAddModal] = useState(false);

  const showClauseForm = (id) => {
    setUserId(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowAddModal(true);
  };

  // Hide the modal
  const hideAddModal = () => {
    setshowAddModal(false);
  };

   // Edit banner modal
   const [showEditModal, setshowEditModal] = useState(false);
   const showEditForm = (id) => {
     setUserId(id);
 console.log(id);
     // setDeleteMessage(`Are you sure you want to delete the vegetable`);
     setshowEditModal(true);
   };
 
   // Hide the modal
   const hideEditModal = () => {
     setshowEditModal(false);
   };

  const refreshPage = () => {
    setLoading(true)
    // fetch(process.env.REACT_APP_API_BASE+"user/getall")
    http.get("user_list")
      .then((res) => {
        // console.log(data);
        setuserList(res.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    refreshPage();
  }, []);

  //   const [error, setError] = useState(null);

  const [userList, setuserList] = useState([]);

  

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
        <Loader show={loading} />
          {loading === false &&  (
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between ">
                    <h4 className="card-title mt-2">User List</h4>
                    <button
                    onClick={() => showClauseForm()}
                    type="button"
                    className="btn btn-primary add-btn"
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Add User
                  </button>
                  </div>
                  
                </div>

                <div className="card-body">
                  <div className="listjs-table" id="customerList">
                    <div className="row g-4 mb-3">
                      <div className="col-md-12 d-flex justify-content-between">
                        <div className="search-box ms-2">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap table-bordered"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  value="option"
                                />
                              </div>
                            </th>
                            <th data-sort="customer_name">Name</th>
                            <th data-sort="email">Email</th>
                            <th data-sort="date">Date</th>
                            <th data-sort="date">Mobile</th>
                            <th data-sort="date">Role</th>
                            <th data-sort="status">Status</th>
                            <th data-sort="action">Action</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {userList.map((user, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="chk_child"
                                    value="option1"
                                  />
                                </div>
                              </th>

                              <td className="customer_name">{user.name}</td>
                              <td className="email">{user.email}</td>
                              <td className="date">{moment(user.indate)
                                        .utc()
                                        .format("DD/MM/YYYY")}</td>
                              <td className="date">{user.mobile}</td>
                              <td className="date">{user.role_id}</td>
                              <td className="status">
                                <span
                                  className={`badge text-uppercase ${
                                    user.status == 1
                                      ? "bg-success-subtle text-success"
                                      : "bg-danger-subtle text-danger"
                                  }`}
                                >
                                  {user.status == 1 ? "Active" : "In-active"}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  <div className="edit">
                                    <Link
                                      onClick={() => showEditForm(user.id)}
                                      className=" ri-edit-2-fill fs-4"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                    >
                                      
                                    </Link>
                                  </div>
                                  <div className="remove">
                                    <Link
                                      onClick={() =>
                                        showDeleteModal("User", user.id)
                                      }
                                      className="ri-delete-bin-6-line fs-4"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteRecordModal"
                                    >
                                      
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <AddUserModal
                showModal={showAddModal}
                hideModal={hideAddModal}
                refreshPage={refreshPage}
                type={type}
              />
              <EditUserModal
                  showModal={showEditModal}
                  hideModal={hideEditModal}
                  refreshPage={refreshPage}
                  type={type}
                  id={user_id}
                />
              <DeleteConfirmation
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                id={id}
                message={deleteMessage}
                refreshPage={refreshPage}
              />
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserList;