import React, { useState } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import Footer from "../../common/Footer";
import Select from 'react-select';
const Master = () => {

    const options = [
        { value: 'category', label: 'Category' },
        { value: 'certification_type', label: 'Certification Type' },
        { value: 'client_master', label: 'Client Master' },
        { value: 'department', label: 'Department' },
        { value: 'doc_type', label: 'Doc Type' },
        { value: 'domain_process', label: 'Domain Process' },
        { value: 'gender', label: 'Gender' },
        { value: 'doc_type', label: 'Doc Type' }

    ]
    const [masters, setMasters] = useState([]);
    const opt = [];
    const getMaster = (e) => {
        // console.log(e.value);
        var type = e.value; 
        fetch(process.env.REACT_APP_API_BASE+'master?type=' + type)
            .then((response) => response.json())
            .then((data) => {
                data.forEach(val => {
                    opt.push({
                        "name": val.name,
                        "type": val.type,
                        "id":val._id
                    })
                });
                // console.log(opt);
                setMasters(opt);
            })
    }
    return (
        <>
            {/* <Header />
            <SideBar /> */}
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">Master</h4>
                                    </div>

                                    <div className="card-body">
                                        <div className="listjs-table" id="customerList">
                                            <div className="row g-4 mb-3">
                                                <div className="col-sm-4">
                                                    <div>
                                                        <Select options={options} onChange={getMaster} />

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="table-responsive table-card mt-3 mb-1">
                                                
                                                <table
                                                    className="table align-middle table-nowrap mb-0"
                                                
                                                >
                                                    <thead className="table-light">
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Value</th>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {masters.map((mst) => (
                                                        <tr id={mst.id}>
                                                            <td scope="col">{mst.type}</td> 
                                                            <td scope="col">{mst.name}</td> 
                                                          </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <Footer /> */}
        </>
    );
}

export default Master