import React from 'react'
import { Link, NavLink } from 'react-router-dom'

export const Navlink = () => {
    return (
        <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
            <li className="nav-item mt-1 p-3" role="presentation">
                <NavLink to="/product" className={({ isActive }) => (isActive ? "link-active " : " ")}>General Details</NavLink>
            </li>
            <li className="nav-item mt-1 p-3" role="presentation">
                <NavLink to="/listdetail" className={({ isActive }) => (isActive ? "link-active " : " ")}>Listing Details</NavLink>
            </li>
            <li className="nav-item mt-1 p-3" role="presentation">
                <NavLink to="/additionaldata" className={({ isActive }) => (isActive ? "link-active " : " ")}>Additional Data</NavLink>
            </li>
        </ul>
    )
}
