import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import SupplierNavs from "../SupplierNavs"
import http from "../../../../utils/http";
const AddBillingDetails = () => {
  const { supplier_id } = useParams();
  console.log(supplier_id);

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const [pinStates, setpinStates] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");

  const yupValidation = Yup.object().shape({
    billing_pincode: Yup.string().required("Please enter pincode"),
    billing_city: Yup.string().required("Please enter city"),
    billing_state: Yup.string().required("Please enter state"),
    billing_country: Yup.string().required("Please enter country"),
    gst_number: Yup.string()
      .required("Please enter gst no")
      .matches(
        /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/,
        "Invalid GST number"
      ),
    pan: Yup.string().required("Please enter pan"),
    cin: Yup.string().required("Please enter cin"),
    cin_no: Yup.string().required("Please enter cin no"),

    // gst_doc : Yup.mixed().required("hlw")
    // .test('gst_doc', 'Invalid file type', function (value) {
    //   if (!value) {
    //     // No file selected, so the validation passes
    //     return true;
    //   }

    //   return value[0] && value[0].name.toLowerCase().endsWith('.pdf');

    // }),
    // pan_doc : Yup.mixed().required("hklw")
    // .test('pan_doc', 'Invalid file type', function (value) {
    //   if (!value) {
    //     // No file selected, so the validation passes

    //     return true;
    //   }

    //   return value[0] && value[0].name.toLowerCase().endsWith('.pdf');

    // }),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  // const getGSTValueVali = () => {
  //   const gstNoTwoDigit = getValues('gst_number').substring(0 , 2)
  //   console.log("gst fisrt two",gstNoTwoDigit);
  //   console.log("state_code",selectedOption);
  //   if(gstNoTwoDigit === selectedOption){
  //     // alert("Match GST No. With State")
  //   }else{
  //     alert("Not Match With Gst No and State")
  //   }

  // }

  const [gstPdf, setGstPdf] = useState("");
  const [panPdf, setPanPdf] = useState("");
  const [Res, setRes] = useState("");

  const getFetchSupplier = () => {
    // setSelectedOption(setValue("billing_state",data.billing_state))
    http.get(`get_supplier_by_id/${supplier_id}`)
      // fetch(process.env.REACT_APP_API_BASE + `get_supplier_by_id/${supplier_id}`)
      .then((res) => {
        console.log("check", res.data);
        setValue("billing_pincode", res.data.billing_pincode);
        setValue("billing_state", res.data.billing_state);
        // setSelectedOption( setValue("billing_state",data.billing_state))
        setValue("billing_city", res.data.billing_city);
        setValue("billing_country", res.data.billing_country);
        setValue("gst_number", res.data.gst_number);

        setValue("pan", res.data.pan);
        setValue("cin", res.data.cin);
        setValue("cin_no", res.data.cin_no);
        setValue("gst_doc_old", res.data.gst_doc);
        setValue("pan_doc_old", res.data.pan_doc);
        setGstPdf(res.data.gst_doc);
        setPanPdf(res.data.pan_doc);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  // let editCase = (supplier_id) ? 1 : 0;
  // getFetchSupplier();

  function onSubmit(data) {
    setLoading(true)
    setValue("gst_doc_old", gstPdf);
    setValue("pan_doc_old", panPdf);
    console.log(data);
    // Additional validations or API calls can be performed here
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    http
      .put(`save_supplier_billing_details/${supplier_id}`,
        data,
        config
      )
      // axios.put(`http://192.168.1.177:8080/admin/save_supplier_billing_details/${supplier_id}`, data , config)
      .then((response) => {
        console.log(response);
        setLoading(false)
        navigate(`/supplier-management/add-contact-details/${supplier_id}`);
        // setValue()
      })
      .catch((error) => {
        setLoading(false)
        setMessage("Something went wrong");
      });
  }

  const setAddressDetails = (data) => {
    http.get(`getDataFromPincode/${data}`)
      .then((res) => {
        console.log("Pincode", res.data);
        setValue("billing_city", res.data.city);
        setValue("billing_state", res.data.state);
        setValue("billing_country", res.data.country);
        // setValue("billing_country", data.country);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getbilling_pincodeStateData = () => {
    http.get(`getAllStates`)
      .then((res) => {
        setpinStates(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getPincodeValue = (e) => {
    setAddressDetails(e.target.value);
  };

  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value);
  //   console.log("state code",event.target.value);
  // };

  console.log(selectedOption);
  useEffect(() => {
    getbilling_pincodeStateData();
    // console.log("gst fisrt two",gstNoTwoDigit);
    getFetchSupplier();
    console.log("state_code", selectedOption);
  }, []);

  return (
    <>
      {/* <Header />
      <SideBar /> */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className={`card ${supplier_id}`}>
                    <SupplierNavs supplier_id={supplier_id} />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Pincode
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="billing_pincode"
                              placeholder="Enter Pincode"
                              {...register("billing_pincode")}
                              onBlur={(e) => getPincodeValue(e)}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.billing_pincode ? "d-block" : ""
                              }`}
                            >
                              {errors.billing_pincode
                                ? errors.billing_pincode.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="billing_city"
                              placeholder="Enter City"
                              {...register("billing_city")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.billing_city ? "d-block" : ""
                              }`}
                            >
                              {errors.billing_city
                                ? errors.billing_city.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              State
                            </label>
                            <select
                              className="form-control form-select"
                              name="billing_state"
                              placeholder="Enter State"
                              {...register("billing_state")}
                              // value={selectedOption} onChange={handleSelectChange}
                            >
                              <option value="">Select</option>
                              {pinStates.map((pinStates, index) => (
                                <option key={index} value={pinStates.state}>
                                  {pinStates.state}
                                </option>
                              ))}
                            </select>
                            <div
                              className={`invalid-feedback ${
                                errors.billing_state ? "d-block" : ""
                              }`}
                            >
                              {errors.billing_state
                                ? errors.billing_state.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Country
                            </label>
                            <input
                              className="form-control"
                              name="billing_country"
                              placeholder="Enter Country"
                              {...register("billing_country")}
                            />

                            <div
                              className={`invalid-feedback ${
                                errors.billing_country ? "d-block" : ""
                              }`}
                            >
                              {errors.billing_country
                                ? errors.billing_country.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              GSTIN No
                            </label>
                            <input
                              className="form-control"
                              name="gst_number"
                              placeholder="GSTIN No"
                              {...register("gst_number")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.gst_number ? "d-block" : ""
                              }`}
                            >
                              {errors.gst_number
                                ? errors.gst_number.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              GSTIN Document
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="gst_doc"
                              accept="application/msword,application/pdf"
                              onChange={(e) => {
                                setValue("gst_doc", e.target.files[0]);
                              }}
                            />
                            {gstPdf && (
                              <div
                                className=" d-flex align-items-left overflow-hidden flex-column mt-2"
                                style={{ width: "40%" }}
                              >
                                <Link
                                  to={`https://woodens3.s3.ap-south-1.amazonaws.com/${gstPdf}`}
                                >
                                  Attached GST Doc
                                </Link>
                              </div>
                            )}
                          </div>
                          <div
                            className={`invalid-feedback ${
                              errors.gst_doc ? "d-block" : ""
                            }`}
                          >
                            {errors.gst_doc ? errors.gst_doc.message : ""}
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              PAN No
                            </label>
                            <input
                              className="form-control"
                              name="pan"
                              placeholder="PAN No"
                              {...register("pan")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.pan ? "d-block" : ""
                              }`}
                            >
                              {errors.pan ? errors.pan.message : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              PAN Document
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="pan_doc"
                              accept="application/msword,application/pdf"
                              onChange={(e) => {
                                setValue("pan_doc", e.target.files[0]);
                              }}
                            />
                            {panPdf && (
                              <div
                                className=" d-flex align-items-left overflow-hidden flex-column mt-2"
                                style={{ width: "40%" }}
                              >
                                <Link
                                  to={`https://woodens3.s3.ap-south-1.amazonaws.com/${panPdf}`}
                                >
                                  Attached PAN Doc
                                </Link>
                              </div>
                            )}

                            <div
                              className={`invalid-feedback ${
                                errors.pan_doc ? "d-block" : ""
                              }`}
                            >
                              {errors.pan_doc ? errors.pan_doc.message : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              CIN
                            </label>
                            <input
                              className="form-control"
                              name="cin"
                              placeholder="CIN"
                              {...register("cin")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.cin ? "d-block" : ""
                              }`}
                            >
                              {errors.cin ? errors.cin.message : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              CIN No
                            </label>
                            <input
                              className="form-control"
                              name="cin_no"
                              placeholder="CIN No"
                              {...register("cin_no")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.cin_no ? "d-block" : ""
                              }`}
                            >
                              {errors.cin_no ? errors.cin_no.message : ""}
                            </div>
                          </div>
                        </div>

                        <div className="hstack gap-2 justify-content-end">
                          <button type="submit" className="btn btn-danger">
                          {loading ? "Loading..." : "Save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {/* <Footer/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBillingDetails;