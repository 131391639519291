import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CategoryDropdown from "../Category/CategoryDropdown";
import { IoAddCircleSharp } from "react-icons/io5";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import ImageUploading from "react-images-uploading";
import toast, { Toaster } from "react-hot-toast";
import ProductDropdown from "../Product/ProductDropdown";
import http from "../../../../../utils/http";

export const ProductLink = () => {
  // const [attributedrop, setAttributedrop] = useState([]);
  const [GstList, setGstList] = useState([]);
  const [UomList, setUomList] = useState([]);
  const [BrandList, setBrandList] = useState([]);

  useEffect(() => {
    // fetch_attribute();
    fetch_gst();
    fetch_uom();
    fetch_brand();
  }, []);

  // const fetch_attribute = () => {
  //   axios
  //     .get(process.env.REACT_APP_API_BASE + "get_attribute")
  //     .then((res) => {
  //       setAttributedrop(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  const fetch_gst = () => {
    http
      .get("get_gst")
      .then((res) => {
        setGstList(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetch_uom = () => {
    http
      .get("get_uom")
      .then((res) => {
        console.log(res.data);
        setUomList(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetch_brand = () => {
    http
      .get("get_brand")
      .then((res) => {
        setBrandList(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const yupValidation = Yup.object().shape({
    // running validations
    product_name: Yup.string().required("Please enter product name"),
    part_number: Yup.string().required("Please enter part number"),
    specifications: Yup.string().required("Please enter specifications"),
    pack_size: Yup.string().required("Please enter pack size"),
    gst: Yup.number().typeError("Please select GST"),
    hsn: Yup.number().typeError("Please enter HSN"),
    moq: Yup.number().typeError("Please enter MOQ"),
    uom: Yup.number().typeError("Please select UOM"),
    warranty: Yup.string().required("Please enter warranty"),
    price: Yup.number().typeError("Please enter MRP"),
    sale_price: Yup.number().typeError("Please enter list price"),
    status: Yup.string().required("Please select website display"),
    price_display: Yup.string().required("Please select price display"),
    description: Yup.string().required("Please enter description"),
    meta_title: Yup.string().required("Please enter meta tag title"),
    meta_description: Yup.string().required(
      "Please enter meta tag description"
    ),
    meta_keywords: Yup.string().required("Please enter meta tag keywords"),
    product_tag: Yup.string().required("Please enter product tag"),
    model: Yup.string().required("Please enter model"),
    brand: Yup.number().typeError("Please select brand"),
    categories: Yup.string().required("Please select Category"),
    seo_url: Yup.string().required("Please enter seo url"),

    // commented validations
    // related_product: Yup.string().required("Please select Related product"),
    // best_seller: Yup.string().required("Please enter best seller"),
    // brand: Yup.string().required("Please select brand"),
    // product_category: Yup.string().required("Please select product category"),
    // keywords: Yup.string().required("Please enter keywords"),
    // image_url: Yup.string().required("Please enter image url"),
    // sort_order: Yup.string().required("Please enter sort order"),
    // attribute: Yup.string().required("Please select attribute"),
    // thumb_image: Yup.string().required("Please choose thumb image"),
    // product_images: Yup.string().required("Please choose product images"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit(data) {
    // setValue("attribute", JSON.stringify(attributeList));
    let newData = { ...data, attribute: JSON.stringify(attributeList)};
    console.log(newData);
    setLoading(true);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    http
      .post(`save_product`, newData, config)
      .then((response) => {
        toast.success("Product has been added");
        const timer = setTimeout(() => {
          setLoading(false);
          navigate("/product");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Internal server error");
      });
  }

  const [gen, setgen] = useState(true);
  const [list, setlist] = useState(false);
  const [attr, setattr] = useState(false);
  const [img, setimg] = useState(false);
  const [Meta, setMeta] = useState(false);

  // const handle_Cat_DropDown = (d, e) => {
  //   let fvalues = [];
  //   let cvalues = [];
  //   e.map((n, index) => {
  //     fvalues.push({ label: n.label, value: n.value });
  //     cvalues.push(n.value);
  //   });
  //   let textsss = cvalues.join();
  //   setValue("categories", textsss, { shouldValidate: true });
  //   setValue(d, fvalues);
  // };

  const [Category, setCategory] = useState([]);

  function handle_Cat_DropDown(e) {
    setattributeList("");
    console.log(e);
    setCategory(e);
    setValue("categories", e.value);
    setValue("stcategories", e);

    http
      .get("get_attribute/" + e.value)
      .then((res) => {
        console.log(res);
        if (res.data.length !== 0) {
          let data = res.data;
          data.map((item) => {
            setattributeList((attributeList) => [
              ...attributeList,
              { attribute_id: item.id, name: item.name, value: item.value },
            ]);
          });
        }
        console.log(attributeList);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  // const handle_Rlproudct_DropDown = (d, e) => {
  //   let fvalues = [];
  //   let cvalues = [];
  //   e.map((n, index) => {
  //     fvalues.push({ label: n.label, value: n.value });
  //     cvalues.push(n.value);
  //   });
  //   let textsss = cvalues.join();
  //   console.log(textsss);
  //   setValue("related_product", textsss, { shouldValidate: true });
  //   setValue(d, fvalues);
  // };

  const [attributeList, setattributeList] = useState([]);

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...attributeList];
    onchangeVal[i][name] = value;
    setattributeList(onchangeVal);
    let result = attributeList.map((item, index) => {
      return item;
    });
    console.log(result);
    setValue("attribute", JSON.stringify(result));
  };

  const addAttribute = () => {
    setattributeList([...attributeList, { attribute: "", value: "" }]);
  };

  const RemoveAttribute = (index) => {
    const alist = [...attributeList];
    alist.splice(index, 1);
    // console.log(alist);
    setattributeList(alist);
  };

  const [Displayimages, setDisplayimages] = React.useState([]);
  const [Productimages, setProductimages] = React.useState([]);
  const maxNumber = 69;

  const display_img = (imageList, addUpdateIndex) => {
    // data for submit
    let file;
    let img = imageList.map((imgValue, index) => {
      return (file = imgValue.file);
    });
    setValue("thumb_image", file);
    setDisplayimages(imageList);
    // console.log(imageList);
  };

  const product_img = (imageList, addUpdateIndex) => {
    let img = imageList.map((imgValue, index) => {
      return imgValue.file;
    });
    console.log();
    setValue("product_images", img);
    setProductimages(imageList);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="card">
              <div className="card-header">
                <ul
                  className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <li className="nav-item mt-1" role="presentation">
                    <Link
                      className={gen ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setgen(true);
                        setlist(false);
                        setattr(false);
                        setimg(false);
                        setMeta(false);
                      }}
                    >
                      General Details
                    </Link>
                  </li>
                  <li className="nav-item mt-1 " role="presentation">
                    <Link
                      className={attr ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setattr(true);
                        setgen(false);
                        setlist(false);
                        setimg(false);
                        setMeta(false);
                      }}
                    >
                      Attribute
                    </Link>
                  </li>
                  <li className="nav-item mt-1 " role="presentation">
                    <Link
                      className={img ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setattr(false);
                        setgen(false);
                        setlist(false);
                        setimg(true);
                        setMeta(false);
                      }}
                    >
                      Image
                    </Link>
                  </li>
                  <li className="nav-item mt-1 " role="presentation">
                    <Link
                      className={Meta ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setMeta(true);
                        setlist(false);
                        setattr(false);
                        setgen(false);
                        setimg(false);
                      }}
                    >
                      Meta
                    </Link>
                  </li>
                  <li className="nav-item mt-1 " role="presentation">
                    <Link
                      className={list ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setlist(true);
                        setattr(false);
                        setgen(false);
                        setimg(false);
                        setMeta(false);
                      }}
                    >
                      Listing Price
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {gen && (
                    <div id="general">
                      <div className="row mt-3 mb-3">
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Product Category
                          </label>
                          <CategoryDropdown
                            type="industry_sector"
                            placeholder="Categories"
                            value={Category}
                            change={(e) => handle_Cat_DropDown(e)}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.categories ? "d-block" : ""
                            }`}
                          >
                            {errors.categories ? errors.categories.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Brand
                          </label>
                          <select
                            className="form-select"
                            name="brand"
                            {...register("brand")}
                          >
                            <option value="">Select Brand</option>
                            {BrandList.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.brand_name}
                                </option>
                              );
                            })}
                          </select>
                          <div
                            className={`invalid-feedback ${
                              errors.brand ? "d-block" : ""
                            }`}
                          >
                            {errors.brand ? errors.brand.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Product Name
                          </label>
                          <input
                            className="form-control"
                            name="product_name"
                            placeholder="Enter Product Name"
                            {...register("product_name")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.product_name ? "d-block" : ""
                            }`}
                          >
                            {errors.product_name
                              ? errors.product_name.message
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Model
                          </label>
                          <input
                            className="form-control"
                            name="model"
                            placeholder="Enter Model"
                            {...register("model")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.model ? "d-block" : ""
                            }`}
                          >
                            {errors.model ? errors.model.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Part Number
                          </label>
                          <input
                            className="form-control"
                            name="part_number"
                            placeholder="Enter Part Number"
                            {...register("part_number")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.part_number ? "d-block" : ""
                            }`}
                          >
                            {errors.part_number
                              ? errors.part_number.message
                              : ""}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Specifications
                          </label>
                          <input
                            className="form-control"
                            name="specifications"
                            placeholder="Enter Specifications"
                            {...register("specifications")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.specifications ? "d-block" : ""
                            }`}
                          >
                            {errors.specifications
                              ? errors.specifications.message
                              : ""}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Pack Size
                          </label>
                          <input
                            className="form-control"
                            name="pack_size"
                            defaultValue={"1"}
                            placeholder="Enter Pack Size"
                            {...register("pack_size")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.pack_size ? "d-block" : ""
                            }`}
                          >
                            {errors.pack_size ? errors.pack_size.message : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-12 ">
                          <label> Description</label>
                          <textarea
                            className="form-control"
                            name="keywords"
                            {...register("description")}
                            placeholder="Enter product description"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                          <div
                            className={`invalid-feedback ${
                              errors.description ? "d-block" : ""
                            }`}
                          >
                            {errors.description
                              ? errors.description.message
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-2">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            GST (%)
                          </label>
                          <select
                            className="form-select"
                            name="gst"
                            {...register("gst")}
                          >
                            <option value="">Select GST</option>
                            {GstList.map((Gst, index) => {
                              return (
                                <option key={index} value={Gst.id}>
                                  {Gst.gst}
                                </option>
                              );
                            })}
                          </select>
                          <div
                            className={`invalid-feedback ${
                              errors.gst ? "d-block" : ""
                            }`}
                          >
                            {errors.gst ? errors.gst.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            HSN
                          </label>
                          <input
                            className="form-control"
                            name="hsn"
                            placeholder="Enter HSN"
                            {...register("hsn")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.hsn ? "d-block" : ""
                            }`}
                          >
                            {errors.hsn ? errors.hsn.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            MOQ
                          </label>
                          <input
                            className="form-control"
                            name="moq"
                            placeholder="Enter MOQ"
                            {...register("moq")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.moq ? "d-block" : ""
                            }`}
                          >
                            {errors.moq ? errors.moq.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            UOM
                          </label>
                          <select
                            className="form-select"
                            name="uom"
                            {...register("uom")}
                          >
                            <option value="">Select UOM</option>
                            {UomList.map((uom, index) => {
                              return <option value={uom.id}>{uom.uom}</option>;
                            })}
                          </select>
                          <div
                            className={`invalid-feedback ${
                              errors.uom ? "d-block" : ""
                            }`}
                          >
                            {errors.uom ? errors.uom.message : ""}
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Warranty
                          </label>
                          <input
                            className="form-control"
                            name="warranty"
                            placeholder="Enter Warranty"
                            {...register("warranty")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.warranty ? "d-block" : ""
                            }`}
                          >
                            {errors.warranty ? errors.warranty.message : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-12">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Product Tags
                          </label>
                          <input
                            className="form-control"
                            name="product_tag"
                            placeholder="Enter Product tag"
                            {...register("product_tag")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.product_tag ? "d-block" : ""
                            }`}
                          >
                            {errors.product_tag
                              ? errors.product_tag.message
                              : ""}
                          </div>
                        </div>
                      </div>
                      {/* <div className="row mt-3 mb-3">
                        <div className="col-lg-12">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Related Products
                          </label>
                          <ProductDropdown
                            type="industry_sector"
                            placeholder="Related Products"
                            value={getValues("strelated_product")}
                            change={(e) =>
                              handle_Rlproudct_DropDown("strelated_product", e)
                            }
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.related_product ? "d-block" : ""
                            }`}
                          >
                            {errors.related_product
                              ? errors.related_product.message
                              : ""}
                          </div>
                        </div>
                      </div> */}
                      <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          disabled
                          onClick={() => {
                            // (true);
                            setgen(false);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setattr(true);
                            setgen(false);
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                  {attr && (
                    <div className="container border" id="general">
                      <div className="row ">
                        <div className="col-md-4 border-end px-3 py-2">
                          Attribute
                        </div>
                        <div className="col-md-6 border-end px-3 py-2">
                          Text
                        </div>
                        <div className="col-md-2 p-2"></div>
                      </div>

                      {attributeList.length
                        ? attributeList.map((item, index) => {
                            return (
                              <div key={index} className="row border-top">
                                <div className="col-md-4 d-flex align-items-center border-end p-3">
                                 
                                  <label
                                    className="form-label"
                                    htmlFor="project-title-input"
                                  >
                                    {item.name}
                                  </label>
                                 
                                </div>
                                <div className="col-md-6 d-flex align-items-center border-end p-3">
                                  <textarea
                                    className="form-control"
                                    name="value"
                                    value={item.value}
                                    onChange={(e) => handleChange(e, index)}
                                    placeholder="Value"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                  ></textarea>
                               
                                </div>
                                
                              </div>
                            );
                          })
                        : ""}
                      {/* <div className="row border-top ">
                        <div className="col-md-10 p-3"></div>
                        <div className="col-md-2 p-3">
                          <button
                            type="button"
                            className="btn btn-primary float-end"
                            onClick={addAttribute}
                          >
                            <IoAddCircleSharp size={"20px"} />
                          </button>
                        </div>
                      </div> */}
                      <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setattr(false);
                            setgen(true);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setimg(true);
                            setattr(false);
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                  {img && (
                    <div className="container " id="general">
                      {/* Product Thumb */}
                      <ImageUploading
                        value={Displayimages}
                        onChange={display_img}
                        dataURLKey="data_url"
                        acceptType={["jpg", "gif", "png"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="container">
                            <div>Product Thumbnail</div>
                            <button
                              className="btn btn-primary btn-sm"
                              style={isDragging ? { color: "red" } : null}
                              type="button"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </button>
                            &nbsp;
                            {/* <button
                              className="btn btn-primary btn-sm"
                              onClick={onImageRemoveAll}
                            >
                              Remove image
                            </button> */}
                            <div className="row">
                              {Displayimages.map((image, index) => (
                                <div key={index} className="col-md-3  mt-2">
                                  <div className="border d-flex align-items-center overflow-hidden flex-column">
                                    <img
                                      src={image.data_url}
                                      alt=""
                                      style={{ height: "150px" }}
                                    />
                                    <div className="p-2">
                                      <button
                                        className="btn btn-primary btn-sm"
                                        type="button"
                                        onClick={() => onImageUpdate(index)}
                                      >
                                        Update
                                      </button>
                                      <button
                                        className="btn btn-primary btn-sm ms-2"
                                        type="button"
                                        onClick={() => onImageRemove(index)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>

                      {/* Product Images */}
                      <ImageUploading
                        multiple
                        value={Productimages}
                        onChange={product_img}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={["jpg", "gif", "png"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="container mt-3">
                            <div>Product Gallery</div>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              style={isDragging ? { color: "red" } : null}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm ms-2"
                              onClick={onImageRemoveAll}
                            >
                              Remove all images
                            </button>
                            <div className="row">
                              {Productimages.map((image, index) => (
                                <div key={index} className="col-md-3  mt-2">
                                  <div className="border d-flex align-items-center overflow-hidden flex-column">
                                    <div>
                                      <img
                                        src={image.data_url}
                                        alt=""
                                        style={{ height: "150px" }}
                                      />
                                    </div>
                                    <div className="p-2">
                                      <button
                                        className="btn btn-primary btn-sm"
                                        type="button"
                                        onClick={() => onImageUpdate(index)}
                                      >
                                        Update
                                      </button>
                                      <button
                                        className="btn btn-primary btn-sm ms-2"
                                        type="button"
                                        onClick={() => onImageRemove(index)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                      <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setimg(false);
                            setattr(true);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setMeta(true);
                            setimg(false);
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                  {Meta && (
                    <div id="general">
                      <div className="row mb-3">
                        <div className="col-lg-12">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Meta Tag Title
                          </label>
                          <input
                            className="form-control"
                            name="meta_title"
                            placeholder="Enter Meta Tag Title"
                            {...register("meta_title")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.meta_title ? "d-block" : ""
                            }`}
                          >
                            {errors.meta_title ? errors.meta_title.message : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <label> Meta Tag Description</label>
                          <textarea
                            className="form-control"
                            name="meta_description"
                            {...register("meta_description")}
                            placeholder="Enter meta tag description"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                          <div
                            className={`invalid-feedback ${
                              errors.meta_description ? "d-block" : ""
                            }`}
                          >
                            {errors.meta_description
                              ? errors.meta_description.message
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <label> Meta Tag Keywords</label>
                          <textarea
                            className="form-control"
                            name="meta_keywords"
                            {...register("meta_keywords")}
                            placeholder="Enter meta tag keyword"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                          <div
                            className={`invalid-feedback ${
                              errors.meta_keywords ? "d-block" : ""
                            }`}
                          >
                            {errors.meta_keywords
                              ? errors.meta_keywords.message
                              : ""}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Product Url
                          </label>
                          <input
                            className="form-control"
                            name="seo_url"
                            placeholder="Enter Product Url"
                            {...register("seo_url")}
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.seo_url ? "d-block" : ""
                            }`}
                          >
                            {errors.seo_url ? errors.seo_url.message : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setimg(true);
                            setMeta(false);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setlist(true);
                            setMeta(false);
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                  {list && (
                    <div>
                      <div id="list">
                        <div className="row mt-3 mb-3">
                          <div className="col-lg-4">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              MRP
                            </label>
                            <input
                              className="form-control"
                              name="price"
                              placeholder="Enter MRP "
                              {...register("price")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.price ? "d-block" : ""
                              }`}
                            >
                              {errors.price ? <p>Please enter MRP</p> : ""}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              List Price
                            </label>
                            <input
                              className="form-control"
                              name="sale_price"
                              placeholder="Enter List Price "
                              {...register("sale_price")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.sale_price ? "d-block" : ""
                              }`}
                            >
                              {errors.sale_price ? (
                                <p>Please enter List Price</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Website Display
                            </label>
                            <select
                              className="form-select"
                              name="status"
                              {...register("status")}
                            >
                              <option value="">Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                            <div
                              className={`invalid-feedback ${
                                errors.status ? "d-block" : ""
                              }`}
                            >
                              {errors.status ? errors.status.message : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-lg-4">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Price Display
                            </label>
                            <select
                              className="form-select"
                              name="price_display"
                              {...register("price_display")}
                            >
                              <option value="">Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                            <div
                              className={`invalid-feedback ${
                                errors.price_display ? "d-block" : ""
                              }`}
                            >
                              {errors.price_display
                                ? errors.price_display.message
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setlist(false);
                            setMeta(true);
                          }}
                        >
                          Previous
                        </button>
                        {/* <button
                          type="submit"
                          className="btn btn-danger w-sm"
                          // onClick={() => {
                          //   if (errors) {
                          //     toast.error(
                          //       "Please fill all the required fields"
                          //     );
                          //   }
                          // }}
                        >
                          Submit
                        </button> */}
                        <button
                          type="submit"
                          className="btn btn-danger w-sm"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
