import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../../../shared/Loader";
import http from "../../../../utils/http";

export default function UserRoleList() {
  const navigate = useNavigate();
  const [rolesList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refreshPage();
  }, []);

  function handleDelete(id) {
    http
      .delete(`delete_role/${id}`)
      .then((response) => {
        // console.log('User has been deleted');

        refreshPage();
      })
      .catch((error) => {
        console.log("error delete");
        if (error) {
          console.log(error);
        }
      });
  }

  const handleEdit = (role_id) => {
    console.log(role_id);
    navigate(`/users/user-roles-edit/${role_id}`);
  };
  const refreshPage = () => {
    setLoading(true);
    http
      .get("get_roles")
      .then((res) => {
        console.log(res.data);
        setRoleList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      {/* <Header />
            <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title mb-0">
                        User Roles & Permission
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="row g-4 mb-3">
                        <div className="col-md-12 d-flex justify-content-between">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                          <Link to="/users/user-roles">
                            <button
                              type="button"
                              className="btn btn-primary add-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add Role
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table className="table align-middle table-nowrap mb-0 table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">
                                {/* <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    value="option"
                                                                />
                                                            </div> */}
                              </th>
                              <th data-sort="customer_name">#</th>
                              <th data-sort="customer_name">Role</th>

                              <th data-sort="action">Action</th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {rolesList.map((roles, index) => (
                              <tr key={index}>
                                <th scope="row"></th>
                                <td className="customer_name">{index + 1}</td>
                                <td className="email">{roles.name}</td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="edit">
                                      <Link
                                        onClick={() => handleEdit(roles.id)}
                                        className=" ri-edit-2-fill fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                      ></Link>
                                    </div>
                                    <div className="remove">
                                      <Link
                                        onClick={() => handleDelete(roles.id)}
                                        className="ri-delete-bin-6-line fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                      ></Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
