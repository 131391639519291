import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import axios from "axios";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";

export const Pricing = () => {
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  const [priceList, setpriceList] = useState([]);

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    setLoading(true);
    http
      .get("get_product_supplier_mapping")
      .then((res) => {
        setpriceList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);
  const showDeleteModal = (id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete pricing ?`);
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    http
      .delete(
          `delete_product_supplier_mapping_by_id/${id}`
      )
      .then((response) => {
        toast.success("Pricing deleted");
        setDeleteMessage("User has been deleted");
        fetchdata();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   const [error, setError] = useState(null);

  const showClauseForm = (id) => {
    setUserId(id);

    console.log(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowClauseModal(true);
  };

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  const handleEdit = (idd) => {
    setType("Edit");
    setUserId(idd);
    setshowClauseModal(true);
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  const [checked, setchecked] = useState("");
  const [Status, setStatus] = useState(false);

  const OnStatus = (e, id) => {
    setStatus(true);
    // console.log(e.target, id);
    const { name, checked } = e.target;

    let data = {
      clm: "status",
      clm_val: checked === true ? 1 : 0,
      type_id: id,
      type: "pricing",
    };
    setchecked(checked === true ? 1 : 0 );
    // console.log(data);
    http
      .post(`catalog_switch`, data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />

            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex  justify-content-between ">
                        <h4 className="card-title mt-2">
                          Product Pricing List
                        </h4>
                        <Link
                          // onClick={() => showClauseForm()}
                          to="/pricing/add-pricing"
                          type="button"
                          className="btn btn-primary add-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Product Pricing
                        </Link>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Enter name"
                                  // onChange={(e) => {
                                  //   searchList(e);
                                  // }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex align-items-center justify-content-end">
                            <i
                              class="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              // onClick={handleSearchButtonClick}
                            ></i>
                            <i
                              class="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th>
                                <th data-sort="customer_name">#</th>
                                <th data-sort="email">Product Name</th>
                                <th data-sort="date">Supplier</th>
                                <th data-sort="date">Tax Rate</th>
                                <th data-sort="date">MRP</th>
                                <th data-sort="date">Purchasing Price</th>
                                <th data-sort="date">Discount</th>
                                 <th data-sort="status">
                                  Status
                                </th> 
                                <th data-sort="action" className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {priceList.map((price_val, index) => (
                                <tr key={index}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>
                                  <td className="customer_name">{index + 1}</td>
                                  <td className="email">
                                    {price_val.product_name.slice(0, 20)}
                                  </td>
                                  <td className="email">
                                    {/* {price_val.supplier_name.slice(0, 20)} */}
                                    {price_val.supplier_name}
                                    
                                    {/* {price_val.product_name.slice(0, 20)} */}
                                  </td>
                                  <td className="date">{price_val.tax_rate}</td>
                                  <td className="date">₹ {price_val.mrp}</td>
                                  <td className="date">
                                    ₹ {price_val.purchase_price}
                                  </td>
                                  <td className="date">
                                    {price_val.discount}%
                                  </td>
                                  <td className="customer_name">
                                        <div class="form-check form-switch text-center">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            name={`status-${price_val.id}`}
                                            onChange={(e) => OnStatus(e, price_val.id)}
                                            checked={
                                              Status
                                                ? checked.Status
                                                : price_val.status
                                            }
                                          />
                                        </div>
                                      </td>

                                  <td>
                                    <div className="d-flex gap-2 justify-content-center">
                                      <div className="edit">
                                        <Link
                                          className=" ri-edit-2-fill fs-4"
                                          to={`/pricing/edit-pricing/${price_val.id}`}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          onClick={() =>
                                            showDeleteModal(price_val.id)
                                          }
                                          className="ri-delete-bin-6-line fs-4"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
