import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useEffect } from "react";
import http from "../../../../utils/http";

export const EditMasterUOMModal  = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {

  // useEffect(() => {
  //   btHandleEdit()
  // }, []);
  
  

  const yupValidation = Yup.object().shape({
    uom: Yup.string().required("Please enter uom"),
    status: Yup.string().required("Please enter status"),
  });

 
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  const btHandleEdit = () => {
    console.log(id);
    http.get(`get_uom_by_id/${id}`)

      .then((res) => {
        console.log(res);
        setValue("uom", res.data.uom);
        setValue("status", res.data.status);
       
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  
  useEffect(() => {
    btHandleEdit(id)
  }, [id]);

  function onSubmit(data) {
    console.log(data);
    setLoading(true);
    http
        .put(`update_uom/${id}`, data)
        .then((response) => {
          refreshPage();
        })
        .catch((error) => {
          console.log("error delete");
          if (error) {
            console.log(error);
          }
        });
 
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Master UOM</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 ${resss !== "" && ""}`}>
            <div className="col-lg-6">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  UOM
                </label>
                <input
                  name="gst"
                  type="text"
                  className="form-control"
                  placeholder="Enter Gst"
                  {...register("uom")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${errors.gst ? "d-block" : ""}`}
                >
                  {errors.gst ? errors.gst.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label for="assignedtoName-field" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  className="form-select"
                  {...register("status")}
                >
                  <option value=""> --status-- </option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.status ? "d-block" : ""
                  }`}
                >
                  {errors.status ? errors.status.message : ""}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="hstack gap-2 justify-content-end mt-4 ">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {btnToggle ? (
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            )}
            {/* <button type="submit" className="btn btn-danger" onClick={()=>trashAll()}>Trash All</button> */}
          {/* </div> */} 
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              // reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit" onClick={hideModal}>
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
