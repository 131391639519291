import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
// import ImageUploading from "react-images-uploading";
// import photo from "../../../../assets/images/photo.png";
// import AddUserModal from "../../../../shared/AddUserModal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AddBannerModal from "./AddBannerModal";
import EditBannerModal from "./EditBannerModal";
import http from "../../../../../utils/http";

export const BannerMgm = () => {
  let addRef = useRef(null);
  let editRef = useRef(null);
  let CloseRef = useRef(null);

  // Loader Code
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    refreshPage();
  }, []);
  // Loader code end

  const [Banner, setBanner] = React.useState([]);

  const Banner_img = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList);
    // console.log(imageList, addUpdateIndex);
    setBanner(imageList);
  };

  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState("");
  const [resss, setMessage] = useState("");

  const [error, setError] = useState(null);

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete user`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_images_by_id/${id}`)
      .then((response) => {
        setDeleteMessage("User has been deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  const refreshPage = () => {
    setLoading(true);
    http.get("get_images")
      .then((res) => {
        setuserList(res.data);
        setLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //   const [error, setError] = useState(null);

  const [userList, setuserList] = useState([]);

  // Add banner modal

  const [showAddModal, setshowAddModal] = useState(false);

  const showClauseForm = (id) => {
    setUserId(id);

    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowAddModal(true);
  };

  // Hide the modal
  const hideAddModal = () => {
    setshowAddModal(false);
  };

  const handleEdit = (idd) => {
    setType("Edit");
    setUserId(idd);
    setshowAddModal(true);
  };

  // Edit banner modal

  const [showEditModal, setshowEditModal] = useState(false);

  const showEditForm = (id) => {
    setUserId(id);

    console.log(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowEditModal(true);
  };

  // Hide the modal
  const hideEditModal = () => {
    setshowEditModal(false);
  };

  const yupValidation = Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    alt: Yup.string().required("Please enter alt"),
    url: Yup.string().required("Please enter link"),
  });
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, setValue, reset, formState } = useForm(
    formOptions
  );
  const { errors } = formState;

  function onSubmit(data) {
    console.log(data);

    setLoading(true);
    http
      .post("save_images", data)
      .then((response) => {
        console.log(response);
        setMessage("User has been added");
        setLoading(false);

        const timer = setTimeout(() => {
          refreshPage();
        }, 1500);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const [ID, setID] = useState("");

  function onUpdate(data) {
    console.log(data);

    setLoading(true);
    http
      .put("update_images/" + ID, data)
      .then((response) => {
        console.log(response);
        setMessage("User has been added");
        setLoading(false);
        reset();
        const timer = setTimeout(() => {
          refreshPage();
        }, 1500);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  return (
    <>
      {/* <Header />
      <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />

            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title mb-0">Banner List</h4>
                    </div>
                    <div className="card-body">
                      {/* <!-- Edit Button trigger modal --> */}
                      <button
                        type="button"
                        className="btn btn-primary d-none"
                        data-bs-toggle="modal"
                        data-bs-target="#EditModal"
                        ref={editRef}
                      >
                        Launch demo modal
                      </button>

                      {/* <!-- Edit Modal --> */}
                      <div
                        className="modal fade"
                        id="EditModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Edit Banner Image
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <form onSubmit={handleSubmit(onUpdate)}>
                              <div className="modal-body">
                                <div className={`row g-3`}>
                                  <div className="col-lg-4">
                                    <div id="modal-id">
                                      <label
                                        htmlFor="orderId"
                                        className="form-label"
                                      >
                                        Title
                                      </label>
                                      <input
                                        name="title"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Title"
                                        {...register("title")}
                                      />
                                      {/* if (errors.title !== undefined && errors.title !== null) { */}
                                      <div
                                        className={`invalid-feedback ${
                                          errors.title ? "d-block" : ""
                                        }`}
                                      >
                                        {errors.title
                                          ? errors.title.message
                                          : ""}
                                      </div>
                                      {/* }  */}
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div>
                                      <label
                                        htmlFor="client_nameName-field"
                                        className="form-label"
                                      >
                                        Alt
                                      </label>
                                      <input
                                        type="text"
                                        id="alt"
                                        name="alt"
                                        className="form-control"
                                        placeholder="Enter Alt"
                                        required=""
                                        {...register("alt")}
                                      />

                                      <div
                                        className={`invalid-feedback ${
                                          errors.alt ? "d-block" : ""
                                        }`}
                                      >
                                        {errors.alt ? errors.alt.message : ""}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div>
                                      <label
                                        htmlFor="tasksTitle-field"
                                        className="form-label"
                                      >
                                        Link
                                      </label>
                                      <input
                                        type="text"
                                        id="url"
                                        name="url"
                                        className="form-control"
                                        placeholder="Enter Link"
                                        required=""
                                        {...register("url")}
                                      />
                                      <div
                                        className={`invalid-feedback ${
                                          errors.url ? "d-block" : ""
                                        }`}
                                      >
                                        {errors.url ? errors.url.message : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="row mt-3">
                                  <ImageUploading
                                    multiple
                                    value={Banner}
                                    onChange={Banner_img}
                                    dataURLKey="data_url"
                                    acceptType={["jpg", "gif", "png"]}
                                  >
                                    {({
                                      imageList,
                                      onImageUpload,
                                      onImageRemoveAll,
                                      onImageUpdate,
                                      onImageRemove,
                                      isDragging,
                                      dragProps,
                                    }) => (
                                      // write your building UI
                                      <div className="container">
                                        <button
                                          className="BanBorder rounded d-flex flex-column justify-content-center align-items-center py-4"
                                          style={
                                            isDragging ? { color: "red" } : null
                                          }
                                          type="button"
                                          onClick={onImageUpload}
                                          {...dragProps}
                                        >
                                          <img
                                            src={photo}
                                            alt=""
                                            className="imgAnim"
                                          />
                                          <p
                                            className="mt-2"
                                            style={{ fontWeight: "400" }}
                                          >
                                            Drop your image here, or&nbsp;
                                            <span style={{ color: "#5869d8" }}>
                                              browse
                                            </span>
                                          </p>
                                        </button>
                                        &nbsp;
                                        
                                        <div className="row">
                                          {Banner.map((image, index) => (
                                            <div
                                              key={index}
                                              className="col-md-3 mt-2 mb-3"
                                            >
                                              <div className="border d-flex align-items-center overflow-hidden flex-column">
                                                <img
                                                  src={image.data_url}
                                                  alt=""
                                                  style={{ height: "150px" }}
                                                />
                                                <div className="p-2">
                                                  <Link
                                                    className=" ri-edit-2-fill fs-4"
                                                    type="button"
                                                    onClick={() =>
                                                      onImageUpdate(index)
                                                    }
                                                  ></Link>
                                                  <button
                                                    className="btn btn-primary btn-sm ms-2"
                                                    type="button"
                                                    onClick={() =>
                                                      onImageRemove(index)
                                                    }
                                                  ></button>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </ImageUploading>
                                </div> */}
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-bs-dismiss="modal"
                                  ref={CloseRef}
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  onClick={() => CloseRef.current.click()}
                                >
                                  Save
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-sm-auto">
                            <div></div>
                          </div>
                          <div className="col-sm">
                            <div className="d-flex justify-content-between ">
                              <div className="search-box ms-2">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Search..."
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                              <Link
                                // onClick={() => addRef.current.click()}
                                onClick={() => showClauseForm()}
                                type="button"
                                className="btn btn-primary add-btn"
                              >
                                <i className="ri-add-line align-bottom me-1"></i>{" "}
                                Add Banner image
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th>
                                <th>#</th>
                                <th data-sort="customer_name">Title</th>
                                <th data-sort="customer_name">Alt</th>
                                <th data-sort="date">Link</th>
                                <th data-sort="action">Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {userList.map((user, index) => (
                                <tr key={index}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>
                                  <td className="customer_name">{index + 1}</td>
                                  <td className="customer_name">
                                    {user.title}
                                  </td>
                                  <td className="customer_name">{user.alt}</td>
                                  <td className="date">{user.url}</td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <Link to={`/upload-banner/${user.id}/${user.type}`} className="ri-image-add-line fs-4 "></Link>
                                      </div>
                                      <div className="edit">
                                        <Link
                                          className=" ri-edit-2-fill fs-4"
                                          // onClick={() => {
                                          //   editRef.current.click();
                                          //   fetchDataById(user.id);
                                          //   setID(user.id);
                                          // }}
                                          onClick={() => {
                                            showEditForm(user.id);
                                          }}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          onClick={() =>
                                            showDeleteModal("User", user.id)
                                          }
                                          className="ri-delete-bin-6-line fs-4"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddBannerModal
                    showModal={showAddModal}
                    hideModal={hideAddModal}
                    refreshPage={refreshPage}
                    type={type}
                    id={user_id}
                  />
                  <EditBannerModal
                    showModal={showEditModal}
                    hideModal={hideEditModal}
                    refreshPage={refreshPage}
                    type={type}
                    id={user_id}
                  />
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
