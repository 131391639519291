import React, { useState, useEffect , useRef} from "react";
import { Link , useSearchParams } from "react-router-dom";

import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import moment from "moment";
import http from "../../../../../utils/http";
import CategoryDropdownFilter from "../Category/CategoryDropdownFilter";

import ProductReviewModal from "./ProductReviewModal";
import ReactFlatpickr from "../../../../shared/ReactFlatpickr";
import ProductDropdownSingle from "../Product/ProductDropdownSingle";
import Paginate from "../../../../shared/Paginate";

export const ProductReview = () => {
  const params = new URLSearchParams(window.location.search);

  let url_customer = params.get("customer");
  let url_product_id = params.get("product_id");
  let url_from_date = params.get("from_date");
  let url_to_date = params.get("to_date");
  let url_status = params.get("status");
  let url_page = params.get("page");

  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState("");
  const [resss, setMessage] = useState("");
  const [error, setError] = useState(null);

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);

  /*************FILTERS*******************/
  const [searchParams, setSearchParams] = useSearchParams();
  const [customerName, setcustomerName] = useState("");
  const [ProductId, setProductId] = useState("");
  const [productValue, setproductValue] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [product_status, setProductStatus] = useState();


  const [reviewList, setReviewList] = useState([]);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();
  const [Offset, setOffset] = useState("");

  function searchCustomer (e) {
    setcustomerName(e.target.value)
  }

  function getProductId (e) {
    setproductValue(e);
    setProductId(e.value);
  }
  console.log("productValue",productValue);

  const creation_rfq_from_date = {
    onChange: (selectedDates, dateStr, instance) => {
      setfrom_date(dateStr)
    }
  }
  const creation_rfq_to_date = {
    onChange: (selectedDates, dateStr, instance) => {
      setto_date(dateStr)
    }
  }

  function productStatus(e) {
    setProductStatus(e.target.value);
  }


  useEffect(() => {
    if (url_customer != "") {
      setcustomerName(url_customer);
    }
    if (url_product_id != "") {
      setProductId(url_product_id);
    }
    if (url_from_date != "") {
      setfrom_date(url_from_date);
    }
    if (url_to_date != "") {
      setto_date(url_to_date);
    }
    if (url_status != "") {
      setProductStatus(url_status);
    }

    refreshPage(
      url_customer,
      url_product_id,
      url_from_date,
      url_to_date,
      url_status,
      url_page
    );
  }, []);

  let ref = useRef(null);

  function reset_filter() {
    setcustomerName("");
    setProductId("");
    setproductValue("");
    setfrom_date("");
    setto_date("");
    // setProductStatus("")
    ref.current.value = "";
    // console.log("value", ref.current.value);
    refreshPage("", "", "", "","",  1);
  }

  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(
      customerName,
      ProductId,
      from_date,
      to_date,
      product_status,
      pageNumber
    );
  };

  function search_filter() {
    removeErrorParam();
    setSearchParams(params);
    refreshPage(
      customerName,
      ProductId,
      from_date,
      to_date,
      product_status,
      1
    );
  }

  function getPages(urls = "") {
    setLoading(true);

    http
      .get(`get_product_page_count${urls}`)
      .then((res) => {
        console.log(res.data);
        setPages(res.data.page_no);
        setTotal(res.data.total_products);
        setOffset(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }



  const refreshPage = (
    customer = "",
    product_id = "",
    from_date = "",
    to_date = "",
    status = "",
    page_to = ""
  ) => {
    setLoading(true);

    // let page_to_load = page_to != "" ? page_to : currentPage;
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (customer) {
      urls += `&customer=${customer}`;
    }
    if (product_id) {
      urls += `&product_id=${product_id}`;
    }
    if (from_date) {
      urls += `&from_date=${from_date}`;
    }
    if (to_date) {
      urls += `&to_date=${to_date}`;
    }
    if (status) {
      urls += `&status=${status}`;
    }
    console.log("page_to_load", page_to_load);
    setSearchParams(urls);
    // getPages(urls);
    http
      .get(`get_reviews${urls}`)
      .then((res) => {
        setReviewList(res.data);
        setLoading(false);
        // UserComponentVerifiy(res);
      })
      .catch((err) => {
        // toast.error(err.message);
        console.log(err.message);
      });
  };
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete product review ?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    console.log(id);
    setError(null);
    http
      .delete(`delete_review/${id}`)
      .then((response) => {
        setDeleteMessage("User has been deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  //   const [error, setError] = useState(null);

  //   const showClauseForm = (id) => {
  //     setUserId(id);

  //     console.log(id);
  //     // setDeleteMessage(`Are you sure you want to delete the vegetable`);
  //     setshowClauseModal(true);
  //   };

  // Hide the modal
  //   const hideClauseModal = () => {
  //     setshowClauseModal(false);
  //   };

  // Loader Code
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  // Loader code end

  // Add banner modal

  const [showAddModal, setshowAddModal] = useState(false);

  const showClauseForm = (id) => {
    setUserId(id);

    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowAddModal(true);
  };

  // Hide the modal
  const hideAddModal = () => {
    setshowAddModal(false);
  };

  const [showAddressModalCard, setshowAddressModalCard] = useState(false);
  //Show Address Modal
  const [showAddressModal, setshowAddressModal] = useState(false);
  const showClauseAddress = (id) => {
    setUserId(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowAddressModal(true);
    setshowAddressModalCard(true);
  };
  // Hide the modal
  const hideAddressModal = () => {
    setshowAddressModal(false);
  };

  // Edit banner modal

  const [showreviewModal, setshowReviewModal] = useState(false);

  const showReviewModal = (id) => {
    setUserId(id);

    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowReviewModal(true);
  };

  // Hide the modal
  const hideReviewModal = () => {
    setshowReviewModal(false);
  };

 
  

 
  
  
  
  return (
    <>
      {/* <Header />
      <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between ">
                      <h4 className="card-title mb-0">Product Review</h4>
                    </div>
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-md-11">
                            <div className="row">
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Name, Email, etc.."
                                  onChange={(e) => {
                                    searchCustomer(e);
                                  }}
                                  value={customerName}
                                />
                              </div>
                              <div className="col-md-3">
                               <ProductDropdownSingle
                               placeholder="Search Product"
                               value={productValue}
                                change={(e) => getProductId(e)}
                               />
                              </div>
                              <div className="col-md-2">
                                <ReactFlatpickr
                                placeholder="From Date"
                                options={creation_rfq_from_date}
                                value={from_date}
                                />
                              </div>
                              <div className="col-md-2">
                              <ReactFlatpickr
                                placeholder="To Date"
                                options={creation_rfq_to_date}
                                value={to_date}
                                />
                              </div>
                              <div className="col-md-2">
                              <select class="form-select mb-3"
                               aria-label="Default select example"
                               onChange={(e) => productStatus(e)}
                              //  defaultValue={url_status}
                               ref={ref}
                               >
                                  <option value="">Status </option>
                                  <option value="1">Approve</option>
                                  <option value="0">Pending</option>
                              </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 mb-3 d-flex align-items-center">
                            <i
                              class="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              onClick={search_filter}
                            ></i>
                            <i
                              class="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={reset_filter}
                            ></i>
                          </div>
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th data-sort="customer_name" style={{ width: "2rem" }}>Sr.no</th>
                                <th data-sort="customer_name">Customer</th>
                                <th data-sort="date">Product</th>
                                <th data-sort="date" style={{ width: "2rem" }}>Rating</th>
                                <th data-sort="date" style={{ width: "2rem" }}>Indate</th>
                                <th data-sort="date"  style={{ width: "2rem" }}>Status</th>
                                <th data-sort="action" style={{ width: "3rem" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                            {loading === true && (
                              <tr>
                                <td className="customer_name">
                                  <Loader show={loading} />
                                </td>
                              </tr>
                            )}
                              {
                              loading === false &&
                              (reviewList.length
                                ? reviewList.map((item, index) => (
                                <tr key={index}>
                                  <td className="customer_name">{index + 1}</td>
                                  <td
                                    className="customer_name"
                                    style={{ width: "5rem" }}
                                  >
                                    {item.customer_name} <br />{" "}
                                    {item.customer_email}
                                  </td>
                                  <td
                                    className="mobile text-wrap"
                                    style={{ width: "19rem" }}
                                  >
                                    {item.product_name === null
                                      ? "No"
                                      : item.product_name}
                                  </td>
                                  <td className="mobile">
                                    {
                                      <div class="badge rounded-pill bg-success mb-0 ">
                                        <i class="mdi mdi-star"></i>{item.rating}.0
                                      </div>
                                    }
                                  </td>
                                  <td className="mobile">
                                    {moment(item.indate)
                                      .utc()
                                      .format("DD/MM/YYYY")}
                                  </td>
                                  <td className="status text-center ">
                                  <span
                                    className={`badge text-uppercase ${
                                      item.status == 1
                                        ? "bg-success-subtle text-success"
                                        : "bg-danger-subtle text-warning"
                                    }`}
                                  >
                                    {item.status == 1 ? "Approve" : "Pending"}
                                  </span>
                                </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <Link
                                          className="ri-eye-line fs-4"
                                          onClick={() => {
                                            showReviewModal(item.id);
                                          }}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          onClick={() =>
                                            showDeleteModal("User", item.id)
                                          }
                                          className="ri-delete-bin-6-line fs-4"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                              : null)}
                            </tbody>
                          </table>
                        </div>
                        {pages > 1 && (
                      <Paginate
                        postsPerPage={20}
                        totalPosts={total}
                        paginate={paginate}
                        url_page={url_page}
                      />
                    )}
                      </div>
                    </div>
                  </div>
                  <ProductReviewModal
                    showModal={showreviewModal}
                    hideModal={hideReviewModal}
                    refreshPage={refreshPage}
                    type={type}
                    id={user_id}
                  />

                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
