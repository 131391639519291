import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";

export const AttributeForm = () => {
  const [attr_group_List, setattr_group_List] = useState([]);

  useEffect(() => {
    fetchattrgroup();
  }, []);

  const fetchattrgroup = () => {
    http
      .get("get_attribute_group")
      .then((res) => {
        console.log(res.data);
        setattr_group_List(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please enter attribute name"),
    value: Yup.string().required("Please enter master values"),
    attribute_group_id: Yup.string().required("Please select attribute group"),
    // type: Yup.string().required("Please select type"),
    status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit(data) {
    console.log(data);
    setLoading(true);

    http
      .post(`save_attribute`, data)
      .then((response) => {
        toast.success("Attribute has been added");
        const timer = setTimeout(() => {
          setLoading(false);
          navigate("/attribute");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const handleDropDown = (d, e) => {
    let fvalues = [];
    e.map((n, index) => {
      fvalues.push(n.value);
    });
    let textsss = fvalues.join();
    setValue(d, textsss, { shouldValidate: true });
  };

  return (
    <>
      <div
        className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message ===
          "" && "d-none"}`}
      >
        {message}
      </div>
      {/* <Header />
      <SideBar /> */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Add Attribute</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <div className="col-lg-6 ">
                      <label
                        className="form-label"
                        htmlhtmlhtmlFor="project-title-input"
                      >
                        Attribute Group
                      </label>
                      <select
                        className="form-select"
                        name="attribute_group_id"
                        {...register("attribute_group_id")}
                      >
                        <option value="">Select Attribute Group</option>
                        {attr_group_List.map((attr, index) => {
                          return <option value={attr.id}>{attr.name}</option>;
                        })}
                      </select>

                      <div
                        className={`invalid-feedback ${
                          errors.attribute_group_id ? "d-block" : ""
                        }`}
                      >
                        {errors.attribute_group_id
                          ? errors.attribute_group_id.message
                          : ""}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Attribute Name
                      </label>
                      <input
                        className="form-control"
                        name="name"
                        placeholder="Enter Attribute Name"
                        {...register("name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.name ? "d-block" : ""
                        }`}
                      >
                        {errors.name ? errors.name.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <label> Master Values</label>
                      <textarea
                        className="form-control"
                        name="value"
                        {...register("value")}
                        placeholder="Enter master values"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                      <div
                        className={`invalid-feedback ${
                          errors.value ? "d-block" : ""
                        }`}
                      >
                        {errors.value ? errors.value.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    {/* <div className="col-lg-6 ">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Type
                      </label>
                      <select
                        className="form-select"
                        name="type"
                        {...register("type")}
                      >
                        <option value="1">
                          Alphanumeric
                        </option>
                        <option value="0">Alpha</option>
                      </select>
                      <div
                        className={`invalid-feedback ${
                          errors.type ? "d-block" : ""
                        }`}
                      >
                        {errors.type ? errors.type.message : ""}
                      </div>
                    </div> */}
                    <div className="col-lg-6 ">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Status
                      </label>
                      <select
                        className="form-select"
                        name="status"
                        {...register("status")}
                      >
                        <option value="1">Active</option>
                        <option value="0">In Active</option>
                      </select>
                      <div
                        className={`invalid-feedback ${
                          errors.status ? "d-block" : ""
                        }`}
                      >
                        {errors.status ? errors.status.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 mt-4 text-end">
                    <button
                      type="submit"
                      className="btn btn-danger w-sm"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Save Attribute"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
