import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";
// import ImageUploading from "react-images-uploading";
// import photo from "../../../assets/images/photo.png";

const EditBannerModal = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  const [Banner, setBanner] = React.useState([]);

  const Banner_img = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList);
    // console.log(imageList, addUpdateIndex);
    setBanner(imageList);
  };

  const yupValidation = Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    alt: Yup.string().required("Please enter alt"),
    url: Yup.string().required("Please enter link"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  if (showModal) {
    (() => {
      http.get("get_images_by_id/" + id)
        .then((res) => {
          setValue("title", res.data.title);
          setValue("alt", res.data.alt);
          setValue("url", res.data.url);
        })
        .catch((err) => {
          console.log(err.message);
        });
    })();
  }

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    setBtnloading(true);
    http
      .put("update_images/" + id, data)
      .then((response) => {
        console.log(response);
        const timer = setTimeout(() => {
          reset();
          setBtnloading(false);
          refreshPage();
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Banner</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3`}>
            <div className="col-lg-4">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Title
                </label>
                <input
                  name="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter Title"
                  {...register("title")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.title ? "d-block" : ""
                  }`}
                >
                  {errors.title ? errors.title.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>

            <div className="col-lg-4">
              <div>
                <label htmlFor="client_nameName-field" className="form-label">
                  Alt
                </label>
                <input
                  type="text"
                  id="alt"
                  name="alt"
                  className="form-control"
                  placeholder="Enter Alt"
                  required=""
                  {...register("alt")}
                />

                <div
                  className={`invalid-feedback ${errors.alt ? "d-block" : ""}`}
                >
                  {errors.alt ? errors.alt.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <label htmlFor="tasksTitle-field" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  id="url"
                  name="url"
                  className="form-control"
                  placeholder="Enter Link"
                  required=""
                  {...register("url")}
                />
                <div
                  className={`invalid-feedback ${errors.url ? "d-block" : ""}`}
                >
                  {errors.url ? errors.url.message : ""}
                </div>
              </div>
            </div>
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button variant="danger" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            disabled={Btnloading}
            onClick={hideModal}
          >
            {Btnloading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditBannerModal;
