import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../../../../common/Header";
import SideBar from "../../../../common/SideBar";
import Footer from "../../../../common/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";

import MasterDropDown from "../../MasterDropDown";
import Textarea from "../../../../shared/Textarea";
import { Navlink } from "../../../../common/Navlink";


export const Listdetailform = () => {

    const navigate = useNavigate();
    console.log("hi Its loading");
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [inputs, setInputs] = useState([]);

    const yupValidation = Yup.object().shape({
        standard_type: Yup.string().required("Please select standard type"),
        standard_code: Yup.string().required("Please enter standard code"),
        standard_name: Yup.string().required("Please enter standard name"),
        standard_title: Yup.string().required("Please enter standard title"),
        current_publish_year: Yup.string().required("Please enter publish year"),
        management_system: Yup.string().required("Please select management system"),
        publication_date: Yup.string().required("Please select publication date"),
        display_abbreviation: Yup.string().required("Please enter short name"),
        requirement_guideline: Yup.string().required(
            "Please select requirement/guideline"
        ),
        standard_description: Yup.string().required(
            "Please enter standard description"
        ),
        displayorder: Yup.string().required("Please enter priority "),
        displaystatus: Yup.string().required("Please select status "),
        department_id: Yup.string().required("Please select department"),
    });

    const formOptions = { resolver: yupResolver(yupValidation) };
    const {
        register,
        setValue,
        resetField,
        getValues,
        handleSubmit,
        reset,
        formState,
    } = useForm(formOptions);
    const { errors } = formState;
    function onSubmit(data) {
        setLoading(true);
        axios
            .post(process.env.REACT_APP_API_BASE + `standard/`, data)
            .then((response) => {

                setMessage("Standard has been added");
                const timer = setTimeout(() => {

                    setLoading(false);
                    navigate("/standard");
                }, 2000);
                return () => clearTimeout(timer);


            })
            .catch((error) => {
                setLoading(false);
                setMessage("Something went wrong");
            });
    }
    let standard_type = "";
    let standard_code = "";
    let current_publish_year = "";
    const handleStName = (e) => {
        console.log(e.target.name);

        if (e.target.name == "standard_type") {
            standard_type = e.target.value;
            setValue("standard_type", standard_type);
        }
        if (e.target.name == "standard_code") {
            standard_code = e.target.value;
            setValue("standard_code", standard_code);
        }
        if (e.target.name == "current_publish_year") {
            current_publish_year = e.target.value;
            setValue("current_publish_year", current_publish_year);
        }
        let standard_name =
            standard_type + " " + standard_code + " : " + current_publish_year;
        setValue("standard_name", standard_name);
    };
    const handleDropDown = (d, e) => {
        let fvalues = [];
        e.map((n, index) => {
            fvalues.push(n.value);
        });
        let textsss = fvalues.join();
        setValue(d, textsss, { shouldValidate: true });
    };

    return (
        <>
            <div className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message === '' && "d-none"}`}>{message}</div>
            <Header />
            <SideBar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Add Product</h4>
                        </div>
                    </div>
                </div> */}
                        <div className="card">
                            <div className="card-header">
                                <Navlink />
                            </div>
                            <div className="card-body">

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row mb-3">

                                        <div className="col-lg-4">
                                            <label
                                                className="form-label"
                                                htmlFor="project-title-input"
                                            >
                                                MRP
                                            </label>
                                            <input
                                                className="form-control"
                                                name="standard_title"
                                                placeholder="Enter MRP "
                                                {...register("standard_title")}
                                            />
                                            <div
                                                className={`invalid-feedback ${errors.standard_title ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.standard_title
                                                    ? errors.standard_title.message
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label
                                                className="form-label"
                                                htmlFor="project-title-input"
                                            >
                                                List Price
                                            </label>
                                            <input
                                                className="form-control"
                                                name="standard_title"
                                                placeholder="Enter List Price "
                                                {...register("standard_title")}
                                            />
                                            <div
                                                className={`invalid-feedback ${errors.standard_title ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.standard_title
                                                    ? errors.standard_title.message
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label className="form-label" htmlFor="project-title-input">
                                                Website Display
                                            </label>
                                            <select
                                                className="form-select"
                                                name="displaystatus"
                                                {...register("displaystatus")}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            <div
                                                className={`invalid-feedback ${errors.displaystatus ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.displaystatus
                                                    ? errors.displaystatus.message
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-4">
                                            <label className="form-label" htmlFor="project-title-input">
                                                Price Display
                                            </label>
                                            <select
                                                className="form-select"
                                                name="displaystatus"
                                                {...register("displaystatus")}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            <div
                                                className={`invalid-feedback ${errors.displaystatus ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.displaystatus
                                                    ? errors.displaystatus.message
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4 text-end">
                                        <button type="submit" className="btn btn-danger w-sm" disabled={loading}>
                                            {loading ? 'Loading...' : 'Save Product'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Footer />
        </>
    )
}
