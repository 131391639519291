import React, { useState, useEffect, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import http from "../../../../../utils/http";

const AttributeDropdown = (props) => {
  const opt = [];

  const [options, setoptions] = useState(opt);

  let selectRef = useRef(null);

  const clearValue = () => {
    console.log(selectRef);
    selectRef.current.clearValue();
  };

  useEffect(() => {
    clearValue();
    http.get("get_all_attribute")
      .then((res) => {
        console.log(res.data);
        let data = res.data
        data.forEach((val) => {
          opt.push({
            label: val.name,
            value: val.id,
          });
        });

        setoptions(opt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props.id, props.refresh]);

  return (
    // <Select
    //     options={options}
    //     onChange={props.change}
    //     placeholder={props.placeholder}
    //     isMulti
    //     isSearchable={true}
    // />
    <CreatableSelect
      ref={selectRef}
      isClearable
      options={options}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.change}
    />
  );
};

export default AttributeDropdown;
