import React, { useState, useEffect,useRef } from "react";
import { Link  , useSearchParams} from "react-router-dom";
import AddUserModal from "../../../../shared/AddUserModal";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";

import SideBar from "../../../../common/SideBar";
import Header from "../../../../common/Header";
import Loader from "../../../../shared/Loader";
import Footer from "../../../../common/Footer";
import { AddCustomerModal } from "./AddCustomer";
import { EditCustomerModal } from "./EditCustomer";
import http from "../../../../../utils/http";
import { CustomerAddressList } from "./CustomerAddressList";
import moment from "moment";
import Paginate from "../../../../shared/Paginate";

export const Customer = () => {
  const params = new URLSearchParams(window.location.search);

  let url_company_name = params.get("company_name");
  let url_address = params.get("address");
  let url_customer = params.get("customer");
  let url_page = params.get("page");


  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState("");
  const [resss, setMessage] = useState("");
  const [error, setError] = useState(null);

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);

  
  const [searchParams, setSearchParams] = useSearchParams();
  // *************Filter's**************************
  const [company_name, setcompany_name] = useState("");
  const [cust_address, setcust_address] = useState("");
  const [cust_name, setcust_name] = useState("");

  function searchCompanyName (e) {
    setcompany_name(e.target.value)
  }
  function searchAddress (e) {
    setcust_address(e.target.value)
  }
  function searchCustName (e) {
    setcust_name(e.target.value)
  }

  const [userList, setuserList] = useState([]);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();

  const [Offset, setOffset] = useState("");

  useEffect(() => {
    if (url_company_name != "") {
      setcompany_name(url_company_name);
    }
    if (url_address != "") {
      setcust_address(url_address);
    }
    if (url_customer != "") {
      setcust_name(url_customer);
    }

    refreshPage(
      url_company_name,
      url_address,
      url_customer,
      url_page
    );
  }, []);

  let ref = useRef(null);

  function reset_filter() {
    setcompany_name("");
    setcust_address("");
    setcust_name("");
    // ref.current.value = "";
    // console.log("value", ref.current.value);
    refreshPage("", "", "",  1);
  }

  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(
      company_name,
      cust_address,
      cust_name,
      pageNumber
    );
  };

  function search_filter() {
    removeErrorParam();
    setSearchParams(params);
    refreshPage(
      company_name,
      cust_address,
      cust_name,
      1
    );
  }

  function getPages(urls = "") {
    setLoading(true);

    http
      .get(`get_customers_count${urls}`)
      .then((res) => {
        console.log(res.data);
        setPages(res.data.page_no);
        setTotal(res.data.total);
        setOffset(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const refreshPage = (
    company_name = "",
    cust_address = "",
    cust_name = "",
    page_to = ""
  ) => {
    setLoading(true);

    // let page_to_load = page_to != "" ? page_to : currentPage;
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (company_name) {
      urls += `&company_name=${company_name}`;
    }
    if (cust_address) {
      urls += `&address=${cust_address}`;
    }

    if (cust_name) {
      urls += `&customer=${cust_name}`;
    }

    console.log("page_to_load", page_to_load);
    setSearchParams(urls);
    getPages(urls);
    http
      .get(`get_customers${urls}`)
      .then((res) => {
        setuserList(res.data);
        setLoading(false);
        // UserComponentVerifiy(res);
      })
      .catch((err) => {
        // toast.error(err.message);
        console.log(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete customer?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    console.log(id);
    setError(null);
    http
      .delete(`delete_customer_by_id/${id}`)
      .then((response) => {
        setDeleteMessage("User has been deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  //   const [error, setError] = useState(null);

  //   const showClauseForm = (id) => {
  //     setUserId(id);

  //     console.log(id);
  //     // setDeleteMessage(`Are you sure you want to delete the vegetable`);
  //     setshowClauseModal(true);
  //   };

  // Hide the modal
  //   const hideClauseModal = () => {
  //     setshowClauseModal(false);
  //   };

  // Loader Code
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  // Loader code end

  // Add banner modal

  const [showAddModal, setshowAddModal] = useState(false);

  const showClauseForm = (id) => {
    setUserId(id);

    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowAddModal(true);
  };
 

  // Hide the modal
  const hideAddModal = () => {
    setshowAddModal(false);
  };

const [showAddressModalCard, setshowAddressModalCard] = useState(false);
//Show Address Modal
const [showAddressModal, setshowAddressModal] = useState(false);
  const showClauseAddress = (id) => {
    setUserId(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowAddressModal(true);
    setshowAddressModalCard(true)
  };
// Hide the modal
const hideAddressModal = () => {
  setshowAddressModal(false);
};

  // Edit banner modal

  const [showEditModal, setshowEditModal] = useState(false);

  const showEditForm = (id) => {
    setUserId(id);

    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowEditModal(true);
  };

  // Hide the modal
  const hideEditModal = () => {
    setshowEditModal(false);
  };


  return (
    <>
      {/* <Header />
      <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between ">
                      <h4 className="card-title mb-0">Customer List</h4>
                      <Link
                        onClick={() => showClauseForm()}
                        // to="/webpage/add-webpage"
                        className="btn btn-primary add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Add
                        Customer
                      </Link>
                    </div>
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Company Name"
                                  onChange={(e) => {
                                    searchCompanyName(e);
                                  }}
                                  value={company_name}
                                />
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Pincode"
                                  onChange={(e) => {
                                    searchAddress(e);
                                  }}
                                  value={cust_address}
                                />
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Contact Name/Email/Mobile"
                                  onChange={(e) => {
                                    searchCustName(e);
                                  }}
                                  value={cust_name}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex align-items-center">
                            <i
                              class="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              onClick={search_filter}
                            ></i>
                            <i
                              class="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={reset_filter}
                            ></i>
                          </div>
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th data-sort="customer_name">#</th>
                                <th data-sort="customer_name">Company Name</th>
                                <th data-sort="customer_name">Name</th>
                                <th data-sort="email">Email</th>
                                <th data-sort="date">Mobile</th>
                                <th data-sort="customer_name">Pincode</th>
                                <th data-sort="status">Indate</th>
                                <th data-sort="status">Status</th>
                                <th data-sort="action">Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                            {loading === true && (
                            <tr>
                              <td className="customer_name">
                                <Loader show={loading} />
                              </td>
                            </tr>
                          )}
                              {loading === false &&
                            (userList.length
                              ? userList.map((user, index) => (
                                <tr key={index}>
                                  <td className="customer_name">{index + Offset + 1}</td>
                                  <td className="customer_name">{user.company_name}</td>
                                  <td className="customer_name">
                                    {user.f_name + " " + user.l_name}
                                  </td>
                                  <td className="email">{user.email}</td>
                                  <td className="mobile">{user.mobile}</td>
                                  <td className="email">{user.pincode}</td>
                                  <td className="mobile">{moment(user.indate)
                                        .utc()
                                        .format("DD/MM/YYYY")}</td>
                                  <td className="status">
                                    <span className="badge bg-success-subtle text-success text-uppercase">
                                      {user.status == 1
                                        ? "Active"
                                        : "In-active"}
                                    </span>
                                  </td>

                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <Link
                                          className="ri-home-8-fill fs-4"
                                          onClick={() => {
                                            showClauseAddress(user.id);
                                          }}
                                        ></Link>
                                      </div>
                                      <div className="edit">
                                        <Link
                                          className="ri-edit-2-fill fs-4"
                                          onClick={() => {
                                            showEditForm(user.id);
                                          }}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          onClick={() =>
                                            showDeleteModal("User", user.id)
                                          }
                                          className="ri-delete-bin-6-line fs-4"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )) 
                            : null)}
                            </tbody>
                          </table>
                        </div>
                        {pages > 1 && (
                      <Paginate
                        postsPerPage={20}
                        totalPosts={total}
                        paginate={paginate}
                        url_page={url_page}
                      />
                    )}
                      </div>
                    </div>
                  </div>
                  <AddCustomerModal
                    showModal={showAddModal}
                    hideModal={hideAddModal}
                    refreshPage={refreshPage}
                    type={type}
                    // id={user_id}
                  />
                 { showAddressModalCard === true ? 
                  <CustomerAddressList
                  showModal={showAddressModal}
                  hideModal={hideAddressModal}
                  refreshPage={refreshPage}
                  cardShow={setshowAddressModalCard}
                  id={user_id} 
                /> : null}
                
                  <EditCustomerModal
                    showModal={showEditModal}
                    hideModal={hideEditModal}
                    refreshPage={refreshPage}
                    type={type}
                    id={user_id}
                  />
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};