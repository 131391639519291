import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../../components/pages/controllers/Login";
import SignUp from "../../components/pages/controllers/SignUp";
import Home from "../../components/pages/controllers/Home";
import Users from "../../components/pages/controllers/Users";
import Master from "../../components/pages/views/Master";
import ClauseMapping from "../../components/pages/controllers/ClauseMapping";
import NewTemplate from "../../components/pages/views/NewTemplate";
import StartInspection from "../../components/pages/controllers/StartInspection";
import PrivateRoutes from "../../utils/PrivateRoutes";
import PublicRoutes from "../../utils/PublicRoutes";
import NotFound from "../pages/views/NotFound";
import Dashboard from "../pages/views/Dashboard";
import AddStandard from "../pages/views/AddStandard";
// import Suppliers from '../pages/views/Suppliers';
import ScrollToTop from "../../utils/scrollToTop";
import SmfMatrixMapping from "../pages/views/SmfMatrixMapping";
import MatrixMapping from "../pages/controllers/MatrixMapping";
import { Category } from "../pages/views/Catalog/Category/Category";
import AddBasinfo from "../pages/views/Supplier/AddBasicInfo";
import AddBillingDetails from "../pages/views/Supplier/AddBillingDetails";
import AddContactDetails from "../pages/views/Supplier/AddContactDetails";
import AddAdditionalDetails from "../pages/views/Supplier/AddAdditionalDetails";
import MasterGST from "../pages/views/Master/MasterGST";
import MasterUOM from "../pages/views/Master/MasterUOM";
import MasterBusinessType from "../pages/views/Master/MasterBusinessType";
import MasterPincode from "../pages/views/MasterPincode";
import MasterBrandName from "../pages/views/MasterBrandName";
import MasterTypeofCompany from "../pages/views/Master/MasterTypeofCompany";

import { Attribute } from "../pages/views/Catalog/Attribute/Attribute";
import { Product } from "../pages/views/Catalog/Product/Product";
import { Pricing } from "../pages/views/Catalog/Pricing/Pricing";
import { CategoryForm } from "../pages/views/Catalog/Category/CategoryForm";
import { AttributeForm } from "../pages/views/Catalog/Extra/AttributeForm";
import { Listdetailform } from "../pages/views/Catalog/Extra/Listdetailform";
import { AddDataform } from "../pages/views/Catalog/Extra/AddDataform";
import { EditCategoryForm } from "../pages/views/Catalog/Category/EditCategoryForm";
import { EditAttributeForm } from "../pages/views/Catalog/Attribute/EditAttributeForm";
import { ProductForm } from "../pages/views/Catalog/Extra/ProductForm";
import { EditProductForm } from "../pages/views/Catalog/Product/EditProductForm";
import { PricingForm } from "../pages/views/Catalog/Pricing/PricingForm";
import { EditPricingForm } from "../pages/views/Catalog/Pricing/EditPricingForm";
import { ProductLink } from "../pages/views/Catalog/Extra/ProductLink";
import Standard from "../pages/controllers/Standard";
import { BannerMgm } from "../pages/views/Catalog/Banner/BannerMgm";
import { Webpage } from "../pages/views/Catalog/Web Page/Webpage";
import { WebpageForm } from "../pages/views/Catalog/Web Page/WebpageForm";
import { EditWebpageForm } from "../pages/views/Catalog/Web Page/EditWebpageForm";
import { Customer } from "../pages/views/Catalog/Customer/Customer";
import { Order } from "../pages/views/Catalog/Order/Order";
import { OrderForm } from "../pages/views/Catalog/Order/OrderForm";
import { OrderItemForm } from "../pages/views/Catalog/Order/OrderItemForm";
import UserRoles from "../pages/views/User/UserRoles";
import UserList from "../pages/views/UserList";
import UserRoleList from "../pages/views/User/UserRoleList";
import UserRolesEdit from "../pages/views/User/UserRolesEdit";
import Header from "./Header";
import SideBar from "./SideBar";
import Footer from "./Footer";
import SupplierList from "../pages/views/Supplier/SupplierList";
import { OrderConfirmForm } from "../pages/views/Catalog/Order/OrderConfirm";

import DataTab from "../pages/views/DataTab";

import DiscountCouponList from "../pages/views/Discount Coupon/DiscountCouponList";
import { DiscountCouponForm } from "../pages/views/Discount Coupon/DiscountCouponForm";
import { DiscountCouponEdit } from "../pages/views/Discount Coupon/DiscountCouponEdit";
import Dashboard2 from "../pages/views/Dashboard2";
import { OrderView } from "../pages/views/Catalog/Order/OrderView";
import { SeoSetting } from "../pages/views/Catalog/SEO/SeoSetting";
import { SeoForm } from "../pages/views/Catalog/SEO/SeoForm";
import { EditSeoForm } from "../pages/views/Catalog/SEO/EditSeoForm";
import BannerUpload from "../pages/views/Catalog/Banner/BannerUpload";
import { TestProduct } from "../pages/views/Catalog/Product/TestProduct";
import { LeadManagement } from "../pages/views/Catalog/Lead Management/LeadMgmnt";
import { AddLead } from "../pages/views/Catalog/Lead Management/AddLead";
import { UploadLead } from "../pages/views/Catalog/Lead Management/UploadLead";
import { AssignMnagementList } from "../pages/views/Catalog/Lead Management/AssignMnagementList";
import { CustomerAddressForm } from "../pages/views/Catalog/Customer/CustomerAddressForm";
import { CustomerAddressList } from "../pages/views/Catalog/Customer/CustomerAddressList";
import MasterPaymentModes from "../pages/views/Master/MasterPaymentModes";
import { SalesReport } from "../pages/views/Reports Management/SalesReport";
import { UserReport } from "../pages/views/Reports Management/UserReport";
import SupplierReports from "../pages/views/Reports Management/SupplierReports";
import { SupplierView } from "../pages/views/Supplier/SupplierView";

import { BulkCreation } from "../pages/views/Catalog/Product/BulkCreation";
import { RFQList } from "../pages/views/Catalog/RFQ/RFQList";
import { CreateOffer } from "../pages/views/Catalog/RFQ/CreateOffer";
import { CreateTicket } from "../pages/views/Catalog/Escalation Management/CreateTicket";
import { OrderInvoice } from "../pages/views/Catalog/Order/OrderInvoice";
import { SalesReturn } from "../pages/views/Catalog/Order/SalesReturn";
import { TicketList } from "../pages/views/Catalog/Escalation Management/TicketList";
import { RFQOfferList } from "../pages/views/Catalog/RFQ/RFQOfferList";
import { RFQDetailsPageList } from "../pages/views/Catalog/RFQ/RFQDetailsPageList";
import { ProductReview } from "../pages/views/Catalog/Review/ProductReview";
import { SalesReturnList } from "../pages/views/Catalog/Order/SalesReturnList";

const Links = () => {
  let location = useLocation();

  return (
    <div id="layout-wrapper">
      <ScrollToTop />
      {location.pathname !== "/login" && location.pathname !== "/sign-up" && (
        <Header />
      )}
      {location.pathname !== "/login" && location.pathname !== "/sign-up" && (
        <SideBar />
      )}
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route element={<PublicRoutes />}>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/sign-up" element={<SignUp />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route exact path="/" element={<Dashboard2 />} />
          {/* <Route exact path="/" element={<Dashboard />} /> */}
          <Route exact path="/datatab" element={<DataTab />} />

          <Route exact path="/users" element={<UserList />} />
          <Route
            exact
            path="/users/user-roles-list"
            element={<UserRoleList />}
          />
          <Route exact path="/users/user-roles" element={<UserRoles />} />

          <Route
            exact
            path="/users/user-roles-edit/:role_id"
            element={<UserRolesEdit />}
          />

          <Route
            exact
            path="/users/user-roles-edit/:role_id"
            element={<UserRolesEdit />}
          />

          <Route
            exact
            path="/website-management/discount-coupon"
            element={<DiscountCouponList />}
          />
          <Route
            exact
            path="/website-management/discount-coupon-form"
            element={<DiscountCouponForm />}
          />
          <Route
            exact
            path="/website-management/discount-coupon-form-edit/:coupon_id"
            element={<DiscountCouponEdit />}
          />

          <Route exact path="/category" element={<Category />} />
          <Route
            exact
            path="/category/add-category"
            element={<CategoryForm />}
          />
          <Route
            exact
            path="/category/edit-category/:id"
            element={<EditCategoryForm />}
          />
          <Route exact path="/attribute" element={<Attribute />} />
          <Route
            exact
            path="/attribute/add-attribute"
            element={<AttributeForm />}
          />
          <Route
            exact
            path="/attribute/edit-attribute/:id"
            element={<EditAttributeForm />}
          />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/catlog/product-review" element={<ProductReview />} />
          <Route exact path="/product/add-product" element={<TestProduct />} />
          <Route
            exact
            path="/product/edit-product/:id"
            element={<EditProductForm />}
          />
          <Route
            exact
            path="/product/bulk-product-upload"
            element={<BulkCreation />}
          />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/pricing/add-pricing" element={<PricingForm />} />
          <Route
            exact
            path="/pricing/edit-pricing/:price_id"
            element={<EditPricingForm />}
          />
          <Route exact path="/listdetail" element={<Listdetailform />} />
          <Route exact path="/additionaldata" element={<AddDataform />} />
          
          <Route
            exact
            path="/product/add-product-data"
            element={<ProductLink />}
          />
          <Route exact path="/supplier-management" element={<SupplierList />} />
          <Route
            exact
            path="/supplier-management/supplier_view/:supplier_id"
            element={<SupplierView />}
          />
          <Route
            exact
            path="/supplier-management/add-basic-info"
            element={<AddBasinfo />}
          />
          <Route
            exact
            path="/supplier-management/add-basic-info/:supplier_id"
            element={<AddBasinfo />}
          />
          <Route
            exact
            path="/supplier-management/add-billing-details"
            element={<AddBillingDetails />}
          />
          <Route
            exact
            path="/supplier-management/add-billing-details/:supplier_id"
            element={<AddBillingDetails />}
          />
          <Route
            exact
            path="/supplier-management/add-contact-details"
            element={<AddContactDetails />}
          />
          <Route
            exact
            path="/supplier-management/add-contact-details/:supplier_id"
            element={<AddContactDetails />}
          />
          <Route
            exact
            path="/supplier-management/add-additional-details"
            element={<AddAdditionalDetails />}
          />
          <Route
            exact
            path="/supplier-management/add-additional-details/:supplier_id"
            element={<AddAdditionalDetails />}
          />

          <Route exact path="/master/add-gst" element={<MasterGST />} />
          <Route exact path="/master/add-uom" element={<MasterUOM />} />
          <Route
            exact
            path="/master/add-business-type"
            element={<MasterBusinessType />}
          />
          <Route exact path="/master/add-pincode" element={<MasterPincode />} />
          <Route
            exact
            path="/master/add-brand-name"
            element={<MasterBrandName />}
          />
          <Route
            exact
            path="/master/add-typeof-company"
            element={<MasterTypeofCompany />}
          />
          <Route
            exact
            path="/master/add-payment-modes"
            element={<MasterPaymentModes />}
          />

          {/* <Route exact path="/standard/add_new" element={<AddStandard />} />  */}

          <Route exact path="/master" element={<Master />} />
          <Route exact path="/add-banner" element={<BannerMgm />} />
          <Route
            exact
            path="/upload-banner/:banner_id/:banner_type"
            element={<BannerUpload />}
          />
          <Route exact path="/webpage" element={<Webpage />} />
          <Route exact path="/webpage/add-webpage" element={<WebpageForm />} />
          <Route
            exact
            path="/webpage/edit-webpage/:id"
            element={<EditWebpageForm />}
          />
          <Route exact path="/customer" element={<Customer />} />
          <Route
            exact
            path="/customer/customer-address-list"
            element={<CustomerAddressList />}
          />
          <Route
            exact
            path="/customer/customer-address"
            element={<CustomerAddressForm />}
          />
          <Route exact path="/order" element={<Order />} />
          <Route exact path="/order/sales-return" element={<SalesReturnList />} />
          <Route exact path="/order/create-order" element={<OrderForm />} />
          <Route
            exact
            path="/order/add-items/:id"
            element={<OrderItemForm />}
          />
          <Route
            exact
            path="/order/order-confirm/:id"
            element={<OrderConfirmForm />}
          />
          <Route exact path="/order/view-order/:id" element={<OrderView />} />
          <Route exact path="/order/order-return" element={<SalesReturn />} />
          <Route exact path="/order-invoice/:id" element={<OrderInvoice />} />

          <Route exact path="/seo" element={<SeoSetting />} />
          <Route exact path="/seo/add-seo" element={<SeoForm />} />
          <Route exact path="/seo/edit-seo/:id" element={<EditSeoForm />} />

          <Route exact path="/lead-management" element={<LeadManagement />} />
          <Route exact path="/lead-management/add-lead" element={<AddLead />} />
          <Route
            exact
            path="/lead-management/upload-lead"
            element={<UploadLead />}
          />
          <Route
            exact
            path="/lead-management/assign-lead-list"
            element={<AssignMnagementList />}
          />

          <Route exact path="/reports/sales-report" element={<SalesReport />} />
          <Route exact path="/reports/user-report" element={<UserReport />} />
          <Route
            exact
            path="/reports/supplier-report"
            element={<SupplierReports />}
          />

          <Route exact path="/rfq/rfq-list" element={<RFQList />} />
          <Route exact path="/rfq/offer-list/:rfqId" element={<RFQOfferList />} />
          <Route exact path="/rfq/details-page-list/:rfqId" element={<RFQDetailsPageList />} />
          <Route exact path="/create-offer" element={<CreateOffer />} />

          <Route exact path="/escalation-management/create-ticket" element={<CreateTicket />} />
          <Route exact path="/escalation-management/ticket-list" element={<TicketList />} />
        </Route>
      </Routes>
      {location.pathname !== "/login" && location.pathname !== "/sign-up" && (
        <Footer />
      )}
    </div>
  );
  {
    /*   
  // return (
  //   <>
  //     <Router>
  //       <main>
  //         <Routes >            
  //           <Route path="/" exact>
  //             <Home />
  //           </Route>
  //           <Route element={<PrivateRoutes />}>
  //             <Route path="/templates" element={<Templates />} />
  //           </Route>
  //           <Route element={<PrivateRoutes />}>
  //             <Route path="/templates/public_library" element={<TemplatesPublicLibrary/>} />             
  //           </Route>
  
  //           <Route path="/schedules" exact>
  //             <Schedules />
  //           </Route>

  //           <Route path="/manage_schedule" exact>
  //             <Schedules />
  //           </Route>

  //           <Route path="/missed_inspections" exact>
  //             <Schedules />
  //           </Route>

  //           <Route path="/createNewTemplate" exact>
  //             <NewTemplate />
  //           </Route>

  //           <Route path="/inspections" exact>
  //             <Inspections />
  //           </Route>

  //           <Route path="/login" exact>
  //             <Login />
  //           </Route>

  //           <Route path="/sign-up" exact>
  //             <SignUp />
  //           </Route>
  //           <Route path="/logout" exact>
  //             <Redirect to="/login" />
  //           </Route>

  //           <Route path="/inspection/:template_id/:page" exact>
  //             <StartInspection />
  //           </Route>
  //           <Redirect to="/" />
  //         </Routes >
  //       </main>
  //     </Router>
  //   </>
  // ); */
  }
};

export default Links;
