import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../../shared/Loader";
import AttributeDropdown from "./AttributeDropdown";
import { Link, useNavigate } from "react-router-dom";
import http from "../../../../../utils/http";

export const EditAttributeForm = ({
  CatID,
  Attr_Id,
  refresh,
  setAttr_Edit,
  id,
}) => {
  useEffect(() => {
    refreshPage();
  }, [Attr_Id]);
  console.log("hi Its loading");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const [MasterVal, setMasterVal] = useState("");

  const refreshPage = () => {
    reset()
    setMasterVal("")
    setLoading(true);
    http.get(process.env.REACT_APP_API_BASE + "get_attribute_by_id/" + Attr_Id)
      .then((res) => {
        console.log(res.data)
        setValue("attribute_group_id", res.data.attribute_group_id);
        setValue("name", res.data.name);
        setValue("attribute", res.data.attribute);
        setValue("status", res.data.status);
        setValue("value", res.data.value);
        let value = res.data.val
        let master = value.join("\n");
        setMasterVal(master)
        console.log(JSON.parse(res.data.value));
        console.log(master);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [atribute, setAttribute] = useState([]);

  function OnChange(e) {
    setAttribute(e);
    if(e){
      setValue("name", e.label)
      setValue("attribute",e)
     }
  }

  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please enter attribute name"),
    // value: Yup.string().required("Please enter master values"),
    // attribute_group_id: Yup.string().required("Please select attribute group"),
    // type: Yup.string().required("Please select type"),
    status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    setBtnloading(true);
    console.log(data);
    http
      .put(`update_attribute/` + Attr_Id, data)
      .then((response) => {
        console.log(response);
        toast.success("Attribute has been updated");
        const timer = setTimeout(() => {
          navigate("/attribute");
          window.scroll({
            top: 100,
            left: 100,
            behavior: "smooth",
          });
          setBtnloading(false);
          refresh(CatID);
          setAttr_Edit(false);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const cancelFunc = () => {
    setAttr_Edit(false);
    window.scroll({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  const ValueOnCh=(e)=>{
    const textarea = e.target.value
    const values = textarea.split('\n');
    // console.log(textarea);
    // console.log(values);
    setValue('value', JSON.stringify(values))
    setMasterVal(e.target.value)
  }

  return (
    <div className="card" id={id}>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            {/* <div className="col-lg-6 ">
                          <label
                            className="form-label"
                            htmlhtmlhtmlFor="project-title-input"
                          >
                            Attribute Group
                          </label>
                          <select
                            className="form-select"
                            name="attribute_group_id"
                            {...register("attribute_group_id")}
                          >
                            <option value="">Select Attribute Group</option>
                            {attr_group_List.map((attr, index) => {
                              return (
                                <option
                                  key={index}
                                  selected={
                                    attr.id === getValues("attribute_group_id")
                                      ? true
                                      : false
                                  }
                                  value={attr.id}
                                >
                                  {attr.name}
                                </option>
                              );
                            })}
                          </select>

                          <div
                            className={`invalid-feedback ${
                              errors.attribute_group_id ? "d-block" : ""
                            }`}
                          >
                            {errors.attribute_group_id
                              ? errors.attribute_group_id.message
                              : ""}
                          </div>
                        </div> */}
            <div className="col-lg-12">
              <label className="form-label" htmlFor="project-title-input">
                Attribute Name <span className="text-danger">*</span>
              </label>
              {/* <input
                className="form-control"
                name="name"
                placeholder="Enter Attribute Name"
                {...register("name")}
              /> */}
              <AttributeDropdown
                placeholder="Select or Enter attribute"
                value={getValues("attribute")}
                change={(e) => OnChange(e)}
                id={CatID}
              />
              <div
                className={`invalid-feedback ${errors.name ? "d-block" : ""}`}
              >
                {errors.name ? errors.name.message : ""}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <label> Master Values</label>
              <textarea
                className="form-control"
                name="master_value  "
                // value={getValues("master_value")}
                value={MasterVal}
                onChange={(e)=> ValueOnCh(e)}
                placeholder="Enter master values"
                rows="3"
              ></textarea>
              <div
                className={`invalid-feedback ${errors.value ? "d-block" : ""}`}
              >
                {errors.value ? errors.value.message : ""}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6 ">
              <label className="form-label" htmlFor="project-title-input">
                Type
              </label>
              <select className="form-select" name="type" {...register("type")}>
                <option value="1">Alpha</option>
                <option value="0">Numeric</option>
              </select>
              <div
                className={`invalid-feedback ${errors.type ? "d-block" : ""}`}
              >
                {errors.type ? errors.type.message : ""}
              </div>
            </div>
            <div className="col-lg-6 ">
              <label className="form-label" htmlFor="project-title-input">
                Status
              </label>
              <select
                className="form-select"
                name="status"
                {...register("status")}
              >
                <option value="1">Active</option>
                <option value="0">In Active</option>
              </select>
              <div
                className={`invalid-feedback ${errors.status ? "d-block" : ""}`}
              >
                {errors.status ? errors.status.message : ""}
              </div>
            </div>
          </div>
          <div className="mb-3 mt-4 text-end">
            <Link
              type="button"
              to={"/attribute"}
              className="btn btn-danger w-sm"
              onClick={cancelFunc}
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="btn btn-primary w-sm ms-2"
              disabled={Btnloading}
            >
              {Btnloading ? "Loading..." : "Save Attribute"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
