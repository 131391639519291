import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import SupplierNavs from "../SupplierNavs";
import http from "../../../../utils/http";
import ReactFlatpickr from "../../../shared/ReactFlatpickr";
const AddAdditionalDetails = () => {
  const { supplier_id } = useParams();       
  console.log("AdditionalDetails" + supplier_id);
  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);
  const [bnaTypes, setbnaTypes] = useState([]);
  const [compTypes, setcompTypes] = useState([]);

  const yupValidation = Yup.object().shape({
    brand_name: Yup.string().required("Please select brand name"),
    company_type: Yup.string().required("Please select type of company"),
    incorporation_date: Yup.string().required("Please enter date"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  const getFetchSupplier = () => {
    // setSelectedOption(setValue("billing_state",data.billing_state))
    http
      .get(`get_supplier_by_id/${supplier_id}`)
      .then((res) => {
        setValue("brand_name", res.data.brand_name);
        setValue("company_type", res.data.company_type);
        console.log("res.data.incorporation_date" ,moment(res.data.incorporation_date)
        .utc()
        .format("DD/MM/YYYY"));
        setIncorporationDate(res.data.incorporation_date)
        // const date = new Date(res.data.incorporation_date)
          // .toISOString()
          // .split("T")[0];
        // console.log(date);
        // setValue("incorporation_date", res.data.incorporation_date);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  console.log("incorporationDate" , incorporationDate);
  let editCase = supplier_id ? 1 : 0;
  if (editCase == 1) {
    getFetchSupplier();
  }

  function onSubmit(data) {
    setLoading(true);
    console.log(data);
    http
      .put(`save_supplier_additional_details/${supplier_id}`, data)
      // axios.put(`http://192.168.1.222:8080/admin/save_supplier_billing_details/${supplier_id}`, data)
      .then((response) => {
        console.log(response);
        setLoading(false);
        navigate(`/supplier-management`);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const getbrandNameData = () => {
    http
      .get(`get_brand`)
      .then((res) => {
        setbnaTypes(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getcompanyTypeData = () => {
    http
      .get(`get_company_type`)
      .then((res) => {
        console.log(res.data);
        setcompTypes(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getbrandNameData();
    getcompanyTypeData();
  }, []);

const [incorporationDate,setIncorporationDate] = useState("");
  const creation_incorporation_date = {
    onChange: (selectedDates, dateStr, instance) => {
      console.log(dateStr)
      setValue("incorporation_date" , dateStr)
      // setIncorporationDate(dateStr);
    },
  };


  return (
    <>
      <div
        className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message ===
          "" && "d-none"}`}
      >
        {message}
      </div>
      {/* <Header />
      <SideBar /> */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <SupplierNavs supplier_id={supplier_id} />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Brand Name
                            </label>
                            <select
                              className="form-select"
                              name="brand_name"
                              {...register("brand_name")}
                            >
                              <option value="">Select</option>
                              {bnaTypes.map((bnaType, index) => (
                                <option key={index} value={bnaType.brand_name}>
                                  {bnaType.brand_name}
                                </option>
                              ))}
                            </select>
                            <div
                              className={`invalid-feedback ${
                                errors.brand_name ? "d-block" : ""
                              }`}
                            >
                              {errors.brand_name
                                ? errors.brand_name.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input" 
                            >
                              Incorporation Date
                            </label>
                            <ReactFlatpickr
                              placeholder="Enter Incorporation Date"
                            name="incorporation_date"
                            value={incorporationDate}
                              options={creation_incorporation_date}
                              {...register("incorporation_date")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.incorporation_date ? "d-block" : ""
                              }`}
                            >
                              {errors.incorporation_date
                                ? errors.incorporation_date.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Type of Company
                            </label>
                            <select
                              className="form-select"
                              name="company_type"
                              {...register("company_type")}
                            >
                              <option value="">Select</option>
                              {compTypes.map((compType, index) => (
                                <option
                                  key={index}
                                  value={compType.company_type}
                                >
                                  {compType.company_type}
                                </option>
                              ))}
                            </select>
                            <div
                              className={`invalid-feedback ${
                                errors.company_type ? "d-block" : ""
                              }`}
                            >
                              {errors.company_type
                                ? errors.company_type.message
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="submit" className="btn btn-danger">
                            {loading ? "Loading..." : "Save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdditionalDetails;
