import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import http from "../../../../../utils/http";
// import ImageUploading from "react-images-uploading";
// import photo from "../../../assets/images/photo.png";

const AddBannerModal = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  const [Banner, setBanner] = React.useState([]);

  const Banner_img = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList);
    // console.log(imageList, addUpdateIndex);
    setBanner(imageList);
  };

  const yupValidation = Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    alt: Yup.string().required("Please enter alt"),
    url: Yup.string().required("Please enter link"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  function onSubmit(data) {

    setLoading(true);
    setValue("type","banner")
    setValue("type_id","4")
    http
      .post("save_images", data)
      .then((response) => {
        console.log(response);
        reset();
        setMessage("User has been added");
        setLoading(false);
        refreshPage();

        // const timer = setTimeout(() => {
        //   refreshPage();
        // }, 1500);
        // return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
    <Modal.Header closeButton>
      <Modal.Title>Add Banner </Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className={`row g-3`}>
          <div className="col-lg-4">
            <div id="modal-id">
              <label htmlFor="orderId" className="form-label">
                Title
              </label>
              <input
                name="title"
                type="text"
                className="form-control"
                placeholder="Enter Title"
                {...register("title")}
              />
              <div
                className={`invalid-feedback ${
                  errors.title ? "d-block" : ""
                }`}
              >
                {errors.title ? errors.title.message : ""}
              </div>
              {/* }  */}
            </div>
          </div>

          <div className="col-lg-4">
            <div>
              <label htmlFor="client_nameName-field" className="form-label">
                Alt
              </label>
              <input
                type="text"
                id="alt"
                name="alt"
                className="form-control"
                placeholder="Enter Alt"
                required=""
                {...register("alt")}
              />

              <div
                className={`invalid-feedback ${errors.alt ? "d-block" : ""}`}
              >
                {errors.alt ? errors.alt.message : ""}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <label htmlFor="tasksTitle-field" className="form-label">
                Link
              </label>
              <input
                type="text"
                id="url"
                name="url"
                className="form-control"
                placeholder="Enter Link"
                required=""
                {...register("url")}
              />
              <div
                className={`invalid-feedback ${errors.url ? "d-block" : ""}`}
              >
                {errors.url ? errors.url.message : ""}
              </div>
            </div>
          </div>
        </div>
        
      </Modal.Body>
      <Modal.Footer>
        <input type="hidden" name="standard_id" value={id} />
        <Button variant="danger" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="success" type="submit" onClick={hideModal}>
          {loading ? "Loading..." : "Save"}
        </Button>
      </Modal.Footer>
    </form>
  </Modal>
  );
};

export default AddBannerModal;
