import React from "react";
import "./App.css";
import Links from "./components/common/Links";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Links />
    </BrowserRouter>
  );
};
export default App;
