import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CategoryDropdown from "../Category/CategoryDropdown";
import ImageUploading from "react-images-uploading";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";
import ReactQuill from "react-quill";
import BrandDropdown from "./BrandDropdown";
import GSTDropdown from "./GSTDropdown";
import UOMDropdown from "./UOMDropdown";
import CategoryProductDropDown from "./CategoryProductDropDown";

export const TestProduct = () => {
  const navigate = useNavigate();
  console.log("hi Its loading");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const yupValidation = Yup.object().shape({
    // running validations
    product_name: Yup.string().required("Please enter product name"),
    pack_size: Yup.string().required("Please enter pack size"),
    gst: Yup.string().required("Please select GST"),
    hsn: Yup.number().typeError("Please enter HSN"),
    moq: Yup.number().typeError("Please enter MOQ"),
    uom: Yup.string().required("Please select UOM"),
    price: Yup.number().typeError("Please enter MRP"),
    sale_price: Yup.number().typeError("Please enter list price"),
    status: Yup.string().required("Please select status"),
    website_display: Yup.string().required("Please select website display"),
    price_display: Yup.string().required("Please select price display"),
    description: Yup.string().required("Please enter description"),
    brand: Yup.string().required("Please select brand"),
    categories: Yup.string().required("Please select Category"),

    // commented validations
    // meta_title: Yup.string().required("Please enter meta tag title"),
    // meta_description: Yup.string().required("Please enter meta tag description"),
    // meta_keywords: Yup.string().required("Please enter meta tag keywords"),
    // seo_url: Yup.string().required("Please enter seo url"),
    // model: Yup.string().required("Please enter model"),
    // part_number: Yup.string().required("Please enter part number"),
    // specifications: Yup.string().required("Please enter specifications"),
    // warranty: Yup.string().required("Please enter warranty"),
    // product_tag: Yup.string().required("Please enter product tag"),
    // related_product: Yup.string().required("Please select Related product"),
    // best_seller: Yup.string().required("Please enter best seller"),
    // brand: Yup.string().required("Please select brand"),
    // product_category: Yup.string().required("Please select product category"),
    // keywords: Yup.string().required("Please enter keywords"),
    // image_url: Yup.string().required("Please enter image url"),
    // sort_order: Yup.string().required("Please enter sort order"),
    // attribute: Yup.string().required("Please select attribute"),
    // thumb_image: Yup.string().required("Please choose thumb image"),
    // product_images: Yup.string().required("Please choose product images"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit(data) {
    let newData = { ...data, attribute: JSON.stringify(Attributes) };
    console.log(newData);
    setLoading(true);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    http
      .post(`save_product`, newData, config)
      .then((response) => {
        toast.success("Product has been added");
        const timer = setTimeout(() => {
          setLoading(false);
          navigate("/product");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Internal server error");
      });
  }

  const [gen, setgen] = useState(true);
  const [list, setlist] = useState(false);
  const [attr, setattr] = useState(false);
  const [img, setimg] = useState(false);
  const [Meta, setMeta] = useState(false);

  // const handle_Cat_DropDown = (d, e) => {
  //   let fvalues = [];
  //   let cvalues = [];
  //   e.map((n, index) => {
  //     fvalues.push({ label: n.label, value: n.value });
  //     cvalues.push(n.value);
  //   });
  //   let textsss = cvalues.join();
  //   setValue("categories", textsss, { shouldValidate: true });
  //   setValue(d, fvalues);
  // };

  const [Category, setCategory] = useState([]);
  const [valueDropdown, setvalueDropdown] = useState([]);

  function handle_Cat_DropDown(e) {
    setattributeList("");
    console.log(e);
    setCategory(e);
    setValue("categories", e.value);
    setValue("stcategories", e);

    http
      .get("get_attribute/" + e.value)
      .then((res) => {
        console.log(res.data);
        let data = res.data;
        data.map((item) => {
          setattributeList((attributeList) => [
            ...attributeList,
            { attribute_id: item.id, name: item.name, value: item.val },
          ]);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  console.log(valueDropdown);

  // const handle_Rlproudct_DropDown = (d, e) => {
  //   let fvalues = [];
  //   let cvalues = [];
  //   e.map((n, index) => {
  //     fvalues.push({ label: n.label, value: n.value });
  //     cvalues.push(n.value);
  //   });
  //   let textsss = cvalues.join();
  //   console.log(textsss);
  //   setValue("related_product", textsss, { shouldValidate: true });
  //   setValue(d, fvalues);
  // };

  const [attributeList, setattributeList] = useState([]);

  console.log("attributeList" , attributeList.value === undefined);
  const [Attributes, setAttributes] = useState([]);

  const handleChange = (e, i, id) => {
    const { value } = e.target;
    let newAttr = { attribute_id: id, value: value };
    console.log("newAttr", newAttr);
    setAttributes([...Attributes, newAttr]);
  };

  console.log("Attributes", Attributes);

  const addAttribute = () => {
    setattributeList([...attributeList, { attribute: "", value: "" }]);
  };

  const RemoveAttribute = (index) => {
    const alist = [...attributeList];
    alist.splice(index, 1);
    // console.log(alist);
    setattributeList(alist);
  };

  const [Displayimages, setDisplayimages] = React.useState([]);
  const [Productimages, setProductimages] = React.useState([]);
  const maxNumber = 69;

  const display_img = (imageList, addUpdateIndex) => {
    // data for submit
    let file;
    let img = imageList.map((imgValue, index) => {
      return (file = imgValue.file);
    });
    setValue("thumb_image", file);
    setDisplayimages(imageList);
    // console.log(imageList);
  };

  const product_img = (imageList, addUpdateIndex) => {
    let img = imageList.map((imgValue, index) => {
      return imgValue.file;
    });

    setValue("product_images", img);
    setProductimages(imageList);
  };

  const [content, setContent] = useState("");
  const onChange = (text) => {
    console.log(text);
    setContent(text);
    setValue("description", text);
  };

  const [BrandList, setBrandList] = useState([]);
  const [GstList, setGstList] = useState([]);
  const [UomList, setUomList] = useState([]);

  function BrandChange(e) {
    console.log(e);
    setBrandList(e);
    if (e) {
      setValue("brand", e.label);
    }
  }

  function GSTChange(e) {
    console.log(e);
    setGstList(e);
    if (e) {
      setValue("gst", e.label);
    }
  }

  function UOMChange(e) {
    console.log(e);
    setUomList(e);
    if (e) {
      setValue("uom", e.label);
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Add Product</h4>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card">
                <div className="card-header productHead pb-2">
                  {/* <ul
                  className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <li className="nav-item mt-1" role="presentation">
                    <Link
                      className={gen ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setgen(true);
                        setlist(false);
                        setattr(false);
                        setimg(false);
                        setMeta(false);
                      }}
                    >
                      General Details
                    </Link>
                  </li>
                  <li className="nav-item mt-1 " role="presentation">
                    <Link
                      className={attr ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setattr(true);
                        setgen(false);
                        setlist(false);
                        setimg(false);
                        setMeta(false);
                      }}
                    >
                      Attribute
                    </Link>
                  </li>
                  <li className="nav-item mt-1 " role="presentation">
                    <Link
                      className={img ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setattr(false);
                        setgen(false);
                        setlist(false);
                        setimg(true);
                        setMeta(false);
                      }}
                    >
                      Image
                    </Link>
                  </li>
                  <li className="nav-item mt-1 " role="presentation">
                    <Link
                      className={Meta ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setMeta(true);
                        setlist(false);
                        setattr(false);
                        setgen(false);
                        setimg(false);
                      }}
                    >
                      Meta
                    </Link>
                  </li>
                  <li className="nav-item mt-1 " role="presentation">
                    <Link
                      className={list ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setlist(true);
                        setattr(false);
                        setgen(false);
                        setimg(false);
                        setMeta(false);
                      }}
                    >
                      Listing Price
                    </Link>
                  </li>
                </ul> */}
                  General Details
                </div>
                <div className="card-body">
                  {/* {gen && ( */}
                  <div id="general">
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Product Category <span className="text-danger">*</span>
                        </label>
                        <CategoryProductDropDown
                          type="industry_sector"
                          placeholder="Categories"
                          value={Category}
                          change={(e) => handle_Cat_DropDown(e)}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.categories ? "d-block" : ""
                          }`}
                        >
                          {errors.categories ? errors.categories.message : ""}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Brand <span className="text-danger">*</span>
                        </label>
                        {/* <select
                          className="form-select"
                          name="brand"
                          {...register("brand")}
                        >
                          <option value="">Select Brand</option>
                          {BrandList.map((item, index) => {
                            return (
                              <option key={index} value={item.brand_name}>
                                {item.brand_name}
                              </option>
                            );
                          })}
                        </select> */}
                        <BrandDropdown
                          placeholder="Select or Enter Brand"
                          value={BrandList}
                          change={(e) => BrandChange(e)}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.brand ? "d-block" : ""
                          }`}
                        >
                          {errors.brand ? errors.brand.message : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Product Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="product_name"
                          placeholder="Enter Product Name"
                          {...register("product_name")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.product_name ? "d-block" : ""
                          }`}
                        >
                          {errors.product_name
                            ? errors.product_name.message
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Model
                        </label>
                        <input
                          className="form-control"
                          name="model"
                          placeholder="Enter Model"
                          {...register("model")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.model ? "d-block" : ""
                          }`}
                        >
                          {errors.model ? errors.model.message : ""}
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Part Number
                        </label>
                        <input
                          className="form-control"
                          name="part_number"
                          placeholder="Enter Part Number"
                          {...register("part_number")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.part_number ? "d-block" : ""
                          }`}
                        >
                          {errors.part_number ? errors.part_number.message : ""}
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Specifications
                        </label>
                        <input
                          className="form-control"
                          name="specifications"
                          placeholder="Enter Specifications"
                          {...register("specifications")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.specifications ? "d-block" : ""
                          }`}
                        >
                          {errors.specifications
                            ? errors.specifications.message
                            : ""}
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Pack Size
                        </label>
                        <input
                          className="form-control"
                          name="pack_size"
                          defaultValue={"1"}
                          placeholder="Enter Pack Size"
                          {...register("pack_size")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.pack_size ? "d-block" : ""
                          }`}
                        >
                          {errors.pack_size ? errors.pack_size.message : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12 ">
                        <label> Description</label><span className="text-danger">*</span>
                        {/* <textarea
                          className="form-control"
                          name="keywords"
                          {...register("description")}
                          placeholder="Enter product description"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea> */}
                        <ReactQuill
                          theme="snow"
                          value={content}
                          onChange={onChange}
                          placeholder="Enter Description"
                          defaultValue={""}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.description ? "d-block" : ""
                          }`}
                        >
                          {errors.description ? errors.description.message : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          GST (%)<span className="text-danger">*</span>
                        </label>
                        {/* <select
                          className="form-select"
                          name="gst"
                          {...register("gst")}
                        >
                          <option value="">Select GST</option><span className="text-danger">*</span>
                          {GstList.map((Gst, index) => {
                            return (
                              <option key={index} value={Gst.gst}>
                                {Gst.gst}
                              </option>
                            );
                          })}
                        </select> */}
                        <GSTDropdown
                          placeholder="Select or Enter GST"
                          value={GstList}
                          change={(e) => GSTChange(e)}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.gst ? "d-block" : ""
                          }`}
                        >
                          {errors.gst ? errors.gst.message : ""}
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          HSN
                        </label><span className="text-danger">*</span>
                        <input
                          className="form-control"
                          name="hsn"
                          placeholder="Enter HSN"
                          {...register("hsn")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.hsn ? "d-block" : ""
                          }`}
                        >
                          {errors.hsn ? errors.hsn.message : ""}
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          MOQ
                        </label>
                        <input
                          className="form-control"
                          name="moq"
                          placeholder="Enter MOQ"
                          defaultValue={"1"}
                          {...register("moq")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.moq ? "d-block" : ""
                          }`}
                        >
                          {errors.moq ? errors.moq.message : ""}
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          UOM<span className="text-danger">*</span>
                        </label>
                        {/* <select
                          className="form-select"
                          name="uom"
                          {...register("uom")}
                        >
                          <option value="">Select UOM</option>
                          {UomList.map((uom, index) => {
                            return <option value={uom.uom}>{uom.uom}</option>;
                          })}
                        </select> */}
                        <UOMDropdown
                          placeholder="Select or Enter UOM"
                          value={UomList}
                          change={(e) => UOMChange(e)}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.uom ? "d-block" : ""
                          }`}
                        >
                          {errors.uom ? errors.uom.message : ""}
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Warranty
                        </label>
                        <input
                          className="form-control"
                          name="warranty"
                          placeholder="Enter Warranty"
                          {...register("warranty")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.warranty ? "d-block" : ""
                          }`}
                        >
                          {errors.warranty ? errors.warranty.message : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Product Tags
                        </label>
                        <input
                          className="form-control"
                          name="product_tag"
                          placeholder="Enter Product tag"
                          {...register("product_tag")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.product_tag ? "d-block" : ""
                          }`}
                        >
                          {errors.product_tag ? errors.product_tag.message : ""}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mt-3 mb-3">
                        <div className="col-lg-12">
                          <label
                            className="form-label"
                            htmlFor="project-title-input"
                          >
                            Related Products
                          </label>
                          <ProductDropdown
                            type="industry_sector"
                            placeholder="Related Products"
                            value={getValues("strelated_product")}
                            change={(e) =>
                              handle_Rlproudct_DropDown("strelated_product", e)
                            }
                          />
                          <div
                            className={`invalid-feedback ${
                              errors.related_product ? "d-block" : ""
                            }`}
                          >
                            {errors.related_product
                              ? errors.related_product.message
                              : ""}
                          </div>
                        </div>
                      </div> */}
                    {/* <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          disabled
                          onClick={() => {
                            // (true);
                            setgen(false);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setattr(true);
                            setgen(false);
                          }}
                        >
                          Next
                        </button>
                      </div> */}
                    {/* </div> */}
                    {/* )} */}
                    {/* {attr && ( */}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header productHead pb-2 ">
                  Attribute Details
                </div>
                <div className="card-body">
                  <div className="mb-3" id="general">
                    {/* <div className="row ">
                      <div className="col-md-4 border-end px-3 py-2">
                        Attribute
                      </div>
                      <div className="col-md-6 border-end px-3 py-2">Text</div>
                      <div className="col-md-2 p-2"></div>
                    </div> */}
                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Attribute
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Text
                        </label>
                      </div>
                    </div>
                    {attributeList.length
                      ? attributeList.map((item, index) => {
                          return (
                            <div key={index} className="row">
                              <div className="col-md-6 d-flex align-items-center p-3">
                                <label
                                  className="form-label"
                                  htmlFor="project-title-input"
                                >
                                  {item.name}
                                </label>
                              </div>
                              <div className="col-md-6 d-flex align-items-center p-3">
                                {item.value.length > 0 ? (
                                  <select
                                    className="form-select"
                                    name="value"
                                    onChange={(e) =>
                                      handleChange(e, index, item.attribute_id)
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    {item.value.map((val, index) => (
                                      // val[index] !== undefined ?  (
                                        <option key={index} value={val}>
                                          {val}
                                        </option>
                                      // ) : ""
                      ))}
                                  </select>
                                ) : (
                                  <input
                                    className="form-control"
                                    name="product_name"
                                    placeholder="Enter Value"
                                    onBlur={(e) =>
                                      handleChange(e, index, item.attribute_id)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header productHead pb-2 ">
                  Image Details
                </div>
                <div className="card-body">
                  <div className=" mb-3" id="general">
                    <div className="row mb-3">
                      {/* Product Thumb */}
                      <div className="col-lg-12">
                        <ImageUploading
                          value={Displayimages}
                          onChange={display_img}
                          dataURLKey="data_url"
                          acceptType={["jpg", "gif", "png"]}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div>
                              <div>Product Thumbnail (256 X 256)</div>
                              <button
                                className="btn btn-primary btn-sm mt-1"
                                style={isDragging ? { color: "red" } : null}
                                type="button"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop here
                              </button>
                              &nbsp;
                              {/* <button
                              className="btn btn-primary btn-sm"
                              onClick={onImageRemoveAll}
                            >
                              Remove image
                            </button> */}
                              <div className="row">
                                {Displayimages.map((image, index) => (
                                  <div key={index} className="col-md-3 mt-2">
                                    <div className="border d-flex align-items-center overflow-hidden flex-column">
                                      <img
                                        src={image.data_url}
                                        alt=""
                                        style={{ height: "150px" }}
                                      />
                                      <div className="p-2">
                                        {/* <button
                                          className="btn btn-primary btn-sm"
                                          type="button"
                                          onClick={() => onImageUpdate(index)}
                                        >
                                          Update
                                        </button> */}
                                        <button
                                          className="btn btn-primary btn-sm ms-2"
                                          type="button"
                                          onClick={() => onImageRemove(index)}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>

                      <div className="col-lg-12">
                        {/* Product Images */}
                        <ImageUploading
                          multiple
                          value={Productimages}
                          onChange={product_img}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={["jpg", "gif", "png"]}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="mt-3">
                              <div>Product Gallery (750 X 750)</div>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm mt-1"
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop here
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm ms-2 mt-1"
                                onClick={onImageRemoveAll}
                              >
                                Remove all images
                              </button>
                              <div className="row">
                                {Productimages.map((image, index) => (
                                  <div key={index} className="col-md-3  mt-2">
                                    <div className="border d-flex align-items-center overflow-hidden flex-column">
                                      <div>
                                        <img
                                          src={image.data_url}
                                          alt=""
                                          style={{ height: "150px" }}
                                        />
                                      </div>
                                      <div className="p-2">
                                        {/* <button
                                          className="btn btn-primary btn-sm"
                                          type="button"
                                          onClick={() => onImageUpdate(index)}
                                        >
                                          Update
                                        </button> */}
                                        <button
                                          className="btn btn-primary btn-sm ms-2"
                                          type="button"
                                          onClick={() => onImageRemove(index)}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                    {/* <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setimg(false);
                            setattr(true);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setMeta(true);
                            setimg(false);
                          }}
                        >
                          Next
                        </button>
                      </div> */}
                  </div>
                  {/* )}
                  {Meta && ( */}
                  {/* <div id="general">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Meta Tag Title
                        </label>
                        <input
                          className="form-control"
                          name="meta_title"
                          placeholder="Enter Meta Tag Title"
                          {...register("meta_title")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.meta_title ? "d-block" : ""
                          }`}
                        >
                          {errors.meta_title ? errors.meta_title.message : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <label> Meta Tag Description</label>
                        <textarea
                          className="form-control"
                          name="meta_description"
                          {...register("meta_description")}
                          placeholder="Enter meta tag description"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                        <div
                          className={`invalid-feedback ${
                            errors.meta_description ? "d-block" : ""
                          }`}
                        >
                          {errors.meta_description
                            ? errors.meta_description.message
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <label> Meta Tag Keywords</label>
                        <textarea
                          className="form-control"
                          name="meta_keywords"
                          {...register("meta_keywords")}
                          placeholder="Enter meta tag keyword"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                        <div
                          className={`invalid-feedback ${
                            errors.meta_keywords ? "d-block" : ""
                          }`}
                        >
                          {errors.meta_keywords
                            ? errors.meta_keywords.message
                            : ""}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Product Url
                        </label>
                        <input
                          className="form-control"
                          name="seo_url"
                          placeholder="Enter Product Url"
                          {...register("seo_url")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.seo_url ? "d-block" : ""
                          }`}
                        >
                          {errors.seo_url ? errors.seo_url.message : ""}
                        </div>
                      </div>
                    </div>
                    {/* <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setimg(true);
                            setMeta(false);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setlist(true);
                            setMeta(false);
                          }}
                        >
                          Next
                        </button>
                      </div> */}
                  {/* </div>  */}
                  {/* )}
                  {list && ( */}
                </div>
              </div>
              <div className="card">
                <div className="card-header productHead pb-2 ">
                  Listing Details
                </div>
                <div className="card-body">
                  <div id="list">
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          MRP <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="price"
                          placeholder="Enter MRP "
                          {...register("price")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.price ? "d-block" : ""
                          }`}
                        >
                          {errors.price ? <p>Please enter MRP</p> : ""}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          List Price <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="sale_price"
                          placeholder="Enter List Price "
                          {...register("sale_price")}
                        />
                        <div
                          className={`invalid-feedback ${
                            errors.sale_price ? "d-block" : ""
                          }`}
                        >
                          {errors.sale_price ? (
                            <p>Please enter List Price</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Website Display
                        </label>
                        <select
                          className="form-select"
                          name="website_display"
                          {...register("website_display")}
                          defaultValue={"1"}
                        >
                          <option value="">Select</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        <div
                          className={`invalid-feedback ${
                            errors.website_display ? "d-block" : ""
                          }`}
                        >
                          {errors.website_display
                            ? errors.website_display.message
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Price Display
                        </label>
                        <select
                          className="form-select"
                          name="price_display"
                          {...register("price_display")}
                          defaultValue={"1"}
                        >
                          <option value="">Select</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        <div
                          className={`invalid-feedback ${
                            errors.price_display ? "d-block" : ""
                          }`}
                        >
                          {errors.price_display
                            ? errors.price_display.message
                            : ""}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Priority
                        </label>
                        <input
                          className="form-control"
                          name="priority"
                          placeholder="Enter priority"
                          {...register("priority")}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Status
                        </label>
                        <select
                          className="form-select"
                          name="status"
                          {...register("status")}
                          defaultValue={"1"}
                        >
                          <option value="">Select</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        <div
                          className={`invalid-feedback ${
                            errors.status ? "d-block" : ""
                          }`}
                        >
                          {errors.status ? errors.status.message : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mb-3 mt-4 text-end d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger w-sm"
                          onClick={() => {
                            setlist(false);
                            setMeta(true);
                          }}
                        >
                          Previous
                        </button> */}
              {/* <button
                          type="submit"
                          className="btn btn-danger w-sm"
                          // onClick={() => {
                          //   if (errors) {
                          //     toast.error(
                          //       "Please fill all the required fields"
                          //     );
                          //   }
                          // }}
                        >
                          Submit
                        </button> */}
              {/* <button
                          type="submit"
                          className="btn btn-danger w-sm"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </button>
                      </div> */}
              <div className="d-flex justify-content-end mb-3">
                <Link
                  to={"/product"}
                  type="button"
                  className="btn btn-danger me-2 pt-8 pb-8"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="btn btn-success ps-8 pe-8"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Save Product"}
                </button>
              </div>
              {/* )} */}
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
