import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setUserSession } from "../../../utils/common";
import axios from "axios";

const Login = (props) => {
  console.log(process.env.REACT_APP_API_BASE);

  const navigate = useNavigate();
  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // handle button click of login form
  const handleLogin = (response) => {
    console.log(response);
    setError(null);
    setLoading(true);

    axios
      .post(process.env.REACT_APP_API_BASE + "login_admin", {
        email: username.value,
        password: password.value,
      })
      .then((response) => {
        setLoading(false);
        setUserSession(
          response.data.token,
          response.data.name,
          response.data.user
        );
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          console.log(error);
          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  return (
    <div className="auth-page-wrapper pt-5">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay"></div>
      </div>
      <div className="auth-page-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5 ">
              <div className="card mt-4 ">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">
                      <span className="h3 text-black">WOODEN</span>
                      <span className="h3 text-warning">SOLE</span>
                    </h5>
                    <p className="text-muted">
                      Sign in to continue to Admin Panel.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <form>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Username
                        </label>
                        <input
                          type="text"
                          {...username}
                          autoComplete="new-password"
                          className="form-control"
                          id="username"
                          placeholder="Enter username"
                        />
                      </div>

                      <div className="mb-3">
                        {/* <div className="float-end">
                                            <Link to="forgotpassword" className="text-muted">Forgot password?</Link>
                                        </div> */}
                        <label className="form-label" htmlFor="password-input">
                          Password
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            type="password"
                            {...password}
                            autoComplete="new-password"
                            className="form-control pe-5 password-input"
                            placeholder="Enter password"
                            id="password-input"
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                          ></button>
                        </div>
                      </div>

                      {/* <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                        <label className="form-check-label" htmlFor="auth-remember-check">Remember me</label>
                                    </div> */}

                      <div className="mt-4">
                        {error && (
                          <>
                            <small style={{ color: "red" }}>{error}</small>
                            <br />
                          </>
                        )}
                        <br />

                        {/* <button className="btn btn-success w-100" type="button" onClick={handleLogin} 
                                        disabled={loading}>
                                            {loading ? 'Loading...' : 'Login'}
                                            </button> */}
                        <button
                          type="submit"
                          className="btn btn-success w-100"
                          disabled={loading}
                          onClick={handleLogin}
                        >
                          {loading ? "Loading..." : "Login"}
                        </button>
                        <br />
                      </div>

                      {/* <div className="mt-4 text-center">
                                        <div className="signin-other-title">
                                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>
                                            <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>
                                            <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>
                                            <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                                        </div>
                                    </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
