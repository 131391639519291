import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";

export const SeoForm = () => {
  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const [File, setFile] = useState("");

  const yupValidation = Yup.object().shape({
    url: Yup.string().required("Please enter url"),
    canonical_url: Yup.string().required("Please enter canonical url"),
    meta_title: Yup.string().required("Please enter page title"),
    meta_keywords: Yup.string().required("Please enter page keywords"),
    meta_description: Yup.string().required("Please enter page description"),
    og_title: Yup.string().required("Please enter og title"),
    og_description: Yup.string().required("Please enter og description"),
    og_url: Yup.string().required("Please enter og url"),
    og_type: Yup.string().required("Please enter og type"),
    additional_tags: Yup.string().required("Please enter additional tags"),
    status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit(data) {
    console.log(data);
    setLoading(true);

    let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

    http
      .post(`save_seo`, data, config)
      .then((response) => {
        toast.success("Seo has been created");
        const timer = setTimeout(() => {
          setLoading(false);
          navigate("/seo");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Add SEO</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        URL
                      </label>
                      <input
                        className="form-control"
                        name="url"
                        placeholder="Enter URL"
                        {...register("url")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.url ? "d-block" : ""
                        }`}
                      >
                        {errors.url ? errors.url.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Canonical Url
                      </label>
                      <input
                        className="form-control"
                        name="canonical_url"
                        placeholder="Enter Canonical Url"
                        {...register("canonical_url")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.canonical_url ? "d-block" : ""
                        }`}
                      >
                        {errors.canonical_url
                          ? errors.canonical_url.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Page Title
                      </label>
                      <input
                        className="form-control"
                        name="meta_title"
                        placeholder="Enter Page Title"
                        {...register("meta_title")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.meta_title ? "d-block" : ""
                        }`}
                      >
                        {errors.meta_title ? errors.meta_title.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Page Keywords
                      </label>
                      <input
                        className="form-control"
                        name="meta_keywords"
                        placeholder="Enter Page Keywords"
                        {...register("meta_keywords")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.meta_keywords ? "d-block" : ""
                        }`}
                      >
                        {errors.meta_keywords
                          ? errors.meta_keywords.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label>Page Description</label>
                      <textarea
                        className="form-control"
                        name="meta_description"
                        {...register("meta_description")}
                        placeholder="Enter Page Description"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                      <div
                        className={`invalid-feedback ${
                          errors.meta_description ? "d-block" : ""
                        }`}
                      >
                        {errors.meta_description
                          ? errors.meta_description.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        OG Title
                      </label>
                      <input
                        className="form-control"
                        name="og_title"
                        placeholder="Enter OG Title"
                        {...register("og_title")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.og_title ? "d-block" : ""
                        }`}
                      >
                        {errors.og_title ? errors.og_title.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label>OG Description</label>
                      <textarea
                        className="form-control"
                        name="og_description"
                        {...register("og_description")}
                        placeholder="Enter OG Description"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                      <div
                        className={`invalid-feedback ${
                          errors.og_description ? "d-block" : ""
                        }`}
                      >
                        {errors.og_description
                          ? errors.og_description.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        OG Url
                      </label>
                      <input
                        className="form-control"
                        name="og_url"
                        placeholder="Enter OG Url"
                        {...register("og_url")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.og_url ? "d-block" : ""
                        }`}
                      >
                        {errors.og_url ? errors.og_url.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        OG Type
                      </label>
                      <input
                        className="form-control"
                        name="og_type"
                        placeholder="Enter OG Type"
                        {...register("og_type")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.og_type ? "d-block" : ""
                        }`}
                      >
                        {errors.og_type ? errors.og_type.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                       OG Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="og_image"
                        onChange={(e) => {
                          setValue("og_image", e.target.files[0]);
                          setFile(URL.createObjectURL(e.target.files[0]));
                        }}
                        placeholder="Enter display order"
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.image ? "d-block" : ""
                        }`}
                      >
                        {errors.image ? errors.image.message : ""}
                      </div>
                      {File !== "" && (
                        <div
                          className=" d-flex align-items-center overflow-hidden flex-column mt-2"
                          style={{ width: "20%" }}
                        >
                          <img src={File} alt="" style={{ width: "100%" }} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Status
                      </label>
                      <select
                        className="form-select"
                        name="status"
                        defaultValue="1"
                        {...register("status")}
                      >
                        <option value=""> Select Status </option>
                        <option value="1"> Active </option>
                        <option value="0">In Active</option>
                      </select>
                      <div
                        className={`invalid-feedback ${
                          errors.status ? "d-block" : ""
                        }`}
                      >
                        {errors.status ? errors.status.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label>Additional Tags(for page)</label>
                      <textarea
                        className="form-control"
                        name="additional_tags"
                        {...register("additional_tags")}
                        placeholder="Enter Additional Tags"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                      <div
                        className={`invalid-feedback ${
                          errors.additional_tags ? "d-block" : ""
                        }`}
                      >
                        {errors.additional_tags
                          ? errors.additional_tags.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 mt-4 text-end">
                    <button
                      type="submit"
                      className="btn btn-danger w-sm"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Save SEO"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
