import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";

import Loader from "../../../../shared/Loader";
import CustomerDropdown from "../Customer/CustomerDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";
import moment from "moment";
import Paginate from "../../../../shared/Paginate";
import ReactFlatpickr from "../../../../shared/ReactFlatpickr";

export const SalesReturnList = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  console.log(window.location.search);
  // const url_page = params.get("page");
  let url_customer_id = params.get("customer_id");
  let url_order_no = params.get("order_no");
  let url_order_status = params.get("order_status");
  let url_from_date = params.get("from_date");
  let url_to_date = params.get("to_date");
  let url_page = params.get("page");

  console.log(params.get("from_date"));
  console.log(url_from_date);

  /*************FILTERS*******************/
  const [Customer_value, setCustomer_value] = useState([]);
    const [Customer_id, setCustomer_id] = useState("");
    const [Order_no, setOrder_no] = useState("");
    const [Order_status, setOrder_status] = useState("");
    const [from_date, setfrom_date] = useState("");
    const [to_date, setto_date] = useState("");
  
    const CustonChange = (e) => {
      console.log(e);
      setCustomer_value(e);
      setCustomer_id(e.value);
    };

    const OrdNoChange = (e) => {
      console.log(e.target.value);
      setOrder_no(e.target.value);
    };
  
    const OrStatonChange = (e) => {
      console.log(e.target.value);
      setOrder_status(e.target.value);
    };
  
    const onStartChange = (e) => {
      // let fromDate = (e.target.value).split('-').reverse().join('/') 
      console.log(e.target.value);
      setfrom_date(e.target.value);
    };
  
    const onEndChange = (e) => {
      console.log(e.target.value);
      setto_date(e.target.value);
    };
  
    const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  const [userList, setuserList] = useState([]);
  const [Pages, setPages] = useState([]);
  const [SRNO, setSRNO] = useState([]);
  const [total, setTotal] = useState();

  useEffect(() => {
    if (url_customer_id != "") {
      setCustomer_id(url_customer_id);
    }
    if (url_order_no != "") {
      setOrder_no(url_order_no);
    }
    if (url_order_status != "") {
      setOrder_status(url_order_status);
    }
    if (url_from_date != "") {
      setfrom_date(url_from_date);
    }
    if (url_to_date != "") {
      setto_date(url_to_date);
    }

    refreshPage(
      url_customer_id,
      url_order_no,
      url_order_status,
      url_from_date,
      url_to_date,
      url_page
    );
    
  }, []);

  const reset = () => {
    setCustomer_value("");
    setCustomer_id("");
    setOrder_no("");
    setOrder_status("");
    setfrom_date("");
    setto_date("");
    refreshPage("" , "" , "" , "", "", 1)
  };


  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(
      Customer_id,
      Order_no,
      Order_status,
      from_date,
      to_date,
      pageNumber
    );
  };

  const search = () => {
    removeErrorParam();
    setSearchParams(params);
    refreshPage(Customer_id, Order_no, Order_status, from_date, to_date, 1);
  };

  function getPages(urls = "") {
    setLoading(true);
    http
      .get(`get_order_list_count${urls}`)
      .then((res) => {
        console.log(res);
        setPages(res.data.page_no);
        setTotal(res.data.total_orders)
        setSRNO(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const refreshPage = (
    customer_id = "",
    order_no = "",
    order_status = "",
    from_date = "",
    to_date = "",
    page_to = ""
  ) => {
    setLoading(true);
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (customer_id) {
      urls += `&customer_id=${Customer_id}`;
    }
    if (order_no) {
      urls += `&order_no=${Order_no}`;
    }
    if (order_status) {
      urls += `&order_status=${Order_status}`;
    }
    if (from_date) {
      urls += `&from_date=${from_date}`;
    }
    if (to_date) {
      urls += `&to_date=${to_date}`;
    }
    setSearchParams(urls);
    getPages(urls);
    http
      .get(`get_order_list${urls}`)
      .then((res) => {
        console.log(res);
        setuserList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

 

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete order?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_product/${id}`)
      .then((response) => {
        toast.success("Order deleted");
        setDeleteMessage("User has been deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end
  
  const orderStatus = [
    { class: "badge bg-primary", name: "Placed" },
    { class: "badge bg-warning", name: "Ready for Dispatch" },
    { class: "badge bg-success", name: "Shipped" },
    { class: "badge bg-danger", name: "Cancelled" },
  ];
  

  const creation_from_date = {
    onChange: (selectedDates, dateStr, instance) => {
      // console.log(dateStr)
      setfrom_date(dateStr);
    },
  };
  const creation_to_date = {
    onChange: (selectedDates, dateStr, instance) => {
      // console.log(dateStr)
      setto_date(dateStr);
    },
  };

  console.log("fromdate" , from_date);

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex  justify-content-between align-items-center">
                    <h4 className="card-title mb-0">Sales Return List</h4>
                    <Link
                      // onClick={() => showClauseForm()}
                      to="/order/order-return"
                      type="button"
                      className="btn btn-primary add-btn"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create Return
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="listjs-table" id="customerList">
                      <div className="row g-4 mb-2">
                        <div className="col-md-11 ">
                          <div className="row align-items-center">
                            <div className="col-md-3">
                              <CustomerDropdown
                                placeholder="Customer"
                                value={Customer_value}
                                change={(e) => CustonChange(e)}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Ref No/ Order No"
                                value={Order_no}
                                onChange={(e) => OrdNoChange(e)}
                              />
                            </div>
                            <div className="col-md-3">
                              <div className="input-group">
                                <ReactFlatpickr
                                placeholder="From Date (Creation Date)"
                                options={creation_from_date}
                                value={from_date}
                              />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-group">
                              <ReactFlatpickr
                                placeholder="To Date (Creation Date)"
                                options={creation_to_date}
                                value={to_date}
                              />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1 d-flex align-items-center">
                          <i
                            className="bx bx-search text-primary fs-2"
                            style={{ cursor: "pointer" }}
                            onClick={search}
                          ></i>
                          <i
                            className="bx bx-reset text-primary fs-2 ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={reset}
                          ></i>
                        </div>
                      </div>
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap table-bordered"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              {/* <th scope="col">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th> */}
                              <th
                                data-sort="customer_name"
                                style={{ width: "5%" }}
                                valign="top"
                              >
                                Sr
                              </th>
                              <th
                                data-sort="email"
                                style={{ width: "35%" }}
                                valign="top"
                              >
                                Customer Name
                              </th>
                              <th data-sort="date" valign="top">
                                Order No  
                              </th>
                              <th data-sort="date" valign="top">
                               Order Date
                              </th>
                              <th
                                data-sort="status"
                                valign="top"
                              >
                                Total Amt
                              </th>
                              <th
                                data-sort="action"
                                valign="top"
                                style={{textAlign:"center"}}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          {loading === false && (
                            <tbody className="list form-check-all">
                              {userList.map((user, index) => (
                                <tr key={index}>
                                  {/* <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th> */}
                                  <td className="customer_name" valign="top">
                                    {SRNO + index + 1}
                                  </td>
                                  <td className="email" valign="top">
                                    {user.customer_name} 
                                  </td>
                                  <td className="email" valign="top">
                                    <span className="text-primary f">
                                      {" "}
                                      {/* <Link to={`/order/view-order/${user.id}`}> */}
                                        {user.order_no}
                                      {/* </Link> */}
                                    </span>
                                  </td>
                                  <td className="email" valign="top">
                                    {moment(user.order_date)
                                      .utc()
                                      .format("DD/MM/YYYY")}
                                  </td>
                                  <td className="date" valign="top">
                                    {user.total_amount}
                                  </td>
                                  <td valign="top">
                                    <div className="d-flex gap-2 justify-content-center">
                                      <div className="view">
                                        <Link
                                          className=" ri-eye-line fs-4"
                                          to={`/order/view-order/${user.id}`}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          onClick={() =>
                                            showDeleteModal("User", user.id)
                                          }
                                          className="ri-delete-bin-6-line fs-4"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                      {Pages > 1 && (
                        <Paginate
                        postsPerPage={20}
                        totalPosts={total}
                        paginate={paginate}
                        url_page={url_page}
                      />
                      )}
                    </div>
                  </div>
                </div>
                <DeleteConfirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitDelete}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={deleteMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
