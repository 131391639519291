import React, { useState, useEffect,useRef } from "react";

import { useNavigate, Link ,useSearchParams} from "react-router-dom";

// import DeleteConfirmation from "../../../shared/DeleteConfirmation";
import Loader from "../../../shared/Loader";
import http from "../../../../utils/http";

const SupplierReports = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  let url_company_name = params.get("company_name");
  let url_address = params.get("address");
  let url_supplier_contact = params.get("supplier_contact");
  let url_page = params.get("page");


  const [searchParams, setSearchParams] = useSearchParams();
  // *************Filter's**************************
  const [company_name, setcompany_name] = useState("");
  const [supp_address, setsupp_address] = useState("");
  const [supp_name, setsupp_name] = useState("");

  function searchCompanyName (e) {
    setcompany_name(e.target.value)
  }
  function searchAddress (e) {
    setsupp_address(e.target.value)
  }
  function searchSuppName (e) {
    setsupp_name(e.target.value)
  }
  const [supplierList, setSupplierList] = useState([]);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();
  const [Offset, setOffset] = useState("");

  useEffect(() => {
    if (url_company_name != "") {
      setcompany_name(url_company_name);
    }
    if (url_address != "") {
      setsupp_address(url_address);
    }
    if (url_supplier_contact != "") {
      setsupp_name(url_supplier_contact);
    }

    refreshPage(
      url_company_name,
      url_address,
      url_supplier_contact,
      url_page
    );
  }, []);

  let ref = useRef(null);

  function reset_filter() {
    setcompany_name("");
    setsupp_address("");
    setsupp_name("");
    // ref.current.value = "";
    // console.log("value", ref.current.value);
    refreshPage("", "", "",  1);
  }

  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(
      company_name,
      supp_address,
      supp_name,
      pageNumber
    );
  };

  function search_filter() {
    removeErrorParam();
    setSearchParams(params);
    refreshPage(
      company_name,
      supp_address,
      supp_name,
      1
    );
  }

  function getPages(urls = "") {
    setLoading(true);

    http
      .get(`get_supplier_count${urls}`)
      .then((res) => {
        console.log(res.data);
        setPages(res.data.page_no);
        setTotal(res.data.total);
        setOffset(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const refreshPage = (
    company_name = "",
    supp_address = "",
    supp_name = "",
    page_to = ""
  ) => {
    setLoading(true);

    // let page_to_load = page_to != "" ? page_to : currentPage;
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (company_name) {
      urls += `&company_name=${company_name}`;
    }
    if (supp_address) {
      urls += `&address=${supp_address}`;
    }

    if (supp_name) {
      urls += `&supplier=${supp_name}`;
    }

    console.log("page_to_load", page_to_load);
    setSearchParams(urls);
    getPages(urls);
    http
      .get(`get_supplier${urls}`)
      .then((res) => {
        setSupplierList(res.data);
        setLoading(false);
        // UserComponentVerifiy(res);
      })
      .catch((err) => {
        // toast.error(err.message);
        console.log(err.message);
      });
  };

  

  // Set up some additional local state
  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [error, setError] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to remove standard?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  function handleEdit(supplier_id) {
    console.log(supplier_id);
    navigate(`/supplier-management/add-basic-info/${supplier_id}`);
  }

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_supplier/${id}`)
      .then((response) => {
        setDeleteMessage("Supplier has been deleted");

        const timer = setTimeout(() => {
          refreshPage();
          hideConfirmationModal();
        }, 1000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setDeleteMessage("Supplier has been deleted");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

 

  // Loader Code
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  // Loader code end

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h4 className="card-title mt-2">Supplier Reports List</h4>
                    {/* <Link
                      to="/supplier-management/add-basic-info"
                      type="button"
                      className="btn btn-primary add-btn"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                      Supplier
                    </Link> */}
                  </div>

                  <div className="card-body">
                    <div className="listjs-table" id="customerList">
                      <div className="row g-4 mb-3">
                        <div className="col-md-11">
                          <div className="row">
                            <div className="col-md-4">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Code / Company Name / GST IN"
                                onChange={(e) => {
                                  searchCompanyName(e);
                                }}
                                value={company_name}
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="City / Pincode"
                                onChange={(e) => {
                                  searchAddress(e);
                                }}
                                value={supp_address}
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Contact Name / Email / Mobile"
                                onChange={(e) => {
                                  searchSuppName(e);
                                }}
                                value={supp_name}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1 d-flex align-items-center justify-content-end">
                          <i
                            class="bx bx-search text-primary fs-2"
                            style={{ cursor: "pointer" }}
                            onClick={search_filter}
                          ></i>
                          <i
                            class="bx bx-reset text-primary fs-2 ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={reset_filter}
                          ></i>
                        </div>
                      </div>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap table-bordered"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th scope="col">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th scope="col">#</th>
                              <th data-sort="email">Code</th>
                              <th data-sort="phone">Company Name / GST IN / City</th>
                              <th data-sort="customer_name">Contact Person</th>
                              <th data-sort="email">E Mail / Mobile</th>
                              <th data-sort="email">Created by <br/>/ Created date</th>
                              <th data-sort="action">Status</th>
                              {/* <th data-sort="action">Action</th> */}
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                          {loading === true && (
                            <tr>
                              <td className="customer_name">
                                <Loader show={loading} />
                              </td>
                            </tr>
                          )}
                          {loading === false && 
                          supplierList.length 
                          ?   supplierList.map((suppList, index) => (
                              <tr>
                                <th scope="row">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="chk_child"
                                      value="option1"
                                    />
                                  </div>
                                </th>
                                {/* <td>{index + 1}</td> */}
                                <td>{index + 1}</td>

                                <td>SUPPL05612</td>
                                <td>{suppList.company_name.slice(0, 18)} <br/> {suppList.gst_number} <br/>{suppList.city}</td>
                                <td>{suppList.contact_name}</td>
                                <td>{suppList.contact_email} <br/> {suppList.contact_mobile}</td>
                                <td>Admin <br/> 31/12/23</td>
                                {/* <td>{suppList.contact_mobile}</td> */}
                                <td className="status">
                                  <span
                                    className={`badge text-uppercase ${
                                      suppList.status == 1
                                        ? "bg-success-subtle text-success"
                                        : "bg-danger-subtle text-danger"
                                    }`}
                                  >
                                    {suppList.status == 1
                                      ? "approve"
                                      : "pending"}
                                  </span>
                                </td>
                                {/* <td>
                                  <div className="d-flex gap-2">
                                    
                                    <div className="edit">
                                      <Link
                                       
                                        onClick={() => handleEdit(suppList.id)}
                                        className=" ri-edit-2-fill fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                      ></Link>
                                    </div>
                                    <div className="remove">
                                     
                                      <Link
                                        className="ri-delete-bin-6-line fs-4"
                                        onClick={() =>
                                          showDeleteModal(
                                            "supplier",
                                            suppList.id
                                          )
                                        }
                                      ></Link>
                                    </div>
                                  </div>
                                </td> */}
                              </tr>
                            )) : null }
                          </tbody>
                        </table>
                      </div>

                      {/* <div className="d-flex justify-content-end">
                  <div className="pagination-wrap hstack gap-2">
                    <a
                      className="page-item pagination-prev disabled"
                      href="javascript:void(0);"
                    >
                      Previous
                    </a>
                    <ul className="pagination listjs-pagination mb-0"></ul>
                    <a
                      className="page-item pagination-next"
                      href="javascript:void(0);"
                    >
                      Next
                    </a>
                  </div>
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierReports;
