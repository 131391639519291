import React, {useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";

export const EditMasterPincodeModal  = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  const yupValidation = Yup.object().shape({
    pincode: Yup.string().required("Please enter pincode"),
    city: Yup.string().required("Please enter city"),
    state: Yup.string().required("Please enter state"),
    country: Yup.string().required("Please enter country"),
    status: Yup.string().required("Please enter status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  const btHandleEdit = () => {
    console.log(id);
    fetch(process.env.REACT_APP_API_BASE + `get_pincode_by_id/${id}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setValue("pincode", data.pincode);
        setValue("city", data.city);
        setValue("state", data.state);
        setValue("country", data.country);
        setValue("status", data.status);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    btHandleEdit(id)
  }, [id]);

  function onSubmit(data) {
    console.log(data);
    setLoading(true);
    axios
      .put(process.env.REACT_APP_API_BASE + `update_pincode/${id}`, data)
      .then((response) => {
        console.log(response);

        setLoading(false);
        reset();
        refreshPage();
        // setbtnToggle(true)
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Master Address</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 ${resss !== "" && ""}`}>
            <div className="col-lg-3 ">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  Pincode
                </label>
                <input
                  name="pincode"
                  type="number"
                  className="form-control"
                  placeholder="Enter pincode"
                  {...register("pincode")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${
                    errors.pincode ? "d-block" : ""
                  }`}
                >
                  {errors.pincode ? errors.pincode.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>
            <div className="col-lg-3 ">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  City
                </label>
                <input
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder="Enter city"
                  {...register("city")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${errors.city ? "d-block" : ""}`}
                >
                  {errors.city ? errors.city.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>
            <div className="col-lg-3 ">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  State
                </label>
                <input
                  name="state"
                  type="text"
                  className="form-control"
                  placeholder="Enter state"
                  {...register("state")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${
                    errors.state ? "d-block" : ""
                  }`}
                >
                  {errors.state ? errors.state.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>
            <div className="col-lg-3 ">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  Country
                </label>
                <input
                  name="country"
                  type="text"
                  className="form-control"
                  placeholder="Enter country"
                  {...register("country")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${
                    errors.country ? "d-block" : ""
                  }`}
                >
                  {errors.country ? errors.country.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>
            <div className="col-lg-3 ">
              <div>
                <label for="assignedtoName-field" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  className="form-select"
                  {...register("status")}
                >
                  <option value="">Select status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.status ? "d-block" : ""
                  }`}
                >
                  {errors.status ? errors.status.message : ""}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit" onClick={hideModal}>
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
