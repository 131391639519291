import React, { use, useState , useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import http from "../../../../../utils/http";
import moment from "moment";

export const UpdateLead = React.memo(function UpdateLead(props) {
  const {showModal,hideModal,refreshPage,eid} = props
  const [leadLists, setLeadList] = useState([]);
 
  const getLeadData = () => {
    console.log(eid);
    http.get(`get_lead_feedback/${eid}`)
      .then((res) => {
        console.log(res);
        setLeadList(res.data)
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  
  console.log(leadLists);

useEffect(() => {
  getLeadData()
}, [eid]);

   


  const yupValidation = Yup.object().shape({
    date_of_call: Yup.string().required("Please enter date of call"),
    channel_of_communication: Yup.string().required("Please enter channel of communication"), 
    feedback: Yup.string().required("Please enter feedback"),
    next_followup_date: Yup.string().required("Please enter next followup date"),
    lead_status: Yup.string().required("Please enter lead status"),
  });
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    setValue("lead_id" , eid)
    console.log(data);
    setBtnloading(true);
    http
      .post(`add_lead_feedback`, data)
      .then((response) => {
        console.log(response);
        const timer = setTimeout(() => {
          setBtnloading(false);
          reset();
          hideModal();
          refreshPage();
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Progess</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 `}>
            <div className="col-lg-12">
              <div className="table-responsive mt-3 mb-1">
                <table
                  className="table align-middle table-nowrap table-bordered"
                  id="customerTable"
                >
                  <thead className="table-light">
                    <tr>
                      <th scope="col" valign="top">
                        Date
                      </th>
                      <th
                        data-sort="customer_name"
                        valign="top"
                        style={{ width: "80%" }}
                      >
                        Feedback
                      </th>
                      <th data-sort="customer_name" valign="top">
                        Status
                      </th>
                      <th data-sort="customer_name" valign="top">
                        Updated By
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list form-check-all">
                    {leadLists.map((leadList, index) => (
                    <tr key={index} valign="top">
                      <td className="customer_name">{moment(leadList.indate).utc().format("DD/MM/YYYY")}</td>
                      <td className="email">{leadList.feedback}</td>
                      <td className="mobile">{leadList.lead_status}</td>
                      <td className="mobile">Admin</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className={`row g-3 `}>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Date
                </label>
                <input
                  name="date_of_call"
                  type="date"
                  className="form-control"
                  placeholder="Enter Date"
                  {...register("date_of_call")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.date_of_call ? "d-block" : ""
                  }`}
                >
                  {errors.date_of_call? errors.date_of_call.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Channel of Communication
                </label>
                <select
                  className="form-select"
                  name="channel_of_communication"
                  {...register("channel_of_communication")}
                >
                  <option value=""> Select Channel of Communication</option>
                  <option value="email">Email</option>
                  <option value="mobil">Mobile</option>
                  <option value="whatsapp">WhatsApp</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.channel_of_communication ? "d-block" : ""
                  }`}
                >
                  {errors.channel_of_communication ? errors.channel_of_communication.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Feedback in brief
                </label>
                <textarea
                  className="form-control"
                  name="feedback"
                  {...register("feedback")}
                  placeholder="Enter Feedback"
                  rows="3"
                ></textarea>
                <div
                  className={`invalid-feedback ${
                    errors.feedback ? "d-block" : ""
                  }`}
                >
                  {errors.feedback ? errors.feedback.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Date of Next Followup
                </label>
                <input
                  name="next_followup_date"
                  type="date"
                  className="form-control"
                  placeholder="Enter Date of Next Followup"
                  {...register("next_followup_date")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.next_followup_date ? "d-block" : ""
                  }`}
                >
                  {errors.next_followup_date ? errors.next_followup_date.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Lead Status
                </label>
                <select
                  className="form-select"
                  name="lead_status"
                  {...register("lead_status")}
                >
                  <option value=""> Select Lead Status</option>
                  <option value="new">New</option>
                  <option value="contacted">Contacted</option>
                  <option value="qualified">Qualified</option>
                  <option value="won">Won</option>
                  <option value="lost">Lost</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.lead_status ? "d-block" : ""
                  }`}
                >
                  {errors.lead_status ? errors.lead_status.message : ""}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={eid} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            disabled={Btnloading}
            // onClick={hideModal}
          >
            {Btnloading ? "Loading..." : "Update Lead"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
});

