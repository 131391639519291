import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import slugify from "slugify";
import CategoryDropdown from "./CategoryDropdown";
import http from "../../../../../utils/http";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import CategoryChildParentDropdown from "./CategoryChildParentDropdown";

export const EditCategoryForm = () => {
  // console.log("hi");
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [userList, setuserList] = useState([]);
  const [parent_category, setparent_category] = useState([]);

  useEffect(() => {
    refreshPage();
    fetchparent_category();
  }, []);

  const fetchparent_category = () => {
    http
      .get("get_category_tree")
      .then((res) => {
        setparent_category(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [Categ, setCateg] = useState("");
  const [File, setFile] = useState("");
  const [slug, setSlug] = useState("");

  const refreshPage = () => {
    setLoading(true);
    http
      .get("get_category_by_id/" + id)
      .then((res) => {
        console.log(res.data);
        setSlug(res.data.slug);
        setCateg(res.data.image);
        setValue("category_name", res.data.category_name);
        setValue("parent_id", res.data.parent_id);
        setValue("description", res.data.description);
        setContent(res.data.description);
        setValue("show_home", res.data.show_home);
        setValue("status", res.data.status);
        setValue("image_old", res.data.image);
        setValue("display", res.data.display);
        setValue("cat_parent", res.data.cat_parent);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(slug);
  const yupValidation = Yup.object().shape({
    category_name: Yup.string().required("Please enter category name"),
    // parent_id: Yup.string().required("Please select parent category"),
    description: Yup.string().required("Please enter description"),
    display: Yup.string().required("Please select display"),
    show_home: Yup.string().required("Please select show home"),
    status: Yup.string().required("Please select status"),
    // seo_url: Yup.string().required("Please enter seo url"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  function getSEOUrl(e) {
    let val = e.target.value;
    console.log(val);
    let slugifyLink = slugify(val);
    setValue("seo_url", slugifyLink);
    setSlug(slugifyLink);
  }

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    console.log(Categ);
    // setValue("seo_url", slug);
    setValue("image_old", Categ);
    setBtnloading(true);

    console.log(data);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    http
      .put(`update_category/` + id, data, config)
      .then((response) => {
        console.log(response);
        toast.success("Category has been updated");
        const timer = setTimeout(() => {
          setBtnloading(false);
          navigate("/category");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  }

  const handleDropDown = (d, e) => {
    let fvalues = [];
    e.map((n, index) => {
      fvalues.push(n.value);
    });
    let textsss = fvalues.join();
    setValue(d, textsss, { shouldValidate: true });
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  const [Cat_Parent, setCat_Parent] = useState([]);

  function handle_Cat_DropDown(e) {
    console.log(e);
    setCat_Parent(e);
    setValue("parent_id", e.value);
    setValue("cat_parent", e);
  }

  const [content, setContent] = useState("");
  const onChange = (text) => {
    console.log(text);
    setContent(text);
    setValue("description", text);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />

            {loading === false && (
              <div>
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Edit Category</h4>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {/* {userList.map((user, index) => { */}
                      <div>
                        <div className="row mb-3">
                          <div className="col-lg-6">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Category Name
                            </label>
                            <input
                              className="form-control"
                              name="category_name"
                              placeholder="Enter Category Name"
                              {...register("category_name")}
                              onChange={(e) => getSEOUrl(e)}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.category_name ? "d-block" : ""
                              }`}
                            >
                              {errors.category_name
                                ? errors.category_name.message
                                : ""}
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Parent Category
                            </label>
                            {/* <select
                              className="form-select"
                              name="parent_id"
                              {...register("parent_id")}
                            >
                              <option value="">Select Category</option>
                              {parent_category.map((parent_cat, index) => {
                                return (
                                  <option
                                    value={parent_cat.id}
                                    selected={
                                      parent_cat.id == getValues("parent_id")
                                        ? true
                                        : false
                                    }
                                  >
                                    {parent_cat.breadcrumb}
                                  </option>
                                );
                              })}
                            </select> */}
                            <CategoryChildParentDropdown
                              type="industry_sector"
                              placeholder="Categories"
                              value={getValues("cat_parent")}
                              change={(e) => handle_Cat_DropDown(e)}
                            />
                            {/* <div
                              className={`invalid-feedback ${
                                errors.parent_id ? "d-block" : ""
                              }`}
                            >
                              {errors.parent_id ? errors.parent_id.message : ""}
                            </div> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 mb-3">
                            <label> Description</label>
                            {/* <textarea
                              className="form-control"
                              name="description"
                              {...register("description")}
                              placeholder="Enter category description"
                              id="exampleFormControlTextarea1"
                              rows="3"
                            ></textarea> */}
                            <ReactQuill
                              theme="snow"
                              value={content}
                              onChange={onChange}
                              placeholder="Enter Description"
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.description ? "d-block" : ""
                              }`}
                            >
                              {errors.description
                                ? errors.description.message
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-lg-6 ">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              File
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="image"
                              accept="application/jpg,application/png"
                              onChange={(e) => {
                                setValue("image", e.target.files[0]);
                                setFile(URL.createObjectURL(e.target.files[0]));
                              }}
                            />
                            {/* <input
                              type="hidden"
                              className="form-control"
                              name="image_old"
                              value={setValue("image_old", Categ)}
                            /> */}
                            {/* {File == "" &&
                            <div className="mt-2">
                              <p>File name : {Categ}</p>
                            </div>
                            } */}

                            {File !== "" || Categ === "" ? (
                              <div
                                className=" d-flex align-items-center overflow-hidden flex-column mt-2"
                                style={{ width: "40%" }}
                              >
                                <img
                                  src={File}
                                  alt=""
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className=" d-flex align-items-center overflow-hidden flex-column mt-2"
                                style={{ width: "40%" }}
                              >
                                <img
                                  src={`https://woodens3.s3.ap-south-1.amazonaws.com/${Categ}`}
                                  alt=""
                                  style={{ width: "100%" }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Website Display
                            </label>
                            <select
                              className="form-select"
                              name="display"
                              {...register("display")}
                              value={userList.display}
                            >
                              <option value="">Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                            <div
                              className={`invalid-feedback ${
                                errors.display ? "d-block" : ""
                              }`}
                            >
                              {errors.display ? errors.display.message : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-lg-6">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Show Home
                            </label>
                            <select
                              className="form-select"
                              name="show_home"
                              {...register("show_home")}
                              value={userList.show_home}
                            >
                              <option value="">Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                            <div
                              className={`invalid-feedback ${
                                errors.show_home ? "d-block" : ""
                              }`}
                            >
                              {errors.show_home ? errors.show_home.message : ""}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Status
                            </label>
                            <select
                              className="form-select"
                              name="status"
                              {...register("status")}
                              value={userList.status}
                            >
                              <option value="">Select status</option>
                              <option value="1">Active</option>
                              <option value="0">In Active</option>
                            </select>
                            <div
                              className={`invalid-feedback ${
                                errors.status ? "d-block" : ""
                              }`}
                            >
                              {errors.status ? errors.status.message : ""}
                            </div>
                          </div>
                          <div className="col-md-6">
                            {/* <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              SEO Url
                            </label> */}
                            <input
                              className="form-control"
                              name="seo_url"
                              type="hidden"
                              placeholder="Enter SEO url"
                              defaultValue={slug}
                              {...register("seo_url")}
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.seo_url ? "d-block" : ""
                              }`}
                            >
                              {errors.seo_url ? errors.seo_url.message : ""}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 mt-4 text-end">
                          <Link
                            type="button"
                            className="btn btn-danger me-2 pt-8 pb-8"
                            to="/category"
                          >
                            Cancel
                          </Link>
                          <button
                            type="submit"
                            className="btn btn-success ps-8 pe-8"
                            disabled={Btnloading}
                          >
                            {Btnloading ? "Loading..." : "Save Category"}
                          </button>
                        </div>
                      </div>
                      {/* })} */}
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
