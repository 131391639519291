import React from 'react';

import Form from 'react-bootstrap/Form';
function InspectionPageSelect() {
  return (
    <Form.Select aria-label="Default select example">
    <option>Page 1 of 1</option>
    <option value="1">Title Page</option>
    <option value="2">Untitled Page</option>
  </Form.Select>
  );
}

export default InspectionPageSelect;