import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";
import CategoryDropdown from "../Category/CategoryDropdown";

export const BulkCreation = () => {
  useEffect(() => {
    fetchsupplier();
  }, []);

  const [supplierList, setsupplierList] = useState([]);
  const [ZipFile, setZipFile] = useState("");
  const [ExcelFile, setExcelFile] = useState("");

  const CS_File_Url =
    "https://woodens3.s3.ap-south-1.amazonaws.com/10010400.xls";

  const fetchsupplier = () => {
    http
      .get("get_supplier")
      .then((res) => {
        console.log(res.data);
        setsupplierList(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const downloadExcelFile = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const [File, setFile] = useState("");

  const yupValidation = Yup.object().shape({
    supplier: Yup.string().required("Please select supplier"),
    make: Yup.string().required("Please select make"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit(data) {
    console.log(data);
    setLoading(true);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    http
      .post(`save_product_bulk`, data, config)
      .then((response) => {
        toast.success("Bulk product has been uploaded");
        const timer = setTimeout(() => {
          setLoading(false);
          navigate("/product");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const [Cat_Parent, setCat_Parent] = useState([]);

  function handle_Cat_DropDown(e) {
    console.log(e);
    setCat_Parent(e);
    setValue("parent_id", e.value);
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Product bulk upload</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <strong>Bulk upload process</strong>
                  <br />
                  1. Select Category and download template
                  <br />
                  2. Fillin the data in the template
                  <br />
                  3. Create Zip file for the images <br />
                  4. Image name in the file must be Unique and Letter Case
                  should be the same asin the file
                  <br />
                  5. Do not the change template file name (it must be the same
                  as downloaded).
                  <br />
                  6. Display - Set as 1 for displaying catalogue on the website
                  ; 0 for hiding.
                  <br />
                  7. Show Price - Set as 1 for price display on the website ; 0
                  for hiding.
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Category
                      </label>
                      <CategoryDropdown
                        type="industry_sector"
                        placeholder="Categories"
                        value={Cat_Parent}
                        change={(e) => handle_Cat_DropDown(e)}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.url ? "d-block" : ""
                        }`}
                      >
                        {errors.url ? errors.url.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-end">
                      <button
                        type="button"
                        className="btn btn-primary w-sm"
                        onClick={() => downloadExcelFile(CS_File_Url)}
                      >
                        Download Excel
                      </button>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-3">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Supplier
                      </label>
                      <select
                        className="form-select"
                        name="supplier"
                        {...register("supplier")}
                      >
                        <option value="">Select Supplier</option>
                        {supplierList.map((supplier, index) => {
                          return (
                            <option value={supplier.id}>
                              {supplier.company_name}
                            </option>
                          );
                        })}
                      </select>

                      <div
                        className={`invalid-feedback ${
                          errors.supplier ? "d-block" : ""
                        }`}
                      >
                        {errors.supplier ? errors.supplier.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Make
                      </label>
                      <select
                        className="form-select"
                        name="make"
                        {...register("make")}
                      >
                        <option value="">Select Make</option>
                        <option value="1">Make</option>
                      </select>

                      <div
                        className={`invalid-feedback ${
                          errors.make ? "d-block" : ""
                        }`}
                      >
                        {errors.make ? errors.make.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Excel
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="excel_file"
                        onChange={(e) => {
                          setValue("excel_file", e.target.files[0]);
                          setExcelFile(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                    </div>
                    <div className="col-lg-2">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Zip File
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="zip_file"
                        onChange={(e) => {
                          setValue("zip_file", e.target.files[0]);
                          setZipFile(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                    </div>
                    <div className="col-lg-2 d-flex align-items-end">
                      <button
                        type="submit"
                        className="btn btn-primary w-sm"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Upload Excel"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
