import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import http from "../../../../../utils/http";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import moment from "moment";
import { CreateEscalation } from "./CreateEscalation";
import CustomerDropdown from "../Customer/CustomerDropdown";


export const CreateTicket = () => {
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [order_id, setOrderId] = useState("");
  const [resss, setMessage] = useState("");
  const [error, setError] = useState(null);

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);

  const [ticketLists, setTicketList] = useState([]);

  /*************FILTERS*******************/
  const [Customer_value, setCustomer_value] = useState([]);
  const [Customer_id, setCustomer_id] = useState("");
  const [Order_no, setOrder_no] = useState("");

  const orderStatus = [
    { class: "badge bg-primary", name: "Placed" },
    { class: "badge bg-warning", name: "Ready for Dispatch" },
    { class: "badge bg-success", name: "Shipped" },
    { class: "badge bg-danger", name: "Cancelled" },
  ];

  const CustonChange = (e) => {
    console.log(e);
    setCustomer_value(e);
    setCustomer_id(e.value);
  };

  const OrdNoChange = (e) => {
    console.log(e.target.value);
    setOrder_no(e.target.value);
  };
  console.log("Customer Id", Customer_id);
  console.log("Order No", Order_no);

  const [showTable, setshowTable] = useState(false);
  const search = () => {
   
    let urls = "";

    if (Customer_id) {
      urls += `&customer_id=${Customer_id}`;
    }
    if (Order_no) {
      urls += `&order_no=${Order_no}`;
    }
    console.log(urls);
    http
    //http://192.168.1.177:8080/admin/get_order_list?page=1&customer_id=1
      .get(`get_order_list?page=1${urls}`)
      .then((res) => {
        console.log(res);
        setTicketList(res.data);
        setshowTable(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  console.log("TicketId", ticketLists);
  const reset = () => {
    console.log("Reset");
    setCustomer_value("");
    setCustomer_id("");
    setOrder_no("");
    setshowTable(false)
  };


  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete lead?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    console.log(id);
    setError(null);
    http
      .delete(`delete_customer_by_id/${id}`)
      .then((response) => {
        setDeleteMessage("User has been deleted");
        // fetchdata();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  //   const [error, setError] = useState(null);

  // Add banner modal

  const [showAddModal, setshowAddModal] = useState(false);

  const showClauseForm = (id) => {
    setOrderId(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowAddModal(true);
  };

  // Hide the modal
  const hideAddModal = () => {
    setshowAddModal(false);
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  // Loader code end

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex  justify-content-between">
                        <h4 className="card-title mt-2">Create Ticket</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-2">
                          <div className="col-md-10 ">
                            <div className="row align-items-center">
                              <div className="col-md-4">
                                <CustomerDropdown
                                  placeholder="Customer"
                                  value={Customer_value}
                                  change={(e) => CustonChange(e)}
                                />
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Ref No/ Order No"
                                  value={Order_no}
                                  onChange={(e) => OrdNoChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex align-items-center">
                            <i
                              className="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              onClick={search}
                            ></i>
                            <i
                              className="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={reset}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showTable === true ? (
                    <div className="card">
                      <div className="card-body">
                        <div className="listjs-table" id="customerList">
                          <div className="table-responsive table-card mt-3 mb-1">
                            <table
                              className="table align-middle table-nowrap table-bordered"
                              id="customerTable"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th data-sort="customer_name">Sr. No.</th>
                                  <th data-sort="customer_name">
                                    Customer Name
                                  </th>
                                  <th data-sort="customer_name">Order No</th>
                                  <th data-sort="customer_name">Order Date</th>
                                  <th data-sort="customer_name">
                                    Order Status
                                  </th>
                                  <th
                                    data-sort="action"
                                    className="text-center"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="list form-check-all">
                                {ticketLists.map((ticket , index) => (
                                  <tr key={index}>
                                  <td className="customer_name">{index + 1}</td>
                                  <td className="customer_name">
                                    {ticket.customer_name}
                                  </td>
                                  <td className="customer_name">
                                    {ticket.order_no}
                                  </td>
                                  <td className="customer_name">
                                    {moment(ticket.order_date)
                                      .utc()
                                      .format("DD/MM/YYYY")}
                                  </td>
                                  <td className="customer_name">
                                  <span
                                      className={
                                        orderStatus[ticket.order_status].class
                                      }
                                    >
                                      {orderStatus[ticket.order_status].name}
                                    </span>
                                  </td>
                                  <td className="mobile text-center">
                                    <Link onClick={() => showClauseForm(ticket.id)}>
                                      Create Escalation
                                    </Link>
                                  </td>
                                </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <CreateEscalation
                    showModal={showAddModal}
                    hideModal={hideAddModal}
                    // refreshPage={fetchdata}
                    type={type}
                    orderId={order_id}
                    CustomerId={Customer_id}
                  />
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
