import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import http from "../../../../../utils/http";
import CustomerDropdown from "../Customer/CustomerDropdown";
import moment from "moment";
import ReactFlatpickr from "../../../../shared/ReactFlatpickr";

export const CreateEscalation = ({
  showModal,
  hideModal,
  refreshPage,
  orderId,
  CustomerId,
  type,
  data,
}) => {
  

  console.log(orderId);
  const yupValidation = Yup.object().shape({
    // gst: Yup.number()
    // .typeError("Amount must be a number")
    // .required("Please enter gst"),
    product_id: Yup.number().typeError("Please select Product").required("Please enter Product"),
    issue_type: Yup.string().required("Please enter issue_type"),
    issue_date: Yup.string().required("Please enter issue_date"),
    quantity: Yup.number().typeError("Please select quantity").required("Please enter quantity"),
    order_quantity: Yup.number().typeError("Please select order_quantity").required("Please enter order_quantity"),
    issue_description: Yup.string().required("Please enter order_quantity"),
    
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions 
  );

  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");

  const [Btnloading, setBtnloading] = useState(false);

  const [File, setFile] = useState("");
  const [productLists, setProductLists] = useState([]);

  useEffect(() => {
    fetchOrderbyId()
  }, [orderId]);

  const fetchOrderbyId = () => {
    setLoading(true);
    http
      .get("get_order_by_id/" + orderId)
      .then((res) => {
        setProductLists(res.data.order_items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  console.log("productLists" , productLists);



function selectProcutId (e) {
  console.log("selectProcutId",e.target[e.target.selectedIndex].getAttribute('data-qty')
  );
  setValue("product_id" , e.target.value)
  setValue("order_quantity" , e.target[e.target.selectedIndex].getAttribute('data-qty'))
}

// console.log("CustomerId" , CustomerId);
// console.log("Product ID" , productId);
// console.log("Quantity" , quantityNo);

const [issuetype, setIssueType] = useState("")

function selectIssue(e) {
  console.log(e.target.value);
  setIssueType(e.target.value)
}

  function onSubmit(data) {

    setLoading(true);
    let config = {  
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let newdata = {
      ...data,
      customer_id: CustomerId,
      order_id : orderId
    }
    console.log(newdata , config);
    http
      .post("create_escalation", newdata , config)
      .then((response) => {
        console.log(response);
        setLoading(false);
          reset();
            hideModal();
            refreshPage();
            reset();
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });

  }
  const [issue_date, setissue_date] = useState("");
  const issueDate = {
    onChange: (selectedDates, dateStr, instance) => {
      console.log(dateStr)
      setValue("issue_date",dateStr)
      setissue_date(dateStr)
    }
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Ticket</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 ${resss !== "" && ""}`}>
          <div className="col-lg-12">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Product
                </label>
                <select
                  className="form-select"
                  name="product_id"
                  {...register("product_id")}
                  onChange={(e) => selectProcutId(e)}
                >
                  <option value=""> Select Product</option>
                  {productLists.map((productList) => (
                    <option data-qty={productList.quantity} value={productList.product_id}>{productList.product_name}</option>
                  ))}
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.product_id ? "d-block" : ""
                  }`}
                >
                  {errors.product_id ? errors.product_id.message : ""}
                </div>
              </div>
            </div>
          <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Select Issue
                </label>
                <select
                  className="form-select"
                  name="issue_type"
                  value={issuetype}
                  {...register("issue_type")}
                  onChange={(e) => selectIssue(e)}
                >
                  <option value=""> Select an Issue</option>
                  <option value="Product not working">Product not working</option>
                  <option value="Short uantity received">Short uantity received</option>
                  <option value="Damaged shipment">Damaged shipment</option>
                  <option value="Documentaion issue">Documentaion issue</option>
                  <option value="Product quantity issue">Product quantity issue</option>
                  <option value="Product mismatch">Product mismatch</option>
                  <option value="Any other issue">Any other issue</option>
                  <option value="Catelogue desc is wrong / incomplete">Catelogue desc is wrong / incomplete</option>
                  <option value="Product expired / nearing expiry">Product expired / nearing expiry</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.issue_type ? "d-block" : ""
                  }`}
                >
                  {errors.issue_type ? errors.issue_type.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Issue Date
                </label>
                <ReactFlatpickr
                  placeholder="Issue Date"
                  options={issueDate}
                  value={issue_date}
                  {...register("issue_date")}
                  />
                <div
                  className={`invalid-feedback ${
                    errors.issue_date ? "d-block" : ""
                  }`}
                >
                  {errors.issue_date ? errors.issue_date.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Quantity
                </label>
                <input
                  name="quantity"
                  type="text"
                  className="form-control"
                  placeholder="Enter Quantity"
                  {...register("quantity")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.quantity ? "d-block" : ""
                  }`}
                >
                  {errors.quantity ? errors.quantity.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label" >
                  Order Quantity
                </label>
                <input
                  name="order_quantity"
                  type="text"
                  className="form-control"
                  placeholder="Enter Order Quantity"
                  {...register("order_quantity")}
                  readOnly={true}
                  style={{backgroundColor:"#EEEEEE"}}
                />
                <div
                  className={`invalid-feedback ${
                    errors.order_quantity ? "d-block" : ""
                  }`}
                >
                  {errors.order_quantity ? errors.order_quantity.message : ""}
                </div>
              </div>
            </div>
             <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Attach Image
                </label>
                <input
                  name="attach_image"
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setValue("attach_image", e.target.files[0]);
                    setFile(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                <div
                  className={`invalid-feedback ${
                    errors.attach_image ? "d-block" : ""
                  }`}
                >
                  {errors.attach_image ? errors.attach_image.message : ""}
                </div>
                {File !== "" && (
                  <div
                    className=" d-flex align-items-center overflow-hidden flex-column mt-2"
                    style={{ width: "40%" }}
                  >
                    <img src={File} alt="" style={{ width: "100%" }} />
                  </div>
                )}
              </div>
            </div> 
          </div>
          <div className="col-lg-12 mt-3">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Issue Description
                </label>
                <textarea
                  className="form-control"
                  name="issue_description"
                  {...register("issue_description")}
                  placeholder="Enter Issue Description"
                  rows="3"
                ></textarea>
                <div
                  className={`invalid-feedback ${
                    errors.issue_description ? "d-block" : ""
                  }`}
                >
                  {errors.issue_description
                    ? errors.issue_description.message
                    : ""}
                </div>
              </div>
            </div>
          {/* <div className="hstack gap-2 justify-content-end mt-4 ">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {btnToggle ? (
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            )}
            {/* <button type="submit" className="btn btn-danger" onClick={()=>trashAll()}>Trash All</button> */}
          {/* </div> */} 
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={orderId} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit">
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
