// Navigate.js

import React , {useState} from "react";

const Paginate = ({ postsPerPage, totalPosts, paginate,url_page="" }) => {
  console.log(url_page);
  const [currentPage, setCurrentPage] = useState(1)
  const pageNumbers = [];
  console.log(pageNumbers);
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="d-flex justify-content-end ">
      <nav aria-label="Page navigation">
        <ul className="pagination">
        {pageNumbers.includes(currentPage) &&
        <li class={`page-item ${url_page == 1 ? "disabled" : ""}`}>
        <button  class="page-link" onClick={() => {
                    setCurrentPage(url_page - 1);
                    paginate(url_page - 1);
                }}>
                    Prev
                </button>
                </li>}
          {pageNumbers.map((number) => (
            
            <li
              key={number}
           
              className="page-number page-item "
            >
              <button className={`page-link ${url_page==number?'active':''}`} aria-label="Next" onClick={() => paginate(number)}>
                {number}
              </button>
            </li>
          ))}
          {pageNumbers.includes(currentPage) &&
        <li class={`page-item ${url_page == pageNumbers.length ? "disabled" : ""}`}>
        <button  class="page-link" onClick={() => {
                    setCurrentPage(Number(url_page) + 1);
                    paginate(Number(url_page) + 1);
                }}>
                    Next
                </button>
                </li>}
        </ul>
      </nav>
    </div>
  );
};

export default Paginate;


// {[...Array(pages).keys()].map((page) => (
//    <li
//      key={page + 1}
//      className={`page-item ${
//        currentPage === page + 1 ? "active" : ""
//      }`}
//    >
//      <button
//        className="page-link"
//        onClick={() => {
//          handlePageChange(page + 1);
//        }}
//      >
//        {page + 1}
//      </button>
//    </li>
//  ))}