import React, { useState, useEffect, useRef } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import Footer from "../../common/Footer";
// import Select from 'react-select';
// import { Link } from 'react-router-dom';

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../../shared/Loader";
import { AddMasterBrandName } from "./AddMasterBrandName";
import { EditMasterBrandNameModal } from "./EditMasterBrandNameModal ";
import DeleteConfirmation from "../../shared/DeleteConfirmation";

const MasterBrandName = () => {
  const yupValidation = Yup.object().shape({
    brand_name: Yup.string().required("Please enter name"),

    status: Yup.string().required("Please enter status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  const [userList, setuserList] = useState([]);



  const [type, setType] = useState("Add");
  const [user_id, setUserId] = useState("");

  const [id, setId] = useState(null);
  const [error, setError] = useState(null);

 

  const refreshPage = () => {
    setLoading(true)
    fetch(process.env.REACT_APP_API_BASE + "get_brand")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setuserList(data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    refreshPage();
  }, []);


// Add banner modal

const [showAddModal, setshowAddModal] = useState(false);

const showClauseForm = (id) => {
  setUserId(id);
  // setDeleteMessage(`Are you sure you want to delete the vegetable`);
  setshowAddModal(true);
};

// Hide the modal
const hideAddModal = () => {
  setshowAddModal(false);
};

// Edit banner modal
const [showEditModal, setshowEditModal] = useState(false);
const showEditForm = (id) => {
  setUserId(id);
console.log(id);
  // setDeleteMessage(`Are you sure you want to delete the vegetable`);
  setshowEditModal(true);
};

// Hide the modal
const hideEditModal = () => {
  setshowEditModal(false);
};

const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
  false
);
const [deleteMessage, setDeleteMessage] = useState(null);

const showDeleteModal = (id) => {
  console.log("click");
  setId(id);
  console.log(id);
  setDeleteMessage(`Are you sure you want to delete Brand Name?`);
  setDisplayConfirmationModal(true);
};

// Hide the modal
const hideConfirmationModal = () => {
  setDisplayConfirmationModal(false);
};



  const submitDelete = () => {
    console.log(id);
    axios
      .delete(process.env.REACT_APP_API_BASE + `delete_brand/${id}`)
      .then((response) => {
        // console.log('User has been deleted');

        refreshPage();
      })
      .catch((error) => {
        console.log("error delete");
        if (error) {
          console.log(error);
        }
      });
  };

  return (
    <>
      {/* <Header />
            <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title mb-0">Brand Name</h4>
                    </div>
                    <div className="card-body">
                      <div className="row g-4 mb-3">
                        <div className="col-md-12 d-flex justify-content-between">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary add-btn"
                            onClick={() => showClauseForm()}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add Brand Name
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive table-card mt-3  mb-1">
                        <table className="table align-middle table-nowrap mb-0 table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th data-sort="customer_name">#</th>
                              <th data-sort="customer_name">Name</th>
                              <th data-sort="status">Status</th>
                              <th data-sort="action">Action</th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {userList.map((user, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="chk_child"
                                      value="option1"
                                    />
                                  </div>
                                </th>
                                <td className="customer_name">{index + 1}</td>
                                <td className="email">{user.brand_name}</td>
                                <td className="status">
                                  <span
                                    className={`badge text-uppercase ${
                                      user.status == 1
                                        ? "bg-success-subtle text-success"
                                        : "bg-danger-subtle text-danger"
                                    }`}
                                  >
                                    {user.status == 1 ? "Active" : "In-active"}
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="edit">
                                      <Link
                                        onClick={() => {
                                          showEditForm(user.id);
                                        }}
                                        className=" ri-edit-2-fill fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        to="/master/add-business-type?id=46"
                                      ></Link>
                                    </div>
                                    <div className="remove">
                                      <Link
                                        onClick={() => showDeleteModal(user.id)}
                                        className="ri-delete-bin-6-line fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                      ></Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <AddMasterBrandName
                  showModal={showAddModal}
                  hideModal={hideAddModal}
                  refreshPage={refreshPage}
                  type={type}
                  // id={user_id}
                />
                <EditMasterBrandNameModal
                  showModal={showEditModal}
                  hideModal={hideEditModal}
                  refreshPage={refreshPage}
                  type={type}
                  id={user_id}
                />
                <DeleteConfirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitDelete}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={deleteMessage}/>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default MasterBrandName;
