import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../../shared/Loader";
import http from "../../../../../utils/http";
import Confirmation from "../../../../shared/Confirmation";

export const OrderView = () => {
  const { id } = useParams();
  const [order_item, setorder_item] = useState([]);
  const [order_list, setorder_list] = useState([]);
  const [OrderHistory, setOrderHistory] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    fetchOrderbyId();
    fetchOrderHistory();
  };

  const fetchOrderbyId = () => {
    setLoading(true);
    http
      .get("get_order_by_id/" + id)
      .then((res) => {
        console.log(res.data);
        setorder_list([res.data]);
        setorder_item(res.data.order_items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchOrderHistory = () => {
    setLoading(true);
    http
      .get("get_order_history/" + id)
      .then((res) => {
        console.log("order_his", res.data);
        setOrderHistory(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const yupValidation = Yup.object().shape({
    // name: Yup.string().required("Please enter attribute name"),
    // value: Yup.string().required("Please enter master values"),
    // attribute_group_id: Yup.string().required("Please select attribute group"),
    // // type: Yup.string().required("Please select type"),
    // status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  function currentOrderChange(e) {
    console.log(e.target.value);
    showConfirmModal(e.target.value);
  }

  const orderStatus = [
    {
      class: "badge bg-primary d-flex align-items-center mb-0 ms-1",
      name: "Placed",
      text_1: "An order has been placed.",
      text_2: "Seller has processed your order.",
      icon: "ri-shopping-bag-line",
    },
    {
      class: "badge bg-success d-flex align-items-center mb-0 ms-1",
      name: "Confirm",
      text_1: "An order has been confirm.",
      text_2: "Seller has processed your order.",
      icon: "ri-shopping-bag-line",
    },
    {
      class: "badge bg-warning d-flex align-items-center mb-0 ms-1",
      name: "Ready for Dispatch",
      text_1: "Your Item has been picked up by courier partner",
      text_2: "",
      icon: "mdi mdi-gift-outline",
    },
    {
      class: "badge bg-success d-flex align-items-center mb-0 ms-1",
      name: "Shipped",
      text_1: "An order has been placed.",
      text_2: "Seller has processed your order.",
      icon: "ri-truck-line",
    },
    {
      class: "badge bg-danger d-flex align-items-center mb-0 ms-1",
      name: "Cancelled",
      text_1: "An order has been cancelled.",
      text_2: "",
      icon: "",
    },
  ];

  const [orderStatusId, setOrderStatusId] = useState();

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [confirmMessage, setConfirmMessage] = useState(null);

  const showConfirmModal = (order_code) => {
    setOrderStatusId(order_code);
    console.log(order_code);
    setConfirmMessage(`Are you sure you want to change order status ?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  function submitConfirm() {
    setLoading(true);
    console.log("Order Status", orderStatusId);
    var data = {
      order_id: id,
      order_status: orderStatusId,
    };
    // console.log("Final",data);
    http
      .post("order_progress_update", data)
      .then((response) => {
        console.log(response);
        setLoading(false);
        refresh();
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />
            {loading === false && (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Order DETAILS</h4>
                    </div>
                  </div>
                </div>
                <div>
                  {order_list.map((order, index) => {
                    return (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="d-flex">
                                      <h5 className="card-title flex-grow-1 mb-0">
                                        Customer Details
                                      </h5>
                                      {/* <div className="flex-shrink-0">
                                        <Link className="link-secondary">View Profile</Link>
                                        </div> */}
                                    </div>
                                    <ul className="list-unstyled mb-0 vstack gap-3 mt-3">
                                      <li>
                                        <div className="d-flex align-items-center">
                                          {/* <div className="flex-shrink-0">
                                    <img
                                      src="assets/images/users/avatar-3.jpg"
                                      alt=""
                                      className="avatar-sm rounded"
                                    />
                                  </div> */}
                                          <div className="flex-grow-1 ">
                                            <h6 className="fs-14 mb-1">
                                              {order.customer_name}
                                            </h6>
                                            <p className="text-muted mb-0">
                                              Customer
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                                        {order.customer_email}
                                      </li>
                                      <li>
                                        <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                                        {order.customer_mobile}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-md-3">
                                    <h5 className="card-title mb-3">
                                      {/* <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "} */}
                                      Billing Address
                                    </h5>
                                    <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                                      <li className="fw-medium fs-14">
                                        {order.billing_name}
                                      </li>
                                      <li>{order.customer_mobile}</li>
                                      <li>{order.billing_address}</li>
                                      <li>
                                        {order.billing_city},{" "}
                                        {order.billing_state}
                                      </li>
                                      <li>{order.billing_country}</li>
                                    </ul>
                                  </div>
                                  <div className="col-md-3">
                                    <h5 className="card-title mb-3">
                                      {/* <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "} */}
                                      Shipping Address
                                    </h5>
                                    <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                                      <li className="fw-medium fs-14">
                                        {order.shipping_name}
                                      </li>
                                      <li>{order.customer_mobile}</li>
                                      <li>{order.shipping_address}</li>
                                      <li>
                                        {order.shipping_city},{" "}
                                        {order.shipping_state}
                                      </li>
                                      <li>{order.shipping_country}</li>
                                    </ul>
                                  </div>
                                  <div className="col-md-3">
                                    <h5 className="card-title mb-3">
                                      {/* <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "} */}
                                      Payment Details
                                    </h5>
                                    <div className="d-flex align-items-center mb-2">
                                      <div className="flex-shrink-0">
                                        <p className="text-muted mb-0">
                                          Transactions Id:
                                        </p>
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <h6 className="mb-0">
                                          {order.transaction_id}
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                      <div className="flex-shrink-0">
                                        <p className="text-muted mb-0">
                                          Payment Method:
                                        </p>
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <h6 className="mb-0">{order.payment_method}</h6>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                      <div className="flex-shrink-0">
                                        <p className="text-muted mb-0">
                                          Payment Mode : 
                                        </p>
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <h6 className="mb-0 text-uppercase">{order.payment_mode}</h6>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                      <div className="flex-shrink-0">
                                        <p className="text-muted mb-0 ">
                                          Status :
                                        </p>
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <h6 className="mb-0 text-uppercase">
                                          {order.payment_status}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-header">
                                <div className="d-flex align-items-center">
                                  <h5 className="card-title flex-grow-1 mb-0">
                                    Order #{order.order_no}
                                  </h5>
                                  <div className="flex-shrink-0">
                                    <Link
                                      to={`/order-invoice/${id}`}
                                      className="btn btn-success btn-sm me-2"
                                    >
                                      <i className="ri-download-2-fill align-middle me-1"></i>
                                      Invoice
                                    </Link>
                                    <Link
                                      to={`/order`}
                                      className="btn btn-danger btn-sm"
                                    >
                                      {/* <i className="ri-download-2-fill align-middle me-1"></i>{" "} */}
                                      Back
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive table-card">
                                  <table className="table table-nowrap align-middle table-borderless mb-0">
                                    <thead className="table-light text-muted">
                                      <tr>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">UOM</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Sales Price</th>
                                        <th scope="col">HSN</th>
                                        <th scope="col">Tax (%)</th>
                                        <th scope="col" className="text-end">
                                          Taxable Amt
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {order_item.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{item.product_name.slice(0,40)}</td>
                                            <td>{item.uom}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.price}</td>
                                            <td>{item.hsn}</td>
                                            <td>{item.tax_rate}</td>
                                            <td className="text-end">
                                              {item.total_price}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      <tr className="border-top border-top-dashed">
                                        <td colspan="5"></td>
                                        <td
                                          colspan="2"
                                          className="fw-medium p-0"
                                        >
                                          <table className="table table-borderless mb-0">
                                            <tbody>
                                            <tr>
                                                <td>Sub Total :</td>
                                                <td className="text-end">
                                                  ₹{Math.round( order.tax_amount + order.taxable_amount )}
                                                </td>
                                              </tr>
                                            <tr>
                                                <td>Tax (inclusive) :</td>
                                                <td className="text-end">
                                                  ₹{Math.round(order.tax_amount)}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  Discount{" "}
                                                  <span className="text-muted">
                                                   ({order.discount_coupon})
                                                  </span>{" "}
                                                  :
                                                </td>
                                                <td className="text-end">
                                                {order.discount_amount ? `-₹${order.discount_amount}` : ""} 
                                                </td>
                                              </tr>
                                              {/* <tr>
                                                <td>Shipping Charge :</td>
                                                <td className="text-end">
                                                  ₹65.00
                                                </td>
                                              </tr> */}
                                              <tr className="border-top border-top-dashed">
                                                <th scope="row">Total :</th>
                                                <th className="text-end">
                                                  ₹{order.total_amount}
                                                </th>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="card">
                              <div className="card-header">
                                <div className="d-sm-flex align-items-center">
                                  <h5 className="card-title flex-grow-1 d-flex align-items-center mb-0">
                                    Order Status :{" "}
                                    <h5
                                      className={
                                        orderStatus[order.order_status].class
                                      }
                                    >
                                      {orderStatus[order.order_status].name}
                                    </h5>
                                  </h5>
                                  <div className="flex-shrink-0 mt-2 mt-sm-0">
                                    {/* <Link className="btn btn-soft-info btn-sm mt-2 mt-sm-0">
                                  {/* <i className="ri-map-pin-line align-middle me-1"></i>{" "} */}
                                    {/* Change Status
                                </Link>  */}
                                    <select
                                      className="form-select btn-sm"
                                      name="order_status"
                                      // value={Order_status}
                                      onChange={(e) => currentOrderChange(e)}
                                      placeholder=" Select Order Status"
                                    >
                                      <option value="">Change Status</option>
                                      <option value="0">Placed</option>
                                      <option value="1">Confirm</option>
                                      <option value="2">
                                        Ready for dispatch
                                      </option>
                                      <option value="3">Shipped</option>
                                      <option value="4">Cancelled</option>
                                    </select>

                                    {/* <Link className="btn btn-soft-danger btn-sm ms-2 mt-2 mt-sm-0">
                                  <i className="mdi mdi-archive-remove-outline align-middle me-1"></i>{" "}
                                  Cancel Order
                                </Link> */}
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                {OrderHistory.map((item, index) => {
                                  return (
                                    <div className="profile-timeline">
                                      <div
                                        className="accordion accordion-flush"
                                        id="accordionFlushExample"
                                      >
                                        <div className="accordion-item border-0">
                                          <div
                                            className="accordion-header"
                                            id="headingOne"
                                          >
                                            <a
                                              className="accordion-button p-2 shadow-none"
                                              data-bs-toggle="collapse"
                                              href="#collapseOne"
                                              aria-expanded="true"
                                              aria-controls="collapseOne"
                                            >
                                              <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 avatar-xs">
                                                  <div className="avatar-title bg-success rounded-circle">
                                                    <i
                                                      className={
                                                        orderStatus[
                                                          item.order_status
                                                        ].icon
                                                      }
                                                    ></i>
                                                  </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                  <h6 className="fs-15 mb-0 fw-semibold">
                                                    {
                                                      orderStatus[
                                                        item.order_status
                                                      ].name
                                                    }{" "}
                                                    -{" "}
                                                    <span className="fw-normal">
                                                      {new Date(
                                                        item.updated_on
                                                      ).toDateString()}
                                                    </span>
                                                  </h6>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                          <div
                                          // id="collapseOne"
                                          // className="accordion-collapse collapse show"
                                          // aria-labelledby="headingOne"
                                          // data-bs-parent="#accordionExample"
                                          >
                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                              <h6 className="mb-1">
                                                {
                                                  orderStatus[item.order_status]
                                                    .text_1
                                                }
                                              </h6>
                                              <p className="text-muted">
                                                {new Date(
                                                  item.updated_on
                                                ).toDateString()}
                                              </p>

                                              <h6 className="mb-1">
                                                {
                                                  orderStatus[item.order_status]
                                                    .text_2
                                                }
                                              </h6>
                                              {orderStatus[item.order_status]
                                                .text_2 ? (
                                                <p className="text-muted mb-0">
                                                  {new Date(
                                                    item.updated_on
                                                  ).toDateString()}
                                                </p>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="accordion-item border-0">
                                  <div
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <a
                                      className="accordion-button p-2 shadow-none"
                                      data-bs-toggle="collapse"
                                      href="#collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="collapseTwo"
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 avatar-xs">
                                          <div className="avatar-title bg-success rounded-circle">
                                            <i className="mdi mdi-gift-outline"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h6 className="fs-15 mb-1 fw-semibold">
                                            Packed -{" "}
                                            <span className="fw-normal">
                                              Thu, 16 Dec 2021
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                      <h6 className="mb-1">
                                        Your Item has been picked up by courier
                                        partner
                                      </h6>
                                      <p className="text-muted mb-0">
                                        Fri, 17 Dec 2021 - 9:45AM
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item border-0">
                                  <div
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <a
                                      className="accordion-button p-2 shadow-none"
                                      data-bs-toggle="collapse"
                                      href="#collapseThree"
                                      aria-expanded="false"
                                      aria-controls="collapseThree"
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 avatar-xs">
                                          <div className="avatar-title bg-success rounded-circle">
                                            <i className="ri-truck-line"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h6 className="fs-15 mb-1 fw-semibold">
                                            Shipping -{" "}
                                            <span className="fw-normal">
                                              Thu, 16 Dec 2021
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                      <h6 className="fs-14">
                                        {order.customer_name}
                                      </h6>
                                      <h6 className="mb-1">
                                        Your item has been shipped.
                                      </h6>
                                      <p className="text-muted mb-0">
                                        Sat, 18 Dec 2021 - 4.54PM
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item border-0">
                                  <div
                                    className="accordion-header"
                                    id="headingFour"
                                  >
                                    <a
                                      className="accordion-button p-2 shadow-none"
                                      data-bs-toggle="collapse"
                                      href="#collapseFour"
                                      aria-expanded="false"
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 avatar-xs">
                                          <div className="avatar-title bg-light text-success rounded-circle">
                                            <i className="ri-takeaway-fill"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h6 className="fs-14 mb-0 fw-semibold">
                                            Out For Delivery
                                          </h6>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div
                                    id="collapseFour"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                      <h6 className="fs-14">
                                        {order.customer_name}
                                      </h6>
                                      <h6 className="mb-1">
                                        Your item has been shipped.
                                      </h6>
                                      <p className="text-muted mb-0">
                                        Sat, 18 Dec 2021 - 4.54PM
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item border-0">
                                  <div
                                    className="accordion-header"
                                    id="headingFive"
                                  >
                                    <a
                                      className="accordion-button p-2 shadow-none"
                                      data-bs-toggle="collapse"
                                      href="#collapseFile"
                                      aria-expanded="false"
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 avatar-xs">
                                          <div className="avatar-title bg-light text-success rounded-circle">
                                            <i className="mdi mdi-package-variant"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h6 className="fs-14 mb-0 fw-semibold">
                                            Delivered
                                          </h6>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div> */}
                                      </div>
                                      {/* <!--end accordion--> */}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {/* <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "30%" }}>
                              Product Name
                            </th>
                            <th scope="col">UOM</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Sales Price</th>
                            <th scope="col">HSN</th>
                            <th scope="col">Tax (%)</th>
                            <th scope="col">Taxable Amt</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order_item.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.product_name}</td>
                                <td>{item.uom}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                                <td>{item.hsn}</td>
                                <td>{item.tax_rate}</td>
                                <td>{item.total_price}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="mb-3 mt-4 text-end d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-success ms-2 w-sm"
                        >
                          Download
                        </button>
                      </div>
                    </form>
                  </div>
                </div> */}
                <Confirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitConfirm}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={confirmMessage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
