import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import axios from "axios";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";

export const SeoSetting = () => {
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  const [SeoList, setSeoList] = useState([]);

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    setLoading(true);
    http
      .get("get_seo_list")
      .then((res) => {
        setSeoList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);
  const showDeleteModal = (id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete SEO ?`);
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    http
      .delete(`delete_seo_by_id/${id}`)
      .then((response) => {
        toast.success("SEO deleted");
        setDeleteMessage("User has been deleted");
        fetchdata();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   const [error, setError] = useState(null);

  const showClauseForm = (id) => {
    setUserId(id);

    console.log(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowClauseModal(true);
  };

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  const handleEdit = (idd) => {
    setType("Edit");
    setUserId(idd);
    setshowClauseModal(true);
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex  justify-content-between ">
                        <h4 className="card-title mt-2">SEO List</h4>
                        <Link
                          // onClick={() => showClauseForm()}
                          to="/seo/add-seo"
                          type="button"
                          className="btn btn-primary add-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          SEO
                        </Link>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Enter name"
                                  // onChange={(e) => {
                                  //   searchList(e);
                                  // }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex align-items-center justify-content-end">
                            <i
                              class="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              // onClick={handleSearchButtonClick}
                            ></i>
                            <i
                              class="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th>
                                <th data-sort="customer_name">#</th>
                                <th data-sort="email">Url</th>
                                <th data-sort="date">Title</th>
                                <th data-sort="status">Status</th>
                                <th data-sort="action">Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {SeoList.map((Seo, index) => (
                                <tr key={index}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>
                                  <td className="customer_name">{index + 1}</td>
                                  <td className="email">{Seo.url}</td>
                                  <td className="email">{Seo.meta_title}</td>
                                  <td className="status">
                                    <span
                                      className={`badge text-uppercase ${
                                        Seo.status == 1
                                          ? "bg-success-subtle text-success"
                                          : "bg-danger-subtle text-danger"
                                      }`}
                                    >
                                      {Seo.status == 1
                                        ? "Active"
                                        : "In-active"}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <Link
                                          className=" ri-edit-2-fill fs-4"
                                          to={`/seo/edit-seo/${Seo.id}`}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          onClick={() =>
                                            showDeleteModal(Seo.id)
                                          }
                                          className="ri-delete-bin-6-line fs-4"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
