import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import http from "../../../../../utils/http";

export const UploadLead = ({ CatID, refresh, resetForm }) => {
  useEffect(() => {
    GetUser()
  }, [refresh]);

  const [atribute, setAttribute] = useState([]);
  const [rolesList, setRoleList] = useState([]);

  const GetUser = ()=>{
    http
    .get("user_list")
    .then((res) => {
      console.log(res.data);
      setRoleList(res.data);
    })
    .catch((err) => {
      console.log(err.message);
    });
  }

  function OnChange(e) {
    console.log(e);
    setAttribute(e);
    if (e) {
      setValue("name", e.label);
      setValue("attribute", e);
    }
  }

  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please enter attribute name"),
    // value: Yup.string().required("Please enter master values"),
    // attribute_group_id: Yup.string().required("Please select attribute group"),
    // type: Yup.string().required("Please select type"),
    status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  const [File, setFile] = useState("");
  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    setValue("category_id", CatID);
    console.log(data);
    setBtnloading(true);
    http
      .post(`save_attribute`, data)
      .then((response) => {
        toast.success("Attribute has been added");
        const timer = setTimeout(() => {
          setBtnloading(false);
          setAttribute("");
          window.scroll({
            top: 100,
            left: 100,
            behavior: "smooth",
          });
          refresh(CatID);
          reset();
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const ValueOnCh = (e) => {
    const textarea = e.target.value;
    const values = textarea.split("\n");
    console.log(values);
    setValue("value", JSON.stringify(values));
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Toaster position="top-center" />
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Upload Lead</h4>
                <Link
                  to="/lead-management/add-lead"
                  className="btn btn-primary add-btn"
                >
                  Add New Lead
                </Link>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Lead Owner
                    </label>
                    <select
                      className="form-select"
                      name="show_home"
                      {...register("show_home")}
                    >
                      <option value=""> Select Lead Owner</option>
                      {rolesList.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <div
                      className={`invalid-feedback ${
                        errors.category_name ? "d-block" : ""
                      }`}
                    >
                      {errors.category_name ? errors.category_name.message : ""}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Lead Source
                    </label>
                    <select
                      className="form-select"
                      name="show_home"
                      {...register("show_home")}
                    >
                      <option value=""> Select Lead Source</option>
                      <option value="1"> Yes</option>
                      <option value="0">No</option>
                    </select>
                    <div
                      className={`invalid-feedback ${
                        errors.category_name ? "d-block" : ""
                      }`}
                    >
                      {errors.category_name ? errors.category_name.message : ""}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div>
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Upload File
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="image"
                        onChange={(e) => {
                          setValue("image", e.target.files[0]);
                          setFile(URL.createObjectURL(e.target.files[0]));
                        }}
                        placeholder="Enter display order"
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                      <div className="mt-3">
                        <Link
                          // onClick={() => showClauseForm()}
                          //   to="/category/add-category"
                          className="text-primary"
                        >
                          Download Sample
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 mt-4 text-end">
                  <button
                    type="submit"
                    className="btn btn-danger w-sm"
                    disabled={Btnloading}
                  >
                    {Btnloading ? "Loading..." : "Save Lead"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
