// return the user data from the session storage
import { useNavigate } from "react-router-dom";
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
   
  // return the token from the session storage
  export const getToken = () => {
    return localStorage.getItem('token') || null;
  }
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
   
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    
  }
   
  export const setUserSession = (token, name, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('name', name);
    localStorage.setItem('user', JSON.stringify(user));
  }