import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import http from "../../../../utils/http";

export const AddMasterModal = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  const yupValidation = Yup.object().shape({
    gst: Yup.number()
      .typeError("Please enter only digit")
      .required("Please enter only digit"),

    status: Yup.string().required("Please enter status"),
  });

 
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit,setError, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  function onSubmit(data) {
    console.log(data)
    setLoading(true);
    http
        .post("save_gst", data)
        .then((response) => {
          console.log(response);
  
          setLoading(false);
          reset();
            hideModal();
            refreshPage();
            reset();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            setLoading(false);
            // console.log(error.respo);
            setError('gst', { type: 'custom', message:error.response.data.message});

          }
          setLoading(false);
          setMessage("Something went wrong");
        });
 
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header >
        <Modal.Title>Add Master GST</Modal.Title>
        <button type="button" class="btn-close" aria-label="Close" onClick={() => {
              hideModal();
              reset();
            }}></button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 ${resss !== "" && ""}`}>
            <div className="col-lg-6">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  GST
                </label>
                <input
                  name="gst"
                  type="text"
                  className="form-control"
                  placeholder="Enter Gst"
                  {...register("gst")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${errors.gst ? "d-block" : ""}`}
                >
                  {errors.gst ? errors.gst.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label for="assignedtoName-field" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  className="form-select"
                  {...register("status")}
                  defaultValue={1}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.status ? "d-block" : ""
                  }`}
                >
                  {errors.status ? errors.status.message : ""}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="hstack gap-2 justify-content-end mt-4 ">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {btnToggle ? (
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            )}
            {/* <button type="submit" className="btn btn-danger" onClick={()=>trashAll()}>Trash All</button> */}
          {/* </div> */} 
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit">
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
