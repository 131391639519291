import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import http from "../../../../../utils/http";

export const AddLead = () => {
  
  useEffect(() => {
    GetLeadSource();
    
  }, []);

  const navigate = useNavigate();
  const [LeadSource, setLeadSource] = useState([]);
  const [readOnlyData, setreadOnlyData] = useState(false);

  const GetLeadSource = () => {
    http
      .get("lead_source_master")
      .then((res) => {
        console.log(res.data);
        setLeadSource(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getPincodeValue = (e) => {
    if (e.target.value) {
      setAddressDetails(e.target.value);
    } else {
      setValue("city", "");
    }
  };

  const setAddressDetails = (data) => {
    http
      .get(`getDataFromPincode/${data}`)
      .then((res) => {
        console.log("State_code", res.data.state);
        if (res.data) {
          setValue("city", res.data.city);
          setreadOnlyData(true)
        }
        console.log(res.data.state_code);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const pincodeRegExp = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
  const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  
  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string().required("Please enter email").matches(emailRegExp , "Email is not valid"),
    mobile: Yup.string().required("Please enter mobile no").matches(phoneRegExp, 'mobile no is not valid').length(10),
    lead_source: Yup.string().required("Please select lead source"),
    company_name: Yup.string().required("Please enter company name"),
    pincode: Yup.string().required("Please enter pincode").matches(pincodeRegExp, 'Pincode is not valid').length(6),
    designation: Yup.string().required("Please enter designation"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    console.log(data);
    setBtnloading(true);
    http
      .post(`save_lead`, data)
      .then((response) => {
        toast.success("Lead has been added");
        const timer = setTimeout(() => {
          setBtnloading(false);
          setreadOnlyData(false)
          navigate("/lead-management");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        // console.log(error.status);
        console.log(error);
      });
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Toaster position="top-center" />
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Add Lead</h4>
                <Link
                  className="btn btn-primary w-sm"
                  to={"/lead-management/upload-lead"}
                >
                  Import Lead
                </Link>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Lead Source
                    </label>
                    <select
                      className="form-select"
                      name="lead_source"
                      {...register("lead_source")}
                    >
                      <option value=""> Select Lead Source</option>
                      {LeadSource.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <div
                      className={`invalid-feedback ${
                        errors.lead_source ? "d-block" : ""
                      }`}
                    >
                      {errors.lead_source ? errors.lead_source.message : ""}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Email
                    </label>
                    <input
                      className="form-control"
                      name="email"
                      placeholder="Enter Email"
                      {...register("email")}
                    />
                    <div
                      className={`invalid-feedback ${
                        errors.email ? "d-block" : ""
                      }`}
                    >
                      {errors.email ? errors.email.message : ""}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Name
                    </label>
                    <input
                      className="form-control"
                      name="name"
                      placeholder="Enter Name"
                      {...register("name")}
                    />
                    <div
                      className={`invalid-feedback ${
                        errors.name ? "d-block" : ""
                      }`}
                    >
                      {errors.name ? errors.name.message : ""}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Mobile
                    </label>
                    <input
                    type="number"
                      className="form-control"
                      name="mobile"
                      placeholder="Enter Mobile No"
                      {...register("mobile")}
                    />
                    <div
                      className={`invalid-feedback ${
                        errors.mobile ? "d-block" : ""
                      }`}
                    >
                      {errors.mobile ? errors.mobile.message : ""}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Company Name
                    </label>
                    <input
                      className="form-control"
                      name="category_name"
                      type="company_name"
                      placeholder="Enter Company Name"
                      {...register("company_name")}
                    />
                    <div
                      className={`invalid-feedback ${
                        errors.company_name ? "d-block" : ""
                      }`}
                    >
                      {errors.company_name ? errors.company_name.message : ""}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Pincode
                    </label>
                    <input
                    type="number"
                      className="form-control"
                      name="pincode"
                      placeholder="Enter Pincode"
                      {...register("pincode")}
                      onChange={(e) => getPincodeValue(e)}
                    />
                    <div
                      className={`invalid-feedback ${
                        errors.pincode ? "d-block" : ""
                      }`}
                    >
                      {errors.pincode ? errors.pincode.message : ""}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      City
                    </label>
                    <input
                      className="form-control"
                      name="city"
                      placeholder="Enter City"
                      {...register("city")}
                      disabled={readOnlyData}
                    />
                    <div
                      className={`invalid-feedback ${
                        errors.city ? "d-block" : ""
                      }`}
                    >
                      {errors.city ? errors.city.message : ""}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label" htmlFor="project-title-input">
                      Designation
                    </label>
                    <input
                      className="form-control"
                      name="designation"
                      placeholder="Enter Designation"
                      {...register("designation")}
                    />
                    <div
                      className={`invalid-feedback ${
                        errors.designation ? "d-block" : ""
                      }`}
                    >
                      {errors.designation ? errors.designation.message : ""}
                    </div>
                  </div>
                </div>
                <div className="mb-3 mt-4 text-end">
                  <button
                    type="submit"
                    className="btn btn-danger w-sm"
                    disabled={Btnloading}
                  >
                    {Btnloading ? "Loading..." : "Save Lead"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
