import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import http from "../../../../utils/http";

export const EditMasterTypeOfCompanyModal = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  const yupValidation = Yup.object().shape({
    company_type: Yup.string().required("Please enter type of compamy"),
    status: Yup.string().required("Please enter status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;


  const btHandleEdit = () => {
    console.log(id);
    http.get(`get_company_type_by_id/${id}`)
      .then((res) => {
        console.log(res);
        setValue("company_type", res.data.company_type);
        setValue("status", res.data.status);
       
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    btHandleEdit(id)
  }, [id]);

  function onSubmit(data) {
    console.log(data);
    setLoading(true);
    http
      .put(`update_company_type/${id}`, data)
      .then((response) => {
        console.log(response);
        setLoading(false);
        reset();
        refreshPage();
        // setbtnToggle(true)
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Master Type of company</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 ${resss !== "" && ""}`}>
            <div className="col-lg-6">
              <div id="modal-id">
                <label for="orderId" className="form-label">
                  Type of Company
                </label>
                <input
                  name="company_type"
                  type="text"
                  className="form-control"
                  placeholder="Enter Type of company"
                  {...register("company_type")}
                />
                {/* if (errors.name !== undefined && errors.name !== null) { */}
                <div
                  className={`invalid-feedback ${
                    errors.company_type ? "d-block" : ""
                  }`}
                >
                  {errors.company_type ? errors.company_type.message : ""}
                </div>
                {/* }  */}
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="assignedtoName-field" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  className="form-select"
                  {...register("status")}
                >
                  <option value=""> --status-- </option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.status ? "d-block" : ""
                  }`}
                >
                  {errors.status ? errors.status.message : ""}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              // reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit" onClick={hideModal}>
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
