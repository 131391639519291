import React, { useState, useEffect } from "react";
import Select from "react-select";
import http from "../../../../../utils/http";

const ProductDropdownSingle = (props) => {
  const opt = [];

  const [options, setoptions] = useState([]);
  useEffect(() => {
    http
      .get("get_product_dropdown")
      .then((res) => {
        let data = res.data;
        data.forEach((val) => {
          opt.push({
            label: val.product_name,
            value: val.id,
          });
        });

        setoptions(opt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <Select
      options={options}
      onChange={props.change}
      value={props.value}
      placeholder={props.placeholder}
      isSearchable={true}
    />
  );
};

export default ProductDropdownSingle;
