import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import axios from "axios";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";

import CategoryDropdownFilter from "./CategoryDropdownFilter";
import moment from "moment";
import http from "../../../../../utils/http";
// import { removeUserSession } from "../../../../../utils/common";
import Paginate from "../../../../shared/Paginate";

export const Category = () => {
  let url_params = "";
  const params = new URLSearchParams(window.location.search);
  // const url_page = params.get("page");
  let url_category = params.get("category");
  let url_parent_id = params.get("parent_id");
  let url_status = params.get("status");
  let url_page = params.get("page");

  const navigate = useNavigate();

  console.log("running");
  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [srn, setSrn] = useState();
  const [error, setError] = useState(null);

  /*************FILTERS*******************/
  const [category_name, setCategory_name] = useState("");
  const [parent_id_search, setParentCat] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [category_status, setCategoryStatus] = useState();
  function categoryStatus(e) {
    setCategoryStatus(e.target.value);
  }

  function onchange(e) {
    console.log("e", e);
    setParentCat(e.value);
    setCategoryValue(e);
  }
  function searchList(e) {
    e.preventDefault();
    setCategory_name(e.target.value);
  }
  /*************FILTERS*******************/

  const [userList, setuserList] = useState([]);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();

  const [Offset, setOffset] = useState("");

  useEffect(() => {
    if (url_category != "") {
      setCategory_name(url_category);
    }
    if (url_parent_id != "") {
      setParentCat(url_parent_id);
    }
    if (url_status != "") {
      setCategoryStatus(url_status);
    }

    refreshPage(url_category, url_parent_id, url_status, url_page);
  }, []);

  function reset_filter() {
    setCategoryStatus("");
    setParentCat("");
    setCategory_name("");
    setCategoryValue("");
    console.log("value", ref.current.value);
    ref.current.value = "";
    refreshPage("", "", "", 1);
  }

  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(category_name, parent_id_search, category_status, pageNumber);
  };


  function search_filter() {

    removeErrorParam();
    setSearchParams(params);
    refreshPage(category_name, parent_id_search, category_status, 1);
  }

  function getPages(urls = "") {
    setLoading(true);

    http
      .get(`get_category_page_count${urls}`)
      .then((res) => {
        console.log(res);
        setPages(res.data.page_no);
        console.log("Page___no",res.data.page_no);
        setTotal(res.data.total_category);
        setOffset(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const refreshPage = (
    category = "",
    parent_id = "",
    status = "",
    page_to = ""
  ) => {
    setLoading(true);

    // let page_to_load = page_to != "" ? page_to : currentPage;
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (category) {
      urls += `&category=${category}`;
    }

    if (parent_id) {
      urls += `&parent_id=${parent_id}`;
    }

    if (status) {
      urls += `&status=${status}`;
    }
    console.log("page_to_load", page_to_load);
    setSearchParams(urls);
    getPages(urls);
    http
      .get(`get_category${urls}`)
      .then((res) => {
        setuserList(res.data);
        setLoading(false);
        // UserComponentVerifiy(res);
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err.message);
      });
  };

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    // console.log(id);
    setDeleteMessage(`Are you sure you want to delete category ?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_category/${id}`)
      .then((response) => {
        // setDeleteMessage("User has been deleted");
        toast.success("Category deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  //   const [error, setError] = useState(null);

  const showClauseForm = (id) => {
    setUserId(id);
    // console.log(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowClauseModal(true);
  };

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  // const handleEdit = (idd) => {
  //   setType("Edit");
  //   setUserId(idd);
  //   setshowClauseModal(true);
  // };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  
  const toggleSwitch = (id,columns) => {    
    
    let newCats = [];
    if(columns == 'status'){
      newCats = userList.map(user => user.id === id ? {...user,status: !user.status} : user);     
    }else if(columns == 'display'){
      newCats = userList.map(user => user.id === id ? {...user,display: !user.display} : user);     
    }else if(columns == 'show_home'){
      newCats = userList.map(user => user.id === id ? {...user,show_home: !user.show_home} : user);           
    }
    setuserList(newCats);
  };
  const catalog_switch = (e, id,columns) => {
    
    const { name, checked } = e.target;
    let data = {
      clm: columns,
      clm_val: checked === true ? 1 : 0,
      type_id: id,
      type: "category",
    };
    toggleSwitch(id,columns);  

    http
      .post(`catalog_switch`, data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  let ref = useRef(null);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Toaster position="top-center" />
          {/* <Loader show={loading} /> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between ">
                    <h4 className="card-title mt-2">Category List</h4>
                    <Link
                      // onClick={() => showClauseForm()}
                      to="/category/add-category"
                      type="button"
                      className="btn btn-primary add-btn"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                      Category
                    </Link>
                  </div>
                </div>

                <div className="card-body">
                  <div className="listjs-table" id="customerList">
                    <div className="row g-4 mb-4">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Enter name or code"
                              onChange={(e) => {
                                searchList(e);
                              }}
                              value={category_name}
                            />
                          </div>
                          <div className="col-md-4">
                            <CategoryDropdownFilter
                              placeholder="Search Parent Category"
                              value={categoryValue}
                              change={(e) => onchange(e)}
                              url_parent_id={url_parent_id}
                              setCategoryValue={setCategoryValue}
                            />
                          </div>
                          <div className="col-md-3">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) => categoryStatus(e)}
                                
                              ref={ref}
                            >
                              <option value=""> --Status--</option>
                              <option value="1">Active</option>
                              <option value="0">In-active</option>
                            </select>
                          </div>
                          <div className="col-md-1 d-flex align-items-center justify-content-end">
                            <i
                              className="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              onClick={search_filter}
                            ></i>
                            <i
                              className="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={reset_filter}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap table-bordered"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th data-sort="customer_name">#</th>
                            <th data-sort="customer_name">Category code</th>
                            <th data-sort="customer_name text-wrap" style={{maxWidth:"7rem"}}>Category name</th>
                            <th
                              data-sort="customer_name"
                              className="text-center"
                              style={{ width: "10%" }}
                            >
                              Website Display
                            </th>
                            <th
                              data-sort="customer_name"
                              className="text-center"
                              style={{ width: "10%" }}
                            >
                              Show Home
                            </th>
                            <th
                              data-sort="status"
                              className="text-center"
                              style={{ width: "10%" }}
                            >
                              Status
                            </th>
                            <th data-sort="status">Created By</th>
                            <th data-sort="status">Created Date</th>
                            <th data-sort="action" className="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {loading === true && (
                            <tr>
                              <td className="customer_name">
                                <Loader show={loading} />
                              </td>
                            </tr>
                          )}
                          {loading === false &&
                            (userList.length
                              ? userList.map((user, index) => (
                                  <tr key={index}>
                                    <td className="customer_name">
                                      {index + Offset + 1}
                                    </td>
                                    <td className="customer_name">
                                      {user.code}
                                    </td>
                                    <td className="customer_name">
                                      {user.category_name}
                                    </td>
                                    <td className="customer_name">
                                      <div className="form-check form-switch text-center">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          name={`display-${user.id}`}
                                          onChange={(e) => catalog_switch(e, user.id,'display')}
                                          checked={user.display ==1 ? true:false
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="customer_name">
                                      <div className="form-check form-switch text-center">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          name={`show_home-${user.id}`}
                                          onChange={(e) => catalog_switch(e, user.id,'show_home')}
                                          checked={user.show_home==1?true:false}
                                        />
                                      </div>
                                    </td>
                                    <td className="customer_name">
                                      <div className="form-check form-switch text-center">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          name={`status-${user.id}`}
                                          onChange={(e) => catalog_switch(e, user.id,'status')}
                                         
                                          checked={user.status  ==1 ? true :false
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="customer_name">
                                      {user.created_by}
                                    </td>
                                    <td className="customer_name">
                                      {moment(user.indate)
                                        .utc()
                                        .format("DD/MM/YYYY")}
                                    </td>
                                    <td>
                                      <div className="d-flex gap-2 justify-content-center">
                                        <div className="edit">
                                          <Link
                                            className=" ri-edit-2-fill fs-4"
                                            to={`/category/edit-category/${user.id}`}
                                          ></Link>
                                        </div>
                                        <div className="remove">
                                          <Link
                                            onClick={() =>
                                              showDeleteModal("User", user.id)
                                            }
                                            className="ri-delete-bin-6-line fs-4"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"
                                          ></Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              : null)}
                        </tbody>
                      </table>
                    </div>
                    {pages > 1 && (
                      <Paginate
                        postsPerPage={20}
                        totalPosts={total}
                        paginate={paginate}
                        url_page={url_page}
                      />
                    )}
                  </div>
                </div>
              </div>
              <DeleteConfirmation
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                id={id}
                message={deleteMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <td>
                      <div class="dropdown d-inline-block">
                      <button
                      class="btn btn-soft-secondary btn-sm dropdown"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      >
                      <i class="ri-more-fill align-middle"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end" style="">
                      <li>
                      <a href="#!" class="dropdown-item">
                      <i class="ri-eye-fill align-bottom me-2 text-muted"></i> View
                      </a>
                      </li>
                      <li>
                      <a class="dropdown-item edit-item-btn">
                      <i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                      </a>
                      </li>
                      <li>
                      <a class="dropdown-item remove-item-btn">
                      <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                      </a>
                      </li>
                      </ul>
                      </div>
                    </td> */
}
