import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AttributeDropdown from "./AttributeDropdown";
import http from "../../../../../utils/http";

export const AttributeList = ({ CatID, refresh, resetForm }) => {
 
  useEffect(() => {
    console.log("attribute form running")
  }, [refresh])

  

  const [atribute, setAttribute] = useState([]);

  function OnChange(e) {
    console.log(e);
    setAttribute(e)
   if(e){
    setValue("name", e.label)
    setValue("attribute",e)
   }
  }

  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please enter attribute name"),
    // value: Yup.string().required("Please enter master values"),
    // attribute_group_id: Yup.string().required("Please select attribute group"),
    // type: Yup.string().required("Please select type"),
    status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    setValue("category_id", CatID);
    console.log(data);
    setBtnloading(true);
    http
      .post(`save_attribute`, data)
      .then((response) => {
        toast.success("Attribute has been added");
        const timer = setTimeout(() => {
          setBtnloading(false);
          setAttribute("")
          window.scroll({
            top: 100,
            left: 100,
            behavior: "smooth",
          });
          refresh(CatID);
          reset();
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const ValueOnCh=(e)=>{
    const textarea = e.target.value
    const values = textarea.split('\n');
    console.log(values);
    setValue('value', JSON.stringify(values))
  }

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            {/* <div className="col-lg-6 ">
            <label
              className="form-label"
              htmlhtmlhtmlFor="project-title-input"
            >
              Attribute
            </label>
            <select
              className="form-select"
              name="attribute_group_id"
              {...register("attribute_group_id")}
            >
              <option value="">Select Attribute</option>
              {attr_group_List.map((attr, index) => {
                return (
                  <option value={attr.id}>{attr.name}</option>
                );
              })}
            </select>

            <div
              className={`invalid-feedback ${
                errors.attribute_group_id ? "d-block" : ""
              }`}
            >
              {errors.attribute_group_id
                ? errors.attribute_group_id.message
                : ""}
            </div>
          </div> */}
            <div className="col-lg-12">
              <label className="form-label" htmlFor="project-title-input">
                Attribute Name
              </label>
              {/* <input
              className="form-control"
              name="name"
              placeholder="Enter Attribute Name"
              {...register("name")}
            /> */}
              <AttributeDropdown
                placeholder="Select or Enter attribute"
                value={atribute}
                change={(e) => OnChange(e)}
                id={CatID}
                refresh={refresh}
                
              />
              <div
                className={`invalid-feedback ${errors.name ? "d-block" : ""}`}
              >
                {errors.name ? errors.name.message : ""}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <label> Master Values</label>
              <textarea
                className="form-control"
                name="value"
                // {...register("value")}
                onChange={(e)=> ValueOnCh(e)}
                placeholder="Enter master values"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
              <div
                className={`invalid-feedback ${errors.value ? "d-block" : ""}`}
              >
                {errors.value ? errors.value.message : ""}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6 ">
              <label className="form-label" htmlFor="project-title-input">
                Type
              </label>
              <select className="form-select" name="type" {...register("type")}>
                <option value="1">Alpha</option>
                <option value="0">Numeric</option>
              </select>
              <div
                className={`invalid-feedback ${errors.type ? "d-block" : ""}`}
              >
                {errors.type ? errors.type.message : ""}
              </div>
            </div>
            <div className="col-lg-6 ">
              <label className="form-label" htmlFor="project-title-input">
                Status
              </label>
              <select
                className="form-select"
                name="status"
                {...register("status")}
              >
                <option value="1">Active</option>
                <option value="0">In Active</option>
              </select>
              <div
                className={`invalid-feedback ${errors.status ? "d-block" : ""}`}
              >
                {errors.status ? errors.status.message : ""}
              </div>
            </div>
          </div>
          <div className="mb-3 mt-4 text-end">
            <button
              type="submit"
              className="btn btn-danger w-sm"
              disabled={Btnloading}
            >
              {Btnloading ? "Loading..." : "Save Attribute"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
