import axios from 'axios';
import { getToken } from './getToken';


const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Change request data/error here
http.interceptors.request.use(
  (config) => {
    const token = getToken();
    // config.headers.Authorization = {
    //   ...config.headers,
    //   Authorization: `Bearer ${token ? token : ''}`,
    // };
    config.headers.Authorization = `${token ? token : ''}`;
    return config;
  },
  (error) => {
    
    return Promise.reject(error);
  }
);

export default http;