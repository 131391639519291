import React, { useState, useEffect  , useRef } from "react";
import { Link ,useSearchParams} from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";
import Flatpickr from "react-flatpickr";
import ReactFlatpickr from "../../../../shared/ReactFlatpickr";
import Paginate from "../../../../shared/Paginate";
import moment from "moment";

export const RFQList = () => {
  const params = new URLSearchParams(window.location.search);

  let url_request_no = params.get("request_no");
  let url_customer_name = params.get("customer_name");
  console.log(url_customer_name);
  let url_offer_from_date = params.get("offer_from_date");
  let url_offer_to_date = params.get("offer_to_date");
  let url_rfq_from_date = params.get("rfq_from_date");
  let url_rfq_to_date = params.get("rfq_to_date");
  let url_status = params.get("status");
  let url_page = params.get("page");


  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

/*************FILTERS*******************/
  const [Req_no, setReq_no] = useState("");
  const [Rfq_Name_value, setRfq_Name_value] = useState("");
  const [product_status, setProductStatus] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [rfq_from_date, setrfq_from_date] = useState("");
  const [rfq_to_date, setrfq_to_date] = useState("");

  const ReqNoChange = (e) => {
    console.log(e.target.value);
    setReq_no(e.target.value);
  };
  const RfqonChange = (e) => {
    console.log(e.target.value);
    setRfq_Name_value(e.target.value);
  };
  function productStatus(e) {
    setProductStatus(e.target.value);
  }
  const creation_from_date = {
    onChange: (selectedDates, dateStr, instance) => {
      setfrom_date(dateStr)
    }
  }
  const creation_to_date = {
    onChange: (selectedDates, dateStr, instance) => {
      setto_date(dateStr)
    }
  }
  
  const creation_rfq_from_date = {
    onChange: (selectedDates, dateStr, instance) => {
      setrfq_from_date(dateStr)
    }
  }
  const creation_rfq_to_date = {
    onChange: (selectedDates, dateStr, instance) => {
      setrfq_to_date(dateStr)
    }
  }

  const [rfqList, setRfqList] = useState([]);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();
  const [Offset, setOffset] = useState("");

  useEffect(() => {
    if (url_request_no != "") {
      setReq_no(url_request_no);
    }
    if (url_customer_name != "") {
      setRfq_Name_value(url_customer_name);
    }
    if (url_status != "") {
      setProductStatus(url_status);
    }

    if (url_offer_from_date != "") {
      setfrom_date(url_offer_from_date);
    }
    if (url_offer_to_date != "") {
      setto_date(url_offer_to_date);
    }
    if (url_rfq_from_date != "") {
      setrfq_from_date(url_rfq_from_date);
    }
    if (url_rfq_to_date != "") {
      setrfq_to_date(url_rfq_to_date);
    }
    
    refreshPage(
      url_request_no,
      url_customer_name,
      url_status,
      url_offer_from_date,
      url_offer_to_date,
      url_rfq_from_date,
      url_rfq_to_date,
      url_page
    );
  }, []);

  let ref = useRef(null);

  function reset_filter() {
    setReq_no("")
    setRfq_Name_value("")
    setProductStatus("")
    setfrom_date("")
    setto_date("")
    setrfq_from_date("")
    setrfq_to_date("")
    ref.current.value = "";
    console.log("value", ref.current.value);
    refreshPage("", "", "", "","", "", "",  1);
  }

  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(
      Req_no,
      Rfq_Name_value,
      product_status,
      from_date,
      to_date,
      rfq_from_date,
      rfq_to_date,
      pageNumber
    );
  };

  function search_filter() {
    removeErrorParam();
    setSearchParams(params);
    refreshPage(
      Req_no,
      Rfq_Name_value,
      product_status,
      from_date,
      to_date,
      rfq_from_date,
      rfq_to_date,
      1
    );
  }

  function getPages(urls = "") {
    setLoading(true);

    http
      .get(`get_rfq_count${urls}`)
      .then((res) => {
        console.log(res.data);
        setPages(res.data.page_no);
        setTotal(res.data.total);
        setOffset(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const refreshPage = (
    request_no = "",
    url_customer_name = "",
    status = "",
    offer_from_date = "",
    offer_to_date="",
    rfq_from_date="",
    rfq_to_date="",
    page_to = ""
  ) => {
    setLoading(true);

    // let page_to_load = page_to != "" ? page_to : currentPage;
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (request_no) {
      urls += `&request_no=${request_no}`;
    }
    if (url_customer_name) {
      urls += `&customer_name=${url_customer_name}`;
    }

    if (offer_from_date) {
      urls += `&offer_from_date=${offer_from_date}`;
    }
    if (offer_to_date) {
      urls += `&offer_to_date=${offer_to_date}`;
    }
    if (rfq_from_date) {
      urls += `&rfq_from_date=${rfq_from_date}`;
    }
    if (rfq_to_date) {
      urls += `&rfq_to_date=${rfq_to_date}`;
    }
    if (status) {
      urls += `&status=${status}`;
    }
    console.log("page_to_load", page_to_load);
    setSearchParams(urls);
    getPages(urls);
    http
      .get(`get_rfq_list${urls}`)
      .then((res) => {
        setRfqList(res.data);
        setLoading(false);
        // UserComponentVerifiy(res);
      })
      .catch((err) => {
        // toast.error(err.message);
        console.log(err.message);
      });
  };

console.log(rfqList);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);
  const showDeleteModal = (id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete SEO ?`);
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    http
      .delete(`delete_seo_by_id/${id}`)
      .then((response) => {
        toast.success("SEO deleted");
        setDeleteMessage("User has been deleted");
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   const [error, setError] = useState(null);

  const showClauseForm = (id) => {
    setUserId(id);

    console.log(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowClauseModal(true);
  };

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  const handleEdit = (idd) => {
    setType("Edit");
    setUserId(idd);
    setshowClauseModal(true);
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  
  
  console.log("from_date" , from_date)
  console.log("to_date" , to_date)
  console.log("rfq_from_date" , rfq_from_date)
  console.log("rfq_to_date" , rfq_to_date)

  
  
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex  justify-content-between ">
                        <h4 className="card-title mt-2">RFQ List</h4>
                        {/* <Link
                          // onClick={() => showClauseForm()}
                          to="/seo/add-seo"
                          type="button"
                          className="btn btn-primary add-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          SEO
                        </Link> */}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-lg-11">
                            <div className="row">
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Enter Request No"
                                  value={Req_no}
                                onChange={(e) => ReqNoChange(e)}
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="form-control Search"
                                  placeholder="Company name / Name / Email / Mobile"
                                  value={Rfq_Name_value}
                                  onChange={(e) => RfqonChange(e)} 
                                />
                              </div>
                              <div className="col-md-3">
                              <select class="form-select mb-3"
                               aria-label="Default select example"
                               value={product_status}
                                  onChange={(e) => productStatus(e)}
                                ref={ref}
                               >
                                  <option selected="">Select Status </option>
                                  <option value="pending">Pending</option>
                                  <option value="assigned">Assigned</option>
                                  <option value="mapped">Mapped</option>
                                  <option value="offer Created">Offer Created</option>
                                  <option value="5">Cancelled</option>
                                  <option value="6">No Followup</option>
                                  <option value="7">Buyer Accept</option>
                                  <option value="8">Buyer Reject</option>
                                  <option value="9">Declined</option>
                                  <option value="10">Sales Closed</option>
                              </select>
                              </div>
                              {/* <div className="col-md-3">
                              <ReactFlatpickr
                                placeholder="From Date (Offer creation)"
                                options={creation_from_date}
                                value={from_date}
                                />
                              </div> */}
                              {/* <div className="col-md-3">
                              <ReactFlatpickr
                                placeholder="To Date (Offer creation)"
                                options={creation_to_date}
                                value={to_date}
                                />
                              </div> */}
                              <div className="col-md-3">
                                <ReactFlatpickr
                                placeholder="From Date (Rfq Date)"
                                options={creation_rfq_from_date}
                                value={rfq_from_date}
                                />
                              </div>
                              <div className="col-md-3 ">
                              <ReactFlatpickr
                                placeholder="To Date (Rfq) Date"
                                options={creation_rfq_to_date}
                                value={rfq_to_date}
                                />
                              </div>
                              
                            </div>
                          </div>
                          <div className="col-md-1 mt-5 pt-4 mb-0  d-flex align-items-center justify-content-end">
                            <i
                              className="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              onClick={search_filter}
                            ></i>
                            <i
                              className="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={reset_filter} 
                            ></i>
                          </div>
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th data-sort="customer_name" valign="top">
                                  No
                                </th>
                                <th
                                  data-sort="email"
                                  valign="top"
                                  style={{ width: "11%" }}
                                >
                                  Request No
                                </th>
                                <th data-sort="date" valign="top">
                                 Company name 
                                </th>
                                <th data-sort="date" valign="top">
                                  Buyer Name 
                                </th>
                                <th data-sort="date" valign="top">
                                 Email 
                                </th>
                                <th data-sort="date" valign="top">
                                 Mobile 
                                </th>
                                <th data-sort="status" valign="top">
                                  Request Date
                                </th>
                                <th data-sort="status" valign="top">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                            {loading === true && (
                            <tr>
                              <td className="customer_name">
                                <Loader show={loading} />
                              </td>
                            </tr>
                          )}
                           {loading === false &&
                          (rfqList.length
                              ?
                              rfqList.map((rfq, index) => (
                                <tr key={index}>
                                  <td className="customer_name" valign="top">
                                    {index + 1}
                                  </td>
                                  <td className="email" valign="top">
                                    <Link
                                      to={`/rfq/details-page-list/${rfq.id}`}
                                      className="text-wrap"
                                    >
                                      {rfq.request_no}
                                    </Link>
                                    <br />
                                  </td>
                                  <td valign="top">
                                    {rfq.company_name}
                                  </td>
                                  <td valign="top">
                                    {rfq .name}
                                  </td>
                                  <td valign="top">
                                    {rfq.email}
                                  </td>
                                  <td valign="top">
                                    {rfq.mobile}
                                  </td>
                                  <td className="email" valign="top">
                                  {moment(rfq.indate)
                                        .utc()
                                        .format("DD/MM/YYYY")}
                                  </td>
                                  <td className="email" valign="top">
                                    <Link to={`/rfq/offer-list/${rfq.id}`}>
                                      Create offer
                                    </Link>
                                  </td>
                                </tr>
                                )) 
                               : null)}
                            </tbody>
                          </table>
                        </div>
                        {pages > 1 && (
                      <Paginate
                        postsPerPage={20}
                        totalPosts={total}
                        paginate={paginate}
                        url_page={url_page}
                      />
                        )}
                      </div>
                    </div>
                  </div>
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
