import React, { useState } from "react";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import http from "../../../../../utils/http"
import CategoryDropdown from "../Category/CategoryDropdown";
import { AttributeList } from "./AttributeList";
import { EditAttributeForm } from "./EditAttributeForm";
import { HashLink } from "react-router-hash-link";
import CategoryProductDropDown from "../Product/CategoryProductDropDown";

export const Attribute = () => {
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  console.log("hi Its loading");
  const [loading, setLoading] = useState(false);

  const [userList, setuserList] = useState([]);

  const refresh = (id) => {
    setCatID(id);
    fetchdata(id);
  };

  const [attributeList, setattributeList] = useState([]);

  // console.log(CatID);
  const fetchdata = (id) => {
    setLoading(true);
    http.get("get_attribute/" + id)
      .then((res) => {
        console.log(res.data);
        setuserList(res.data);
        // let result = data.map((item) => {
        //   setattributeList((attributeList) => [
        //     ...attributeList,
        //     { attribute_id: item.id, show_in_filter: item.show_in_filter, mandatory: item.mandatory, priority_for_detail: item.priority_for_detail, priority_for_decription: item.priority_for_decription },
        //   ]);
        // });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete attribute ? `);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  console.log(attributeList);

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_attribute/${id}`)
      .then((response) => {
        toast.success("Attribute deleted");
        refresh(CatID);
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);
          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  const yupValidation = Yup.object().shape({
    // name: Yup.string().required("Please enter attribute name"),
    // value: Yup.string().required("Please enter master values"),
    // attribute_group_id: Yup.string().required("Please select attribute group"),
    // type: Yup.string().required("Please select type"),
    // status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  const [Form, setForm] = useState(false);
  const [Category, setCategory] = useState([]);
  const [CatID, setCatID] = useState("");
  const [Attr_Id, setAttr_Id] = useState("");
  const [Attr_Edit, setAttr_Edit] = useState(false);
  const [Attr_form_reset, setAttr_form_reset] = useState(false);

  function onchange(e) {
    setAttr_form_reset(true)
    console.log(e);
    setCategory(e);
    setForm(true);
    setAttr_Edit(false);
    setLoading(true);

    fetchdata(e.value);
    refresh(e.value);
  }

  const EditAttr = (id) => {
    setAttr_Id(id);
    setAttr_Edit(true);
  };

  const [checked, setchecked] = useState({Status: "", ShowInFilter: "", Mandatory:""});
  const [Status, setStatus] = useState(false);

  const OnStatus = (e, id) => {
    setStatus(true);
    // console.log(e.target, id);
    const { name, checked } = e.target;

    let data = {
      clm: "status",
      clm_val: checked === true ? 1 : 0,
      type_id: id,
      type: "attribute",
    };
    setchecked({ ...checked, [name]: checked === true ? 1 : 0 });
    // console.log(data);

    http
      .post(`catalog_switch`, data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [OnShow, setOnShow] = useState(false);

  const OnShow_in_filter = (e, id) => {
    setOnShow(true);
    // console.log(e.target, id);
    const { name, checked } = e.target;

    let data = {
      clm: "show_in_filter",
      clm_val: checked === true ? 1 : 0,
      type_id: id,
      type: "attribute",
    };
    setchecked({ ...checked, [name]: checked === true ? 1 : 0 });
    // console.log(data);

    http
      .post(`catalog_switch`, data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [OnMand, setOnMand] = useState(false);

  const OnMandatory = (e, id) => {
    setOnMand(true);
    // console.log(e.target, id);
    const { name, checked } = e.target;

    let data = {
      clm: "mandatory",
      clm_val: checked === true ? 1 : 0,
      type_id: id,
      type: "attribute",
    };
    setchecked({ ...checked, [name]: checked === true ? 1 : 0 });
    // console.log(data);

    http
      .post(`catalog_switch`, data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex  justify-content-between">
                      <h4 className="card-title mt-2">Attribute</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row ">
                      <div className="col-md-6">
                        <label
                          className="form-label"
                          htmlFor="project-title-input"
                        >
                          Product Category
                        </label>
                        <CategoryProductDropDown
                          placeholder="Category"
                          value={Category}
                          change={(e) => onchange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {Form && (
                  <>
                    <div className="card">
                      <div className="card-body">
                        <div className="listjs-table" id="customerList">
                          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                          <div className="table-responsive table-card mt-3 mb-1">
                            <table
                              className="table align-middle table-nowrap table-bordered"
                              id="customerTable"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th data-sort="customer_name">#</th>
                                  <th data-sort="email">Attribute Name</th>
                                  <th
                                    data-sort="status"
                                    className="text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Status
                                  </th>
                                  <th
                                    data-sort="status"
                                    className="text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Show in Filter
                                  </th>
                                  <th
                                    data-sort="status"
                                    className="text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Mandatory
                                  </th>
                                  <th
                                    data-sort="status"
                                    className="text-center"
                                    style={{ width: "10%" }}
                                  >
                                    Priority
                                  </th>
                                  <th
                                    data-sort="action"
                                    className="text-center"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="list form-check-all">
                                <Loader show={loading} />
                                {loading === false &&
                                  userList.map((user, index) => (
                                    <tr key={index}>
                                      <td className="customer_name ">
                                        {index + 1}
                                      </td>
                                      <td className="email ">{user.name}</td>
                                      {/* <td className="status">
                                          <span
                                            className={`badge text-uppercase ${
                                              user.status == 1
                                                ? "bg-success-subtle text-success"
                                                : "bg-danger-subtle text-danger"
                                            }`}
                                          >
                                            {user.status == 1
                                              ? "Active"
                                              : "In-active"}
                                          </span>
                                        </td> */}
                                      {/* <td className="customer_name">
                                          <div className="form-check text-center">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="status"
                                              checked={user.status === 1 ? true : false}
                                              {...register("status")}
                                            />
                                          </div>
                                        </td> */}
                                      <td className="customer_name">
                                        <div class="form-check form-switch text-center">
                                          {/* <label
                                        class="form-check-label"
                                        for="status"
                                        hidden="true"
                                      >
                                        Status
                                      </label> */}
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            name={`status-${user.id}`}
                                            onChange={(e) => OnStatus(e, user.id)}
                                            checked={
                                              Status
                                                ? checked.Status
                                                : user.status
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="customer_name">
                                        <div class="form-check form-switch text-center">
                                          {/* <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="show_in_filter"
                                            {...register("show_in_filter")}
                                          /> */}
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            name={`show_in_filter-${user.id}`}
                                            onChange={(e) => OnShow_in_filter(e, user.id)}
                                            checked={
                                              OnShow
                                                ? checked.ShowInFilter
                                                : user.show_in_filter
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="customer_name">
                                        <div class="form-check form-switch text-center">
                                          {/* <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="mandatory"
                                            {...register("mandatory")}
                                          /> */}
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            name={`mandatory-${user.id}`}
                                            onChange={(e) => OnMandatory(e, user.id)}
                                            checked={
                                              OnMand
                                                ? checked.Mandatory
                                                : user.mandatory
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="customer_name">
                                        <div className="form-check">
                                          <input
                                            className="form-control"
                                            name="priority"
                                            type="number"
                                            {...register("priority")}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex gap-2 justify-content-center">
                                          <div className="edit">
                                            {/* <Link
                                              className=" ri-edit-2-fill fs-4"
                                              to={"#edit"}
                                              onClick={() => EditAttr(user.id)}
                                            ></Link> */}
                                            <HashLink
                                              smooth
                                              to={"#edit"}
                                              className="ri-edit-2-fill fs-4"
                                              onClick={() => EditAttr(user.id)}
                                            />
                                          </div>
                                          <div className="remove">
                                            <Link
                                              onClick={() =>
                                                showDeleteModal("User", user.id)
                                              }
                                              className="ri-delete-bin-6-line fs-4"
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteRecordModal"
                                            ></Link>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          {/* <div className=" text-end">
                              <button
                                type="submit"
                                className="btn btn-primary w-sm"
                                disabled={loading}
                              > */}
                          {/* {loading ? "Loading..." : "Update"} */}
                          {/* Update
                              </button>
                            </div> */}
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                    {!Attr_Edit ? (
                      <AttributeList refresh={refresh} resetForm={Attr_form_reset} CatID={CatID} />
                    ) : (
                      <EditAttributeForm
                        id={"edit"}
                        refresh={refresh}
                        CatID={CatID}
                        Attr_Id={Attr_Id}
                        setAttr_Edit={setAttr_Edit}
                      />
                    )}
                  </>
                )}
                <DeleteConfirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitDelete}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={deleteMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
