import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import http from "../../../../../utils/http";

export const WebpageForm = () => {
  const [attr_group_List, setattr_group_List] = useState([]);

  useEffect(() => {
    fetchattrgroup();
  }, []);

  const fetchattrgroup = () => {
    http
      .get("get_attribute_group")
      .then((res) => {
        console.log(res.data);
        setattr_group_List(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const [File, setFile] = useState("");

  const yupValidation = Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    short_description: Yup.string().required("Please enter short description"),
    // description: Yup.string().required("Please enter description"),
    meta_title: Yup.string().required("Please enter meta title"),
    meta_description: Yup.string().required("Please enter meta description"),
    meta_keyword: Yup.string().required("Please enter meta keyword"),
    status: Yup.string().required("Please select status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit(data) {
    console.log(data);
    setLoading(true);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    http
      .post(`save_webpage`, data, config)
      .then((response) => {
        toast.success("Webpage has been created");
        const timer = setTimeout(() => {
          setLoading(false);
          navigate("/webpage");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const [value, setVal] = useState("");

  const [content, setContent] = useState("");
  const onChange = (text) => {
    console.log(text);
    setContent(text);
    setValue("description", text)
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Add Webpage</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Title
                      </label>
                      <input
                        className="form-control"
                        name="title"
                        placeholder="Enter Title"
                        {...register("title")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.title ? "d-block" : ""
                        }`}
                      >
                        {errors.title ? errors.title.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <label> Short Description</label>
                      <textarea
                        className="form-control"
                        name="short_description"
                        {...register("short_description")}
                        placeholder="Enter Short Description"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                      <div
                        className={`invalid-feedback ${
                          errors.short_description ? "d-block" : ""
                        }`}
                      >
                        {errors.short_description
                          ? errors.short_description.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <label>Description</label>
                      <ReactQuill
                        theme="snow"
                        // value={value}
                        onChange={onChange}
                        placeholder="Enter Description"
                        defaultValue={''}
                      />
                      {/* <textarea
                        className="form-control"
                        name="description"
                        {...register("description")}
                        placeholder="Enter Description"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea> */}
                      {/* <div
                        className={`invalid-feedback ${
                          errors.description ? "d-block" : ""
                        }`}
                      >
                        {errors.description ? errors.description.message : ""}
                      </div> */}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6 ">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Meta Title
                      </label>
                      <input
                        className="form-control"
                        name="meta_title"
                        placeholder="Enter Meta Title"
                        {...register("meta_title")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.meta_title ? "d-block" : ""
                        }`}
                      >
                        {errors.meta_title ? errors.meta_title.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <label>Meta Description</label>
                      <textarea
                        className="form-control"
                        name="meta_description"
                        {...register("meta_description")}
                        placeholder="Enter Meta Description"
                        id="exampleFormControlTextarea1"
                        rows="1"
                      ></textarea>
                      <div
                        className={`invalid-feedback ${
                          errors.meta_description ? "d-block" : ""
                        }`}
                      >
                        {errors.meta_description
                          ? errors.meta_description.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6 ">
                      <label>Meta Keywords</label>
                      <textarea
                        className="form-control"
                        name="meta_keyword"
                        {...register("meta_keyword")}
                        placeholder="Enter Meta Keywords"
                        id="exampleFormControlTextarea1"
                        rows="1"
                      ></textarea>
                      <div
                        className={`invalid-feedback ${
                          errors.meta_keyword ? "d-block" : ""
                        }`}
                      >
                        {errors.meta_keyword
                          ? errors.meta_keyword.message
                          : ""}
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="image"
                        accept="application/jpg,application/png"
                        onChange={(e) => {
                          setValue("image", e.target.files[0]);
                          setFile(URL.createObjectURL(e.target.files[0]));
                        }}
                        placeholder="Enter display order"
                      />
                      {File !== "" && (
                        <div
                          className=" d-flex align-items-center overflow-hidden flex-column mt-3"
                          style={{ width: "40%" }}
                        >
                          <img src={File} alt="" style={{ width: "100%" }} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Status
                      </label>
                      <select
                        className="form-select"
                        name="status"
                        {...register("status")}
                      >
                        <option defaultValue="1" value="1">
                          Active
                        </option>
                        <option value="0">In Active</option>
                      </select>
                      <div
                        className={`invalid-feedback ${
                          errors.status ? "d-block" : ""
                        }`}
                      >
                        {errors.status ? errors.status.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 mt-4 text-end">
                    <button
                      type="submit"
                      className="btn btn-danger w-sm"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Save Webpage"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
