import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
// import MasterDropDown from "../pages/views/MasterDropDown";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'

import http from "../../../utils/http";




const EditUserModal = ({ showModal, hideModal, refreshPage, id, type, data }) => {
    const yupValidation = Yup.object().shape({
        name: Yup.string().required('Please enter name'),
        email: Yup.string().required('Please enter email').email('Please enter valid email'),
        role_id: Yup.string().required('Please select role'),
        password: Yup.string().required('Please enter password'),
        mobile: Yup.string().required('Please enter number'),
        status: Yup.string().required('Please enter number')
    })
    const formOptions = { resolver: yupResolver(yupValidation) }
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const [loading, setLoading] = useState(false);
    const [resss, setMessage] = useState('');
    const { errors } = formState


    function getEditUser(id) {
        http.get(`get_user_by_id/${id}`)
            .then((res) => {
                setValue("name", res.data.name)
                setValue("email", res.data.email)
                setValue("role_id", res.data.role_id)
                setValue("mobile", res.data.mobile)
                setValue("password", res.data.password)
                setValue("status", res.data.status)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    function onSubmit(data) {
        console.log(data);
       
            setLoading(true);
            http.put(`update_user/${id}`, data)
                .then(response => {
                    console.log(response);
                    console.log('User has been edited');
                    reset()
                    setLoading(false);
                        hideModal();
                        refreshPage();
                }).catch(error => {
                    console.log('error delete');
                    if (error) {
                        console.log(error)
                    }
                });
            setMessage('User has been updated');
            setTimeout(() => {
                setMessage('')
            }, 1000);

        
       



    }



    useEffect(() => {
        getEditUser(id)
    }, [id]);



    return (
        <Modal show={showModal} onHide={hideModal} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className={`alert alert-success ${resss === "" && "d-none"}`}>{resss}</div>
                    <div className={`row g-3 ${resss !== "" && "d-none"}`}>
                        <div className="col-lg-6">
                            <div id="modal-id">
                                <label htmlFor="orderId" className="form-label">Name</label>
                                <input
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    {...register('name')}
                                />
                                {/* if (errors.name !== undefined && errors.name !== null) { */}
                                <div className={`invalid-feedback ${errors.name ? 'd-block' : ''}`}>{errors.name ? errors.name.message : ''}</div>
                                {/* }  */}
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div>
                                <label for="client_nameName-field" className="form-label">Email</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    required=""
                                    {...register('email')} />

                                <div className={`invalid-feedback ${errors.email ? 'd-block' : ''}`}>{errors.email ? errors.email.message : ''}</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <label for="tasksTitle-field" className="form-label">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    required=""
                                    {...register('password')} />
                                <div className={`invalid-feedback ${errors.password ? 'd-block' : ''}`}>{errors.password ? errors.password.message : ''}</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <label for="tasksTitle-field" className="form-label">Mobile</label>
                                <input
                                    type="number"
                                    id="mobile"
                                    name="mobile"
                                    className="form-control"
                                    placeholder="Enter phone"
                                    required=""
                                    {...register('mobile')} />
                                <div className={`invalid-feedback ${errors.mobile ? 'd-block' : ''}`}>{errors.mobile ? errors.mobile.message : ''}</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <label for="department_ids" className="form-label">Role</label>
                                <select
                                    type="text"
                                    id="role_id"
                                    name="role_id"
                                    className="form-select"
                                    {...register('role_id')}
                                >
                                    <option value="">Select department</option>
                                    <option value="1">Hr</option>
                                    <option value="2">Sales</option>
                                    <option value="3">Operational</option>
                                </select>
                                <div className={`invalid-feedback ${errors.role_id ? 'd-block' : ''}`}>{errors.role_id ? errors.role_id.message : ''}</div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div>
                                <label for="assignedtoName-field" className="form-label">Status</label>
                                <select
                                    name="status"
                                    className="form-select"
                                    {...register('status')}
                                >
                                    <option value="">Select status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                                <div className={`invalid-feedback ${errors.status ? 'd-block' : ''}`}>{errors.status ? errors.status.message : ''}</div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className={`${resss !== "" && "d-none"}`}>
                    <input type="hidden" name="standard_id" value={id} />
                    <Button variant="danger" onClick={hideModal}>
                        Cancel
                    </Button>
                    <Button variant="success" type="submit">
                        {loading ? 'Loading...' : 'Save'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default EditUserModal;