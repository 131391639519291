import React, { useState, useEffect } from "react";
import Select from "react-select";
import http from "../../../../../utils/http";

const DeliveryAddDrop = (props) => {
  const opt = [];
  console.log('gettemp_delivary_id',props.gettemp_delivary_id);
  const [options, setoptions] = useState([]);
  
  function getIndexOfK(arr, k) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].value == k) {
        return arr[i].label;
      }
    }
  }

  let defaultObj = "";
  if (props.gettemp_delivary_id) {
    let defaltLabel = getIndexOfK(options, props.gettemp_delivary_id);
    defaultObj = { label: defaltLabel, value: props.gettemp_delivary_id };
    console.log('defaultObj',defaultObj);
  }

  useEffect(() => {
  
    fecthBillingAddress();
  }, [props.id, props]);

  function fecthBillingAddress() {
    http
      .get(`get_customer_address_by_id/${props.id}`)
      .then((res) => {
        console.log("Billing AddDrop Down",res.data);
        // setLastIndex(res.data)
        if (res.status == 204) {
          opt.push({
            label: "Create New",
            value: "create_new",
          });
          setoptions(opt);
        } 
        else {
          let result = res.data;
          let address = result.filter((item) => item.user_id == props.id);
          address.forEach((val) => {
            let Add = val.address;
            let city = ", " + val.city;
            let pincode = "-" + val.pincode;
            let country = ", " + val.country;
            opt.push({
              label: Add + city + pincode + country,
              value: val.id,
            });
          });
          opt.push({
            label: "Create New",
            value: "create_new",
          });

          setoptions(opt);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  console.log(options);

  return (
    <Select      
      options={options}
      onChange={props.change}
      placeholder={props.placeholder}
      value={props.value || defaultObj || ""}
      isSearchable={true}
      
    />
  );
};
  
export default DeliveryAddDrop;
