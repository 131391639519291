import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import http from "../../../../../utils/http";
import photo from "../../../../assets/images/banner_bg_select.png";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";

export default function BannerUpload() {
  const { banner_id, banner_type } = useParams();
  const navigate = useNavigate();
  console.log(banner_id, banner_type);
  const formOptions = {};
  const { register, setValue, getValues, handleSubmit, formState } = useForm(
    formOptions
  );

  const { errors } = formState;

  const [Banner, setBanner] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [AllBannerImgs, setAllBannerImgs] = useState();
  const [Btnloading, setBtnloading] = useState(false);
  const [id, setId] = useState(null);
  const [message, setMessage] = useState("");

  const Banner_img = (imageList, addUpdateIndex) => {
    let img = imageList.map((imgValue, index) => {
      return imgValue.file;
    });
    console.log(img);
    // data for submit
    // console.log(imageList);
    // console.log(imageList, addUpdateIndex);
    setBanner(imageList);
    setAllBannerImgs(img);
  };
  function getBanner() {
    setLoading(true);
    http
      .get(`get_uploaded_image/${banner_id}/${banner_type}`)
      .then((res) => {
        console.log("banner", res.data);
        setBannerList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  useEffect(() => {
    getBanner();
  }, []);

  function onSubmit() {
    console.log("Post", AllBannerImgs);
    if (AllBannerImgs !== undefined) {
      setBtnloading(true);
      let dataBanner = {
        images: AllBannerImgs,
        type: banner_type,
        type_id: banner_id,
      };
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log("config " , config);
      http
        .post(`upload_images`, dataBanner, config)
        .then((response) => {
          toast.success("Banner has been added");
          navigate(`/upload-banner/${banner_id}/${banner_type}`);
          setBanner([]);
          setAllBannerImgs([]);
          getBanner();
          setBtnloading(false);
          // navigate
        })
        .catch((error) => {
          setBtnloading(false);
          // toast.error("Internal server error");
          console.log(error);
        });
    } else {
      console.log("please Select the image...!!");
    }

    // console.log("Post", Banner);
  }

  const [OnChangeVal, setOnChangeVal] = useState(false);
  const [TitleVal, setTitleVal] = useState("");

  var dataB;
  const imageData = [];
  function getTableFiledDeta(e, id, label) {
    dataB = e.target.value;

    toggleSwitch(id, label, dataB);
    // setOnChangeVal(true);
    // setTitleVal(dataB);
    // let f = { id: id, label: label, value: dataB };
    // imageData.push(f);
    console.log("image", imageData);
  }

  const toggleSwitch = (id, columns, vals) => {
    let newCats = [];
    if (columns == "title") {
      newCats = bannerList.map((user) =>
        user.id === id ? { ...user, title: vals } : user
      );
    } else if (columns == "alt") {
      newCats = bannerList.map((user) =>
        user.id === id ? { ...user, alt: vals } : user
      );
    } else if (columns == "url") {
      newCats = bannerList.map((user) =>
        user.id === id ? { ...user, url: vals } : user
      );
    } else if (columns == "description") {
      newCats = bannerList.map((user) =>
        user.id === id ? { ...user, description: vals } : user
      );
    }
    setBannerList(newCats);
  };
  // function onSubmit() {
  //   console.log("clicked" , dataB);
  // }

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    // console.log(id);
    setDeleteMessage(`Are you sure you want to delete Image ?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const submitDelete = () => {
    http
      .delete(`delete_upl_image/${id}`)
      .then((response) => {
        getBanner();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function onSubmitData() {
    setBtnloading(true);
    console.log("onSubmit2", bannerList);
    http
      .post(`update_image_content`, bannerList)
      .then((response) => {
        toast.success("Banner has been added");
        const timer = setTimeout(() => {
          setBtnloading(false);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setBtnloading(false);
        console.log(error);
      });
  }
  return (
    <>
      <div
        className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message ===
          "" && "d-none"}`}
      >
        {message}
      </div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
          <Toaster position="top-center" />
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title mb-0">Banner Image Upload</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row g-4 mb-3">
                        <div className="col-md-12">
                          <div className="row mt-3">
                            <ImageUploading
                              multiple
                              value={Banner}
                              onChange={Banner_img}
                              dataURLKey="data_url"
                              acceptType={["jpg", "gif", "png"]}
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                              }) => (
                                // write your building UI
                                <div className="container">
                                  <button
                                    className="BanBorder rounded d-flex flex-column justify-content-center align-items-center py-4"
                                    style={isDragging ? { color: "red" } : null}
                                    type="button"
                                    onClick={onImageUpload}
                                    {...dragProps}
                                  >
                                    <div class="mb-3">
                                      <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
                                    </div>
                                    <p
                                      className="mb-4"
                                      style={{ fontWeight: "400" }}
                                    >
                                      Drop your image here, or&nbsp;
                                      <span style={{ color: "#5869d8" }}>
                                        browse
                                      </span>
                                    </p>
                                  </button>
                                  &nbsp;
                                  <div className="row">
                                    {Banner.map((image, index) => (
                                      <div
                                        key={index}
                                        className="col-md-3 mt-2 mb-3"
                                      >
                                        <div className="border d-flex align-items-center overflow-hidden flex-column">
                                          <img
                                            src={image.data_url}
                                            alt=""
                                            style={{ height: "150px" }}
                                          />
                                          <div className="p-2">
                                            <Link
                                              className="btn btn-primary btn-sm"
                                              type="button"
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                            >
                                              Update
                                            </Link>
                                            <button
                                              className="btn btn-primary btn-sm ms-2"
                                              type="button"
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </ImageUploading>
                          </div>
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              type="button"
                              className="btn btn-warning ps-8 pe-8"
                              disabled={Btnloading}
                              onClick={(e) => onSubmit(e)}
                            >
                              <i class=" ri-upload-cloud-fill fs-6 me-1 align-bottom"></i>
                              {Btnloading ? "Loading..." : "Upload"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table className="table align-middle table-nowrap mb-0 table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th data-sort="customer_name">#</th>
                              <th data-sort="customer_name">Image</th>
                              <th data-sort="customer_name">Title</th>
                              <th data-sort="customer_name">Alt</th>
                              <th data-sort="customer_name">Url</th>
                              <th data-sort="customer_name">Description</th>
                              <th data-sort="customer_name">Action</th>
                            </tr>
                          </thead>

                          <tbody className="list form-check-all">
                            {loading === true && (
                              <tr>
                                <td className="customer_name">
                                  <Loader show={loading} />
                                </td>
                              </tr>
                            )}
                            {loading === false &&
                              (bannerList.length
                                ? bannerList.map((list, index) => (
                                    <tr key={index}>
                                      <td className="customer_name">
                                        {index + 1}
                                      </td>
                                      <td className="customer_name">
                                        <img
                                          src={`https://woodens3.s3.ap-south-1.amazonaws.com/${list.image}`}
                                          alt=""
                                          style={{ height: "70px" }}
                                        />
                                      </td>
                                      <td
                                        className="customer_name"
                                        style={{ width: "20%" }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`title-${list.id}`}
                                          value={
                                            OnChangeVal ? TitleVal : list.title
                                          }
                                          onChange={(e) =>
                                            getTableFiledDeta(
                                              e,
                                              list.id,
                                              "title"
                                            )
                                          }
                                        />
                                      </td>
                                      <td
                                        className="customer_name"
                                        style={{ width: "20%" }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`alt-${list.id}`}
                                          value={list.alt}
                                          onChange={(e) =>
                                            getTableFiledDeta(e, list.id, "alt")
                                          }
                                        />
                                      </td>
                                      <td
                                        className="customer_name"
                                        style={{ width: "20%" }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`url-${list.id}`}
                                          value={list.url}
                                          onChange={(e) =>
                                            getTableFiledDeta(e, list.id, "url")
                                          }
                                        />
                                      </td>
                                      <td
                                        className="customer_name"
                                        style={{ width: "20%" }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`description-${list.id}`}
                                          value={list.description}
                                          onChange={(e) =>
                                            getTableFiledDeta(
                                              e,
                                              list.id,
                                              "description"
                                            )
                                          }
                                        />
                                      </td>
                                      <td className="customer_name">
                                        <div className="remove text-center">
                                          <Link
                                            onClick={() =>
                                              showDeleteModal("User", list.id)
                                            }
                                            className="ri-delete-bin-6-line fs-4 "
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"
                                          ></Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : null)}
                          </tbody>
                        </table>
                        <div className="hstack gap-4 mt-4 me-3 justify-content-end">
                          <Link
                            type="button"
                            className="btn btn-danger  pt-8 pb-8"
                            to="/add-banner"
                          >
                            Back
                          </Link>
                          <button
                            type="button"
                            onClick={(e) => onSubmitData(e)}
                            className="btn btn-success ps-8 pe-8"
                            disabled={Btnloading}
                          >
                             {Btnloading ? "Loading..." : "Update"} 
                            
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <DeleteConfirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitDelete}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={deleteMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
