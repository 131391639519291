import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../../shared/Loader";
import http from "../../../../utils/http";
import moment from "moment";

export default function DiscountCouponList() {
  const [couponList, setCouponList] = useState([]);
  const [loading, setLoading] = useState(false);

  const itemDelete = (id) => {
    console.log("Delete", id);
    http
      .delete(`delete_coupon_by_id/${id}`)
      .then((response) => {
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const refreshPage = () => {
    setLoading(true);
    http
      .get("get_coupon_list")
      .then((res) => {
        console.log(res.data);
        setCouponList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    refreshPage();
  }, []);
  return (
    <>
      {/* <Header />
            <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title mb-0">Discount Coupon List</h4>
                    </div>
                    <div className="card-body">
                      <div className="row g-4 mb-3">
                        <div className="col-md-12 d-flex justify-content-between">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                          <Link to="/website-management/discount-coupon-form">
                            <button
                              type="button"
                              className="btn btn-primary add-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add Coupon
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table className="table align-middle table-nowrap mb-0 table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">
                                {/* <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    value="option"
                                                                />
                                                            </div> */}
                              </th>
                              <th data-sort="customer_name">#</th>
                              <th data-sort="customer_name">Coupon</th>
                              <th data-sort="customer_name">Code</th>
                              <th data-sort="customer_name">Type</th>
                              <th data-sort="customer_name">Value</th>
                              <th data-sort="customer_name">MCV</th>
                              <th data-sort="customer_name">From Date</th>
                              <th data-sort="customer_name">To Date</th>
                              <th data-sort="customer_name">Status</th>

                              <th data-sort="action">Action</th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {couponList.map((coupon, index) => (
                              <tr key={index}>
                                <th scope="row"></th>
                                <td className="customer_name">{index + 1}</td>
                                <td className="email">{coupon.coupon_name}</td>
                                <td className="email">{coupon.coupon_code}</td>
                                <td className="email">{coupon.coupon_type}</td>
                                <td className="email">{coupon.coupon_value}</td>
                                <td className="email">
                                  {coupon.min_cart_value}
                                </td>
                                <td className="email">
                                {moment(coupon.from_date)
                                        .utc()
                                        .format("DD/MM/YYYY")}
                                </td>
                                <td className="email">
                                {moment(coupon.to_date)
                                        .utc()
                                        .format("DD/MM/YYYY")}
                                </td>
                                <td className="status">
                                  <span
                                    className={`badge text-uppercase ${
                                      coupon.status == 1
                                        ? "bg-success-subtle text-success"
                                        : "bg-danger-subtle text-danger"
                                    }`}
                                  >
                                    {coupon.status == 1
                                      ? "Active"
                                      : "In-active"}
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="edit">
                                      <Link
                                        className=" ri-edit-2-fill fs-4"
                                        to={`/website-management/discount-coupon-form-edit/${coupon.id}`}
                                      ></Link>
                                    </div>
                                    <div className="remove">
                                      <Link
                                        onClick={() => itemDelete(coupon.id)}
                                        className="ri-delete-bin-6-line fs-4"
                                        //   data-bs-toggle="modal"
                                        //   data-bs-target="#deleteRecordModal"
                                      ></Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
