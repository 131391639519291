import React, { useState, useEffect } from "react";
import Select from "react-select";
import http from "../../../../../utils/http";

const CustomerDropdown = (props) => {
  const opt = [];

  console.log('temp_customer_id',props.temp_customer_id);

  const [options, setoptions] = useState([]);
  console.log(options);

  function getIndexOfK(arr, k) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].value == k) {
        console.log(arr[i].label);
        return arr[i].label;
      }
    }
  }

  let defaultObj = "";
  if (props.temp_customer_id) {
    
    let defaltLabel = getIndexOfK(options, props.temp_customer_id);
    console.log(defaltLabel);
    defaultObj = { label: defaltLabel, value: props.temp_customer_id };
    console.log('defaultObj',defaultObj);
  }

  useEffect(() => {
    fetchCustomers();
  }, [props.id , props , props.temp_customer_id]);

  const fetchCustomers = () => {
    http
      .get("get_customers")
      .then((res) => {
        res.data.forEach((val) => {
          opt.push({
            label: val.f_name + " " + val.l_name,
            value: val.id,
          });
        });

        setoptions(opt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <Select
      options={options}
      onChange={props.change}
      placeholder={props.placeholder}
      value={props.value || defaultObj || ""}
      isSearchable={true}
    />
  );
};

export default CustomerDropdown;
