import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
const Loader = props => {
  const [loading, setLoading] = useState(false);
  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  
  return (
    <div style={style}>
    <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#286ef1"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={props.show}
          />
          </div>
  );
};

export default Loader;
