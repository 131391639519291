import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import slugify from "slugify";
import CategoryDropdown from "./CategoryDropdown";
import http from "../../../../../utils/http";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import CategoryChildParentDropdown from "./CategoryChildParentDropdown";

export const CategoryForm = () => {
  const [parent_category, setparent_category] = useState([]);

  const [File, setFile] = useState("");
  const user_name = localStorage.getItem("name");

  useEffect(() => {
    fetchparent_category();
  }, []);

  const fetchparent_category = () => {
    http
      .get("get_category_tree")
      .then((res) => {
        console.log(res.data);
        setparent_category(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);
  const [slug, setSlug] = useState("");

  const yupValidation = Yup.object().shape({
    category_name: Yup.string().required("Please enter category name"),
    // parent_id: Yup.string().required("Please select parent category"),
    description: Yup.string().required("Please enter description"),
    // display: Yup.string().required("Please select display"),
    // show_home: Yup.string().required("Please select show home"),
    // status: Yup.string().required("Please select status"),
    // image: Yup.string().required("Please choose image"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  function getSEOUrl(e) {
    let val = e.target.value;
    console.log(val);
    let slugifyLink = slugify(val);
    setValue("seo_url", slugifyLink);
    setSlug(slugifyLink);
  }

  function onSubmit(data) {
    // console.log(slug);
    // setValue('seo_url' , slug)
    setValue("display", 1);
    setValue("show_home", 1);
    setValue("status", 1);
    setValue("user_name", user_name);
    console.log(data);
    setLoading(true);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    http
      .post(`save_category`, data, config)
      .then((response) => {
        console.log(response);

        toast.success("Category has been added");
        const timer = setTimeout(() => {
          setLoading(false);
          navigate("/category");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const [Cat_Parent, setCat_Parent] = useState([]);

  function handle_Cat_DropDown(e) {
    console.log(e);
    setCat_Parent(e);
    setValue("parent_id", e.value);
    setValue("cat_parent", e);
  }

  const [content, setContent] = useState("");
  const onChange = (text) => {
    console.log(text);
    setContent(text);
    setValue("description", text);
  };

  return (
    <>
      <div
        className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message ===
          "" && "d-none"}`}
      >
        {message}
      </div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Add Category</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Category Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        name="category_name"
                        placeholder="Enter Category Name"
                        {...register("category_name")}
                        onChange={(e) => getSEOUrl(e)}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.category_name ? "d-block" : ""
                        }`}
                      >
                        {errors.category_name
                          ? errors.category_name.message
                          : ""}
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Parent Category
                      </label>
                      {/* <select
                        className="form-select"
                        name="parent_id"
                        {...register("parent_id")}
                      >
                        <option value="">Select Category</option>
                        {parent_category.map((parent_cat, index) => {
                          return (
                            <option value={parent_cat.id}>
                              {parent_cat.breadcrumb}
                            </option>
                          );
                        })}
                      </select> */}
                      <CategoryChildParentDropdown
                        type="industry_sector"
                        placeholder="Categories"
                        value={Cat_Parent}
                        change={(e) => handle_Cat_DropDown(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <label> Description</label>{" "}
                      <span className="text-danger">*</span>
                      {/* <textarea
                        className="form-control"
                        name="description"
                        {...register("description")}
                        placeholder="Enter category description"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea> */}
                      <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={onChange}
                        placeholder="Enter Description"
                        defaultValue={""}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.description ? "d-block" : ""
                        }`}
                      >
                        {errors.description ? errors.description.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6 ">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Image(255 X 160) <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="image"
                        accept="application/jpg,application/png"
                        onChange={(e) => {
                          setValue("image", e.target.files[0]);
                          setFile(URL.createObjectURL(e.target.files[0]));
                        }}
                        placeholder="Enter display order"
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.image ? "d-block" : ""
                        }`}
                      >
                        {errors.image ? errors.image.message : ""}
                      </div>
                      {File !== "" && (
                        <div
                          className=" d-flex align-items-center overflow-hidden flex-column mt-2"
                          style={{ width: "40%" }}
                        >
                          <img src={File} alt="" style={{ width: "100%" }} />
                        </div>
                      )}
                    </div>
                   
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Short Description 
                      </label>
                      <input
                        className="form-control"
                        name="short_description"
                        placeholder="Enter Short Description"
                        {...register("short_description")}
                        onChange={(e) => getSEOUrl(e)}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.short_description ? "d-block" : ""
                        }`}
                      >
                        {errors.short_description
                          ? errors.short_description.message
                          : ""}
                      </div>
                      </div>

                    {/* <div className="col-lg-6">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Website Display
                      </label>
                      <select
                        className="form-select"
                        name="display"
                        defaultValue="1"
                        {...register("display")}
                      >
                        <option value="1"> Yes </option>
                        <option value="0"> No </option>
                      </select>
                      <div
                        className={`invalid-feedback ${
                          errors.display ? "d-block" : ""
                        }`}
                      >
                        {errors.display ? errors.display.message : ""}
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="row mb-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                        defaultValue="1"
                      >
                        Show Home
                      </label>
                      <select
                        className="form-select"
                        name="show_home"
                        defaultValue="1"
                        {...register("show_home")}
                      >
                        <option value=""> Select Show Home </option>
                        <option value="1"> Yes</option>
                        <option value="0">No</option>
                      </select>
                      <div
                        className={`invalid-feedback ${
                          errors.show_home ? "d-block" : ""
                        }`}
                      >
                        {errors.show_home ? errors.show_home.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Status
                      </label>
                      <select
                        className="form-select"
                        name="status"
                        defaultValue="1"
                        {...register("status")}
                      >
                        <option value=""> Select Status </option>
                        <option value="1"> Active </option>
                        <option value="0">In Active</option>
                      </select>
                      <div
                        className={`invalid-feedback ${
                          errors.status ? "d-block" : ""
                        }`}
                      >
                        {errors.status ? errors.status.message : ""}
                      </div>
                    </div>
                  </div> */}

                  <div className="mb-3 mt-4 text-end">
                    <Link
                      type="button"
                      className="btn btn-danger me-2 pt-8 pb-8"
                      to="/category"
                    >
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-success ps-8 pe-8 "
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Save Category"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
