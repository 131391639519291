import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import http from "../../../../../utils/http";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import { UpdateLead } from "./UpdateLead";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import ReactFlatpickr from "../../../../shared/ReactFlatpickr";
import UserAdminDropDown from "../../User/UserAdminDropDown";
import Paginate from "../../../../shared/Paginate";
// import ReactFlatpickr from "../../../../shared/ReactFlatpickr";

export const LeadManagement = () => {
  const params = new URLSearchParams(window.location.search);

  let url_lead_name = params.get("lead_name");
  let url_company = params.get("company");
  let url_from_date = params.get("from_date");
  let url_to_date = params.get("to_date");
  let url_lead_owner = params.get("lead_owner");
  let url_status = params.get("status");
  let url_page = params.get("page");

  let ref = useRef(null);

  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState("");
  const [resss, setMessage] = useState("");
  const [error, setError] = useState(null);

  /*************FILTERS*******************/
  const [searchParams, setSearchParams] = useSearchParams();
  const [cust_name, setcust_name] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [lead_ownerID, setlead_ownerID] = useState("");
  const [lead_ownerName, setlead_ownerName] = useState("");
  const [lead_status, setStatus] = useState("");

  const [userList, setuserList] = useState([]);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();
  const [Offset, setOffset] = useState("");

  // Loader Code
  const [loading, setLoading] = useState(false);

  function categoryStatus(e) {
    setStatus(e.target.value);
  }
  function searchCompanyName(e) {
    setcompany_name(e.target.value);
  }
  function searchCustName(e) {
    setcust_name(e.target.value);
  }
  const creation_from_date = {
    onChange: (selectedDates, dateStr, instance) => {
      console.log(dateStr);
      setfrom_date(dateStr);
    },
  };
  const creation_to_date = {
    onChange: (selectedDates, dateStr, instance) => {
      // console.log(dateStr)
      setto_date(dateStr);
    },
  };
  function onchange(e) {
    console.log("e", e);
    setlead_ownerID(e.value);
    setlead_ownerName(e.lable);
  }

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);

  useEffect(() => {
    if (url_lead_name != "") {
      setcust_name(url_lead_name);
    }
    if (url_company != "") {
      setcompany_name(url_company);
    }
    if (url_from_date != "") {
      setfrom_date(url_from_date);
    }
    if (url_to_date != "") {
      setto_date(url_to_date);
    }
    if (url_lead_owner != "") {
      setlead_ownerID(url_lead_owner);
    }
    if (url_status != "") {
      setStatus(url_status);
    }

    refreshPage(
      url_lead_name,
      url_company,
      url_from_date,
      url_to_date,
      url_lead_owner,
      url_status,
      url_page
    );
  }, []);

  function reset_filter() {
    setcompany_name("");
    setcust_name("");
    setfrom_date("");
    setto_date("");
    setlead_ownerID("");
    setlead_ownerName("");
    // setStatus("");
    
    ref.current.value = "";
    // console.log("value", ref.current.value);
    refreshPage("", "", "", "", "", "", 1);
  }

  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(
      cust_name,
      company_name,
      from_date,
      to_date,
      lead_ownerID,
      lead_status,
      pageNumber
    );
  };

  function search_filter() {
    removeErrorParam();
    setSearchParams(params);
    refreshPage(
      cust_name,
      company_name,
      from_date,
      to_date,
      lead_ownerID,
      lead_status,
      1
    );
  }

  function getPages(urls = "") {
    setLoading(true);

    http
      .get(`get_leads_count${urls}`)
      .then((res) => {
        console.log(res.data);
        setPages(res.data.page_no);
        setTotal(res.data.total);
        setOffset(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const refreshPage = (
    lead_name = "",
    company = "",
    from_date = "",
    to_date = "",
    lead_owner = "",
    status = "",
    page_to = ""
  ) => {
    setLoading(true);

    // let page_to_load = page_to != "" ? page_to : currentPage;
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (lead_name) {
      urls += `&lead_name=${lead_name}`;
    }
    if (company) {
      urls += `&company=${company}`;
    }
    if (from_date) {
      urls += `&from_date=${from_date}`;
    }
    if (to_date) {
      urls += `&to_date=${to_date}`;
    }
    if (lead_owner) {
      urls += `&lead_owner=${lead_owner}`;
    }
    if (status) {
      urls += `&status=${status}`;
    }

    console.log("page_to_load", page_to_load);
    setSearchParams(urls);
    getPages(urls);
    http
      .get(`get_leads${urls}`)
      .then((res) => {
        setuserList(res.data);
        setLoading(false);
        // UserComponentVerifiy(res);
      })
      .catch((err) => {
        // toast.error(err.message);
        console.log(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete lead?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    console.log(id);
    setError(null);
    http
      .delete(`delete_customer_by_id/${id}`)
      .then((response) => {
        setDeleteMessage("User has been deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  //   const [error, setError] = useState(null);

  //   const showClauseForm = (id) => {
  //     setUserId(id);

  //     console.log(id);
  //     // setDeleteMessage(`Are you sure you want to delete the vegetable`);
  //     setshowClauseModal(true);
  //   };

  // Hide the modal
  //   const hideClauseModal = () => {
  //     setshowClauseModal(false);
  //   };

  // Post Lead modal

  const [runEditModal, setrunEditModal] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);

  const showEditForm = (editId) => {
    console.log(editId);
    setUserId(editId);
    setrunEditModal(true);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowEditModal(true);
  };

  // Hide the modal
  const hideEditModal = () => {
    setshowEditModal(false);
  };

  const leadStatus = {
    new: [{ class: "badge bg-success-emphasis" }],
    contacted: [{ class: "badge bg-primary" }],
    qualified: [{ class: "badge bg-text-info" }],
    won: [{ class: "badge bg-succes" }],
    lost: [{ class: "badge bg-danger" }],
  };

  // console.log("Lead Status" , leadStatus.new[0]);
  // console.log("Lead Status" , leadStatus[]);

  // const[date , setDate] = useState()
  // function getTodate(e){
  // console.log(moment(e).utc().format("DD/MM/YYYY"));
  // console.log(e);

  // }

  console.log("from_date", from_date);
  console.log("to_date", to_date);

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between ">
                    <h4 className="card-title mb-0">Lead List</h4>
                    <Link
                      // onClick={() => showClauseForm()}
                      to="/lead-management/add-lead"
                      className="btn btn-primary add-btn"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add Lead
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="listjs-table" id="customerList">
                      <div className="row g-4 mb-2">
                        <div className="col-lg-11">
                          <div className="row">
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Name / Email"
                                onChange={(e) => {
                                  searchCustName(e);
                                }}
                                value={cust_name}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Company Name / City"
                                onChange={(e) => {
                                  searchCompanyName(e);
                                }}
                                value={company_name}
                              />
                            </div>
                            <div className="col-md-3">
                              {/* <Flatpickr
                                  className="form-control"
                                  data-date-format="d/m/y"
                                  value={from_date}
                                  options={options}
                                  placeholder="From Date (Creation Date)"
                                /> */}
                              <ReactFlatpickr
                                placeholder="From Date (Creation Date)"
                                options={creation_from_date}
                                value={from_date}
                              />
                            </div>
                            <div className="col-md-3">
                              <ReactFlatpickr
                                placeholder="To Date (Creation Date)"
                                options={creation_to_date}
                                value={to_date}
                              />
                            </div>
                            <div className="col-md-3 mt-3">
                              <UserAdminDropDown
                                placeholder="Search Lead Owner"
                                value={lead_ownerName}
                                change={(e) => onchange(e)}
                                // url_parent_id={url_parent_id}
                                // setCategoryValue={setCategoryValue}
                              />
                            </div>
                            <div className="col-md-3 mt-3">
                              <select
                                class="form-select mb-3"
                                aria-label="Default select example"
                                onChange={(e) => categoryStatus(e)}
                                // defaultValue={url_status}
                                ref={ref}
                              >
                                <option value="">Status</option>
                                <option value="new">New</option>
                                <option value="contacted">Contacted</option>
                                <option value="qualified">Qualified</option>
                                <option value="won">Won</option>
                                <option value="lost">Lost</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1 mt-5 pt-3 d-flex align-items-center">
                          <i
                            className="bx bx-search text-primary fs-2"
                            style={{ cursor: "pointer" }}
                            onClick={search_filter}
                          ></i>
                          <i
                            className="bx bx-reset text-primary fs-2 ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={reset_filter}
                          ></i>
                        </div>
                      </div>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap table-bordered"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th data-sort="customer_name" valign="top">
                                Sr No
                                <br />
                                Lead Manager
                                <br />
                                Status
                              </th>
                              <th data-sort="customer_name" valign="top">
                                Buyer
                                <br />
                                E-mail
                                <br />
                                Mobile
                                <br />
                                Company Name
                              </th>
                              <th data-sort="customer_name" valign="top">
                                City
                                <br />
                                Pincode
                                <br />
                                State
                              </th>
                              <th data-sort="email" valign="top">
                                Creation Date
                              </th>
                              <th data-sort="date" valign="top">
                                Followup Date
                              </th>
                              <th
                                data-sort="action"
                                valign="top"
                                className="text-center"
                              >
                                Action Feedback
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {loading === true && (
                              <tr>
                                <td className="customer_name">
                                  <Loader show={loading} />
                                </td>
                              </tr>
                            )}
                            {loading === false &&
                              (userList.length
                                ? userList.map((user, index) => (
                                    <tr key={index} valign="top">
                                      <td className="customer_name">
                                        {index + 1 + Offset} <br />{" "}
                                        {user.lead_manager}
                                        <br />
                                        {/* <span
                                      className={
                                        // leadStatus.{user.lead_status}[0].class
                                      }
                                    >
                                      {user.lead_status}
                                    </span> */}
                                        <span className="badge bg-success">
                                          {user.lead_status}
                                        </span>
                                      </td>
                                      <td className="customer_name">
                                        {user.name.slice(0, 7)}
                                        <br />
                                        {user.email}
                                        <br />
                                        {user.mobile}
                                        <br />
                                        {user.company_name}
                                      </td>
                                      <td className="customer_name">
                                        {user.city} <br /> {user.pincode} <br />{" "}
                                        {user.state}
                                      </td>
                                      <td className="email">
                                        {moment(user.indate)
                                          .utc()
                                          .format("DD/MM/YYYY")}
                                      </td>
                                      <td className="mobile">
                                        {user.followup_date
                                          ? moment(user.followup_date)
                                              .utc()
                                              .format("DD/MM/YYYY")
                                          : "--"}
                                      </td>
                                      <td>
                                        <div className="d-flex flex-column gap-2 align-items-center">
                                          <div>
                                            {user.lead_status_date
                                              ? moment(user.lead_status_date)
                                                  .utc()
                                                  .format("DD/MM/YYYY")
                                              : "--"}
                                            <br /> {user.feedback}
                                          </div>
                                          <div className="d-flex">
                                            <Link
                                              className="text-primary"
                                              onClick={() => {
                                                showEditForm(user.id);
                                              }}
                                            >
                                              Update Feedback
                                            </Link>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : null)}
                          </tbody>
                        </table>
                      </div>
                      {pages > 1 && (
                        <Paginate
                          postsPerPage={20}
                          totalPosts={total}
                          paginate={paginate}
                          url_page={url_page}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {runEditModal == true ? (
                  <UpdateLead
                    showModal={showEditModal}
                    hideModal={hideEditModal}
                    refreshPage={refreshPage}
                    // type={type}
                    eid={user_id}
                  />
                ) : (
                  " "
                )}
                <DeleteConfirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitDelete}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={deleteMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
