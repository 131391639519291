import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AddUserModal from "../../../../shared/AddUserModal";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import axios from "axios";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";
import moment from "moment";

import CategoryDropdownFilter from "../Category/CategoryDropdownFilter";

import Paginate from "../../../../shared/Paginate";

export const Product = () => {
  const params = new URLSearchParams(window.location.search);
  // const url_page = params.get("page");
  let url_product = params.get("product");
  let url_category = params.get("category");
  let url_brand_model_name = params.get("brand_modal");
  let url_category_id = params.get("category_id");
  let url_status = params.get("status");
  let url_page = params.get("page");
  
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);

  // const [searchItem, setSearchItem] = useState("");
  // const [parent_id_search, setParentCat] = useState("");
  // const [categoryValue, setCategoryValue] = useState([]);
  // const [parent_category, setparent_category] = useState([]);

  /*************FILTERS*******************/
  const [product_name, setProduct_name] = useState("");
  const [brand_model_name, setBrand_Model_name] = useState("");
  const [parent_id_search, setParentCat] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [product_status, setProductStatus] = useState();
  function productStatus(e) {
    setProductStatus(e.target.value);
  }

  function onchange(e) {
    console.log("e", e);
    setParentCat(e.value);
    setCategoryValue(e);
  }
  function searchList(e) {
    e.preventDefault();
    setProduct_name(e.target.value);
  }

  function Brand_Model(e) {
    e.preventDefault();
    setBrand_Model_name(e.target.value);
  }
  const [userList, setuserList] = useState([]);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();

  const [Offset, setOffset] = useState("");

  useEffect(() => {
    if (url_product != "") {
      setProduct_name(url_product);
    }
    if (url_brand_model_name != "") {
      setBrand_Model_name(url_brand_model_name);
    }
    if (url_category_id != "") {
      setParentCat(url_category_id);
    }
    if (url_status != "") {
      setProductStatus(url_status);
    }

    refreshPage(
      url_product,
      url_brand_model_name,
      url_category_id,
      url_status,
      url_page
    );
  }, []);

  let ref = useRef(null);

  function reset_filter() {
    setProductStatus("");
    setBrand_Model_name("");
    setParentCat("");
    setProduct_name("");
    setCategoryValue("");
    ref.current.value = "";
    console.log("value", ref.current.value);
    refreshPage("", "", "", "",  1);
  }

  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(
      product_name,
      brand_model_name,
      parent_id_search,
      product_status,
      pageNumber
    );
  };

  function search_filter() {
    removeErrorParam();
    setSearchParams(params);
    refreshPage(
      product_name,
      brand_model_name,
      parent_id_search,
      product_status,
      1
    );
  }

  function getPages(urls = "") {
    setLoading(true);

    http
      .get(`get_product_page_count${urls}`)
      .then((res) => {
        console.log(res.data);
        setPages(res.data.page_no);
        setTotal(res.data.total_products);
        setOffset(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
console.log(pages);
  const refreshPage = (
    product = "",
    brand_model_name = "",
    category_id = "",
    status = "",
    page_to = ""
  ) => {
    setLoading(true);

    // let page_to_load = page_to != "" ? page_to : currentPage;
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (product) {
      urls += `&product=${product}`;
    }
    if (brand_model_name) {
      urls += `&brand_model=${brand_model_name}`;
    }

    if (category_id) {
      urls += `&category_id=${category_id}`;
    }

    if (status) {
      urls += `&status=${status}`;
    }
    console.log("page_to_load", page_to_load);
    setSearchParams(urls);
    getPages(urls);
    http
      .get(`get_products${urls}`)
      .then((res) => {
        setuserList(res.data);
        setLoading(false);
        // UserComponentVerifiy(res);
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete product ?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_product/${id}`)
      .then((response) => {
        setDeleteMessage("Product has been deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  //   const [error, setError] = useState(null);

  const showClauseForm = (id) => {
    setUserId(id);

    console.log(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowClauseModal(true);
  };

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  const handleEdit = (idd) => {
    setType("Edit");
    setUserId(idd);
    setshowClauseModal(true);
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  const toggleSwitch = (id, columns) => {
    let newCats = [];
    if (columns == "status") {
      newCats = userList.map((user) =>
        user.id === id ? { ...user, status: !user.status } : user
      );
    } else if (columns == "website_display") {
      newCats = userList.map((user) =>
        user.id === id
          ? { ...user, website_display: !user.website_display }
          : user
      );
    } else if (columns == "price_display") {
      newCats = userList.map((user) =>
        user.id === id ? { ...user, price_display: !user.price_display } : user
      );
    }
    setuserList(newCats);
  };

  const catalog_switch = (e, id, columns) => {
    const { name, checked } = e.target;
    let data = {
      clm: columns,
      clm_val: checked === true ? 1 : 0,
      type_id: id,
      type: "product",
    };
    toggleSwitch(id, columns);

    http
      .post(`catalog_switch`, data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* <Header />
      <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex  justify-content-between ">
                      <h4 className="card-title mt-2">Product List</h4>
                      <Link
                        // onClick={() => showClauseForm()}
                        to="/product/add-product"
                        type="button"
                        className="btn btn-primary add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Add
                        Product
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="listjs-table" id="customerList">
                      <div className="row g-4 mb-3">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Product code / Product name"
                                onChange={(e) => {
                                  searchList(e);
                                }}
                                value={product_name}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Brand search / Modal"
                                onChange={(e) => {
                                  Brand_Model(e);
                                }}
                                value={brand_model_name}
                              />
                            </div>

                            <div className="col-md-3">
                              <CategoryDropdownFilter
                                placeholder="Search Category"
                                value={categoryValue}
                                change={(e) => onchange(e)}
                                url_parent_id={url_category_id}
                              />
                            </div>
                            <div className="col-md-2">
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                onChange={(e) => productStatus(e)}
                                defaultValue={url_status}
                                ref={ref}
                              >
                                <option value=""> --Status-- </option>
                                <option value="1">Active</option>
                                <option value="0">In-active</option>
                              </select>
                            </div>
                            <div className="col-md-1 d-flex align-items-center justify-content-end">
                              <i
                                class="bx bx-search text-primary fs-2"
                                style={{ cursor: "pointer" }}
                                onClick={search_filter}
                              ></i>
                              <i
                                class="bx bx-reset text-primary fs-2 ms-2"
                                style={{ cursor: "pointer" }}
                                onClick={reset_filter}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap table-bordered"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th data-sort="customer_name">#</th>
                              <th data-sort="email">
                                Product code <br /> Product name{" "}
                              </th>
                              <th data-sort="date">
                                Brand <br /> Model
                              </th>
                              <th data-sort="date">
                                HSN <br /> Tax-rate
                              </th>
                              <th data-sort="date" style={{ width: "0%" }}>
                                Website display
                              </th>
                              <th data-sort="date" style={{ width: "0%" }}>
                                Price display
                              </th>
                              <th data-sort="status" style={{ width: "0%" }}>
                                Status
                              </th>
                              <th data-sort="date">
                                Created by <br /> Created date
                              </th>
                              <th data-sort="date" className="text-center">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {loading === true && (
                              <tr>
                                <td className="customer_name">
                                  <Loader show={loading} />
                                </td>
                              </tr>
                            )}
                            {loading === false &&
                              (userList.length
                                ? userList.map((user, index) => (
                                    <tr key={index}>
                                      <td className="customer_name">
                                        {Offset + index + 1}
                                      </td>
                                      <td className="customer_name">
                                        {" "}
                                        {user.code} <br />{" "}
                                        {user.product_name.slice(0, 18)}
                                      </td>
                                      <td className="email">
                                        {user.brand} <br /> {user.model}
                                      </td>
                                      <td className="email">
                                        {user.hsn} <br /> {user.tax_rate}
                                      </td>
                                      <td className="customer_name">
                                        <div className="form-check form-switch text-center">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            name={`website_display-${user.id}`}
                                            onChange={(e) =>
                                              catalog_switch(
                                                e,
                                                user.id,
                                                "website_display"
                                              )
                                            }
                                            checked={
                                              user.website_display == 1
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="date">
                                        <div class="form-check form-switch text-center">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            name={`price_display-${user.id}`}
                                            onChange={(e) =>
                                              catalog_switch(
                                                e,
                                                user.id,
                                                "price_display"
                                              )
                                            }
                                            checked={
                                              user.price_display == 1
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="status">
                                        <div class="form-check form-switch text-center">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            name={`status-${user.id}`}
                                            onChange={(e) =>
                                              catalog_switch(
                                                e,
                                                user.id,
                                                "status"
                                              )
                                            }
                                            checked={
                                              user.status == 1 ? true : false
                                            }
                                          />
                                        </div>
                                      </td>

                                      <td className="date">
                                        {user.created_by} <br />
                                        {moment(user.indate)
                                          .utc()
                                          .format("DD/MM/YYYY")}
                                      </td>
                                      <td>
                                        <div className="d-flex gap-2 justify-content-center">
                                          <div className="edit">
                                            <a
                                              className="ri-eye-line fs-4"
                                              href={`https://woodensole.vercel.app/products/${user.slug}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            ></a>
                                          </div>
                                          <div className="edit">
                                            <Link
                                              className=" ri-edit-2-fill fs-4"
                                              to={`/product/edit-product/${user.id}`}
                                            ></Link>
                                          </div>
                                          <div className="remove">
                                            <Link
                                              onClick={() =>
                                                showDeleteModal("User", user.id)
                                              }
                                              className="ri-delete-bin-6-line fs-4"
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteRecordModal"
                                            ></Link>
                                          </div>
                                        </div>
                                      </td>
                                      {/* <td className="status">
                                    <span className="badge bg-success-subtle text-success text-uppercase">
                                      {user.status === 1
                                        ? "Active"
                                        : "In-active"}
                                    </span>
                                  </td> */}

                                      {/* <td>
                                    <div className="d-flex gap-2 justify-content-center">
                                      <div className="edit">
                                        <a
                                          className="ri-eye-line fs-4"
                                          href={`https://woodensole.vercel.app/products/${user.slug}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        ></a>
                                      </div>
                                      <div className="edit">
                                        <Link
                                          className=" ri-edit-2-fill fs-4"
                                          to={`/product/edit-product/${user.id}`}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          onClick={() =>
                                            showDeleteModal("User", user.id)
                                          }
                                          className="ri-delete-bin-6-line fs-4"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td> */}
                                    </tr>
                                  ))
                                : null)}
                          </tbody>
                        </table>
                      </div>
                      {pages > 1 && (
                        <Paginate
                          postsPerPage={20}
                          totalPosts={total}
                          paginate={paginate}
                          url_page={url_page}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <DeleteConfirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitDelete}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={deleteMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
