import React from "react";

import {Link} from 'react-router-dom';


const SignUp = ()=> {
    return (
        <div className="auth-page-wrapper pt-5">
        
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div className="bg-overlay"></div>
        </div>        
        <div className="auth-page-content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card mt-4">
                            <div className="card-body p-4">
                                <div className="text-center mt-2">
                                    <h5 className="text-primary">Create your free account</h5>
                                    <p className="text-muted">No credit card, no commitment, and cancel anytime.</p>
                                </div>
                                <div className="p-2 mt-4">
                                    <form>

                                        <div className="mb-3">
                                            <label for="email" className="form-label">Email</label>
                                            <input type="text" className="form-control" id="email" placeholder="Enter email" />
                                        </div>

                                    <div className="row">
                                        <div className="mb-3  col-md-6">
                                            <label for="fname" className="form-label">First name</label>
                                            <input type="text" className="form-control" id="fname" placeholder="Enter first name" />
                                        </div>

                                        <div className="mb-3  col-md-6">
                                            <label for="lname" className="form-label">Last name</label>
                                            <input type="text" className="form-control" id="lname" placeholder="Enter last name" />
                                        </div>
                                        </div>
                                        <div className="mb-3 ">                                            
                                            <label className="form-label" for="password-input">Phone number (optional)</label>
                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                <input type="number" className="form-control pe-5 password-input" placeholder="Enter phone number" />                        
                                            </div>
                                        </div>
                                        

                                        <div className="mb-3 ">                                            
                                            <label className="form-label" for="password-input">Password (min 6 characters)</label>
                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                <input type="password" className="form-control pe-5 password-input" placeholder="Enter password" id="password-input" />
                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                            </div>
                                        </div>
                                        
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                            <label className="form-check-label" for="auth-remember-check">By checking this box, I agree to receive updates, insights and offers from Allied Boston and its affiliates by email and phone to the above contact information. I understand I can withdraw my consent.</label>
                                        </div>

                                        <div className="mt-4">
                                            <button className="btn btn-success w-100" type="button">Sign In</button>
                                        </div>

                                        {/* <div className="mt-4 text-center">
                                            <div className="signin-other-title">
                                                <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>
                                                <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>
                                                <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>
                                                <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                            
                        </div>
                        

                        <div className="mt-4 text-center">
                            <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Login </Link> </p>
                        </div>

                    </div>
                </div>
                
            </div>
            
        </div>

       
        
    </div>
    );
}
export default SignUp;