import React, { useState, useEffect } from "react";

import Select from "react-select";
import http from "../../../../../utils/http";

const CategoryDropdownFilter = (props) => {
  const opt = [];

  function getIndexOfK(arr, k) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].value == k) {
        return arr[i].label;
      }
    }
  }

  const [options, setoptions] = useState([]);
  let defaultObj = "";
  if (props.url_parent_id) {
    let defaltLabel = getIndexOfK(options, props.url_parent_id);
    defaultObj = { label: defaltLabel, value: props.url_parent_id };
  }

  useEffect(() => {
    http
      .get("get_category_tree")
      .then((res) => {
        let data = res.data;
        data.forEach((val) => {
          opt.push({
            label: val.category_name,
            value: val.id,
          });
        });

        setoptions(opt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <Select
      options={options}
      onChange={props.change}
      placeholder={props.placeholder}
      value={props.value || defaultObj || ""}
      isSearchable={true}
    />
  );
};

export default CategoryDropdownFilter;
