import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../shared/Loader";
import http from "../../../../utils/http";
import moment from "moment";

// import Confirmation from "../../../../shared/Confirmation ";

export const SupplierView = () => {
  const { supplier_id } = useParams();
  const formOptions = {};
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);

  console.log("hi Its loading");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    fetchSupplierbyId(supplier_id);
  }, []);

  const fetchSupplierbyId = (supplier_id) => {
    setLoading(true);
    http
      .get(`get_supplier_by_id/${supplier_id}`)
      .then((res) => {
        console.log(res.data);
        setSupplierList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(supplierList);

  function onSubmit() {}

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div class="card">
                    <div className="card-header d-flex justify-content-between">
                      <h4 className="card-title mt-1">Supplier Details</h4>
                      <button
                      // to="/supplier-management/add-basic-info"
                      type="button"
                      className="btn btn-success add-btn"
                    >
                      Approve
                    </button>
                    </div>
                    <div class="col-lg-12">
                      <div class="card-body p-4">
                        <div class="row g-3">
                          <div class="col-lg-4 col-6">
                            <p class="text-muted mb-2 text-uppercase fw-semibold">
                              Company Name
                            </p>
                            <h5 class="fs-14 mb-0">
                              <span id="invoice-no">
                                {supplierList.company_name}
                              </span>
                            </h5>
                          </div>

                          <div class="col-lg-4 col-6">
                            <p class="text-muted mb-2 text-uppercase fw-semibold">
                              Add on Date
                            </p>
                            <h5 class="fs-14 mb-0">
                              <span id="invoice-date">
                                {moment(supplierList.indate)
                                  .utc()
                                  .format("DD/MM/YYYY")}
                              </span>{" "}
                            </h5>
                          </div>
                          <div class="col-lg-4 col-6">
                            <p class="text-muted mb-1  text-uppercase fw-semibold">
                            Current Status
                            </p>
                            <h5
                              className={`badge  text-uppercase fw-semibold fs-10 ${
                                supplierList.approved === 1
                                  ? "bg-success-subtle text-success"
                                  : "bg-danger-subtle text-danger"
                              }`}
                            >
                              <span id="invoice-date">
                                {supplierList.approved === 1
                                  ? "Approved"
                                  : "Not Approved"}
                              </span>{" "}
                            </h5>
                          </div>
                          {/* <div class="col-lg-4 col-6">
                            <p
                              className={`badge  text-uppercase fw-semibold fs-11 ${supplierList.approved === 1 ? "bg-success-subtle text-success" : "bg-danger-subtle text-danger"}`}
                              id="payment-status"
                            >
                              {supplierList.approved === 1 ? "Approved" : "Not Approved"}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-4 border-top border-top-dashed">
                      <div class="row g-3">
                        <div class="col-4">
                          <h6 class="text-muted text-uppercase fw-semibold mb-3">
                            Basic info
                          </h6>
                          <p class="fw-medium mb-2" id="billing-name">
                            {supplierList.company_name}
                          </p>
                          <p
                            class="text-muted mb-1"
                            id="billing-address-line-1"
                          >
                            Type : {supplierList.company_type}
                          </p>
                          <p class="text-muted mb-1">
                            <span>Address: </span>
                            <span id="billing-phone-no">
                              {supplierList.address}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span>Pincode: </span>
                            <span id="billing-phone-no">
                              {supplierList.pincode}
                            </span>
                          </p>
                          <p class="text-muted mb-0">
                            <span>City: </span>
                            <span id="billing-tax-no">
                              {supplierList.city}
                            </span>{" "}
                          </p>
                          <p class="text-muted mb-0">
                            <span>State: </span>
                            <span id="billing-tax-no">
                              {supplierList.state}
                            </span>{" "}
                          </p>
                          <p class="text-muted mb-0">
                            <span>Country: </span>
                            <span id="billing-tax-no">
                              {supplierList.country}
                            </span>{" "}
                          </p>
                        </div>
                        <div class="col-4">
                          <h6 class="text-muted text-uppercase fw-semibold mb-3">
                            Billing Details
                          </h6>
                          <p class="text-muted mb-1">
                            <span>Address: </span>
                            <span id="shipping-phone-no">
                              {supplierList.billing_city}, <br />{" "}
                              {supplierList.billing_state} -{" "}
                              {supplierList.billing_pincode} , <br />{" "}
                              {supplierList.billing_country}{" "}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span className="text-uppercase fw-semibold">
                              GSTIN:{" "}
                            </span>
                            <span id="shipping-phone-no">
                              {"  "}
                              {supplierList.gst_number}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span className="text-uppercase fw-semibold">
                              PAN:{" "}
                            </span>
                            <span id="shipping-phone-no">
                              {"  "}
                              {supplierList.pan}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span className="text-uppercase fw-semibold">
                              CIN:{" "}
                            </span>
                            <span id="shipping-phone-no">
                              {"  "}
                              {supplierList.cin}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span className="text-uppercase fw-semibold">
                              CININ:{" "}
                            </span>
                            <span id="shipping-phone-no">
                              {"  "}
                              {supplierList.cin_no}
                            </span>
                          </p>
                        </div>
                        <div class="col-4">
                          <h6 class="text-muted text-uppercase fw-semibold mb-3">
                            Contact Details
                          </h6>
                          <p class="fw-medium mb-2" id="shipping-name">
                            {supplierList.contact_name}
                          </p>
                          <p class="text-muted mb-1">
                            <span>Email: </span>
                            <span id="shipping-phone-no">
                              {supplierList.contact_email}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span>Phone: </span>
                            <span id="shipping-phone-no">
                              {supplierList.contact_mobile}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span>WhatsApp No: </span>
                            <span id="shipping-phone-no">
                              {supplierList.whatsapp}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span>Designation: </span>
                            <span id="shipping-phone-no">
                              {supplierList.designation}
                            </span>
                          </p>
                        </div>
                        <div class="col-4 mt-4">
                          <h6 class="text-muted text-uppercase fw-semibold mb-3">
                            Additional Details
                          </h6>
                          <p class="text-muted mb-1">
                            <span>Brand Name: </span>
                            <span id="shipping-phone-no">
                              {supplierList.brand_name}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span>Incorporation Date: </span>
                            <span id="shipping-phone-no">
                              {supplierList.incorporation_date}
                            </span>
                          </p>
                          <p class="text-muted mb-1">
                            <span>Type of Company: </span>
                            <span id="shipping-phone-no">
                              {supplierList.company_type}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  <div className="mb-3 text-end">
                    <Link
                      type="button"
                      className="btn btn-danger me-2 pt-8 pb-8 "
                      to='/supplier-management'
                    >
                      <i className="ri-arrow-left-line align-bottom me-1"></i>Back
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
