import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import http from "../../../../../utils/http";

export const EditCustomerModal = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  (() => {
    http.get("get_customer_by_id/" + id)
      .then((res) => {
        console.log(res);
        setValue("company_name" , res.data.company_name)
        setValue("short_name" , res.data.short_name)
        setValue("f_name", res.data.f_name);
        setValue("l_name", res.data.l_name);
        setValue("email", res.data.email);
        setValue("mobile", res.data.mobile);
        setValue("status", res.data.status);
        setValue("pincode" , res.data.pincode)
      })
      .catch((err) => {
        console.log(err.message);
      });
  })();

  const yupValidation = Yup.object().shape({
    company_name: Yup.string().required("Please company name"),
    short_name: Yup.string().required("Please short name"),
    f_name: Yup.string().required("Please enter name"),
    l_name: Yup.string().required("Please enter name"),
    email: Yup.string().required("Please enter email"),
    // password: Yup.string().required("Please enter password"),
    mobile: Yup.string().required("Please enter mobile no"),
    pincode: Yup.string().required("Please enter pincode"),
    status: Yup.string().required("Please select status"),
  });
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  const [Btnloading, setBtnloading] = useState(false);

  function onSubmit(data) {
    console.log(data);
    setBtnloading(true);
    http
      .put("update_customer/" + id, data)
      .then((response) => {
        console.log(response);
        const timer = setTimeout(() => {
          reset();
          setBtnloading(false);
          refreshPage();
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Customer</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 `}>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Company Name
                </label>
                <input
                  name="company_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Company Name"
                  {...register("company_name")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.company_name ? "d-block" : ""
                  }`}
                >
                  {errors.company_name ? errors.company_name.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Short Name
                </label>
                <input
                  name="short_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Short Name"
                  {...register("short_name")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.short_name ? "d-block" : ""
                  }`}
                >
                  {errors.short_name ? errors.short_name.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  First Name
                </label>
                <input
                  name="f_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter First Name"
                  {...register("f_name")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.f_name ? "d-block" : ""
                  }`}
                >
                  {errors.f_name ? errors.f_name.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="modal-id">
                <label htmlFor="orderId" className="form-label">
                  Last Name
                </label>
                <input
                  name="l_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Last Name"
                  {...register("l_name")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.l_name ? "d-block" : ""
                  }`}
                >
                  {errors.l_name ? errors.l_name.message : ""}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label htmlFor="client_nameName-field" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  required=""
                  {...register("email")}
                />

                <div
                  className={`invalid-feedback ${
                    errors.email ? "d-block" : ""
                  }`}
                >
                  {errors.email ? errors.email.message : ""}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div>
                <label htmlFor="tasksTitle-field" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Enter password"
                  required=""
                  {...register("password")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.password ? "d-block" : ""
                  }`}
                >
                  {errors.password ? errors.password.message : ""}
                </div>
              </div>
            </div> */}
            <div className="col-lg-6">
              <div>
                <label htmlFor="tasksTitle-field" className="form-label">
                  Mobile
                </label>
                <input
                  type="number"
                  id="mobile"
                  name="mobile"
                  className="form-control"
                  placeholder="Enter phone"
                  required=""
                  {...register("mobile")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.mobile ? "d-block" : ""
                  }`}
                >
                  {errors.mobile ? errors.mobile.message : ""}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label htmlFor="tasksTitle-field" className="form-label">
                  Pincode
                </label>
                <input
                  type="text"
                  name="pincode"
                  className="form-control"
                  placeholder="Enter Pincode"
                  required=""
                  {...register("pincode")}
                />
                <div
                  className={`invalid-feedback ${
                    errors.pincode ? "d-block" : ""
                  }`}
                >
                  {errors.pincode ? errors.pincode.message : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label htmlFor="assignedtoName-field" className="form-label">
                  Status
                </label>
                <select
                  name="status"
                  className="form-select"
                  {...register("status")}
                  defaultValue={1}
                >
                  <option value="">Select status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <div
                  className={`invalid-feedback ${
                    errors.status ? "d-block" : ""
                  }`}
                >
                  {errors.status ? errors.status.message : ""}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            disabled={Btnloading}
            onClick={hideModal}
          >
            {Btnloading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
