import React, { useState, useEffect } from 'react'
import Header from '../../../common/Header'
import SideBar from '../../../common/SideBar'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import http from '../../../../utils/http';



export default function UserRoles() {

    const yupValidation = Yup.object().shape({
        name: Yup.string().required('Please add user role'),
    })

    const navigate = useNavigate();
    const formOptions = { resolver: yupResolver(yupValidation) }
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const [loading, setLoading] = useState(false);
    const [resss, setMessage] = useState('');
    const { errors } = formState
    const [checkedItems, setCheckedItems] = useState({});
    const [permissions, setPermissions] = useState([]);

    const [roleMenuList, setRoleMemuList] = useState([])

    const handleCheck = (event) => {
        var permissions_array = [...permissions];
        if (event.target.checked) {
            permissions_array = [...permissions, event.target.value];
        } else {
            permissions_array.splice(permissions.indexOf(event.target.value), 1);
        }
        setPermissions(permissions_array);

    };

    function onSubmit(data) {
        const postData = {
            name: data.name,
            menu: permissions.join(','),
        };
        console.log(postData);
        http.post('save_role', postData)
            .then(response => {
                console.log(response);
                console.log("Post");
                setLoading(false);
                reset()
                navigate('/users/user-roles-list')
                // setbtnToggle(true)
            }).catch(error => {
                setLoading(false);
                setMessage('Something went wrong');
            });
    }


    const getMenuList = () => {

        http.get("get_navigation")
            // fetch("http://192.168.1.222:8080/admin/get_navigation")
            .then((res) => {
                console.log(res.data);
                setRoleMemuList(res.data);

            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    console.log(roleMenuList);

    useEffect(() => {
        getMenuList()
    }, []);



    return (
        <>
            {/* <Header />
            <SideBar /> */}
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">User Roles & Permissions</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row g-4 mb-3">
                                            <div className="col-md-12">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div class="form-group row ms-5 ps-3 ">
                                                        <div className={`row  ${resss !== "" && ""}`}>
                                                                <div class="row mb-3">
                                                                    <div class="col-lg-2">
                                                                        <label for="nameInput" class="form-label">Admin Role</label>
                                                                    </div>
                                                                    <div class="col-lg-10">
                                                                        <input type="text"  name="name" class="form-control" id="nameInput" placeholder="User Roles" {...register('name')}/>
                                                                        <div className={`invalid-feedback ${errors.name ? 'd-block' : ''}`}>{errors.name ? errors.name.message : ''}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-lg-2">
                                                                    <label for="nameInput" class="form-label mt-3">Permission</label>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                <div className="table-responsive table-card mt-3 mb-1 h-75" style={{ width: "97%", marginLeft: "0.1rem" }}>
                                                                    <table
                                                                        className="table align-middle table-nowrap mb-0 table-bordered">
                                                                        <thead className="table-light">
                                                                            <tr>
                                                                                <th scope="col">
                                                                                    Menu
                                                                                </th>
                                                                                <th data-sort="customer_name">
                                                                                    Access
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="list form-check-all">
                                                                            {roleMenuList.map((roles, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{roles.name}</td>
                                                                                    <td scope="row">
                                                                                        <div className="form-check">
                                                                                            <input class="form-check-input" name={"permission"} type="checkbox" value={roles.id} id="flexCheckDefault" onChange={handleCheck} />
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="hstack gap-2 justify-content-end ">
                                                        {/* <Link to="/users/user-roles-list"><button type="submit" className="btn btn-danger">Submit</button></Link> */}
                                                        <button type="submit" className="btn btn-danger">Submit</button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
