import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// import axios from "axios";
import http from "../../../../../utils/http";
import moment from "moment";

const ProductReviewModal = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
}) => {
  console.log(id);
  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string()
      .required("Please enter email")
      .email("Please enter valid email"),
    role_id: Yup.string().required("Please select role"),
    password: Yup.string().required("Please enter password"),
    mobile: Yup.string().required("Please enter number"),
    status: Yup.string().required("Please enter number"),
  });
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, formState, setValue } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;

  const [reviewData, setreviewData] = useState([]);

  function getReviewProduct() {
    http
      .get(`get_review_by_id/${id}`)
      .then((res) => {
        // console.log(res);
        setreviewData(res.data[0]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function updateReviewProduct() {
    http
      .get(`get_review_by_id/${id}`)
      .then((res) => {
        // console.log(res);
        setToggleBtn(true)
        setreviewData(res.data[0]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function onSubmit(data) {}

  function switchPage() {

    console.log("Clicked");
    setToggleBtn(false)
    refreshPage()
    hideModal()
  }

  function approvedReview() {
    setLoading(true);
    http
      .get(`approve_review/${id}`)
      .then((response) => {
        console.log(response);
        setLoading(false);
        // hideModal();
        // refreshPage();
        updateReviewProduct();
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  useEffect(() => {
    getReviewProduct();
  }, [id]);

  console.log(reviewData);
  console.log(reviewData.image);

  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Product Review</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`alert alert-success ${resss === "" && "d-none"}`}>
            {resss}
          </div>
          <div className={`row g-3 ${resss !== "" && "d-none"}`}>
            <div
              class="tab-pane fade show active"
              id="nav-speci"
              role="tabpanel"
              aria-labelledby="nav-speci-tab"
            >
              <div class="table-responsive">
                <table class="table mb-0">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>
                        Customer Name
                      </th>
                      <td>{reviewData.customer_name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email</th>
                      <td>{reviewData.customer_email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Product</th>
                      <td>
                        {reviewData.product_name === undefined
                          ? "No"
                          : reviewData.product_name.slice(0, 20)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Rating</th>
                      <td>
                        {
                          <div class="badge rounded-pill bg-success mb-0 ">
                            <i class="mdi mdi-star"></i>
                            {reviewData.rating}.0
                          </div>
                        }
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Description</th>
                      <td>
                        {reviewData.description === undefined
                          ? "No"
                          : reviewData.description.slice(0, 10)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Image</th>
                      <td className="customer_name">
                        <img
                          src={reviewData.image}
                          alt=""
                          style={{ height: "70px" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {reviewData.approved === 1 ? (
                <div class="float-end mt-3">
                  <h6
                    class="fw-medium mb-2 text-success fw-bold"
                    id="shipping-name"
                  >
                    Review Approved
                  </h6>
                  <h6 class="mb-1">
                    <span>Approved on</span>
                    <span id="shipping-phone-no">
                      :{" "}
                      {moment(reviewData.approved_date)
                        .utc()
                        .format("DD/MM/YYYY")}
                    </span>
                  </h6>
                  <h6 class="mb-1">
                    <span>Approved by</span>
                    <span id="shipping-phone-no">
                      : {reviewData.approved_by}
                    </span>
                  </h6>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={`${resss !== "" && "d-none"}`}>
          <input type="hidden" name="standard_id" value={id} />
          {toggleBtn === true ? (
            <Button variant="danger" onClick={switchPage}>
            Cancel 
          </Button>
          ) : (
            <Button variant="danger" onClick={hideModal}>
              Cancel
            </Button>
          )}
          {reviewData.approved === 1 ? (
            ""
          ) : (
            <Button variant="success" type="submit" onClick={approvedReview}>
              {loading ? "Loading..." : "Approve Review"}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ProductReviewModal;
