import React, { useState, useEffect } from "react";
import { Link , useParams } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";
import moment from "moment";


export const RFQDetailsPageList = () => {
  let {rfqId} = useParams()
  console.log(rfqId);
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  const [purchase, setPurchase] = useState([]);
  const [rfqItems, setrfqItems] = useState([]);

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    setLoading(true);
    http
      .get(`get_rfq_by_id/${rfqId}`)
      .then((res) => {
        setPurchase(res.data);
        console.log(res.data.rfq_items);
        setrfqItems(res.data.rfq_items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  console.log(rfqItems);

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);
  const showDeleteModal = (id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete SEO ?`);
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    http
      .delete(`delete_seo_by_id/${id}`)
      .then((response) => {
        toast.success("SEO deleted");
        setDeleteMessage("User has been deleted");
        fetchdata();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   const [error, setError] = useState(null);

  const showClauseForm = (id) => {
    setUserId(id);

    console.log(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowClauseModal(true);
  };

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  const handleEdit = (idd) => {
    setType("Edit");
    setUserId(idd);
    setshowClauseModal(true);
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row g-4 ">
                        <div className="col-md-10">
                          <h4 className="card-title mt-2">
                           RFQ : {purchase.request_no} 
                          </h4>
                        </div>
                        <div className="col-md-2">
                        <Link to={"/rfq/rfq-list"} type="button" className="btn btn-primary add-btn">Back to list</Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6 col-6 ">
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="flex-grow-1 mb-0">Buyer:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="text-muted mb-0">{purchase.name}</h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="flex-grow-1 mb-0">Company Name:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="text-muted mb-0">{purchase.company_name}</h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="flex-grow-1 mb-0">Mobile Number:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="text-muted mb-0">{purchase.mobile}</h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="flex-grow-1 mb-0">RFQ Date:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="text-muted mb-0">{moment(purchase.rfq_date )
                                        .utc()
                                        .format("DD/MM/YYYY")}</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 ">
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="flex-grow-1 mb-0">Location:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="text-muted mb-0">{purchase.city} ,{purchase.state} ,{purchase.pincode}</h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="flex-grow-1 mb-0">Email id:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="text-muted mb-0">{purchase.email}</h6>
                            </div>
                          </div>
                          {/* <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="flex-grow-1 mb-0">Reference:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="text-muted mb-0">#VLZ124561278124</h6>
                            </div>
                          </div> */}
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="flex-grow-1 mb-0">Offer Status:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="text-muted mb-0">{purchase.offer_status}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Enter name"
                                  // onChange={(e) => {
                                  //   searchList(e);
                                  // }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-2 d-flex align-items-center justify-content-end">
                            <i
                              className="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              // onClick={handleSearchButtonClick}
                            ></i>
                            <i
                              className="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div> */}
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr >
                                <th data-sort="customer_name" valign="top">
                                  Sr.No.
                                </th>
                                <th
                                  data-sort="email"
                                  valign="top"
                                  style={{ width: "11%" }}
                                >
                                  Product Name
                                </th>
                                <th
                                  data-sort="email"
                                  valign="top"
                                  style={{ width: "11%" }}
                                >
                                  Make
                                </th>
                                <th data-sort="date" valign="top">
                                  Quantity
                                </th>
                                <th data-sort="status" valign="top">
                                  MRP
                                </th>
                                <th data-sort="status" valign="top">
                                  Tax
                                </th>
                                <th data-sort="status" valign="top">
                                  Sales Price
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {rfqItems.map((item, index) => (
                              <tr key={index}>
                                <td className="customer_name" valign="top">
                                  {index + 1}
                                </td>
                                <td className="email" valign="top">
                                  {item.product_name}
                                </td>
                                <td className="email" valign="top">
                                  {item.make}
                                </td>
                                <td className="email" valign="top">
                                  {item.quantity}
                                </td>
                                <td className="email" valign="top">
                                  {item.mrp}
                                </td>
                                <td className="email" valign="top">
                                  -
                                </td>
                                <td className="email" valign="top">
                                 {item.seller_price}
                                </td>
                              </tr>
                               ))}  
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
