import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import http from "../../../../../utils/http";

export const CustomerAddressModal = ({
  showModal,
  hideModal,
  refreshPage,
  id,
  type,
  data,
  setRefresh,
  setTempAddressId,
  temp_billing_id,
  setGetTempBillingId,
  setTempBillingId,
  setGetTempDelivaryId,
}) => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const pincodeRegExp = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
  const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please company name"),
    email: Yup.string().required("Please company email").matches(emailRegExp , "email is not valid"),
    mobile: Yup.string().required("Please company mobile").matches(phoneRegExp, 'mobile number is not valid').length(10),
    address: Yup.string().required("Please company address"),
    pincode: Yup.string().required("Please company pincode").matches(pincodeRegExp, 'pincode is not valid').length(6),
    city: Yup.string().required("Please company city"),
    state: Yup.string().required("Please company state"),
    country: Yup.string().required("Please company country"),
  });
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;
  const [pinStates, setpinStates] = useState([]);
  const [pinCitys, setpinCitys] = useState([]);

  useEffect(() => {
    getpincodeStateData();
  }, []);

  const getpincodeStateData = () => {
    http
      .get(`getAllCities`)
      .then((res) => {
        setpinStates(res.data);
        setpinCitys(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  console.log("Modal id", id);
  function onSubmit(data) {
    setValue("user_id", id);
    console.log("data", data);
    setLoading(true);
    http
      .post("save_customer_address", data)
      .then((response) => {
        console.log("temadi", response.data.data.id);
        if (temp_billing_id == true) {
          setGetTempBillingId(response.data.data.id);
          setTempBillingId(false);
        } else {
          setGetTempDelivaryId(response.data.data.id);
        }
        reset();
        hideModal();
        setreadOnlyData(false);
        setLoading(false);
        refreshPage();
        setRefresh(true);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong");
      });
  }

  const [readOnlyData, setreadOnlyData] = useState(false);
  const getPincodeValue = (e) => {
    setAddressDetails(e.target.value);
  };
  const setAddressDetails = (data) => {
    http
      .get(`getDataFromPincode/${data}`)
      .then((res) => {
        console.log("State_code", res.data.state);
        if (res.data) {
          setreadOnlyData(true);
          setValue("city", res.data.city);
          setValue("state", res.data.state);
          setValue("country", res.data.country);
          setValue("state_code", res.data.state_code);
          // setSelectedStateCode(data.state_code)
        }
        console.log(res.data.state_code);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Customer Address</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col-lg-4">
              <label className="form-label" htmlFor="project-title-input">
                Customer Name
              </label>
              <input
                className="form-control"
                name="name"
                placeholder="Enter Customer Name"
                {...register("name")}
              />
              <div
                className={`invalid-feedback ${errors.name ? "d-block" : ""}`}
              >
                {errors.name ? errors.name.message : ""}
              </div>
            </div>
            <div className="col-lg-4">
              <label className="form-label" htmlFor="project-title-input">
                Email
              </label>
              <input
                className="form-control"
                name="email"
                placeholder="Enter Email "
                {...register("email")}
              />
              <div
                className={`invalid-feedback ${errors.email ? "d-block" : ""}`}
              >
                {errors.email ? errors.email.message : ""}
              </div>
            </div>
            <div className="col-lg-4">
              <label className="form-label" htmlFor="project-title-input">
                Mobile
              </label>
              <input
                className="form-control"
                name="mobile"
                placeholder="Enter Mobile no"
                {...register("mobile")}
              />
              <div
                className={`invalid-feedback ${errors.mobile ? "d-block" : ""}`}
              >
                {errors.mobile ? errors.mobile.message : ""}
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-8 ">
              <label className="form-label" htmlFor="project-title-input">
                Address
              </label>
              <input
                className="form-control"
                name="address"
                placeholder="Enter Address "
                {...register("address")}
              />
              <div
                className={`invalid-feedback ${
                  errors.address ? "d-block" : ""
                }`}
              >
                {errors.address ? errors.address.message : ""}
              </div>
            </div>
            <div className="col-lg-4">
              <label className="form-label" htmlFor="project-title-input">
                Pincode
              </label>
              <input
                className="form-control"
                name="pincode"
                placeholder="Enter Pincode "
                {...register("pincode")}
                onChange={(e) => getPincodeValue(e)}
              />
              <div
                className={`invalid-feedback ${
                  errors.pincode ? "d-block" : ""
                }`}
              >
                {errors.pincode ? errors.pincode.message : ""}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4">
              <label className="form-label" htmlFor="project-title-input">
                City
              </label>
              <select
                className="form-control form-select"
                name="city"
                placeholder="Enter City "
                {...register("city")}
                disabled={readOnlyData}
              >
                <option value="">Select</option>
                {pinCitys.length
                  ? [...new Set(pinCitys.map((item) => item.city))].map(
                      (city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      )
                    )
                  : " "}
              </select>
              <div
                className={`invalid-feedback ${errors.city ? "d-block" : ""}`}
              >
                {errors.city ? errors.city.message : ""}
              </div>
            </div>
            <div className="col-lg-4">
              <label className="form-label" htmlFor="project-title-input">
                State
              </label>
              <select
                className="form-control form-select"
                name="state"
                placeholder="Enter State "
                {...register("state")}
                disabled={readOnlyData}
              >
                <option value="">Select</option>

                {pinStates.length
                  ? [...new Set(pinStates.map((item) => item.state))].map(
                      (state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      )
                    )
                  : " "}
              </select>
              <div
                className={`invalid-feedback ${errors.state ? "d-block" : ""}`}
              >
                {errors.state ? errors.state.message : ""}
              </div>
            </div>
            <div className="col-lg-4">
              <label className="form-label" htmlFor="project-title-input">
                Country
              </label>
              <input
                className="form-control"
                name="country"
                placeholder="Enter Country "
                {...register("country")}
                disabled={readOnlyData}
              />
              <div
                className={`invalid-feedback ${
                  errors.country ? "d-block" : ""
                }`}
              >
                {errors.country ? errors.country.message : ""}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={id} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit">
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
