import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const SupplierNavs = ({ supplier_id }) => {
    console.log('supss:' + supplier_id);

    return (
        <>
            <div className="card-header">
                <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    {supplier_id ? (
                        <>
                            <li className="nav-item mt-1 " role="presentation">
                                <NavLink to={`/supplier-management/add-basic-info/${supplier_id}`} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link ")}>Basic Info</NavLink>
                            </li>
                            <li className="nav-item mt-1 " role="presentation">
                                <NavLink to={`/supplier-management/add-billing-details/${supplier_id}`} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link ")}>Billing Details</NavLink>
                            </li>
                            <li className="nav-item mt-1 " role="presentation">
                                <NavLink to={`/supplier-management/add-contact-details/${supplier_id}`} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link   ")}>Contact Details</NavLink>
                            </li>
                            <li className="nav-item mt-1 " role="presentation">
                                <NavLink to={`/supplier-management/add-additional-details/${supplier_id}`} className={({ isActive }) => (isActive ? "nav-link active " : "nav-link  ")}>Additional Details</NavLink>
                            </li>
                        </>
                    ) : (
                        <>
                       
                            <li className="nav-item mt-1 " role="presentation">
                                <NavLink to="/supplier-management/add-basic-info" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link ")}>Basic Info</NavLink>
                            </li>
                            <li className="nav-item mt-1 " role="presentation">
                                <NavLink to="/supplier-management/add-billing-details" className={({ isActive }) => (isActive ? "nav-link " : "nav-link disabled")}>Billing Details</NavLink>
                            </li>
                            <li className="nav-item mt-1 " role="presentation">
                                <NavLink to="/supplier-management/add-contact-details" className={({ isActive }) => (isActive ? "nav-link " : "nav-link disabled")}>Contact Details</NavLink>
                            </li>
                            <li className="nav-item mt-1 " role="presentation">
                                <NavLink to="/supplier-management/add-additional-details" className={({ isActive }) => (isActive ? "nav-link  " : "nav-link disabled")}>Additional Details</NavLink>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </>
    )
}

export default SupplierNavs;
        