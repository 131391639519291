import React, { useEffect, useState } from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";

export const PricingForm = () => {
    const yupValidation = Yup.object().shape({
        product_id: Yup.number().typeError('Amount must be a number').required("Please select product name"),
        supplier: Yup.number().typeError('Amount must be a number').required("Please select supplier"),
        discount: Yup.number().typeError('Amount must be a number').required("Please enter discount"),
        moq: Yup.number().typeError('Amount must be a number').required("Please enter MOQ"),
        tax_rate: Yup.number().typeError('Amount must be a number').required("Please enter Text Rate"),
        mrp: Yup.number().typeError('Amount must be a number').required("Please enter price"),
        purchase_price: Yup.number().typeError('Amount must be a number').required("Please enter purchase price"),
        status: Yup.number().typeError('Amount must be a number').required("Please enter select status"),
    });

    const formOptions = { resolver: yupResolver(yupValidation) };
    const {
        register,
        setValue,
        resetField,
        getValues,
        handleSubmit,
        reset,
        formState,
    } = useForm(formOptions);
    const { errors } = formState;


    const navigate = useNavigate();
    console.log("hi Its loading");
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [inputs, setInputs] = useState([]);
    const [selectedId, setselectedId] = useState('');
    const [productList, setproductList] = useState([])
    const [supplierList, setsupplierList] = useState([])

    const [mrpValue, setMrpValue] = useState(null)
    const [purchasePrice, setPurchasePrice] = useState('')
    const [discountPercentage, setDiscountPercentage] = useState('')

    useEffect(() => {
        fetchproducts()
        fetchsupplier()
    }, [])

    const fetchproducts = () => {
        http
            .get("get_products")
            .then((res) => {
                console.log(res.data);
                setproductList(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const fetchsupplier = () => {
        http
            .get("get_supplier")
            .then((res) => {
                console.log(res.data);
                setsupplierList(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    function getProductsItems(selectedId) {
        console.log(selectedId);
        http
            .get(`get_product_by_id/${selectedId}`)
            .then((res) => {
                console.log(res.data);
                // setsupplierList(res.data);
                setMrpValue(res.data.price)
                setValue('moq', res.data.moq)
                setValue('tax_rate', res.data.gst)
                setValue('mrp', res.data.price)
                setValue('purchase_price', res.data.sale_price)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }


    function handleProductItem(event) {
        const selectedId = event.target.value
        setselectedId(selectedId);
        getProductsItems(selectedId)

    }

    const handlePurchasePriceChange = (event) => {
        const newPurchasePrice = event.target.value;
        setPurchasePrice(newPurchasePrice);

        // Calculate discount percentage
        const calculatedDiscount = ((mrpValue - newPurchasePrice) / mrpValue) * 100;
        console.log("discount Per", calculatedDiscount.toFixed(2));
        setValue("discount", calculatedDiscount.toFixed(2))
        setDiscountPercentage(calculatedDiscount.toFixed(2));
    };

    const handleDiscountPercentageChange = (event) => {
        const newDiscountPercentage = event.target.value;
        setDiscountPercentage(newDiscountPercentage);

        // Calculate purchase price
        const calculatedPurchasePrice = mrpValue - (mrpValue * newDiscountPercentage) / 100;
        setPurchasePrice(calculatedPurchasePrice.toFixed(2));
        setValue('purchase_price', calculatedPurchasePrice.toFixed(2))
    };

    function onSubmit(data) {
        setLoading(true);
        console.log(data);
        http
            // .post(process.env.REACT_APP_API_BASE + `save_product_supplier_mapping`, data)
            .post(`save_product_supplier_mapping`, data)
            .then((response) => {
                toast.success("Pricing has been added");
                const timer = setTimeout(() => {
                    setLoading(false);
                    navigate("/pricing");
                }, 2000);
                return () => clearTimeout(timer);
            })
            .catch((error) => {
                setLoading(false);
                setMessage("Something went wrong");
            });
    }

    const handleDropDown = (d, e) => {
        let fvalues = [];
        e.map((n, index) => {
            fvalues.push(n.value);
        });
        let textsss = fvalues.join();
        setValue(d, textsss, { shouldValidate: true });
    };

    return (
        <>
            <div className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message === '' && "d-none"}`}>{message}</div>
            {/* <Header />
            <SideBar /> */}

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                    <Toaster position="top-center" />
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title mb-0">Product Pricing List</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row mb-3">
                                        <div className="col-lg-3">
                                            <label className="form-label" htmlFor="project-title-input">
                                                Product Name
                                            </label>
                                            <select

                                                className="form-select"
                                                name="product_id"
                                                {...register("product_id")}
                                                onChange={(e) => handleProductItem(e)} value={selectedId}
                                            >
                                                <option value="">Select Product</option>
                                                {productList.map((product, index) => {
                                                    return <option value={product.id}>{product.product_name}</option>
                                                })}
                                            </select>
                                            <div
                                                className={`invalid-feedback ${errors.product_id ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.product_id
                                                    ? errors.product_id.message
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label" htmlFor="project-title-input">
                                                Supplier
                                            </label>
                                            <select
                                                className="form-select"
                                                name="supplier"
                                                {...register("supplier")}
                                            >
                                                <option value="">Select Supplier</option>
                                                {supplierList.map((supplier, index) => {
                                                    return <option value={supplier.id}>{supplier.company_name}</option>
                                                })}

                                            </select>

                                            <div
                                                className={`invalid-feedback ${errors.supplier ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.supplier
                                                    ? errors.supplier.message
                                                    : ""}
                                            </div>
                                        </div>

                                        <div className="col-lg-3">
                                            <label
                                                className="form-label"
                                                htmlFor="project-title-input"
                                            >
                                                MOQ
                                            </label>
                                            <input
                                                className="form-control"
                                                name="moq"
                                                placeholder="Enter MOQ"
                                                {...register("moq")}
                                            />
                                            <div
                                                className={`invalid-feedback ${errors.moq ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.moq
                                                    ? errors.moq.message
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <label
                                                className="form-label"
                                                htmlFor="project-title-input"
                                            >
                                                Tax Rate(%)
                                            </label>
                                            <input
                                                className="form-control"
                                                name="tax_rate"
                                                placeholder="Enter Tax Rate(%)"
                                                {...register("tax_rate")}
                                                disabled
                                            />
                                            <div
                                                className={`invalid-feedback ${errors.tax_rate ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.tax_rate
                                                    ? errors.tax_rate.message
                                                    : ""}
                                            </div>
                                        </div>


                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-3">
                                            <label
                                                className="form-label"
                                                htmlFor="project-title-input"
                                            >
                                                MRP
                                            </label>
                                            <input
                                                className="form-control"
                                                name="mrp"
                                                placeholder="Enter  MRP"
                                                {...register("mrp")}
                                                disabled
                                            />
                                            <div
                                                className={`invalid-feedback ${errors.mrp ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.mrp
                                                    ? errors.mrp.message
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <label
                                                className="form-label"
                                                htmlFor="project-title-input"
                                            >
                                                Purchase Price
                                            </label>
                                            <input
                                                className="form-control"
                                                name="purchase_price"
                                                placeholder="Enter Purchase Price"
                                                {...register("purchase_price")}
                                                onChange={(e) => handlePurchasePriceChange(e)}
                                            />
                                            <div
                                                className={`invalid-feedback ${errors.purchase_price ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.purchase_price
                                                    ? errors.purchase_price.message
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <label
                                                className="form-label"
                                                htmlFor="project-title-input"
                                            >
                                                Discount (%)
                                            </label>
                                            <input
                                                className="form-control"
                                                name="discount"
                                                placeholder="Enter Discount"
                                                {...register("discount")}
                                                onChange={(e) => handleDiscountPercentageChange(e)}
                                            />
                                            <div
                                                className={`invalid-feedback ${errors.discount ? "d-block" : ""
                                                    }`}
                                            >
                                                {errors.discount
                                                    ? errors.discount.message
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div>
                                                <label for="assignedtoName-field" className="form-label">Status</label>
                                                <select
                                                    name="status"
                                                    className="form-select"
                                                    {...register('status')}
                                                    defaultValue={"1"}
                                                >
                                                    <option value=""> --status-- </option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                                <div className={`invalid-feedback ${errors.status ? 'd-block' : ''}`}>{errors.status ? errors.status.message : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4 text-end">
                                        <button type="submit" className="btn btn-danger w-sm" disabled={loading}>
                                            {loading ? 'Loading...' : 'Save Pricing'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}
