import React, { useState, useEffect, useRef  } from "react";
import { Link ,useSearchParams} from "react-router-dom";
import http from "../../../../../utils/http";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import Loader from "../../../../shared/Loader";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import CustomerDropdown from "../Customer/CustomerDropdown";
import CategoryProductDropDown from "../Product/CategoryProductDropDown";
import UserAdminDropDown from "../../User/UserAdminDropDown";
import ReactFlatpickr from "../../../../shared/ReactFlatpickr";
import Paginate from "../../../../shared/Paginate";

export const TicketList = () => {
  const params = new URLSearchParams(window.location.search);

  let url_order_no = params.get("order_no");
  let url_customer_id = params.get("customer_id");
  let url_ticket = params.get("ticket");
  let url_product_id = params.get("product_id");
  let url_issue = params.get("issue");
  let url_assigned_to = params.get("assigned_to");
  let url_from_date = params.get("from_date");
  let url_to_date = params.get("to_date");
  let url_status = params.get("status");
  let url_page = params.get("page");

  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState("");
  const [resss, setMessage] = useState("");
  const [error, setError] = useState(null);

  //   const [type, setType] = useState(null);
  //   const [id, setId] = useState(null);

  /*************FILTERS*******************/
  const [Order_no, setOrder_no] = useState("");
  const [Ticket_no, setTicket_no] = useState("");
  const [Customer_value, setCustomer_value] = useState([]);
    const [Customer_id, setCustomer_id] = useState("");
  const [Product_value, setProduct_value] = useState([]);
    const [Product_id, setProduct_id] = useState("");
  const [Assign_value, setAssign_value] = useState([]);
    const [Assign_id, setAssign_id] = useState("");
    const [from_date, setfrom_date] = useState("");
    const [to_date, setto_date] = useState("");
    const [issuetype, setIssueType] = useState("");
    const [product_status, setProductStatus] = useState();


    const OrdNoChange = (e) => {
      console.log(e.target.value);
      setOrder_no(e.target.value);
    };
    const handle_Pro_DropDown = (e) => {
      setProduct_value(e);
      setProduct_id(e.value);
    };
  
    const AssignChange = (e) => {
      console.log(e);
      setAssign_value(e);
      setAssign_id(e.value);
      
    };
    const CustonChange = (e) => {
      console.log(e);
      setCustomer_value(e);
      setCustomer_id(e.value);
      
    };
  
    function TicketNoChange(e){
      setTicket_no(e.target.value)
    }
  
    
    function selectIssue(e){
      setIssueType(e.target.value)
    }
  
    const creation_from_date = {
      onChange: (selectedDates, dateStr, instance) => {
        // console.log(dateStr)
        setfrom_date(dateStr);
      },
    };
    const creation_to_date = {
      onChange: (selectedDates, dateStr, instance) => {
        // console.log(dateStr)
        setto_date(dateStr);
      },
    };
    function productStatus(e) {
      setProductStatus(e.target.value);
    }



  const [userList, setuserList] = useState([]);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();

  const [Offset, setOffset] = useState("");

  useEffect(() => {
    if (url_order_no != "") {
      setOrder_no(url_order_no);
    }
    if (url_customer_id != "") {
      setCustomer_id(url_customer_id);
    }
    if (url_ticket != "") {
      setTicket_no(url_ticket);
    }
    if (url_product_id != "") {
      setProduct_id(url_product_id);
    }
    if (url_issue != "") {
      setIssueType(url_issue);
    }
    if (url_assigned_to != "") {
      setAssign_id(url_assigned_to);
    }
    if (url_from_date != "") {
      setfrom_date(url_from_date);
    }
    if (url_to_date != "") {
      setto_date(url_to_date);
    }
    if (url_status != "") {
      setProductStatus(url_status);
    }

    refreshPage(
      url_order_no,
      url_customer_id,
      url_ticket,
      url_product_id,
      url_issue,
      url_assigned_to,
      url_from_date,
      url_to_date,
      url_status,
      url_page
    );
  }, []);

  let ref = useRef(null);
  function reset_filter() {
    setOrder_no("")
    setCustomer_id("")
    setCustomer_value("")
    setTicket_no("")
    setProduct_id("")
    setProduct_value("")
    setIssueType("")
    setAssign_id("")
    setAssign_value("")
    setfrom_date("")
    setto_date("")
    setProductStatus("")
    ref.current.value = "";
    console.log("value", ref.current.value);
    refreshPage("", "", "", "","", "", "", "","",  1);
  }

  const removeErrorParam = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber) => {
    refreshPage(
      Order_no,
      Customer_id,
      Ticket_no,
      Product_id,
      Assign_id,
      from_date,
      to_date,
      issuetype,
      product_status,
      pageNumber
    );
  };

  function search_filter() {
    removeErrorParam();
    setSearchParams(params);
    refreshPage(
      Order_no,
      Customer_id,
      Ticket_no,
      Product_id,
      issuetype,
      Assign_id,
      from_date,
      to_date,
      product_status,
      1
    );
  }

  function getPages(urls = "") {
    setLoading(true);

    http
      .get(`get_escalation_count${urls}`)
      .then((res) => {
        console.log(res.data);
        setPages(res.data.page_no);
        setTotal(res.data.total);
        setOffset(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const refreshPage = (
    order_no = "",
    customer_id = "",
    ticket = "",
    product_id = "",
    issue="",
    assigned_to="",
    from_date="",
    to_date="",
    status = "",
    page_to = ""
  ) => {
    setLoading(true);

    // let page_to_load = page_to != "" ? page_to : currentPage;
    let page_to_load = (page_to = "" || page_to != null ? page_to : "1");
    let urls = "";
    if (page_to_load) {
      urls += `?page=${page_to_load}`;
    }
    if (order_no) {
      urls += `&order_no=${order_no}`;
    }
    if (customer_id) {
      urls += `&customer_id=${customer_id}`;
    }

    if (ticket) {
      urls += `&ticket=${ticket}`;
    }
    if (product_id) {
      urls += `&product_id=${product_id}`;
    }
    if (issue) {
      urls += `&issue=${issue}`;
    }
    if (assigned_to) {
      urls += `&assigned_to=${assigned_to}`;
    }
    if (from_date) {
      urls += `&from_date=${from_date}`;
    }
    if (to_date) {
      urls += `&to_date=${to_date}`;
    }
    if (status) {
      urls += `&status=${status}`;
    }
    console.log("page_to_load", page_to_load);
    setSearchParams(urls);
    getPages(urls);
    http
      .get(`get_escalation_list${urls}`)
      .then((res) => {
        setuserList(res.data);
        setLoading(false);
        // UserComponentVerifiy(res);
      })
      .catch((err) => {
        // toast.error(err.message);
        console.log(err.message);
      });
  };

  

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete lead?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    console.log(id);
    setError(null);
    http
      .delete(`delete_customer_by_id/${id}`)
      .then((response) => {
        setDeleteMessage("User has been deleted");
        refreshPage();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  //   const [error, setError] = useState(null);

  // Add banner modal

  const [showAddModal, setshowAddModal] = useState(false);

  const showClauseForm = (id) => {
    setUserId(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowAddModal(true);
  };

  // Hide the modal
  const hideAddModal = () => {
    setshowAddModal(false);
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  // Loader code end

  

 
 
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex  justify-content-between">
                        <h4 className="card-title mt-2">
                          Escalation Ticket List
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-md-11">
                            <div className="row">
                              <div className="col-md-3 mb-3">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Order No"
                                  value={Order_no}
                                onChange={(e) => OrdNoChange(e)}
                                />
                              </div>
                              <div className="col-md-3 mb-3">
                                <CustomerDropdown
                                  placeholder="Customer"
                                  value={Customer_value}
                                  change={(e) => CustonChange(e)}
                                  ref={ref}
                                />
                              </div>
                              <div className="col-md-3 mb-3">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Ticket Code"
                                  value={Ticket_no}
                                onChange={(e) => TicketNoChange(e)}
                                />
                              </div>
                              <div className="col-md-3">
                                <CategoryProductDropDown
                                  type="industry_sector"
                                  placeholder="Product"
                                  value={Product_value}
                                  change={(e) => handle_Pro_DropDown(e)}
                                  ref={ref}
                                />
                              </div>
                              <div className="col-md-3">
                                <select
                                  className="form-select "
                                  name="issue_type"
                                  value={issuetype}
                                  onChange={(e) => selectIssue(e)}
                                >
                                  <option value=""> Select an Issue</option>
                                  <option value="Product not working">
                                    Product not working
                                  </option>
                                  <option value="Short uantity received">
                                    Short uantity received
                                  </option>
                                  <option value="Damaged shipment">
                                    Damaged shipment
                                  </option>
                                  <option value="Documentaion issue">
                                    Documentaion issue
                                  </option>
                                  <option value="Product quantity issue">
                                    Product quantity issue
                                  </option>
                                  <option value="Product mismatch">
                                    Product mismatch
                                  </option>
                                  <option value="Any other issue">
                                    Any other issue
                                  </option>
                                  <option value="Catelogue desc is wrong / incomplete">
                                    Catelogue desc is wrong / incomplete
                                  </option>
                                  <option value="Product expired / nearing expiry">
                                    Product expired / nearing expiry
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-3">
                              <UserAdminDropDown
                                  placeholder="Select Assign"
                                  value={Assign_value}
                                  change={(e) => AssignChange(e)}
                                />
                              </div>
                              <div className="col-md-3">
                              <ReactFlatpickr
                                placeholder="From Date (Creation Date)"
                                options={creation_from_date}
                                value={from_date}
                              />
                              </div>
                              <div className="col-md-3">
                              <ReactFlatpickr
                                placeholder="To Date (Creation Date)"
                                options={creation_to_date}
                                value={to_date}
                              />
                              </div>
                              <div className="col-md-3 mt-3">
                                <select
                                  class="form-select "
                                  aria-label="Default select example"
                                  value={product_status}
                                  onChange={(e) => productStatus(e)}
                                ref={ref}
                                >
                                  <option selected="">Select Status</option>
                                  <option value="Open">Open</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Solved">Solved</option>
                                  <option value="Closed">Closed</option>
                                  <option value="Cancelled">Cancelled</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 mt-5 pt-5 d-flex align-items-center justify-content-end" style={{marginBottom:"-30px"}}>
                            <i
                              class="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              onClick={search_filter}
                            ></i>
                            <i
                              class="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={reset_filter}
                            ></i>
                          </div>
                        </div>
                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th data-sort="customer_name">Sr</th>
                                <th data-sort="customer_name">
                                  Ticket code <br /> Creation date
                                </th>
                                <th data-sort="customer_name">
                                  Order no <br /> Product{" "}
                                </th>
                                <th data-sort="email">
                                  Order quantity <br /> Issue quantity
                                </th>
                                <th data-sort="email">
                                  Order date <br /> Issue date
                                </th>
                                <th data-sort="email">
                                  Date assigned <br /> Assigned to
                                </th>
                                <th data-sort="date">
                                  Buyer name <br /> Buyer email <br /> Buyer
                                  mobile
                                </th>
                                <th data-sort="action" className="text-center">
                                  Action <br /> status
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                            {loading === true && (
                            <tr>
                              <td className="customer_name">
                                <Loader show={loading} />
                              </td>
                            </tr>
                          )}
                          {loading === false &&
                            (userList.length
                              ? 
                              userList.map((user, index) => (
                              <tr key={index}>
                                <td className="customer_name">{Offset + index + 1}</td>
                                <td className="customer_name">
                                  {user.code} <br /> 
                                  {moment(user.date_assigned)
                                      .utc()
                                      .format("DD/MM/YYYY")}
                                </td>
                                <td className="customer_name">
                                  {user.order_no} <br /> {user.product_name===null ?  "" : user.product_name.slice(0 ,18) }
                                </td>
                                <td className="customer_name">
                                  {user.order_quantity} <br /> {user.quantity}
                                </td>
                                <td className="customer_name">
                                   {moment(user.order_date)
                                      .utc()
                                      .format("DD/MM/YYYY")} <br /> 
                                      {moment(user.issue_date)
                                      .utc()
                                      .format("DD/MM/YYYY")}
                                </td>
                                <td className="email">
                                  {moment(user.date_assigned)
                                      .utc()
                                      .format("DD/MM/YYYY")} <br /> {user.assigned_to}
                                </td>
                                <td className="email">
                                  {user.buyer_name} <br />
                                  {user.buyer_email} <br />
                                  {user.buyer_mobile}
                                </td>
                                <td className="status text-center ">
                                <div class="edit"><a class="ri-eye-line fs-4" href="#"></a></div>
                                  <span
                                    className={`badge text-uppercase ${
                                      user.status == 'Approve'
                                        ? "bg-success-subtle text-success"
                                        : "bg-danger-subtle text-danger"
                                    }`}
                                  >
                                    {user.status == 'Approve' ? "Approve" : "Open"}
                                  </span>
                                </td>
                                {/* <td>
                                    <div className="d-flex gap-2 justify-content-center">
                                      <div className="edit">
                                        <Link
                                          className=" ri-edit-2-fill fs-4"
                                          to={`/category/edit-category/${user.id}`}
                                        ></Link>
                                      </div>
                                    </div>
                                  </td> */}
                              </tr>
                               )) 
                               : null)}
                            </tbody>
                          </table>
                        </div>
                        {pages > 1 && (
                      <Paginate
                        postsPerPage={20}
                        totalPosts={total}
                        paginate={paginate}
                        url_page={url_page}
                      />
                        )}
                      </div>
                    </div>
                  </div>
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
