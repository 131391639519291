import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import DeleteConfirmation from "../../../../shared/DeleteConfirmation";

import Loader from "../../../shared/Loader";
import CustomerDropdown from "../../../pages/views/Catalog/Customer/CustomerDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../utils/http";
import moment from "moment";
import ReactFlatpickr from "../../../shared/ReactFlatpickr";

export const SalesReport = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const url_page = params.get("page");
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  const [userList, setuserList] = useState([]);
  const [Pages, setPages] = useState([]);
  const [SRNO, setSRNO] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    url_page ? parseInt(url_page) : 1
  );

  useEffect(() => {
    refresh();
    // fetchdata();
    getPages();
  }, [currentPage]);

  const refresh = (
    customer_id = "",
    order_no = "",
    order_status = "",
    from_date = "",
    to_date = ""
  ) => {
    setLoading(true);
    let urls = "";

    if (customer_id) {
      urls += `&customer_id=${Customer_id}`;
    }
    if (order_no) {
      urls += `&order_no=${Order_no}`;
    }
    if (order_status) {
      urls += `&order_status=${Order_status}`;
    }
    if (from_date) {
      urls += `&from_date=${from_date}`;
    }
    if (to_date) {
      urls += `&to_date=${to_date}`;
    }
    getPages(urls);
    http.get(`get_order_list?page=${currentPage}${urls}`
    )
      // fetch(`http://192.168.1.177:8080/admin/get_category?page=${url_page}`)
      .then((res) => {
        console.log(res);
        setuserList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function getPages(urls = "") {
    setLoading(true);
    http.get(`get_order_list_count?page=${currentPage}${urls}`
    )
      .then((res) => {
        console.log(res);
        setPages(res.data.page_no);
        setSRNO(res.data.offset);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const fetchdata = () => {
    setLoading(true);
    http
      .get(process.env.REACT_APP_API_BASE + "get_order_list")
      .then((res) => {
        console.log(res.data);
        setuserList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (type, id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete order?`);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    setError(null);
    http
      .delete(`delete_product/${id}`)
      .then((response) => {
        toast.success("Order deleted");
        setDeleteMessage("User has been deleted");
        fetchdata();
      })
      .catch((error) => {
        setDeleteMessage("Error occured");
        if (error) {
          console.log(error);

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 404
          )
            setError("Invalid Username or password");
          else setError("Something went wrong. Please try again later.");
        }
      });
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  const [startDate, setStartDate] = useState("");
  const [from_date, setfrom_date] = useState("");

  // const [endDate, setEndDate] = useState("");
  const [to_date, setto_date] = useState("");

  const onStartChange = (e) => {
    console.log(e.target.value);
    setfrom_date(e.target.value)
  };

  const onEndChange = (e) => {
    console.log(e.target.value);
    setto_date(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
  };

  const previous = () => {
    console.log(currentPage);
    // navigate(`?page=${currentPage-1}`);
  };

  const [Customer_id, setCustomer_id] = useState("");
  const [Customer_value, setCustomer_value] = useState([]);
  const [Order_status, setOrder_status] = useState("");
  const [Order_no, setOrder_no] = useState("");

  const CustonChange = (e) => {
    console.log(e);
    setCustomer_value(e);
    setCustomer_id(e.value);
  };

  const OrStatonChange = (e) => {
    console.log(e.target.value);
    setOrder_status(e.target.value);
  };

  const OrdNoChange = (e) => {
    console.log(e.target.value);
    setOrder_no(e.target.value);
  };

  const search = () => {
    refresh(Customer_id, Order_no, Order_status, from_date, to_date);
    // setCustomer_id("")
    // setCustomer_value("")
    // setStartDate(null)
    // setEndDate(null)
    // setOrder_no("")
    // setOrder_status("")
    // setfrom_date("")
    // setto_date("")
  };
  const orderStatus = [
    { class: "badge bg-primary", name: "Placed" },
    { class: "badge bg-warning", name: "Ready for Dispatch" },
    { class: "badge bg-success", name: "Shipped" },
    { class: "badge bg-danger", name: "Cancelled" },
  ];
  const reset = () => {
    refresh();
    setCustomer_value("");
    setCustomer_id("");
    setOrder_no("");
    setOrder_status("");
    setfrom_date("");
    setto_date("");
  };

  const creation_from_date = {
    onChange: (selectedDates, dateStr, instance) => {
      // console.log(dateStr)
      setfrom_date(dateStr);
    },
  };
  const creation_to_date = {
    onChange: (selectedDates, dateStr, instance) => {
      // console.log(dateStr)
      setto_date(dateStr);
    },
  };

  
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex  justify-content-between align-items-center">
                    <h4 className="card-title mb-0">Sales Report List</h4>
                    {/* <Link
                      // onClick={() => showClauseForm()}
                      to="/order/create-order"
                      type="button"
                      className="btn btn-primary add-btn"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Order
                    </Link> */}
                  </div>
                  <div className="card-body">
                    <div className="listjs-table" id="customerList">
                      <div className="row g-4 mb-2">
                        <div className="col-md-11 ">
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <CustomerDropdown
                                placeholder="Customer"
                                value={Customer_value}
                                change={(e) => CustonChange(e)}
                              />
                            </div>
                            <div className="col-md-2">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Ref No/ Order No"
                                value={Order_no}
                                onChange={(e) => OrdNoChange(e)}
                              />
                            </div>
                            <div className="col-md-2">
                              <select
                                className="form-select"
                                name="order_status"
                                value={Order_status}
                                onChange={(e) => OrStatonChange(e)}
                                placeholder=" Select Order Status"
                              >
                                <option value="">Order Status</option>
                                <option value="0">Placed</option>
                                <option value="1">Ready for dispatch</option>
                                <option value="2">Shipped</option>
                                <option value="3">Cancelled</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <div className="input-group">
                                <ReactFlatpickr
                                placeholder="From Date (Creation Date)"
                                options={creation_from_date}
                                value={from_date}
                              />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-group">
                              <ReactFlatpickr
                                placeholder="To Date (Creation Date)"
                                options={creation_to_date}
                                value={to_date}
                              />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1 d-flex align-items-center">
                          <i
                            className="bx bx-search text-primary fs-2"
                            style={{ cursor: "pointer" }}
                            onClick={search}
                          ></i>
                          <i
                            className="bx bx-reset text-primary fs-2 ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={reset}
                          ></i>
                        </div>
                      </div>
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap table-bordered"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              {/* <th scope="col">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th> */}
                              <th data-sort="customer_name" >Sr</th>
                              <th data-sort="email" >Customer Name <br/> Email <br/> Mobile </th>
                              <th data-sort="date" >Order No <br/> Order Date <br/> Order Status</th>
                              {/* <th data-sort="date" style={{width : "5%"}}>Billing Address <br/> City <br/> State <br/> Country</th> */}
                              {/* <th data-sort="date" style={{maxWidth : "5%"}}>Delivery Address <br/> City <br/> State <br/> Country </th> */}
                              <th data-sort="tax-amt" >Taxable Amt</th>
                              <th data-sort="status" >Tax Amt</th>
                              <th data-sort="status" >Total Amt</th>
                              {/* <th data-sort="action" style={{textAlign:"center"}}>Action</th> */}
                            </tr>
                          </thead>
                          {loading === false && (
                            <tbody className="list form-check-all">
                              {userList.map((user, index) => (
                                <tr key={index}>
                                  {/* <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th> */}
                                  <td className="customer_name">{SRNO + index + 1}</td>
                                  <td className="email">
                                    {user.customer_name} <br/> {user.customer_email} <br/> {user.customer_mobile}
                                  </td>
                                  <td className="email">
                                    <span className="text-primary f">
                                      {" "}
                                      <Link
                                          to={`/order/view-order/${user.id}`}
                                        >{user.order_no}</Link>
                                    </span>
                                    <br />
                                    {moment(user.order_date)
                                        .utc()
                                        .format("DD/MM/YYYY")}
                                    <br />
                                    <span
                                      className={
                                        orderStatus[user.order_status].class
                                      }
                                    >
                                      {orderStatus[user.order_status].name}
                                    </span>
                                  </td>
                                  
                                  <td className="date">
                                    {user.taxable_amount}
                                  </td>
                                  <td className="date">
                                    {user.taxable_amount}
                                  </td>
                                  <td className="date">{user.total_amount}</td>
                                  {/* <td>
                                    <div className="d-flex gap-2">
                                      <div className="view">
                                        <Link
                                          className=" ri-eye-line fs-4"
                                          to={`/order/view-order/${user.id}`}
                                        ></Link>
                                      </div>
                                      <div className="edit">
                                        <Link
                                          className="ri-edit-2-fill fs-4"
                                          to={`/product/edit-order/${user.id}`}
                                        ></Link>
                                      </div>
                                      <div className="remove">
                                        <Link
                                          onClick={() =>
                                            showDeleteModal("User", user.id)
                                          }
                                          className="ri-delete-bin-6-line fs-4"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        ></Link>
                                      </div>
                                    </div>
                                  </td> */}
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                      {Pages > 1 && (
                        <div className="d-flex justify-content-end">
                          <nav aria-label="Page navigation">
                            <ul className="pagination">
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  aria-label="Previous"
                                  onClick={previous}
                                >
                                  <span aria-hidden="true">&laquo;</span>
                                </a>
                              </li>
                              {[...Array(Pages).keys()].map((page) => (
                                <li
                                  key={page + 1}
                                  className={`page-item ${
                                    currentPage === page + 1 ? "active" : ""
                                  }`}
                                >
                                  <button
                                    className="page-link"
                                    onClick={() => {
                                      handlePageChange(page + 1);
                                    }}
                                  >
                                    {page + 1}
                                  </button>
                                </li>
                              ))}
                              <li className="page-item">
                                <a className="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <DeleteConfirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitDelete}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={deleteMessage}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
