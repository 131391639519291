import React, { useEffect, useState } from "react";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.min.css";
import "../assets/css/icons.min.css";
import "../assets/css/app.min.css";
import "../assets/css/other.css";
import "../assets/css/supplier.css";
import { removeUserSession } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import bell from "../assets/images/svg/bell.svg";
import http from "../../utils/http";

const Header = () => {
  const [user_name, setuser_name] = useState("");
  useEffect(() => {
    http
      .get("user_veify")
      .then((res) => {})
      .catch((err) => {
        UserComponentVerifiy(err);
      });
    const storedPreference = localStorage.getItem("sidebar_Pref");
    if (storedPreference) {
      setSideBar(storedPreference);
    }

    if (localStorage.getItem("token")) {
      const name = localStorage.getItem("name");
      setuser_name(name);
    }
  }, []);

  const navigate = useNavigate();

  const [sideBarSize, setSideBar] = useState("lg");
  const handleSidebar = () => {
    const s = sideBarSize === "sm" ? "lg" : "sm";
    localStorage.setItem("sidebar_Pref", s);
    setSideBar(s);
  };

  let ele = document
    .getElementById("hhtml")
    .setAttribute("data-sidebar-size", sideBarSize);

  const handleLogout = () => {
    removeUserSession();
    localStorage.removeItem("sidebar_Pref");
    navigate("/login");
  };

  function UserComponentVerifiy(err) {
    if (err.response.status == 401) {
      handleLogout();
    }
  }

  return (
    <>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <a href="index.html" className="logo logo-dark">
                  <span className="logo-sm">
                    {/* <img src="assets/images/logo-sm.png" alt="" height="22" /> */}
                    WS
                  </span>
                  <span className="logo-lg">
                    {/* <img src="assets/images/logo-dark.png" alt="" height="17" /> */}
                    WOODEN SOLE
                  </span>
                </a>

                <a href="index.html" className="logo logo-light">
                  <span className="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="assets/images/logo-light.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </a>
              </div>

              <button
                onClick={handleSidebar}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex align-items-center">
              <div className="dropdown d-md-none topbar-head-dropdown header-item">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  id="page-header-search-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-search fs-22"></i>
                </button>
              </div>
              <div
                className="dropdown topbar-head-dropdown ms-1 header-item"
                id="notificationDropdown"
              >
                <button
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  id="page-header-notifications-dropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-bell fs-22"></i>
                  <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                    3<span className="visually-hidden">unread messages</span>
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-notifications-dropdown"
                >
                  <div className="dropdown-head bg-primary bg-pattern rounded-top">
                    <div className="p-3">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="m-0 fs-16 fw-semibold text-white">
                            Notifications
                          </h6>
                        </div>
                        <div className="col-auto dropdown-tabs">
                          <span className="badge bg-light-subtle text-body fs-13">
                            {" "}
                            4 New
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="px-2 pt-2">
                      <ul
                        className="nav nav-tabs dropdown-tabs nav-tabs-custom"
                        data-dropdown-tabs="true"
                        id="notificationItemsTab"
                        role="tablist"
                      >
                        <li
                          className="nav-item waves-effect waves-light"
                          role="presentation"
                        >
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#all-noti-tab"
                            role="tab"
                            aria-selected="true"
                          >
                            All (4)
                          </a>
                        </li>
                        <li
                          className="nav-item waves-effect waves-light"
                          role="presentation"
                        >
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#messages-tab"
                            role="tab"
                            aria-selected="false"
                            tabIndex="-1"
                          >
                            Messages
                          </a>
                        </li>
                        <li
                          className="nav-item waves-effect waves-light"
                          role="presentation"
                        >
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#alerts-tab"
                            role="tab"
                            aria-selected="false"
                            tabIndex="-1"
                          >
                            Alerts
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className="tab-content position-relative"
                    id="notificationItemsTabContent"
                  >
                    <div
                      className="tab-pane fade show active py-2 ps-2"
                      id="all-noti-tab"
                      role="tabpanel"
                    >
                      <div data-simplebar="init" className="pe-2">
                        <div className="simplebar-wrapper">
                          <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                          </div>
                          <div className="simplebar-mask">
                            <div className="simplebar-offset">
                              <div
                                className="simplebar-content-wrapper"
                                tabIndex="0"
                                role="region"
                                aria-label="scrollable content"
                              >
                                <div className="simplebar-content">
                                  <div className="text-reset notification-item d-block dropdown-item position-relative">
                                    <div className="d-flex">
                                      <div className="avatar-xs me-3 flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                          <i className="bx bx-badge-check"></i>
                                        </span>
                                      </div>
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-2 lh-base">
                                            Your <b>Elite</b> author Graphic
                                            Optimization{" "}
                                            <span className="text-secondary">
                                              reward
                                            </span>{" "}
                                            is ready!
                                          </h6>
                                        </a>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            Just 30 sec ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="all-notification-check01"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="all-notification-check01"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item position-relative">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-2.jpg"
                                        className="me-3 rounded-circle avatar-xs flex-shrink-0"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            Angela Bernier
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            Answered to your comment on the cash
                                            flow forecast's graph 🔔.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            48 min ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="all-notification-check02"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="all-notification-check02"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item position-relative">
                                    <div className="d-flex">
                                      <div className="avatar-xs me-3 flex-shrink-0">
                                        <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-16">
                                          <i className="bx bx-message-square-dots"></i>
                                        </span>
                                      </div>
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-2 fs-13 lh-base">
                                            You have received{" "}
                                            <b className="text-success">20</b>{" "}
                                            new messages in the conversation
                                          </h6>
                                        </a>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            2 hrs ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="all-notification-check03"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="all-notification-check03"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item position-relative">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-8.jpg"
                                        className="me-3 rounded-circle avatar-xs flex-shrink-0"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            Maureen Gibson
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            We talked about a project on
                                            linkedin.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            4 hrs ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="all-notification-check04"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="all-notification-check04"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="my-3 text-center view-all">
                                    <button
                                      type="button"
                                      className="btn btn-soft-success waves-effect waves-light"
                                    >
                                      View All Notifications{" "}
                                      <i className="ri-arrow-right-line align-middle"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simplebar-placeholder"></div>
                        </div>
                        <div className="simplebar-track simplebar-horizontal">
                          <div className="simplebar-scrollbar"></div>
                        </div>
                        <div className="simplebar-track simplebar-vertical">
                          <div className="simplebar-scrollbar"></div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade py-2 ps-2"
                      id="messages-tab"
                      role="tabpanel"
                      aria-labelledby="messages-tab"
                    >
                      <div data-simplebar="init" className="pe-2">
                        <div className="simplebar-wrapper">
                          <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                          </div>
                          <div className="simplebar-mask">
                            <div className="simplebar-offset">
                              <div
                                className="simplebar-content-wrapper"
                                tabIndex="0"
                                role="region"
                                aria-label="scrollable content"
                              >
                                <div className="simplebar-content">
                                  <div className="text-reset notification-item d-block dropdown-item">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-3.jpg"
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            James Lemire
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            We talked about a project on
                                            linkedin.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            30 min ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="messages-notification-check01"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="messages-notification-check01"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-2.jpg"
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            Angela Bernier
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            Answered to your comment on the cash
                                            flow forecast's graph 🔔.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            2 hrs ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="messages-notification-check02"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="messages-notification-check02"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-6.jpg"
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            Kenneth Brown
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            Mentionned you in his comment on 📃
                                            invoice #12501.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            10 hrs ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="messages-notification-check03"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="messages-notification-check03"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-reset notification-item d-block dropdown-item">
                                    <div className="d-flex">
                                      <img
                                        src="assets/images/users/avatar-8.jpg"
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                      />
                                      <div className="flex-grow-1">
                                        <a href="#!" className="stretched-link">
                                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                            Maureen Gibson
                                          </h6>
                                        </a>
                                        <div className="fs-13 text-muted">
                                          <p className="mb-1">
                                            We talked about a project on
                                            linkedin.
                                          </p>
                                        </div>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                          <span>
                                            <i className="mdi mdi-clock-outline"></i>{" "}
                                            3 days ago
                                          </span>
                                        </p>
                                      </div>
                                      <div className="px-2 fs-15">
                                        <div className="form-check notification-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="messages-notification-check04"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="messages-notification-check04"
                                          ></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="my-3 text-center view-all">
                                    <button
                                      type="button"
                                      className="btn btn-soft-success waves-effect waves-light"
                                    >
                                      View All Messages{" "}
                                      <i className="ri-arrow-right-line align-middle"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simplebar-placeholder"></div>
                        </div>
                        <div className="simplebar-track simplebar-horizontal">
                          <div className="simplebar-scrollbar"></div>
                        </div>
                        <div className="simplebar-track simplebar-vertical">
                          <div className="simplebar-scrollbar"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade p-4"
                      id="alerts-tab"
                      role="tabpanel"
                      aria-labelledby="alerts-tab"
                    >
                      <div className="empty-notification-elem">
                        <div className="w-25 w-sm-50 pt-3 mx-auto">
                          <img
                            src={bell}
                            className="img-fluid"
                            alt="user-pic"
                          />
                        </div>
                        <div className="text-center pb-5 mt-2">
                          <h6 className="fs-18 fw-semibold lh-base">
                            Hey! You have no notifications
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div
                      className="notification-actions"
                      id="notification-actions"
                    >
                      <div className="d-flex text-muted justify-content-center">
                        Select
                        <div
                          id="select-content"
                          className="text-body fw-semibold px-1"
                        >
                          0
                        </div>
                        Result{" "}
                        <button
                          type="button"
                          className="btn btn-link link-danger p-0 ms-3"
                          data-bs-toggle="modal"
                          data-bs-target="#removeNotificationModal"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dropdown ms-sm-3 header-item topbar-user">
                <button
                  type="button"
                  className="btn"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="d-flex align-items-center">
                    <span className="text-start ms-xl-2">
                      <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        {user_name}
                      </span>
                      <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">
                        User
                      </span>
                    </span>
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  {/* <h6 className="dropdown-header">Welcome {user_name}!</h6> */}
                  <a className="dropdown-item" href="pages-profile.html">
                    <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Profile</span>
                  </a>
                  {/* <a className="dropdown-item" href="apps-chat.html">
    <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
    <span className="align-middle">Messages</span>
    </a>
    <a className="dropdown-item" href="apps-tasks-kanban.html">
    <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
    <span className="align-middle">Taskboard</span>
    </a>
    <a className="dropdown-item" href="pages-faqs.html">
    <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
    <span className="align-middle">Help</span>
    </a>
    <div className="dropdown-divider"></div>
    <a className="dropdown-item" href="pages-profile.html">
    <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
    <span className="align-middle">
    Balance : <b>$5971.67</b>
    </span>
    </a>
    <a
    className="dropdown-item"
    href="pages-profile-settings.html"
    >
    <span className="badge bg-success-subtle text-success mt-1 float-end">
    New
    </span>
    <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
    <span className="align-middle">Settings</span>
    </a>
    <a
    className="dropdown-item"
    href="auth-lockscreen-basic.html"
    >
    <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
    <span className="align-middle">Lock screen</span>
  </a> */}
                  <a
                    className="dropdown-item cursor-pointer"
                    // href="/login"
                    onClick={handleLogout}
                  >
                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1 "></i>{" "}
                    <span className="align-middle " data-key="t-logout">
                      Logout
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
