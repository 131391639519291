import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import http from "../../../../../utils/http";
import CustomerDropdown from "../Customer/CustomerDropdown";
import moment from "moment";
import ReactFlatpickr from "../../../../shared/ReactFlatpickr";

export const SalesReturnModal = ({
  showModal,
  hideModal,
  refreshPage,
  orderId,
  CustomerId,
  type,
  data,
}) => {
  console.log(orderId);
  const yupValidation = Yup.object().shape({});

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );

  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");

  const [Btnloading, setBtnloading] = useState(false);

  const [File, setFile] = useState("");
  const [productLists, setProductLists] = useState([]);
  const [order_code, setorder_code] = useState();

  useEffect(() => {
    fetchOrderbyId();
  }, [orderId]);

  const fetchOrderbyId = () => {
    setLoading(true);
    http
      .get("get_order_by_id/" + orderId)
      .then((res) => {
        setProductLists(res.data.order_items);
        setorder_code(res.data.order_no)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  console.log("productLists", productLists);

  function selectProcutId(e) {
    console.log(
      "selectProcutId",
      e.target[e.target.selectedIndex].getAttribute("data-qty")
    );
    setValue("product_id", e.target.value);
    setValue(
      "order_quantity",
      e.target[e.target.selectedIndex].getAttribute("data-qty")
    );
  }

  // console.log("CustomerId" , CustomerId);
  // console.log("Product ID" , productId);
  // console.log("Quantity" , quantityNo);

  const [issuetype, setIssueType] = useState("");

  function selectIssue(e) {
    console.log(e.target.value);
    setIssueType(e.target.value);
  }

  function onSubmit() {
    console.log(orderId ,order_code ,productLists);
    // setLoading(true);
    // let config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // };
    // let newdata = {
    //   ...data,
    //   customer_id: CustomerId,
    //   order_id: orderId,
    // };
    // console.log(newdata, config);
    // http
    //   .post("create_escalation", newdata, config)
    //   .then((response) => {
    //     console.log(response);
    //     setLoading(false);
    //     reset();
    //     hideModal();
    //     refreshPage();
    //     reset();
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     setMessage("Something went wrong");
    //   });
  }
  const [issue_date, setissue_date] = useState("");
  const issueDate = {
    onChange: (selectedDates, dateStr, instance) => {
      console.log(dateStr);
      setValue("issue_date", dateStr);
      setissue_date(dateStr);
    },
  };

  var dataB;
  function getTableFiledDeta(e , id) {
    dataB = e.target.value;
    toggleSwitch(id, dataB);
  }

  function toggleSwitch (id, vals){
    let newCats = [];
    newCats = productLists.map((user) =>
    user.id === id ? { ...user, return_quantity: vals } : user)
  setProductLists(newCats)
  }

  console.log(productLists);
  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Return Modal</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className={`row g-3 `}>
            <div className="col-lg-12">
              <div className="table-responsive table-card mt-3 mb-1">
                <table
                  className="table align-middle table-nowrap table-bordered"
                  id="customerTable"
                >
                  <thead className="table-light">
                    <tr>
                      <th data-sort="customer_name">Sr.</th>
                      <th data-sort="customer_name">Product Name</th>
                      <th data-sort="email">Order Quantity</th>
                      <th data-sort="date">Return Quantity</th>
                    </tr>
                  </thead>
                  <tbody className="list form-check-all">
                    {productLists.map((item, index) => (
                      <tr key={index}>
                        <td className="customer_name">{index + 1}</td>
                        <td className="customer_name">{item.product_name}</td>
                        <td className="customer_name">{item.quantity}</td>
                        <td>
                          <input
                            type="text"
                            name={`product-${item.id}`}
                            // value={listData}
                            className="form-control"
                            onChange={(e) => getTableFiledDeta(e , item.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="hidden" name="standard_id" value={orderId} />
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button variant="success" type="submit">
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
