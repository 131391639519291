import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import http from "../../../../utils/http";
import ReactFlatpickr from "../../../shared/ReactFlatpickr";

export const DiscountCouponEdit = () => {
  const { coupon_id } = useParams();
  console.log(coupon_id);
  const yupValidation = Yup.object().shape({
    coupon_name: Yup.string().required("Please enter coupon name"),
    coupon_code: Yup.string().required("Please enter coupon code"),
    coupon_type: Yup.string().required("Please select coupon type"),
    coupon_value: Yup.number()
      .typeError("value must be a number")
      .required("Please enter coupon value"),
    min_cart_value: Yup.string().required("Please enter mcv"),
    from_date: Yup.string().required("Please enter from"),
    to_date: Yup.string().required("Please enter to date"),
    status: Yup.string().required("Please enter status"),
  });
  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function onSubmit(data) {
    console.log(data);
    setLoading(true);
    console.log("GO");
    http
      .put(`update_coupon_by_id/${coupon_id}`, data)
      // .post(`http://192.168.1.177:8080/admin/save_coupon`, data)
      .then((response) => {
        console.log(response);
        const timer = setTimeout(() => {
          setLoading(false);
          navigate("/website-management/discount-coupon");
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        console.log("Already Exist");
        setLoading(false);
        console.log(error);
      });
  }

  function getCouponData(coupon_id) {
    console.log(coupon_id);
    http
      .get(`get_coupon_by_id/${coupon_id}`)
      .then((res) => {
        console.log(res);
        // console.log("Product_id see",res.data.product_id);
        setValue("coupon_name", res.data.coupon_name);
        setValue("coupon_code", res.data.coupon_code);
        setValue("coupon_type", res.data.coupon_type);
        setValue("coupon_value", res.data.coupon_value);
        setValue("min_cart_value", res.data.min_cart_value);
        setfrom_date(res.data.from_date)
        setto_date(res.data.to_date)
        setValue("from_date",new Date(res.data.from_date).toISOString().split("T")[0]
        );
        setValue("to_date", new Date(res.data.to_date).toISOString().split("T")[0]);
        setValue("status", res.data.status);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  useEffect(() => {
    getCouponData(coupon_id);
  }, []);

  const [fromDate, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const creation_from_date = {
    onChange: (selectedDates, dateStr, instance) => {
      // console.log(dateStr)
      setValue("from_date" , dateStr)
      setfrom_date(dateStr);
    },
  };
  const creation_to_date = {
    onChange: (selectedDates, dateStr, instance) => {
      // console.log(dateStr)
      setValue("to_date" , dateStr)
      setto_date(dateStr);
    },
  };
  console.log(fromDate , to_date);

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title mb-0">Discount Coupon Edit</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Coupon Name
                      </label>
                      <input
                        className="form-control"
                        name="coupon_name"
                        placeholder="Enter Coupon Name"
                        {...register("coupon_name")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.coupon_name ? "d-block" : ""
                        }`}
                      >
                        {errors.coupon_name ? errors.coupon_name.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Coupon Code
                      </label>
                      <input
                        className="form-control"
                        name="coupon_code"
                        placeholder="Enter Coupon Code"
                        {...register("coupon_code")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.coupon_code ? "d-block" : ""
                        }`}
                      >
                        {errors.coupon_code ? errors.coupon_code.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div>
                        <label
                          htmlFor="assignedtoName-field"
                          className="form-label"
                        >
                          Type
                        </label>
                        <select
                          name="coupon_type"
                          className="form-select"
                          {...register("coupon_type")}
                        >
                          <option value=""> --Select type-- </option>
                          <option value="1">Percentage-based coupons</option>
                          <option value="0">Fixed price coupons</option>
                        </select>
                        <div
                          className={`invalid-feedback ${
                            errors.coupon_type ? "d-block" : ""
                          }`}
                        >
                          {errors.coupon_type ? errors.coupon_type.message : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Value
                      </label>
                      <input
                        className="form-control"
                        name="coupon_value"
                        placeholder="Enter Value"
                        {...register("coupon_value")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.coupon_value ? "d-block" : ""
                        }`}
                      >
                        {errors.coupon_value ? errors.coupon_value.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Minimum Cart Value
                      </label>
                      <input
                        className="form-control"
                        name="min_cart_value"
                        placeholder="Enter Minimum Cart Value"
                        {...register("min_cart_value")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.min_cart_value ? "d-block" : ""
                        }`}
                      >
                        {errors.min_cart_value
                          ? errors.min_cart_value.message
                          : ""}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        From Date
                      </label>
                      <ReactFlatpickr
                      placeholder="Enter From Date"
                      name="from_date"
                      value={fromDate}
                      options={creation_from_date}
                      {...register("from_date")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.from_date ? "d-block" : ""
                        }`}
                      >
                        {errors.from_date ? errors.from_date.message : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        To Date
                      </label>
                      <ReactFlatpickr
                      placeholder="Enter From Date"
                      name="to_date"
                      value={to_date}
                      options={creation_to_date}
                      {...register("to_date")}
                      />
                      <div
                        className={`invalid-feedback ${
                          errors.to_date ? "d-block" : ""
                        }`}
                      >
                        {errors.to_date ? errors.to_date.message : ""}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label
                        className="form-label"
                        htmlFor="project-title-input"
                      >
                        Status
                      </label>
                      <select
                        className="form-select"
                        name="status"
                        defaultValue="1"
                        {...register("status")}
                      >
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                      <div
                        className={`invalid-feedback ${
                          errors.status ? "d-block" : ""
                        }`}
                      >
                        {errors.status ? errors.status.message : ""}
                      </div>
                    </div>
                  </div>
                  {/* <div className="mb-3 mt-4 text-end">
                    <button type="submit" className="btn btn-danger w-sm">
                      Save
                    </button>
                  </div> */}
                  <div className="mb-3 mt-4 text-end">
                    <button
                      type="submit"
                      className="btn btn-danger w-sm"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Save Coupon"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
