import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";


import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// import Loader from "../../shared/Loader";
import http from "../../../../utils/http";
import SupplierNavs from "../SupplierNavs";
// import { useParams } from "next/navigation";



const AddBasinfo = () => {
  let { supplier_id } = useParams()
  // console.log("Id", supplier_id);

  const navigate = useNavigate();
  // console.log("hi Its loading");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([]);

  const [bTypes, setbTypes] = useState([])
  const [pinStates, setpinStates] = useState([])
  const [responseData, setresponseData] = useState([]);

  // const [selectedState, setSelectedState] = useState('');
  const [selectedStateCode, setSelectedStateCode] = useState('');





  const yupValidation = Yup.object().shape({
    company_name: Yup.string().required("Please enter company name"),
    business_type: Yup.string().required("Please select business type"),
    address: Yup.string().required("Please enter address"),
    pincode: Yup.string().required("Please enter pincode"),
    city: Yup.string().required("Please enter city"),
    state: Yup.string().required("Please enter state"),
    country: Yup.string().required("Please enter country"),

  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const {
    register,
    setValue,
    resetField,
    getValues,
    handleSubmit,
    reset,
    getFieldState,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;



  const getFetchSupplier = () => {
    http.get(`get_supplier_by_id/${supplier_id}`)
      .then((res) => {
        setValue("company_name", res.data.company_name)
        setValue("address", res.data.address)
        setValue("pincode", res.data.pincode)
        setValue("business_type", res.data.business_type)
        setValue("city", res.data.city)
        setValue("country", res.data.country)
        setValue("state", res.data.state)
        setValue("state_code", res.data.state_code)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  let editCase = (supplier_id) ? 1 : 0;
  if (editCase == 1) {
    getFetchSupplier();
  }

  const setAddressDetails = (data) => {
    http.get(`getDataFromPincode/${data}`)
      .then((res) => {
        console.log("State_code", res.data.state);
        setValue("city", res.data.city)
        setValue("state", res.data.state)
        setValue("country", res.data.country)
        setValue("state_code", res.data.state_code)
       
        // setSelectedStateCode(data.state_code)
        console.log(res.data.state_code);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  // console.log(selectedStateCode);

  const getPincodeValue = (e) => {
    setAddressDetails(e.target.value)
  }

  const getbusinessTypeData = () => {
    http.get(`get_business_type`)
      .then((res) => {
        setbTypes(res.data)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  const getpincodeStateData = () => {
    http.get(`getAllStates`)
      .then((res) => {
        setpinStates(res.data)

      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  // console.log(getValues('state'));

  function onSubmit(data) {

    // console.log('state_code',selectedStateCode);
    // setValue('state_code', selectedStateCode)
    setLoading(true);
    setValue("state_code", data.state_code)
    console.log(data);

    if (supplier_id) {
      console.log('update', supplier_id);
      http.put(`update_supplier_info/${supplier_id}`, data)
        .then(response => {
          console.log(response);
          console.log('User has been edited');
          setLoading(false);
          navigate(`/supplier-management/add-billing-details/${supplier_id}`)
        }).catch(error => {
          setLoading(false);
          console.log('error delete');
          if (error) {
            console.log(error)
          }
        });
    }
    else {
      http.post("save_supplier_basic_info", data)
        .then(response => {
          console.log(response);
          setresponseData(response.data.data.company_name)

          const supplier_id = response.data.data.id
          console.log("Data Add Successfully");
          console.log(responseData);
          setLoading(false);
          navigate(`/supplier-management/add-billing-details/${supplier_id}`)
        }).catch(error => {
          setMessage('Something went wrong');
          setLoading(false);
        });
    }
  }


  const handleStateChange = (e) => {
    console.log("selectProcutId",e.target[e.target.selectedIndex]); 
    setValue('state_code', e.target[e.target.selectedIndex].getAttribute('data-qty'))
  //   setSelectedState(selectedOption);

  //   // Find the selected state object and extract both state and state_code
  //   const selectedStateObject = pinStates.find(state => state.state === selectedOption);
  //   if (selectedStateObject) {
  //     setSelectedStateCode(selectedStateObject.state_code);
  //   } else {
  //     setSelectedStateCode(''); // If state object not found, reset the state code
  //   }
  };



  // console.log(selectedStateCode);
  useEffect(() => {
    getbusinessTypeData();
    getpincodeStateData();
    
  }, [])

  return (
    <>
      <div className={`toastify on bg-success toastify-center toastify-top toastrrr" aria-live="polite ${message === '' && "d-none"}`}>{message}</div>
      {/* <Header />
      <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <SupplierNavs supplier_id={supplier_id} />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-8 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Company Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="company_name"
                              placeholder="Enter company name"
                              {...register('company_name')}
                            />
                            <div
                              className={`invalid-feedback ${errors.company_name ? "d-block" : ""
                                }`}
                            >
                              {errors.company_name
                                ? errors.company_name.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Business Type
                            </label>
                            <select
                              className="form-control form-select"
                              name="business_type"
                              {...register('business_type')}

                            >
                              <option value="">Select</option>
                              {bTypes.map((bType, index) => (
                                <option key={index} value={bType.name}>
                                  {bType.name}
                                </option>
                              )
                              )}
                            </select>
                            <div
                              className={`invalid-feedback ${errors.business_type ? "d-block" : ""
                                }`}
                            >
                              {errors.business_type
                                ? errors.business_type.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Address
                            </label>
                            <input
                              className="form-control"
                              name="address"
                              placeholder="Enter Address"
                              {...register("address")}
                            />
                            <div
                              className={`invalid-feedback ${errors.address ? "d-block" : ""
                                }`}
                            >
                              {errors.address
                                ? errors.address.message
                                : ""}
                            </div>
                          </div>
                        </div>


                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Pincode
                            </label>
                            <input
                              className="form-control"
                              name="pincode"
                              placeholder="Enter pincode"
                              {...register("pincode")}
                              onChange={(e) => getPincodeValue(e)}
                            />

                            <div
                              className={`invalid-feedback ${errors.pincode ? "d-block" : ""
                                }`}
                            >
                              {errors.pincode
                                ? errors.pincode.message
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              City
                            </label>
                            <input
                              className="form-control"
                              name="city"
                              placeholder="Enter city"
                              {...register("city")}
                            />
                            <div
                              className={`invalid-feedback ${errors.city ? "d-block" : ""
                                }`}
                            >
                              {errors.city
                                ? errors.city.message
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              State
                            </label>
                            <select
                              className="form-control form-select"
                              name="state"
                              {...register('state')}
                              // value={selectedOption} onChange: function(event, index, value) {
                              //   var option = this.props.options.find(option => option.slug === value);
                              //   this.props.onChange(this.props.name, value, option.continent, option.country);
                              // }
                              onChange={(e) => handleStateChange(e)}
                            >
                              <option value="">Select</option>
                              {pinStates.map((pinState, index) => (
                                  <option key={index} data-qty={pinState.state_code}  value={pinState.state}>
                                    {pinState.state}
                                  </option>
                              )
                              )}
                            </select>
                            <div
                              className={`invalid-feedback ${errors.state ? "d-block" : ""
                                }`}
                            >
                              {errors.state
                                ? errors.state.message
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="project-title-input"
                            >
                              Country
                            </label>
                            <input
                              className="form-control"
                              name="country"
                              placeholder="Enter country name"
                              {...register("country")}
                            />
                            <div
                              className={`invalid-feedback ${errors.country ? "d-block" : ""
                                }`}
                            >
                              {errors.country
                                ? errors.country.message
                                : ""}
                            </div>
                          </div>
                          <div className="hstack gap-2 justify-content-end">
                            <button type="submit" className="btn btn-danger">{loading ? "Loading..." : "Save"}</button>
                          </div>
                        </div>
                        {/* <div className="clearfix"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AddBasinfo;