import React, { useState, useEffect, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import http from "../../../../../utils/http";

const GSTDropdown = (props) => {
  const opt = [];

  const [options, setoptions] = useState(opt);
  useEffect(() => {
    http
      .get("get_gst")
      .then((res) => {
        console.log(res.data);
        let data = res.data;
        data.forEach((val) => {
          opt.push({
            label: val.gst,
            value: val.id,
          });
        });

        setoptions(opt);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <CreatableSelect
      options={options}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.change}
      isClearable={true}
    />
  );
};

export default GSTDropdown;
