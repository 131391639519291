import React, { use, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import http from "../../../../../utils/http";
import moment from "moment";

export const CustomerAddressList = ({showModal,hideModal,refreshPage,id,type,data,cardShow,}) => {

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const pincodeRegExp = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
  const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please enter category name"),
    email: Yup.string().required("Please enter email").matches(emailRegExp , "email is not valid"),
    mobile: Yup.string().required("Please enter mobile").matches(phoneRegExp, 'mobile number is not valid').length(10),
    address: Yup.string().required("Please enter address"),
    pincode: Yup.string().required("Please enter pincode").matches(pincodeRegExp, 'pincode is not valid').length(6),
    city: Yup.string().required("Please enter city"),
    state: Yup.string().required("Please enter state"),
    country: Yup.string().required("Please enter country"),
  });
  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit ,setValue,reset, formState } = useForm(
    formOptions
  );
  
  const { errors } = formState;

  
  console.log(id);
  const [userList, setuserList] = useState([]);
  const [pinStates, setpinStates] = useState([]);
  const [pinCitys, setpinCitys] = useState([]);

  useEffect(() => {
    getAddressList();
    getpincodeStateData();
  }, [id]);

  const getpincodeStateData = () => {
    http
      .get(`getAllCities`)
      .then((res) => {
        setpinStates(res.data);
        setpinCitys(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [readOnlyData, setreadOnlyData] = useState(false);
  const getPincodeValue = (e) => {
    setAddressDetails(e.target.value);
  };
  const setAddressDetails = (data) => {
    http
      .get(`getDataFromPincode/${data}`)
      .then((res) => {
        console.log("State_code", res.data.state);
        if (res.data) {
          setreadOnlyData(true);
          setValue("city", res.data.city);
          setValue("state", res.data.state);
          setValue("country", res.data.country);
          setValue("state_code", res.data.state_code);
          // setSelectedStateCode(data.state_code)
        }
        console.log(res.data.state_code);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function getAddressList() {
    console.log(id);
    http
      .get("get_customer_address_by_id/" +id)
      .then((res) => {
        console.log(res.data);
        setuserList(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  console.log(userList);
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");

  const [Btnloading, setBtnloading] = useState(false);


  const [Form, setForm] = useState(false);
  function openAddressForm() {
    setForm(true);
  }

  function openAddressList() {
    setForm(false);
  }

  function onSubmit(data) {
    setValue("user_id" , id)
    setBtnloading(true);
    console.log(data);
    http
      .post("save_customer_address", data)
      .then((response) => {
        console.log(response);
        reset();
        setreadOnlyData(false);
        setBtnloading(false);
        openAddressList()
        getAddressList()
      })
      .catch((error) => {
        setBtnloading(false);
        setMessage("Something went wrong");
      });
  }

  return (
    <Modal show={showModal} onHide={hideModal} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Customer Address</Modal.Title>
      </Modal.Header>

      {Form ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <div className={`row g-3 `}>
                <div className="col-lg-4">
                  <label className="form-label" htmlFor="project-title-input">
                    Customer Name
                  </label>
                  <input
                    className="form-control"
                    name="name"
                    placeholder="Enter Customer Name"
                    {...register("name")}
                  />
                  <div
                    className={`invalid-feedback ${
                      errors.name ? "d-block" : ""
                    }`}
                  >
                    {errors.name ? errors.name.message : ""}
                  </div>
                </div>
                 <div className="col-lg-4">
                  <label className="form-label" htmlFor="project-title-input">
                    Email
                  </label>
                  <input
                    className="form-control"
                    name="email"
                    placeholder="Enter Email "
                    {...register("email")}
                  />
                  <div
                    className={`invalid-feedback ${
                      errors.email ? "d-block" : ""
                    }`}
                  >
                    {errors.email ? errors.email.message : ""}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label" htmlFor="project-title-input">
                    Mobile
                  </label>
                  <input
                  type="number"
                    className="form-control"
                    name="mobile"
                    placeholder="Enter Mobile no"
                    {...register("mobile")}
                  />
                  <div
                    className={`invalid-feedback ${
                      errors.mobile ? "d-block" : ""
                    }`}
                  >
                    {errors.mobile ? errors.mobile.message : ""}
                  </div>
                </div>
                <div className="col-lg-8 ">
                  <label className="form-label" htmlFor="project-title-input">
                    Address
                  </label>
                  <input
                    className="form-control"
                    name="address"
                    placeholder="Enter Address "
                    {...register("address")}
                  />
                  <div
                    className={`invalid-feedback ${
                      errors.address ? "d-block" : ""
                    }`}
                  >
                    {errors.address ? errors.address.message : ""}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label" htmlFor="project-title-input">
                    Pincode
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="pincode"
                    placeholder="Enter Pincode "
                    {...register("pincode")}
                    onChange={(e) => getPincodeValue(e)}
                  />
                  <div
                    className={`invalid-feedback ${
                      errors.pincode ? "d-block" : ""
                    }`}
                  >
                    {errors.pincode ? errors.pincode.message : ""}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label" htmlFor="project-title-input">
                    City
                  </label>
                  <select
                className="form-control form-select"
                name="city"
                placeholder="Enter City "
                {...register("city")}
                disabled={readOnlyData}
              >
                <option value="">Select</option>
                {pinCitys.length
                  ? [...new Set(pinCitys.map((item) => item.city))].map(
                      (city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      )
                    )
                  : " "}
              </select>
                  <div
                    className={`invalid-feedback ${
                      errors.city ? "d-block" : ""
                    }`}
                  >
                    {errors.city ? errors.city.message : ""}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label" htmlFor="project-title-input">
                    State
                  </label>
                  <select
                className="form-control form-select"
                name="state"
                placeholder="Enter State "
                {...register("state")}
                disabled={readOnlyData}
              >
                <option value="">Select</option>

                {pinStates.length
                  ? [...new Set(pinStates.map((item) => item.state))].map(
                      (state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      )
                    )
                  : " "}
              </select>
                  <div
                    className={`invalid-feedback ${
                      errors.state ? "d-block" : ""
                    }`}
                  >
                    {errors.state ? errors.state.message : ""}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label" htmlFor="project-title-input">
                    Country
                  </label>
                  <input
                    className="form-control"
                    name="country"
                    placeholder="Enter Country "
                    {...register("country")}
                    disabled={readOnlyData}
                  />
                  <div
                    className={`invalid-feedback ${
                      errors.country ? "d-block" : ""
                    }`}
                  >
                    {errors.country ? errors.country.message : ""}
                  </div>
                </div> 
              </div>
            </Modal.Body>
            <Modal.Footer>
              <input type="hidden" name="standard_id" value={id} />
              <Button
                variant="danger"
                onClick={openAddressList}
              >
                Cancel
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={Btnloading}
                // onClick={hideModal}
              >
                {Btnloading ? "Loading..." : "Save Address"}
              </Button>
            </Modal.Footer>
          </form>
        </>
      ) : (
        <>
          <Modal.Body>
            <div className={`row g-3 `}>
              <div className="col-lg-12">
              <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th data-sort="customer_name">#</th>
                                <th data-sort="customer_name">Name</th>
                                <th data-sort="email">Address</th>
                                <th data-sort="date">City</th>
                                <th data-sort="status">State</th>
                                <th data-sort="action">Country</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {userList.length ? 
                            userList.map((user, index) => 
                              (
                                        <tr key={index}>
                                          <td className="customer_name">{index + 1}</td>
                                          <td className="customer_name">
                                            {user.name}
                                          </td>
                                          <td className="email">{user.address}</td>
                                          <td className="mobile">{user.city}</td>
                                          <td className="mobile">{user.state}</td>
                                          <td className="mobile">{user.country}</td>
                                        </tr>
                            )
                            ) : null}
                            </tbody>
                          </table>
                        </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <input type="hidden" name="standard_id" value={id} />
            <Button
              variant="danger"
              onClick={() => {
                hideModal();
                cardShow(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              type="submit"
              onClick={openAddressForm}
            >
             Add Address
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};