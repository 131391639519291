import React  from "react";

const Footer = () => {
     return (
        <React.Fragment>
        <footer className="footer">
        <div className="container-fluid">
        <div className="row">
        <div className="col-sm-6">
        ©2024 Woodensole
        </div>
        <div className="col-sm-6">
        </div>
        </div>
        </div>
        </footer>
        
        
        <button  className="btn btn-danger btn-icon" id="back-to-top">
        <i className="ri-arrow-up-line"></i>
        </button>
        
        </React.Fragment>

     )
}

export default Footer;