import React, { useState, useEffect, useRef } from "react";


import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../../../shared/Loader";
import { AddMasterBusinessType } from "./AddMasterBusinessType";
import { EditMasterBusinessTypeModal } from "./EditMasterBusinessTypeModal ";
import DeleteConfirmation from "../../../shared/DeleteConfirmation";
import http from "../../../../utils/http";
// import { Button } from "react-bootstrap";
import moment from "moment";

const MasterBusinessType = () => {
  const yupValidation = Yup.object().shape({
    name: Yup.string().required("Please enter name"),

    status: Yup.string().required("Please enter status"),
  });

  const formOptions = { resolver: yupResolver(yupValidation) };
  const { register, handleSubmit, reset, setValue, formState } = useForm(
    formOptions
  );
  const [loading, setLoading] = useState(false);
  const [resss, setMessage] = useState("");
  const { errors } = formState;


  const [userList, setuserList] = useState([]);
 
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  
  const [user_id, setUserId] = useState("");
  const [type, setType] = useState("Add");

    // Add banner modal

    const [showAddModal, setshowAddModal] = useState(false);

    const showClauseForm = (id) => {
      setUserId(id);
      // setDeleteMessage(`Are you sure you want to delete the vegetable`);
      setshowAddModal(true);
    };
  
    // Hide the modal
    const hideAddModal = () => {
      setshowAddModal(false);
    };

    const [search, setsearch] = useState("");
  function getSearch (e) {
    setsearch(e.target.value);
  }

  function search_filter(){
    refreshPage()
    setsearch(search)
  }
  function reset_filter(){
    refreshPageData()
    setsearch("")
  }

  const refreshPageData = () => {
    setLoading(true)
    http.get(`get_business_type`)
     
      .then((res) => {
        console.log(res);
        setuserList(res.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const refreshPage = () => {
    setLoading(true)
    http.get(`get_business_type?name=${search}`)
     
      .then((res) => {
        console.log(res);
        setuserList(res.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    refreshPage();
  }, []);

// Edit banner modal
const [showEditModal, setshowEditModal] = useState(false);
const showEditForm = (id) => {
  setUserId(id);
console.log(id);
  // setDeleteMessage(`Are you sure you want to delete the vegetable`);
  setshowEditModal(true);
};

// Hide the modal
const hideEditModal = () => {
  setshowEditModal(false);
};

const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
  false
);
const [deleteMessage, setDeleteMessage] = useState(null);

const showDeleteModal = (type, id) => {
  setId(id);
  console.log(id);
  setDeleteMessage(`Are you sure you want to delete Business Type ?`);
  setDisplayConfirmationModal(true);
};

// Hide the modal
const hideConfirmationModal = () => {
  setDisplayConfirmationModal(false);
};

  const submitDelete = () => {
    console.log(id);
    http
      .delete(`delete_business_type/${id}`)
      .then((response) => {
        // console.log('User has been deleted');

        refreshPage();
      })
      .catch((error) => {
        console.log("error delete");
        if (error) {
          console.log(error);
        }
      });
  };

  

  return (
    <>
      {/* <Header />
            <SideBar /> */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <h4 className="card-title mt-2">Business Type List</h4>
                      <button
                            type="button"
                            className="btn btn-primary add-btn"
                            onClick={() => showClauseForm()}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add Business Type
                          </button>
                    </div>
                    <div className="card-body">
                      <div className="row g-4 mb-3">
                        <div className="col-md-12 d-flex justify-content-between">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              value={search}
                              onChange={e => getSearch(e)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                          <div className="col-md-1 d-flex align-items-center justify-content-end">
                          <i
                            class="bx bx-search text-primary fs-2"
                            style={{ cursor: "pointer" }}
                            onClick={search_filter}
                          ></i>
                          <i
                            class="bx bx-reset text-primary fs-2 ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={reset_filter}
                          ></i>
                        </div>
                        </div>
                      </div>
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table className="table align-middle table-nowrap mb-0 table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th data-sort="customer_name" style={{width:"5%"}}>#</th>
                              <th data-sort="customer_name">Name</th>
                              <th data-sort="status" style={{width:"20%" , textAlign:"center"}}>Status</th>
                              <th data-sort="customer_name" >Indate</th>
                              <th data-sort="customer_name" >Created by</th>
                              <th data-sort="action" style={{width:"25%" , textAlign:"center"}}>Action</th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {userList.map((user, index) => (
                              <tr key={index}>
                                <td className="customer_name">{index + 1}</td>
                                <td className="email">{user.name}</td>
                                <td className="status text-center">
                                  <span
                                    className={`badge text-uppercase ${
                                      user.status == 1
                                      ? "bg-success-subtle text-success"
                                      : "bg-danger-subtle text-danger"
                                    }`}
                                  >
                                    {user.status == 1 ? "Active" : "In-active"}
                                  </span>
                                </td>
                                <td className="email">{moment(user.indate)
                                        .utc()
                                        .format("DD/MM/YYYY")}</td>
                                        <td className="email">{user.created_by}</td>
                                <td>
                                  <div className="d-flex gap-2 justify-content-center">
                                    <div className="edit">
                                      <Link
                                        onClick={() => {
                                          showEditForm(user.id);
                                          
                                        }}
                                        className=" ri-edit-2-fill fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        to="/master/add-business-type?id=46"
                                      ></Link>
                                    </div>
                                    <div className="remove">
                                      <Link
                                       onClick={() =>
                                        showDeleteModal("User", user.id)
                                      }
                                        className="ri-delete-bin-6-line fs-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteRecordModal"
                                      ></Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <AddMasterBusinessType
                  showModal={showAddModal}
                  hideModal={hideAddModal}
                  refreshPage={refreshPage}
                  type={type}
                  // id={user_id}
                />
                <EditMasterBusinessTypeModal
                  showModal={showEditModal}
                  hideModal={hideEditModal}
                  refreshPage={refreshPage}
                  type={type}
                  id={user_id}
                />
                <DeleteConfirmation
                  showModal={displayConfirmationModal}
                  confirmModal={submitDelete}
                  hideModal={hideConfirmationModal}
                  id={id}
                  message={deleteMessage}/>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default MasterBusinessType;
