import React, { useState, useEffect } from "react";
import { Link , useParams } from "react-router-dom";
import DeleteConfirmation from "../../../../shared/DeleteConfirmation";
import axios from "axios";
import Loader from "../../../../shared/Loader";
import toast, { Toaster } from "react-hot-toast";
import http from "../../../../../utils/http";

export const CreateOffer = () => {
  let {rfqId} = useParams()
  console.log(rfqId);
  const [type, setType] = useState("Add");
  const [id, setId] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [resss, setMessage] = useState("");
  const [showClauseModal, setshowClauseModal] = useState(false);
  const [error, setError] = useState(null);

  const [purchase, setPurchase] = useState([]);
  const [rfqItems, setrfqItems] = useState([]);


  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    setLoading(true);
    http
      .get(`get_rfq_by_id/${rfqId}`)
      .then((res) => {
        setPurchase(res.data);
        console.log(res.data.rfq_items);
        setrfqItems(res.data.rfq_items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [deleteMessage, setDeleteMessage] = useState(null);
  const showDeleteModal = (id) => {
    setId(id);
    console.log(id);
    setDeleteMessage(`Are you sure you want to delete SEO ?`);
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    http
      .delete(`delete_seo_by_id/${id}`)
      .then((response) => {
        toast.success("SEO deleted");
        setDeleteMessage("User has been deleted");
        fetchdata();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   const [error, setError] = useState(null);

  const showClauseForm = (id) => {
    setUserId(id);

    console.log(id);
    // setDeleteMessage(`Are you sure you want to delete the vegetable`);
    setshowClauseModal(true);
  };

  // Hide the modal
  const hideClauseModal = () => {
    setshowClauseModal(false);
  };

  const handleEdit = (idd) => {
    setType("Edit");
    setUserId(idd);
    setshowClauseModal(true);
  };

  // Loader Code
  const [loading, setLoading] = useState(false);
  // Loader code end

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Toaster position="top-center" />
            <Loader show={loading} />
            {loading === false && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex  justify-content-between ">
                        <h4 className="card-title mt-2">Create Offer List</h4>
                        {/* <Link
                          // onClick={() => showClauseForm()}
                          to="/seo/add-seo"
                          type="button"
                          className="btn btn-primary add-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          SEO
                        </Link> */}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="listjs-table" id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Enter name"
                                  // onChange={(e) => {
                                  //   searchList(e);
                                  // }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex align-items-center justify-content-end">
                            <i
                              class="bx bx-search text-primary fs-2"
                              style={{ cursor: "pointer" }}
                              // onClick={handleSearchButtonClick}
                            ></i>
                            <i
                              class="bx bx-reset text-primary fs-2 ms-2"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap table-bordered"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    />
                                  </div>
                                </th>
                                <th data-sort="customer_name" valign="top">
                                  No
                                </th>
                                <th
                                  data-sort="email"
                                  valign="top"
                                  style={{ width: "11%" }}
                                >
                                  Request No
                                </th>
                                <th data-sort="date" valign="top">
                                  Buyer Name
                                </th>
                                <th data-sort="status" valign="top">
                                  Part Number
                                </th>
                                <th data-sort="status" valign="top">
                                  Product Name
                                </th>
                                <th data-sort="status" valign="top">
                                  Brand
                                </th>
                                <th data-sort="status" valign="top">
                                  Pack Size
                                  <br />
                                  MOQ
                                  <br />
                                  UOM
                                </th>
                                <th data-sort="status" valign="top">
                                  QTY
                                </th>
                                <th data-sort="status" valign="top">
                                  Request Date
                                </th>
                                <th data-sort="status" valign="top">
                                  Status
                                </th>
                                <th data-sort="status" valign="top">
                                  Source of Inquiry
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {purchase.map((item, index) => (
                                <tr key={index}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>
                                  <td className="customer_name" valign="top">
                                    {index + 1}
                                  </td>
                                  <td className="email" valign="top">
                                    <Link to={`#`} className="text-wrap">
                                      R0018445
                                    </Link>
                                  </td>
                                  <td valign="top">
                                    Amit
                                    <br />
                                    Soft Systems India
                                    <br />
                                    amit@gmail.com
                                    <br />
                                    9999999999
                                    <br />
                                    Gautam Buddha Nagar, UP
                                  </td>
                                  <td className="text-wrap" valign="top">
                                    C06304POAV
                                  </td>
                                  <td className="text-wrap" valign="top">
                                    HPL 630A4P ON LOAD Changeover Switch
                                  </td>
                                  <td className="text-wrap" valign="top">
                                    HPL
                                  </td>
                                  <td className="email" valign="top">
                                    1<br />1
                                  </td>
                                  <td className="email" valign="top">
                                    1
                                  </td>
                                  <td className="email" valign="top">
                                    08/01/2024
                                  </td>
                                  <td className="email" valign="top">
                                    Pending
                                    <br />
                                    <Link to={"#"}> comments</Link>
                                  </td>
                                  <td valign="top">
                                    <div className="d-flex gap-2">quote</div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    id={id}
                    message={deleteMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
